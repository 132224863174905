<template>
    <el-container class="container">
        <el-aside class="contain_aside">
            <div class="logo">
                <img style="width: 0.8rem;background-size: cover;margin-bottom: .25rem;" :src="imgUrl" alt="">
            </div>
            <ul class="menu">
                <li v-for="(item, index) in menu" :key="index" :class="selected == index ? 'active' : ''"
                    @click="go(item.path, index)">
                    <i :class="item.icon"></i>
                    <p>{{ item.menuName }}</p>
                </li>
            </ul>
        </el-aside>
        <el-main class="main">
            <router-view></router-view>
        </el-main>
    </el-container>
</template>

<script>
import { getBasicConfiguration } from '../../api/systemOption/platformConfiguration/index'
export default {
    data: function () {
        return {
            selected: 0,
            screenWidth: document.body.clientWidth,
            screenHeight: document.body.clientHeight,
            imgUrl: ''
        }
    },
    computed: {
        menu() {
            return this.$store.state.user.roles
        }
    },
    watch: {
        screenWidth: {
            immediate: true,
            handler: function (newVal) {
                // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
                if (!this.timer) {
                    this.screenWidth = newVal;
                    this.timer = true;
                    let _this = this;
                    setTimeout(() => {
                        //在这里做有关浏览器变化时需要做的操作
                        _this.timer = false;
                    }, 400);
                }
                // if(newVal)
            }
        },
        screenHeight: {
            immediate: true,
            handler: function (newVal) {
                // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
                if (!this.timer) {
                    this.screenHeight = newVal;
                    this.timer = true;
                    let _this = this;
                    setTimeout(() => {
                        //在这里做有关浏览器变化时需要做的操作
                        _this.timer = false;
                    }, 400);
                }
                // if(newVal)
            }
        }
    },
    mounted() {
        const routeIndex = sessionStorage.getItem('routeIndex')
        if (routeIndex != null) {
            this.selected = routeIndex
        }
        this.setRoute()
        window.addEventListener("orientationchange", () => {
            this.screenWidth = document.body.clientWidth;
            this.screenHeight = document.body.clientHeight;
            document.querySelector('.container').style.height = document.body.clientHeight + 'px';
            document.querySelector('.main').style.height = document.body.clientHeight + 'px';
        });
        // console.log(this.menu)
        this._getBasicConfiguration()
    },
    methods: {
        _getBasicConfiguration() {
            getBasicConfiguration().then(res => {
                if (res.state == '200') {
                    this.imgUrl = res.data.imageUrl
                }
            })
        },
        setRoute() {
            this.$store.dispatch('GetInfo')
            const currentPath = this.$route.path
            const path = currentPath.split('/')[1]
            console.log(this.menu, 2222);
            this.menu.forEach((item, index) => {
                if (item.path == ('/' + path)) {
                    this.selected = index
                }
            })
        },
        go(e, index) {
            this.selected = index
            sessionStorage.setItem('routeIndex', index)
            sessionStorage.removeItem('memberManageSelectedIndex')
            this.$router.push(e)

        }
    }
}
</script>

<style scoped>
.container {
    margin: 0;
    padding: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
}

.contain_aside {
    width: 1rem !important;
    height: 100%;
}

.logo {
    padding: 0.8rem 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo img {
    width: .75rem;
    height: .75rem;
    border-radius: 50%;
}

.el-aside {
    background-image: linear-gradient(to bottom, #7B63C7, #3BB6FE);
    border-radius: 0 60px 0 0;
}

.main {
    flex: 1;
    height: 100%;
}

.el-main {
    background-color: #fff;
    padding: 0 !important;
}

.menu li {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: .85rem;
    font-size: .1875rem;
    color: #fff;
    cursor: pointer;
    margin-right: 5px;
}

.menu li.active {
    background: #fff;
    border-radius: 0 8px 8px 0;
    color: #7B63C7;

}

.menu li i {
    font-size: .325rem;
}

.note {
    display: flex;
    align-items: center;
    font-size: .1875rem;
    color: #333;
}

.note i {
    font-size: .225rem;
    color: #ff6600;
    margin-right: .0375rem;
}
</style>
