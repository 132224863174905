import Vue from 'vue'

import Amms from '../views/amms/Amms.vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'amms',
    component: Amms,
    // beforeEnter: (to, form, next) => {
    //   console.log('to==', to);
    //   console.log('form==', form);
    //   if (null != sessionStorage.getItem('token')) {
    //     next('/workContent')
    //   } else {
    //     next('/login')
    //   }
    // },
    redirect: 'login',
    children: [
      {
        path: '/workContent',
        name: 'workContent',
        component: () => import('../views/workContent/workContent.vue'),
      },
      {
        path: '/memberConsume',
        name: 'memberConsume',
        redirect: 'memberConsume/valueStorageConsume',
        component: () => import('../views/memberConsume/MemberConsume.vue'),
        children: [
          {
            path: 'valueStorageConsume',
            name: 'valueStorageConsume',
            component: () => import('../views/memberConsume/components/ValueStorageConsume.vue')
          }, {
            path: 'projectConsume',
            name: 'projectConsume',
            component: () => import('../views/memberConsume/components/ProjectConsume.vue')
          }, {
            path: 'packageConsume',
            name: 'packageConsume',
            component: () => import('../views/memberConsume/components/PackageConsume.vue')
          }, {
            path: 'integralIncrease',
            name: 'integralIncrease',
            component: () => import('../views/memberConsume/components/IntegralIncrease.vue')
          }, {
            path: 'memberMall',
            name: 'memberMall',
            component: () => import('../views/memberConsume/components/MemberMall.vue')
          }, {
            path: 'redemptionDetails',
            name: 'redemptionDetails',
            component: () => import('../views/memberConsume/components/RedemptionDetails.vue')
          }
        ]
      },
      {
        path: '/memberDeposit',
        name: 'memberDeposit',
        redirect: 'memberDeposit/balanceRecharge',
        component: () => import('../views/memberDeposit/MemberDeposit.vue'),
        children: [
          {
            path: 'balanceRecharge',
            name: 'balanceRecharge',
            component: () => import('../views/memberDeposit/components/BalanceRecharge.vue')
          },
          {
            path: 'packageOpening',
            name: 'packageOpening',
            component: () => import('../views/memberDeposit/components/PackageOpening.vue')
          },
          {
            path: 'giftItems',
            name: 'giftItems',
            component: () => import('../views/memberDeposit/components/GiftItems.vue')
          }
        ]
      },
      {
        path: '/memberManage',
        name: 'memberManage',
        redirect: 'memberManage/memberList',
        component: () => import('../views/memberManage/MemberManage.vue'),
        children: [
          {
            path: 'memberList',
            name: 'memberList',
            component: () => import('../views/memberManage/components/MemberList.vue'),
          }, {
            path: 'pendingMembers',
            name: 'pendingMembers',
            component: () => import('../views/memberManage/components/PendingMembers.vue')
          }, {
            path: 'materialsImproved',
            name: 'materialsImproved',
            component: () => import('../views/memberManage/components/MaterialsImproved.vue')
          }, {
            path: 'membershipTransfer',
            name: 'membershipTransfer',
            component: () => import('../views/memberManage/components/MembershipTransfer.vue')
          }, {
            path: 'memberAssets',
            name: 'memberAssets',
            component: () => import('../views/memberManage/components/MemberAssets.vue')
          }, {
            path: 'membershipFreeze',
            name: 'membershipFreeze',
            component: () => import('../views/memberManage/components/MembershipFreeze.vue')
          }, {
            path: 'membershipUpgrade',
            name: 'membershipUpgrade',
            component: () => import('../views/memberManage/components/MembershipUpgrade.vue')
          }, {
            path: 'memberInfo',
            name: 'memberInfo',
            component: () => import('../views/memberManage/components/MemberInfo.vue'),
          }, {
            path: 'scrmMassSend',
            name: 'scrmMassSend',
            component: () => import('../views/memberManage/components/scrmMassSend.vue'),
          }, {
            path: 'carInsurance',
            name: 'carInsurance',
            component: () => import('../views/memberManage/components/carInsurance.vue'),
          }
        ]
      },
      {
        path: '/financialManage',
        name: 'financialManage',
        redirect: 'financialManage/selfFundedDetails',
        component: () => import('../views/financialManage/FinancialManage.vue'),
        children: [
          {
            path: 'selfFundedDetails',
            name: 'selfFundedDetails',
            component: () => import('../views/financialManage/components/SelfFundedDetails.vue')
          }, {
            path: 'salesDetails',
            name: 'salesDetails',
            component: () => import('../views/financialManage/components/SalesDetails.vue')
          }, {
            path: 'afterSalesDetails',
            name: 'afterSalesDetails',
            component: () => import('../views/financialManage/components/AfterSalesDetails.vue')
          }, {
            path: 'insuranceDetails',
            name: 'insuranceDetails',
            component: () => import('../views/financialManage/components/InsuranceDetails.vue')
          }, {
            path: 'packageDetails',
            name: 'packageDetails',
            component: () => import('../views/financialManage/components/PackageDetails.vue')
          }, {
            path: 'projectDetails',
            name: 'projectDetails',
            component: () => import('../views/financialManage/components/ProjectDetails.vue')
          }, {
            path: 'integralIncrease',
            name: 'integralIncrease',
            component: () => import('../views/financialManage/components/IntegralIncrease.vue')
          },
          {
            path: 'packageSummary',
            name: 'packageSummary',
            component: () => import('../views/financialManage/components/PackageSummary.vue')
          }, {
            path: 'gitGivedSummary',
            name: 'gitGivedSummary',
            component: () => import('../views/financialManage/components/GitGivedSummary.vue')
          }, {
            path: 'cardInfoForm',
            name: 'cardInfoForm',
            component: () => import('../views/financialManage/components/CardInfoForm.vue')
          }, {
            path: 'MaintenanceAndStatistics',
            name: 'MaintenanceAndStatistics',
            component: () => import('../views/financialManage/components/MaintenanceAndStatistics.vue')
          }, {
            path: 'cardInfoTable',
            name: 'cardInfoTable',
            component: () => import('../views/financialManage/components/CardInfoTable.vue')
          }, {
            path: 'memberCardSummary',
            name: 'memberCardSummary',
            component: () => import('../views/financialManage/components/MemberCardSummary.vue')
          }, {
            path: 'memberCardBalanceStatistics',
            name: 'memberCardBalanceStatistics',
            component: () => import('../views/financialManage/components/MemberCardBalanceStatistics.vue')
          }, {
            path: 'freezeOperationQuery',
            name: 'freezeOperationQuery',
            component: () => import('../views/financialManage/components/FreezeOperationQuery.vue')
          }, {
            path: 'memberUpgradeInfoTable',
            name: 'memberUpgradeInfoTable',
            component: () => import('../views/financialManage/components/MemberUpgradeInfoTable.vue')
          }, {
            path: 'crossStoreConsumption',
            name: 'crossStoreConsumption',
            component: () => import('../views/financialManage/components/CrossStoreConsumption.vue')
          },
        ]
      },
      {
        path: '/systemOption',
        name: 'systemOption',
        redirect: 'systemOption/systemSettings',
        component: () => import('../views/systemOption/SystemOption.vue'),
        children: [
          {
            path: 'systemSettings',
            name: 'systemSettings',
            component: () => import('../views/systemOption/components/SystemSettings.vue')
          },
          {
            path: 'platformConfiguration',
            name: 'platformConfiguration',
            component: () => import('../views/systemOption/components/PlatformConfiguration.vue')
          },
          {
            path: 'adminSettings',
            name: 'adminSettings',
            component: () => import('../views/systemOption/components/AdminSettings.vue')
          },
          {
            path: 'storeSettings',
            name: 'storeSettings',
            component: () => import('../views/systemOption/components/StoreSettings.vue')
          },
          {
            path: 'officialAccountSettings',
            name: 'officialAccountSettings',
            component: () => import('../views/systemOption/components/OfficialAccountSettings.vue')
          }
        ]
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/Login.vue'),
  }
]

// 获取原型对象push函数
const originalPush = VueRouter.prototype.push

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace

// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}


const router = new VueRouter({
  routes
})

export default router
