<template>
    <div class="memberConsumContainer">
        <div class="memberConsumAside">
            <MemberManageAside />
        </div>
        <div class="memberConsumMain">
            <div class="systemOptionContent">
                <div class="so_content">
                    <router-view></router-view>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import MemberManageAside from '../components/MemberManageAside.vue'
export default {
    name: 'memberconsume',
    components: { MemberManageAside },
    data() {
        return {
        }
    },
}
</script>
<style scoped>
.memberConsumContainer {
    display: flex;
    background-color: rgb(247, 248, 250);
    height: 100%;
    width: 100%;
}

.memberConsumAside {
    height: 100%;
}

.memberConsumMain {
    /* width: 100%; */
    display: flex;
    flex-direction: column;
    /* margin-left: 10px; */
    flex: 1;
    height: auto;
}

.systemOptionContent {
    width: 100%;
    flex: 1;
    background-color: white;
    display: flex;

}


.so_content {
    /* flex: 1; */
    /* width: 80%; */
    width: 100%;
}
</style>