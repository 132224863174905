<template>
    <div class="po_container">
        <div>
            <el-menu :default-active="po_defaultIndex" class="el-menu-demo" mode="horizontal" @select="po_handleSelect">
                <el-menu-item index="po_tag1">套餐开卡</el-menu-item>
            </el-menu>
        </div>
        <div class="br_main">
            <div class="search">
                <el-form size="small" :inline="true" class="form">
                    <el-form-item label="手机号:">
                        <el-input ref="teleRef" v-model="telephone" placeholder="输入手机号查询" size="small"></el-input>
                    </el-form-item>
                </el-form>
                <el-button type="primary" size="small" @click="search">搜索</el-button>
            </div>
            <div class="member_info">
                <el-descriptions class="des margin-top" :column="3" size="mini" border :contentStyle="content_style">
                    <template slot="title">
                        <span class="title">会员基本信息</span>
                    </template>
                    <el-descriptions-item label="手机号" :span="1">
                        <span class="dg">{{ memberInfo.telephone }}</span>
                    </el-descriptions-item>
                    <el-descriptions-item label="会员卡级别" :span="1">
                        <span class="dg">{{ memberInfo.vipLevelName ? memberInfo.vipLevelName : '普通用户' }}</span>
                    </el-descriptions-item>
                    <el-descriptions-item label="客户自费" :span="1">
                        <CommonValueStyle :value="memberInfo.selfBalance ? memberInfo.selfBalance : 0" dw="元" />
                    </el-descriptions-item>
                    <el-descriptions-item label="姓名" :span="1">
                        <span class="dg">{{ memberInfo.name }}</span>
                    </el-descriptions-item>
                    <el-descriptions-item label="车牌号码" :span="1">
                        <template slot="label">
                            <span style="color: red;">*</span>车牌号码
                        </template>
                        <el-select class="dg" placeholder="请选择" v-model="carType" size="small" @change="carChange">
                            <el-option v-for="(item, index) in memberInfo.carTypeList" :key="index" :label="item.carNum"
                                :value="item.carNum"></el-option>
                        </el-select>
                    </el-descriptions-item>
                    <el-descriptions-item label="销售赠送" :span="1">
                        <CommonValueStyle :value="memberInfo.salesBalance ? memberInfo.salesBalance : 0" dw="元" />
                    </el-descriptions-item>
                    <el-descriptions-item label="身份证号" :span="1">
                        <span class="dg">{{ memberInfo.idCard }}</span>
                    </el-descriptions-item>
                    <el-descriptions-item label="车型" :span="1">
                        <span class="dg">{{ carNum }}</span>
                    </el-descriptions-item>
                    <el-descriptions-item label="售后赠送" :span="1">
                        <CommonValueStyle :value="memberInfo.afterBalance ? memberInfo.afterBalance : 0" dw="元" />
                    </el-descriptions-item>
                    <el-descriptions-item label="介绍人" :span="1">
                        <span class="dg">{{ memberInfo.sellerId }}</span>
                    </el-descriptions-item>
                    <el-descriptions-item label="发卡门店" :span="1">
                        <span class="dg">{{ memberInfo.storeDO && memberInfo.storeDO.name ? memberInfo.storeDO.name : ''
                        }}</span>
                    </el-descriptions-item>
                    <el-descriptions-item label="保险赠送" :span="1">
                        <CommonValueStyle :value="memberInfo.insuranceBalance ? memberInfo.insuranceBalance : 0" dw="元" />
                    </el-descriptions-item>
                    <el-descriptions-item label="备注" :span="2">
                        <el-input class="dg" size="small" v-model="remark"></el-input>
                    </el-descriptions-item>
                    <el-descriptions-item label="可用积分" :span="1">
                        <CommonValueStyle :value="memberInfo.credit ? memberInfo.credit : 0" dw="分" />
                    </el-descriptions-item>
                </el-descriptions>
            </div>
            <el-divider content-position="left" class="po_title">选择套餐类型</el-divider>
            <div class="member_footer">
                <div class="chosePackage">
                    <el-descriptions class="des margin-top" :column="4" size="mini" border :contentStyle="content_style1"
                        :colon="true">
                        <el-descriptions-item label="选择套餐" :span="1">
                            <el-select class="dg" placeholder="请选择" v-model="taocanId" size="small" @change="tcChange">
                                <el-option v-for="(item, index) in seviceList" :key="index" :label="item.name"
                                    :value="item.id"></el-option>
                            </el-select>
                        </el-descriptions-item>
                        <el-descriptions-item label="套餐类型" :span="1">
                            <el-select class="dg" placeholder="请选择" v-model="type" size="small">
                                <el-option label="赠送" :value="1"></el-option>
                                <el-option label="购买" :value="0"></el-option>
                            </el-select>
                        </el-descriptions-item>
                        <el-descriptions-item label="套餐价值" :span="1">
                            <span class="dg">{{ price }}</span>
                        </el-descriptions-item>
                        <el-descriptions-item label="有效期(月)" :span="2">
                            <el-input class="dg" size="small" v-model="termMonth" type="number"
                                placeholder="输入有效期"></el-input>
                        </el-descriptions-item>
                    </el-descriptions>
                </div>
                <div class="foot_table">
                    <span class="table_title">当前选择套餐对应服务项目明细</span>
                    <div class="tableStyle">
                        <el-table :data="itemList" style="width: 100%" :stripe="true" size="mini"
                            header-cell-class-name="headerClassName" :cell-style="{ 'text-align': 'center' }" :border="true"
                            :fit="true" height="400">
                            <el-table-column prop="id" label="序号">
                                <template slot-scope="scope">
                                    <span>{{ scope.$index + 1 }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="packageName" label="所属套餐">
                            </el-table-column>
                            <el-table-column prop="itemName" label="服务项目">
                            </el-table-column>
                            <el-table-column prop="num" label="服务次数">
                            </el-table-column>
                            <el-table-column prop="itemPrice" label="项目单价">
                            </el-table-column>
                            <el-table-column prop="total" label="合计价格">
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <div class="btn">
                    <el-button type="primary" @click="save()" size="small" :disabled="isDj">保存</el-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import PubSub from 'pubsub-js'
import CommonValueStyle from '../../components/CommonValueStyle.vue'
import { getUserInfo, getServiceItem, getServicePackItem, addPackage } from '@/api/memberDeposit';
export default {
    data() {
        return {
            activeName: 'first',
            input: '',
            balance: 650,
            value: '',
            po_defaultIndex: 'po_tag1',
            po_activeIndex: 'po_tag1',
            content_style: {
                'width': '25%'
            },
            remark: '',
            content_style1: {
                'width': '15%'
            },
            // label_style: {
            //     'width': '1.3rem',
            //     'justify-content': 'end'
            // },
            memberInfo: {},
            telephone: '',
            carNum: '',
            seviceList: [],
            itemList: [],
            carType: '',
            taocanId: '',
            termMonth: '',
            price: '',
            type: '',
            isDj: false,
            tcId: ''
        }
    },
    components: { CommonValueStyle },
    mounted() {
        this.$refs.teleRef.focus()
        this.getCommen()
    },
    methods: {
        po_handleSelect(key) {
            this.po_activeIndex = key
            PubSub.publish('activeTag', key)
        },
        search() {
            this.$emit('childChange', this.telephone)
            let that = this
            setTimeout(() => {
                getUserInfo({ telephone: that.telephone }).then(res => {
                    if (res.data.actived == 4) {
                        this.$message.info('该会员已冻结')
                        this.isDj = true
                    }
                    that.memberInfo = res.data
                })
            }, 1000);

        },
        carChange(e) {
            this.memberInfo.carTypeList.forEach(item => {
                if (item.carNum == e) {
                    this.carNum = item.model
                }
            })
        },
        tcChange(e) {
            this.seviceList.forEach(item => {
                if (item.id == e) {
                    this.tcId = item.id
                    this.term = item.term
                    this.price = item.price
                    getServicePackItem({ id: item.id }).then(res => {
                        this.itemList = res.data
                    })
                }
            })
        },
        getCommen() {
            getServiceItem({}).then(res => {
                this.seviceList = res.data
            })
        },
        save() {
            // if (this.itemList.length == 0) {
            //     this.$message.error('请选择套餐')
            //     return
            // }
            if (this.tcId == '') {
                this.$message.error('请选择套餐')
                return
            }
            if (this.type.toString() == '') {
                this.$message.error('请先选择套餐类型')
                return
            }
            if (this.telephone == '') {
                this.$message.error('请输入手机号查询')
                return
            }
            if (this.carNum == '') {
                this.$message.error('请先选择车牌号码')
                return
            }

            addPackage({ telephone: this.telephone, userPackageId: this.taocanId, term: this.termMonth, type: this.type, carNum: this.carType, remark: this.remark }).then(res => {
                if (res.state == '200') {
                    this.$message.success('保存成功')
                    this.itemList = []
                    this.remark = ''
                }
            })
        }
    }
}
</script>
<style scoped>
::v-deep .el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
    margin-bottom: 0rem !important;
}

::v-deep .el-descriptions__header {
    margin-bottom: 0rem;
}

.search {
    /* width: 40%; */
    border: none !important;
    display: flex;
}

.tag_container {
    padding: 0 .25rem;
}

.member_info .title {
    margin-top: .25rem;
    display: inline-block;
    font-size: medium;
    font-weight: bold;
    color: rgb(79, 79, 79);
}

.member_info .el-descriptions__header {
    margin: .125rem 0 .0625rem;
}

.br_main {
    background-color: #fff;
    width: 100%;
    padding: .125rem .25rem;
    flex: 1;
    display: flex;
    flex-direction: column;
}


.br_main .po_title {
    background-color: rgb(152, 219, 255);

}

.br_main .el-divider__text {
    color: rgb(24, 168, 255);
    font-size: large;
    font-weight: bold;
    left: 0;
}

.member_footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
}

.chosePackage {
    width: 100%;
    height: .75rem;
}

.foot_table {
    margin: .25rem 0;
    width: 100%;
}

.foot_table .tableStyle {
    /* padding: .125rem 0; */
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 2.8rem;

}

.foot_table .tableStyle .el-pagination {
    align-self: flex-start;
    margin: .125rem 0;
}

/*設置表头背景以及字体顏色*/
.foot_table .headerClassName {
    font-weight: bold;
    color: black;
    background-color: rgb(226, 226, 226) !important;
    text-align: center !important;
}

.foot_table .el-table__row--striped {
    background-color: rgb(242, 242, 242) !important;

}

.table_title {
    font-size: medium;
    color: rgb(105, 102, 102);
    font-weight: bold;
}
</style>