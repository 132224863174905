<template>
	<div class="adminSettingContainer">
		<div class="adminSettingNav">
			<el-menu :default-active="admin_defaultIndex" class="el-menu-demo" mode="horizontal"
				@select="admin_handleSelect">
				<el-menu-item index="admin_tag1">管理员配置</el-menu-item>
				<el-menu-item index="admin_tag2">角色配置</el-menu-item>
			</el-menu>
		</div>
		<div class="adminSettingMain" v-if="admin_activeIndex === 'admin_tag1'">
			<el-button type="primary" @click="openAddAdminDialog" size="small">添加管理员</el-button>
			<div class="tableStyle">
				<el-table :data="table1.tableData" style="width: 100%" :stripe="true" size="mini"
					header-cell-class-name="headerClassName" :cell-style="{ 'text-align': 'center' }" :border="true"
					:fit="true">
					<el-table-column prop="id" label="编号">
						<template slot-scope="scope">
							<span>{{ scope.$index + 1 }}</span>
						</template>
					</el-table-column>
					<el-table-column prop="login" label="账号">
					</el-table-column>
					<el-table-column prop="typeName" label="所属类型">
					</el-table-column>
					<el-table-column prop="role" label="所属角色">
					</el-table-column>
					<!-- <el-table-column prop="store" label="所属门店">
					</el-table-column> -->
					<el-table-column prop="storeDepartment" label="所属部门">
					</el-table-column>
					<el-table-column prop="materialDiscount" label="材料折扣区间">
					</el-table-column>
					<el-table-column prop="manHourDiscount" label="工时折扣区间">
					</el-table-column>
					<el-table-column prop="createLogin" label="创始人">
					</el-table-column>
					<el-table-column prop="discountAmount" label="最大优惠金额">
					</el-table-column>
					<el-table-column label="双重优惠">
						<template slot-scope="scope">
							<span v-if="scope.row.discountDeductionStatus == 1" style="color: green;">开启</span>
							<span v-else style="color: red;">关闭</span>
						</template>
					</el-table-column>
					<el-table-column prop="createTime" label="创建时间">
					</el-table-column>
					<el-table-column label="操作">
						<template slot-scope="scope">
							<el-button @click="edit1(scope.row)" type="text" size="small">编辑</el-button>
							<el-button @click="del1(scope.row)" type="text" size="small">刪除</el-button>
						</template>
					</el-table-column>
				</el-table>
				<el-pagination background layout="prev, pager, next" :total="table1.total" :page-size="table1.pageSize"
					@current-change="changePage1" :current-page.sync="table1.page">
				</el-pagination>
			</div>
		</div>
		<div class="adminSettingMain" v-if="admin_activeIndex === 'admin_tag2'">
			<template v-if="!add">
				<el-button type="primary" @click="addRole" size="small">添加角色</el-button>
				<div class="tableStyle">
					<el-table :data="table2.tableData" style="width: 100%" :stripe="true" size="mini"
						header-cell-class-name="headerClassName" :cell-style="{ 'text-align': 'center' }" :border="true"
						:fit="true">
						<el-table-column prop="id" label="编号">
							<template slot-scope="scope">
								<span>{{ scope.$index + 1 }}</span>
							</template>
						</el-table-column>
						<el-table-column prop="name" label="权限组名称">
						</el-table-column>
						<el-table-column prop="createLogin" label="创始人">
						</el-table-column>
						<el-table-column prop="createTime" label="创建时间">
						</el-table-column>
						<el-table-column prop="remark" label="备注">
						</el-table-column>
						<el-table-column label="操作">
							<template slot-scope="scope">
								<el-button @click="edit2(scope.row)" type="text" size="small">编辑</el-button>
								<el-button @click="del2(scope.row)" type="text" size="small">刪除</el-button>
							</template>
						</el-table-column>
					</el-table>
					<el-pagination background layout="prev, pager, next" :total="table2.total" :page-size="table2.pageSize"
						@current-change="changePage2" :current-page.sync="table2.page">
					</el-pagination>
				</div>
			</template>
			<template v-if="add">
				<el-form ref="roleForm" :model="role" label-width="2rem" size="mini" :rules="rules">
					<el-form-item label="角色名称：" prop="roleName">
						<el-input style="width: 30%;" placeholder="请输入角色名称" v-model="role.roleName" clearable>
						</el-input>
					</el-form-item>
					<el-form-item label="类型：" prop="roleType">
						<el-radio-group v-model="role.roleType">
							<el-radio :label="item.id" v-for="(item, index) in typeList" :key="index">{{ item.name
							}}</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="权限设置：" prop="quanxian">
						<!-- <el-checkbox-group v-model="role.quanxian" style="width: 60%;">
							<el-checkbox :label="item.id" :key="item.id"
								v-for="item in menuList">{{item.menuName}}</el-checkbox>
						</el-checkbox-group> -->
						<el-tree ref="tree" :check-strictly="true" :data="menuList" show-checkbox node-key="id"
							:default-checked-keys="selectArr" :props="defaultProps" @check-change="handleCheckChange">
						</el-tree>
					</el-form-item>
					<el-form-item label="备注：" prop="remark">
						<el-input style="width: 30%;" type="textarea" autosize placeholder="请输入内容" v-model="role.remark">
						</el-input>
					</el-form-item>
				</el-form>
				<div style="display: flex;justify-content: center;width: 60%;">
					<el-button size="small" type="primary" @click="saveRole()">{{ edit ? '修改' : '添加' }}</el-button>
					<el-button size="small" type="primary" @click="fanhui()">返回</el-button>
				</div>
			</template>
		</div>
		<AddAdminModal ref="addAdminModal" :initial-message="model1" @child-event="reLoadTable1" />
	</div>
</template>
<script>
import AddAdminModal from '../adminSettingModal/AddAdminModal.vue'
import {
	delUser,
	getRoleList,
	userList,
	getMenuList,
	getType,
	addRole,
	delRole,
	editRoleInfo,
	updateRole,

} from '@/api/login'
export default {
	components: {
		AddAdminModal,
		// AddRoleModal
	},
	data() {
		return {
			edit: false,
			rowId: '',
			admin_defaultIndex: 'admin_tag1',
			admin_activeIndex: 'admin_tag1',
			model1: {},
			add: false,
			role: {
				roleType: '1',
				quanxian: [],
				remark: '',
				roleName: '',
				treeId: []
			},
			table1: {
				list: [],
				tableData: [],
				page: 1,
				total: 0,
				pageSize: 10,
			},
			table2: {
				list: [],
				tableData: [],
				page: 1,
				total: 0,
				pageSize: 10
			},
			menuList: [],
			selectArr: [],
			typeList: [],
			rules: {
				roleName: [{
					required: true,
					message: '请输入角色名称',
					trigger: 'blur'
				}],
				roleType: [{
					required: true,
					message: '请输入角色类型',
					trigger: 'blur'
				}],
				quanxian: [{
					required: true,
					message: '请选择权限',
					trigger: 'blur'
				}]
			},
			defaultProps: {
				children: 'children',
				label: 'menuName'
			}

		}
	},
	mounted() {
		this.getAdmin()

	},
	methods: {
		handleCheckChange(data, checked, indeterminate) {
			this.role.quanxian = [...this.$refs.tree.getCheckedKeys(), ...this.$refs.tree.getHalfCheckedKeys()]
			this.role.treeId = this.$refs.tree.getCheckedKeys()
			console.log(this.role.quanxian)
		},
		fanhui() {
			this.$refs.roleForm.resetFields();
			this.add = false
			this.getRole()
		},
		admin_handleSelect(key, keyPath) {
			this.admin_activeIndex = key
			this.add = false
			if (key === 'admin_tag2') {
				this.role = {
					roleType: '1',
					quanxian: [],
					remark: '',
					roleName: ''
				}
				this.getRole()
			} else {
				this.getAdmin()
			}
		},
		openAddAdminDialog() {
			this.$refs.addAdminModal.openDialog({
				edit: false
			})
		},
		addRole() {
			this.edit = false
			this.goAdd()
		},
		getAdmin(page) {
			//获取管理员列表list
			userList({
				pageSize: this.table1.pageSize,
				page: this.table1.page
			}).then(res => {
				if (res.state === '200') {
					this.table1.total = res.data.total
					this.table1.list = res.data.records
					this.table1.tableData = this.getAdminList(res.data.records)
				}
			})
		},
		getAdminList(data) {
			return data.map(item => {
				return {
					id: item.id,
					login: item.login,
					typeName: item.typeName,
					role: item.adminRole.name || '',
					store: item.storeDO.name || '',
					storeDepartment: item.storeDepartmentDO.name || '',
					materialDiscount: item.materialDiscount,
					manHourDiscount: item.manHourDiscount,
					createLogin: item.createLogin,
					createTime: item.createTime,
					discountAmount: item.discountAmount,
					discountDeductionStatus: item.discountDeductionStatus
				}
			})
		},
		changePage1(e) {
			this.table1.page = e
			this.getAdmin()
		},
		changePage2(e) {
			this.table2.page = e
			this.getRole()
		},
		edit1(row) {
			const item = this.table1.list.find(item => item.id === row.id)
			this.$refs.addAdminModal.openDialog({
				edit: true,
				id: item.id,
				data: {
					login: item.login,
					pwd: item.pwd,
					roleType: item.adminRole.typeId,
					roleId: item.adminRole.id,
					storeId: item.storeId,
					storeDepartmentId: item.storeDepartmentId,
					materialDiscount: item.materialDiscount,
					manHourDiscount: item.materialDiscount,
					discountAmount: item.discountAmount,
					discountDeductionStatus: item.discountDeductionStatus
				}
			})
		},
		edit2(row) {
			this.edit = true
			this.rowId = row.id
			editRoleInfo({
				id: row.id
			}).then(res => {
				if (res.state === '200') {
					this.role = {
						roleType: res.data.typeName,
						quanxian: res.data.menuId,
						remark: row.remark,
						roleName: res.data.roleName,
						treeId: res.data.treeId
					}
					this.selectArr = res.data.menuId

					this.goAdd()
				}
			})


		},
		goAdd() {
			this.add = true
			//获取类型列表
			getType().then(res => {
				if (res.state === '200') {
					this.typeList = res.data
					// this.role.roleType = this.typeList[0]?.id || ''
				}
			})
			//获取权限列表
			getMenuList().then(res => {
				if (res.state === '200') {
					this.menuList = res.data
				}
			})

			this.$nextTick(() => {
				this.$refs.tree.setCheckedKeys([]);
				this.$refs.tree.setCheckedKeys(this.selectArr);

			})
		},
		del1(row) {
			this.delMsg(this).then(() => {
				this.delUser(row)
			}).catch(() => {
				this.$message({
					type: 'warn',
					message: '删除失败！'
				});
			});
		},
		del2(row) {
			this.delMsg(this).then(() => {
				this.delRole(row)
			}).catch(() => {
				this.$message({
					type: 'warn',
					message: '删除失败！'
				});
			});
		},
		delRole(row) {
			delRole({
				id: row.id
			}).then(res => {
				if (res.state === '200') {
					this.$message({
						message: '删除成功！',
						type: 'success'
					});
					this.table2.total--
					if (this.table2.page > this.table2.total / this.table2.pageSize && this.table2.total % this
						.table2.pageSize === 0) {
						this.table2.page--
					}
					this.getRole()

				}
			})
		},
		delUser(row) {
			delUser({
				id: row.id
			}).then(res => {
				if (res.state === '200') {
					this.$message({
						message: '删除成功！',
						type: 'success'
					});
					this.table1.total--
					if (this.table1.page > this.table1.total / this.table1.pageSize && this.table1.total % this
						.table1.pageSize === 0) {
						this.table1.page--
					}
					this.getAdmin()

				}
			})
		},
		reLoadTable1() {
			this.getAdmin()
		},
		getRole() {
			getRoleList({
				pageSize: this.table2.pageSize,
				page: this.table2.page
			}).then(res => {
				if (res.state === '200') {
					this.table2.total = res.data.total
					this.table2.list = res.data.records
					this.table2.tableData = res.data.records
				}
			})
		},
		saveRole() {
			this.$refs.roleForm.validate(valid => {
				if (valid) {
					if (this.edit && this.rowId) {
						//编辑
						updateRole({
							roleName: this.role.roleName,
							roleType: this.role.roleType,
							menuId: this.role.quanxian,
							remark: this.role.remark,
							id: this.rowId,
							treeId: this.role.treeId
						}).then(res => {
							if (res.state === '200') {
								this.$refs.roleForm.resetFields();
								this.rowId = ''
								this.role = {
									roleType: '1',
									quanxian: [],
									remark: '',
									roleName: ''
								}
								this.fanhui()
								this.$message({
									message: '修改成功！',
									type: 'success'
								});


							}
						})
					} else {
						//添加
						addRole({
							roleName: this.role.roleName,
							roleType: this.role.roleType,
							remark: this.role.remark,
							menuId: this.role.quanxian,
							treeId: this.role.treeId
						}).then(res => {
							if (res.state === '200') {
								this.$refs.roleForm.resetFields();
								this.role = {
									roleType: '1',
									quanxian: [],
									remark: '',
									roleName: ''
								}
								this.$message({
									message: '添加成功！',
									type: 'success'
								});
								this.fanhui()
							}
						})
					}
				}
			})

		}
	},

}
</script>
<style>
.adminSettingContainer {
	width: 100%;
	flex: 1;
	display: flex;
	flex-direction: column;
}

.adminSettingMain {
	background-color: #fff;
	width: 100%;
	padding: .125rem .25rem;
	flex: 1;
	margin-top: .25rem;
}

.tableStyle {
	padding: .125rem 0;
	display: flex;
	flex-direction: column;
	width: 80%;
}

.tableStyle .el-pagination {
	align-self: flex-start;
	margin: .125rem 0;
}

/*設置表头背景以及字体顏色*/
.headerClassName {
	font-weight: bold;
	color: black;
	background-color: rgb(226, 226, 226) !important;
	text-align: center !important;
}

.el-table__row--striped {
	background-color: rgb(242, 242, 242) !important;

}
</style>