<template>
    <div>
        <el-dialog custom-class="addCard" :visible.sync="dialogVisible" :modal="true" :append-to-body="true"
            :close-on-click-modal="false" @close="closeDialog()" width="25vw" top="20vh">
            <el-form ref="form" :model="form" label-width="100px" size="mini" :rules="rules">
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label="车辆品牌" prop="brandId">
                            <el-select v-model="form.brandId" placeholder="请选择" @change="changeBrand">
                                <el-option v-for="item in brandData" :key="item.id" :label="item.brand" :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label="车辆系列" prop="seriesId" @click="changeSeries">
                            <el-select v-model="form.seriesId" placeholder="请选择">
                                <el-option v-for="item in seriesList" :key="item.id" :label="item.name" :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label="车辆型号" prop="model">
                            <el-input v-model="form.model"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="title" class="dialog-title">
                <span><i class="el-icon-warning"></i>{{ edit ? '编辑车型' : '添加车型' }}</span>
            </span>
            <span slot="footer" class="dialog-footer">
                <div slot="footer" class="dialog-footer">
                    <el-button size="small" @click="closeDialog()">取 消</el-button>
                    <el-button size="small" type="primary" @click="save()">确 定</el-button>
                </div>
            </span>
        </el-dialog>
    </div>
</template>  
    
<script>
import { brandList, addCar, updateCar, carSeries } from '@/api/systemOption/systemSettings';

export default {
    data() {
        return {
            seriesList: [],
            brandData: [],
            edit: false,
            dialogVisible: false,
            form: {
                brandId: '',
                seriesId: '',
                model: '',
            },
            rules: {
                brandId: [{ required: true, message: '请选择车辆品牌', trigger: 'blur' }],
                seriesId: [{ required: true, message: '请选择车辆系列', trigger: 'blur' }],
                model: [{ required: true, message: '请输入车辆型号', trigger: 'blur' }]
            }
        };
    },
    methods: {
        resetForm() {
            this.form = {
                brandId: '',
                seriesId: '',
                model: '',
            }
            this.dialogVisible = false
        },
        openDialog(data) {
            // 打开弹出框，并触发父组件的事件传递参数  
            this.dialogVisible = true;
            this.edit = false
            this.getBrandList()

            if (data) {
                this.form = data
                this.edit = true
                this.getSeriesData()
            }
        },
        getSeriesData() {
            carSeries({ brandId: this.form.brandId }).then(res => {
                if (res.state == '200') {
                    this.seriesList = res.data
                }
            })
        },
        changeSeries() {
            if (this.form.id) {
                this.form.modal = ''
            }

        },
        changeBrand() {
            this.getSeriesData()
            this.form.seriesId = ''
        },
        getBrandList() {
            brandList().then(res => {
                if (res.state == '200') {
                    this.brandData = res.data
                }
            })
        },
        closeDialog() {
            this.form = {
                brandId: '',
                seriesId: '',
                model: '',
            }
            this.dialogVisible = false
        },
        save() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    let data = {
                        brandId: this.form.brandId,
                        seriesId: this.form.seriesId,
                        model: this.form.model
                    }
                    if (this.form.id) {
                        updateCar({ ...data, id: this.form.id }).then(res => {
                            if (res.state === '200') {
                                this.$message({
                                    message: '修改成功！',
                                    type: 'success'
                                });
                                this.$emit('child-event')
                                this.closeDialog()
                            }
                        })
                    } else {
                        addCar(data).then(res => {
                            if (res.state == '200') {
                                this.$message({
                                    message: '添加成功！',
                                    type: 'success'
                                });
                                this.$emit('child-event')
                                this.closeDialog()
                            }
                        })
                    }

                }
            })
        }
    }
};
</script>
<style scoped>
/* ::v-deep .el-dialog.addCard {
    margin-top: 20vh !important;
    width: 25vw !important;
} */
</style>
  