<template>
    <div>
        <el-dialog custom-class="addDepartmentDialog" :visible.sync="dialogVisible" :modal="true" :append-to-body="true"
            :close-on-click-modal="false" @close="cancel()">
            <el-form ref="form" :model="form" label-width="1.5rem" size="mini" :rules="rules">
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label="所属门店：" prop="storeId">
                            <el-select placeholder="请选择" v-model="form.storeId">
                                <el-option :label="item.name" :value="item.id" :key="item.id" v-for="(item,index) in storeList"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label="部门名称：" prop="name">
                            <el-input placeholder="输入部门名称" v-model="form.name"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="title" class="dialog-title">
                <span>添加部门</span>
            </span>
            <span slot="footer" class="dialog-footer">
                <div slot="footer" class="dialog-footer">
                    <el-button @click="cancel()">取 消</el-button>
                    <el-button type="primary" @click="save()">确 定</el-button>
                </div>
            </span>
        </el-dialog>
    </div>
</template>  
    
<script>
import { addDepartment, updateDepartment,getStoreNoPage } from '@/api/systemOption/storeSettings';

export default {
    data() {
        return {
            dialogVisible: false,
            form: {
                name: '',
                storeId: ''
            },
            storeList:[],
            rules: {
                name: [{ required: true, message: '请输入部门名称', trigger: 'blur' }],
                storeId: [{ required: true, message: '请选择门店', trigger: 'blur' }],
            }
        };
    },
    methods: {
        openDialog(data) {
            // 打开弹出框，并触发父组件的事件传递参数  
            this.dialogVisible = true;
            this.edit = false
            if (data) {
                this.form = data
                this.edit = true
            }
            getStoreNoPage({}).then(res=>{
                if(res.state==='200'){
                    this.storeList = res.data
                }
            })
        },
        cancel() {
            // 关闭弹出框  
            this.dialogVisible = false;
            this.$refs.form?.resetFields()
        },
        save() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    if (this.edit) {
                        updateDepartment(this.form).then(res => {
                            if (res.state === '200') {
                                this.$message({
                                    message: '修改成功！',
                                    type: 'success'
                                });
                                this.$emit('child-event2')
                                this.dialogVisible = false
                            }
                        })
                    } else {
                        addDepartment(this.form).then(res => {
                            if (res.state === '200') {
                                this.$message({
                                    message: '添加成功！',
                                    type: 'success'
                                });
                                this.$emit('child-event2')
                                this.dialogVisible = false
                            }
                        })
                    }
                }
            })
        }
    }
};
</script>
<style>
.addDepartmentDialog {
    margin-top: 20vh !important;
    width: 25%;

}
</style>
  