<template>
  <el-container class="workcontentContainer">
    <el-header class="workcontent_header">
      <div class="headerStyle">
        <div class="note">
          <i class="el-icon-message-solid"></i>
          <span>欢迎{{ username }}登录，{{ merchantName }}</span>
        </div>
        <div class="header_right">
          <Exit />
        </div>
      </div>
    </el-header>
    <div class="workMain">
      <div class="customerLeft">
        <div class="flex-center">
          <el-form :model="formQuery" size="small" :inline="true" class="form" label-width="1rem">
            <el-form-item label="手机号:">
              <el-input ref="teleRef" v-model="formQuery.telephone" placeholder="输入手机号查询"></el-input>
            </el-form-item>
          </el-form>
          <el-button class="searchBtn" size="small" @click="_getuserInfo">搜索</el-button>
        </div>
        <div class="customInfo">
          <div class="des">
            <el-descriptions :column="5" size="mini" border :colon="true" labelClassName="my-label"
              :contentStyle="content_style">
              <!-- <template slot="title">
                <span style="font-size: small;font-weight: bold;color: rgb(79,79,79)">客户基本信息</span>
              </template> -->
              <template slot="title">
                <span class="title">客户基本信息</span>
              </template>
              <el-descriptions-item label="微信昵称">
                <span class="dg">{{ userInfo.wechatName ? userInfo.wechatName : '' }}</span>
              </el-descriptions-item>
              <el-descriptions-item label="客户姓名">
                <span class="dg"> {{ userInfo.name ? userInfo.name : '' }}</span>
              </el-descriptions-item>
              <el-descriptions-item label="身份证号">
                <span class="dg">{{ userInfo.idCard ? userInfo.idCard : '' }}</span>
              </el-descriptions-item>
              <el-descriptions-item label="性别">
                <p v-if="userInfo.gender && userInfo.gender == 2">女</p>
                <p v-if="userInfo.gender && userInfo.gender == 1">男</p>
              </el-descriptions-item>
              <el-descriptions-item label="账户余额">
                <CommonValueStyle :value="userInfo.selfBalance ? userInfo.selfBalance : 0" dw="元" />
              </el-descriptions-item>
              <el-descriptions-item label="手机号码">
                <span class="dg">{{ userInfo.telephone ? userInfo.telephone : '' }}</span>
              </el-descriptions-item>
              <el-descriptions-item label="会员生日">
                <span class="dg">{{ userInfo.birthday ? userInfo.birthday : '' }}</span>
              </el-descriptions-item>
              <el-descriptions-item label="介绍人">
                <span class="dg">{{ userInfo.sellerId ? userInfo.sellerId : '' }}</span>
              </el-descriptions-item>
              <el-descriptions-item label="发卡店">
                <span class="dg">{{ userInfo.storeDO && userInfo.storeDO.name ? userInfo.storeDO.name : '' }}</span>
              </el-descriptions-item>
              <el-descriptions-item label="可用积分">
                <CommonValueStyle :value="userInfo.credit ? userInfo.credit : 0" dw="分" />
              </el-descriptions-item>
              <el-descriptions-item label="会员级别">
                <span class="dg"> {{ userInfo.vipLevelName ? userInfo.vipLevelName : '普通用户' }}</span>
              </el-descriptions-item>
              <el-descriptions-item label="客户标签">
                <!-- <span class="dg">{{ userInfo.userLabelName ? userInfo.userLabelName : '' }}</span> -->
                <span class="dg">{{ userInfo.userLabelList ? getLabel(userInfo.userLabelList) : '' }}</span>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <span style="color: red;">*</span>车牌号码
                </template>
                <el-select class="dg" placeholder="请选择车牌号码" v-model="carNumber" size="small" @change="clientCarChange">
                  <el-option v-for="(item, index) in userInfo.clientCarDOList" :key="index" :label="item.carNum"
                    :value="item.carNum"></el-option>
                </el-select>
              </el-descriptions-item>
              <el-descriptions-item label="车辆品牌">
                <span class="dg">{{ brand }}</span>
              </el-descriptions-item>
              <el-descriptions-item label="持有套餐">
                <CommonValueStyle :value="userInfo.holdSetmeal ? userInfo.holdSetmeal : 0" dw="套" />
              </el-descriptions-item>
              <el-descriptions-item label="入会日期">
                <span class="dg">{{ userInfo.joiningTime ? userInfo.joiningTime : '' }}</span>
              </el-descriptions-item>
              <el-descriptions-item label="截止日期">
                <span class="dg">{{ userInfo.cutoffTime ? userInfo.cutoffTime : '' }}</span>
              </el-descriptions-item>
              <el-descriptions-item label="车架号" :span="1">
                <span class="dg">{{ carFrameNumber }}</span>
              </el-descriptions-item>
              <el-descriptions-item label="状态" :span="1">
                <span v-if="userInfo.actived == 1" style="color: green;">正常</span>
                <span v-if="userInfo.actived == 2" style="color: green;">待审核</span>
                <span v-if="userInfo.actived == 3" style="color: red;">驳回</span>
                <span v-if="userInfo.actived == 4" style="color: red;">冻结</span>
                <span v-else></span>
              </el-descriptions-item>
              <el-descriptions-item label="持有项目">
                <CommonValueStyle :value="userInfo.holdProject ? userInfo.holdProject : 0" dw="项" />
              </el-descriptions-item>
              <el-descriptions-item label="备注">
                <span class="dg">{{ userInfo.remarks }}</span>
              </el-descriptions-item>
            </el-descriptions>
          </div>
          <div class="record">
            <div class="recordTable">
              <div v-if="recordList.length == 0" style="width:100%;height:100%;">
                <el-empty description="搜索查看记录哦~"></el-empty>
              </div>
              <div class="recordItem" v-for="item in recordList">
                <div class="recordLeft">
                  <span class="recordTitle"> {{ item.name }}</span>
                  <span v-if="item.name == '套餐开卡记录' || item.name == '赠送项目充值记录'">共赠送{{ item.num }}次，累计{{
                    item.money }}元</span>
                  <span v-else-if="item.name == '套餐消费记录' || item.name == '赠送项目消费记录'">共消费{{ item.num }}次，累计{{
                    item.money }}元</span>
                  <span v-else>共充值{{ item.num }}次，累计{{ item.money }}元</span>
                </div>
                <div class="recordRight">
                  <el-button type="text" style="color: rgb(123,99,199); " @click="openRecordDialog(item)">点击相关记录
                  </el-button>
                  <el-button v-if="item.type2" type="text" style="color: rgb(123,99,199); "
                    @click="openRecordDialog(item, true)">{{ item.name2 }}
                  </el-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <RecordModal ref="recordModal" :type="type" :initial-message="parentMessage" @child-event="handleChildEvent" />
        </div>
      </div>
      <div class="customerRight">
        <div class="topTable table">
          <div class="tableInfo">
            <i class="el-icon-message-solid" style="font-size: 14px; color:rgb(165, 165, 27)"></i>
            <span class="reminderInfo">会员保险到期提醒</span>
          </div>
          <div class="table_self">
            <el-table :data="reminderList" border size="mini">
              <el-table-column prop="policyholder" label="姓名">
              </el-table-column>
              <el-table-column prop="telephone" label="手机号" width="120">
              </el-table-column>
              <el-table-column prop="insurance" label="保险公司">
                <template slot-scope="scope">
                  <el-tooltip class="item" effect="dark" :content="scope.row.insurance" placement="top-start">
                    <div class="ellipsis">{{ scope.row.insurance }}</div>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column prop="endTime" label="到期日期" width="120">
                <template slot-scope="scope">
                  <el-tooltip class="item" effect="dark" :content="scope.row.endTime" placement="top-start">
                    <div class="ellipsis">{{ scope.row.endTime }}</div>
                  </el-tooltip>
                </template>
              </el-table-column>
            </el-table>
            <div style="display: flex;justify-content: center">
              <el-pagination layout="prev, pager, next" @current-change="_getreminderList"
                :current-page.sync="reminderPage.page" :page-size="reminderPage.pageSize" :total="reminderTotal">
              </el-pagination>
            </div>

          </div>
          <!-- <div class="moreStyle">
            <a>更多...</a>
          </div> -->
        </div>
        <div class="bottomTable table">
          <div class="tableInfo">
            <i class="el-icon-message-solid" style="font-size: 14px; color:rgb(180, 52, 93)"></i>
            <span class="reminderInfo">会员生日提醒</span>
          </div>
          <div class="table_self">
            <el-table :data="birthdayList" border size="mini">
              <el-table-column prop="name" label="姓名">
              </el-table-column>
              <el-table-column prop="telephone" label="手机号">
              </el-table-column>
              <el-table-column prop="birthday" label="生日">
              </el-table-column>
            </el-table>
          </div>
          <div style="display: flex;justify-content: center">
            <el-pagination layout="prev, pager, next" @current-change="_getBirthdayList"
              :current-page.sync="birthdayPage.page" :page-size="birthdayPage.pageSize" :total="birthdayTotal">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </el-container>
</template>

<script>
import { getBasicConfiguration } from '../../api/systemOption/platformConfiguration/index'
import RecordModal from './recordModal/RecordModal.vue'
import CommonValueStyle from '../components/CommonValueStyle.vue'
import Exit from '../components/Exit.vue';
import { getUserInfo, getCarInsurance, getBirthdayRemind } from '../../api/workContent/index'
export default {
  components: { RecordModal, CommonValueStyle, Exit },
  data: function () {
    return {
      recordList: [],
      formQuery: {
        telephone: ''
      },
      activeName: 'first',
      formQuery: {},
      options: [{
        value: '0',
        label: '男'
      }, {
        value: '1',
        label: '女'
      }],
      value: '',
      balance: 260,
      state: '正常',
      custom: {},
      parentMessage: 'hello',
      type: 0,
      reminderList: [],
      reminderTotal: 0,
      reminderPage: {
        page: 1,
        pageSize: 10
      },
      birthdayList: [],
      birthdayTotal: 0,
      birthdayPage: {
        page: 1,
        pageSize: 10
      },
      userInfo: {},
      //车牌号品牌
      carNumber: '',
      brand: '',
      //车架号carFrameNumber
      carFrameNumber: '',
      content_style: {
        'width': '2rem'
      },
      username: 'xxx',
      merchantName: ''
    }
  },
  created() {
    this._getreminderList()
    this._getBirthdayList()
    this.username = sessionStorage.getItem('login')
    // this.formQuery.telephone  ='13784225658'
    this._getBasicConfiguration()
  },
  mounted() {
    this.$refs.teleRef.focus()
  },
  methods: {
    _getBasicConfiguration() {
      getBasicConfiguration().then(res => {
        if (res.state == '200') {
          this.merchantName = res.data.merchantName
        }
      })
    },
    getLabel(list) {
      let label = []
      if (list.length > 0) {
        list.forEach(item => {
          label.push(item.name)
        })
      }
      return label.join(",")
    },
    openRecordDialog(item, flag) {
      // 通过 $refs 访问子组件实例，并调用 openDialog 方法打开弹出框
      let data = item
      console.log('data==', data)
      if (flag) {
        data = { name: item.name2, type: item.type2 }
      }
      if (this.userInfo.idCard) {
        data.idCard = this.userInfo.idCard
      }
      if (this.userInfo.telephone) {
        data.telephone = this.userInfo.telephone
      }
      this.$refs.recordModal.openDialog(data)
    },
    handleChildEvent(childMessage) {
      // 处理子组件传递的事件和参数
      console.log('Received message from child:', childMessage)
      // 可以在这里执行其他逻辑或更新父组件的数据
    },
    //获取客户基本信息
    _getuserInfo() {
      this.userInfo = {}

      getUserInfo(this.formQuery).then(res => {
        // const { data } = res
        console.log(res)
        if (res) {
          if (res.state == '200') {
            if (res.data) {
              this.userInfo = res.data
              this.getRecordList(res.data.moneyNumDOList)
            } else {
              this.$message.error('暂时没有此用户信息')
            }
          }
        }

      })
    },
    getRecordList(data) {
      if (data) {
        this.recordList = data.map(item => {
          switch (item.name) {
            case '客户自费维保充值记录':
              item.type = 0
              break;
            case '客户自费维保消费记录':
              item.type = 1
              break;
            case '销售赠送维保充值记录':
              item.type = 2
              break;
            case '销售赠送维保消费记录':
              item.type = 3
              break;
            case '售后赠送维保充值记录':
              item.type = 4
              break;
            case '售后赠送维保消费记录':
              item.type = 5
              break;
            case '金融保险基金充值记录':
              item.type = 6
              break;
            case '金融保险基金消费记录':
              item.type = 7
              break;
            case '积分增加记录':
              item.type = 8
              break;
            case '积分消费记录':
              item.type = 9
              break;
            case '套餐开卡记录':
              item.type = 10
              item.name2 = '查看套餐余额'
              item.type2 = 11
              break;
            case '套餐消费记录':
              item.type = 12
              break;
            case '赠送项目充值记录':
              item.type = 13
              item.name2 = '查看赠送项目余额'
              item.type2 = 14
              break;
            case '赠送项目消费记录':
              item.type = 15
              break;
            default:
              break;
          }
          return item
        })

        console.log(this.recordList)
      }
    },
    //获取会员保险列表
    async _getreminderList() {
      await getCarInsurance(this.reminderPage).then(res => {
        if (res) {
          if (res.state == '200') {
            this.reminderList = res.data.records
            this.reminderTotal = res.data.total
          }
        }

      })
    },
    //获取会员生日列表
    async _getBirthdayList() {
      await getBirthdayRemind(this.birthdayPage).then(res => {
        if (res) {
          if (res.state == '200') {
            this.birthdayList = res.data.records
            this.birthdayTotal = res.data.total
          }
        }

      })
    },
    //车牌号选择
    clientCarChange(e) {
      this.userInfo.clientCarDOList.forEach(item => {
        if (e == item.carNum) {
          this.brand = item.model
          this.carFrameNumber = item.carFrameNumber
        }
      })
    }
  }
}
</script>

<style scoped>
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}

.recordTable {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}


::v-deep .el-descriptions__header {
  margin-bottom: 0rem;
}

.my-label {
  text-align: center !important;
}

::v-deep .el-descriptions-item__content {
  word-break: keep-all;
}

.workcontentContainer {
  padding: 0 .125rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

/*顶部通知高度*/
.workcontent_header {
  height: .5rem !important;
}

.workMain {
  display: flex;
  height: 100%;
  padding: .125rem 0;
  flex: 1;
}


.customerLeft {
  display: flex;
  flex-direction: column;
  padding-right: .125rem;
  /* width: 75%; */
  width: 0;
  flex: 3;
}

.customerRight {
  flex: 1;
  display: flex;
  flex-direction: column;
}

/*头部样式*/
.el-header {
  background-color: #fff;
  border-bottom: 1px solid #ddd;
}

.headerStyle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: .5rem;
}

.header_right {
  display: flex;
  align-items: center;
}

.header_message span {
  margin-left: .05rem;
}

.header_message {
  margin: 0 .125rem;
  display: flex;
  align-items: center;
  font-size: .1875rem;
  color: #666;
}


.note {
  display: flex;
  align-items: center;
  /* font-size: .1875rem; */
  color: #333;
}

.note i {
  font-size: .225rem;
  color: #ff6600;
  margin-right: .0375rem;
}

/*右侧表格padding*/
.el-card__body,
.el-main {
  padding: .125rem !important;
}

.des {
  width: 100%;
  padding-bottom: .125rem;
}


.flex-center {
  display: flex;
  align-items: center;
  height: 1rem;
  margin-bottom: .125rem;
  padding: .125rem 0 .125rem 0;
  border: 1px solid #d5d4d4;
  border-radius: .125rem;
  width: 100%;
}


/*搜索按钮样式*/
.flex-center .searchBtn {
  background: #7B63C7;
  border-color: #7B63C7;
  color: #fff;
  width: 2.25rem;
}

/* 描述框里除了上面搜索 */
.customInfo {
  padding: .125rem .25rem !important;
  flex: 1;
  display: flex;
  flex-direction: column;
  border: 1px solid #d5d4d4;
  border-radius: .125rem;
  width: 100%;
}


/*descriptions样式*/
/* .el-descriptions__header {
  margin-bottom: .0625rem !important;
} */

/*记录布局*/
.record {
  /* width: 100%; */
  /* overflow: auto; */
  flex: 1;
  margin-top: .25rem;
}


.recordLeft {
  display: flex;
  flex-direction: column;
}

.recordTitle {
  font-weight: bold;
  padding-bottom: .0625rem;
}

/*点击相关记录样式*/
.recordRight {
  margin-left: .375rem;
}

.recordItem {
  display: flex;
  margin-bottom: .125rem;
  flex: 50%;
  /* height: 40px; */
  margin-bottom: 10px;
}

.recordBottom {
  padding: 0 0 .125rem 0;
}

/*清除手机号input下边距*/
.flex-center .el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
  margin-bottom: 0rem !important;
}


.table {
  border: 1px solid #d5d4d4;
  border-radius: .125rem;
  padding: .125rem .25rem;
  clear: both;
  overflow: hidden !important;
  height: 0;
  flex: 1;
}



.topTable {
  margin-bottom: .125rem;
}

.tableInfo {
  margin: .0063rem 0 .125rem 0;
}

.reminderInfo {
  font-weight: bold;
  font-size: .1875rem;
}

.table i {
  font-size: .1875rem;
}

.table_self {
  overflow: hidden;
}

.moreStyle {
  margin-top: .125rem;
  display: block;
  font-weight: bold;
  color: rgb(82, 82, 82);
  float: right;
}
</style>
