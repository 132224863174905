<template>
  <div>
    <el-dialog custom-class="projectDialog" :visible.sync="dialogVisible" :modal="true" :append-to-body="true"
      :close-on-click-modal="false" @close="closeDialog()">
      <el-tabs>
        <el-tab-pane label="赠送项目信息">
          <el-form ref="form" :rules="rules" :model="form" label-width="1.5rem" size="mini">
            <el-row :gutter="20">
              <el-col :span="24">
                <el-form-item label="项目名称" prop="name">
                  <el-input v-model="form.name" placeholder="请输入项目名称"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="24">
                <el-form-item label="有效期(月)" prop="term">
                  <el-input v-model="form.term" initial-message="0"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="24">
                <span>有效期为0的标识没有有效期，不限次数项目，项目名称后(不限次数)</span>
              </el-col>
            </el-row>
          </el-form>
        </el-tab-pane>
      </el-tabs>

      <span slot="title" class="dialog-title">
        <span>赠送项目信息</span>
      </span>
      <span slot="footer" class="dialog-footer">
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="save()">确 定</el-button>
        </div>
      </span>
    </el-dialog>
  </div>
</template>  
  
<script>
import { addzsPro, updatezsPro } from '@/api/systemOption/systemSettings';

export default {
  data() {
    return {
      dialogVisible: false, 
      form: {
        // id:'',
        name: '',
        term: ''
      },
      rules: {
        name: [{ required: true, message: '请输入项目名称', trigger: 'blur' }],
        term: [{ required: true, message: '请输入有效期', trigger: 'blur' }]
      }
    };
  },
  methods: {
    openDialog(data) {
      // 打开弹出框，并触发父组件的事件传递参数  
      this.dialogVisible = true;
      if (data) {
        this.form = data
      }
    },
    closeDialog() {
      // 关闭弹出框  
      this.dialogVisible = false;
      this.$refs.form?.resetFields()
    },
    save() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.dialogVisible = false
          if (this.form.id) {
            updatezsPro(this.form).then(res => {
              if (res.state === '200') {
                this.$message({
                  message: '修改成功！',
                  type: 'success'
                });
                this.$emit('child-event1')
              }
            })
          } else {
            addzsPro(this.form).then(res => {
              if (res.state === '200') {
                this.$message({
                  message: '添加成功！',
                  type: 'success'
                });
                this.$emit('child-event1')
              }
            })
          }

        }
      })
    }
  }
};
</script>
<style>
.dialog-footer {
  text-align: center;
}

.projectDialog {
  margin-top: 20vh !important;
  width: 25%;
}
</style>
