<!-- 财务管理 -->
<template>
    <div class="financialManageContainer">
        <div class="memberManageAside">
            <MemberManageAside />
        </div>
        <div class="financialManageMain">
            <div class="exit">
                <Exit />
            </div>
            <div class="systemOptionContent">
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>
<script>
import MemberManageAside from '../components/MemberManageAside.vue'
import Exit from '../components/Exit.vue'
export default {
    components: { MemberManageAside, Exit },
    data() {
        return {
            tag: 'br_tag1'
        }
    },
    methods: {
        out() {
            this.$confirm('确认关闭？')
                .then(_ => {
                    done()
                })
                .catch(_ => { })
        },
    },
    mounted() {
        // PubSub.subscribe('activeTag', (msg, data) => {
        //     this.tag = data
        // })
    }
}
</script>
<style scoped>
.financialManageContainer {
    width: 100%;
    height: 100%;
    display: flex;
    background-color: rgb(247, 248, 250);
    position: relative;
}

.financialManageMain {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: absolute;
    left:  1.625rem;;
    right: 0;
    /* width: 100%; */

}

.exit {
    height: .5rem;
    display: flex;
    justify-content: flex-end;
}


.systemOptionContent {
    width: 100%;
    flex: 1;
    background-color: white;
    display: flex;
    flex-direction: column;

}
</style>
