<template>
	<div>
		<el-menu :default-active="cus_defaultIndex" class="el-menu-demo" mode="horizontal" @select="cus_handleSelect">
			<el-menu-item index="cus_tag1">客户来源</el-menu-item>
			<el-menu-item index="cus_tag2">客户性质</el-menu-item>
			<el-menu-item index="cus_tag3">客户分类</el-menu-item>
			<el-menu-item index="cus_tag4">所属区域</el-menu-item>
			<el-menu-item index="cus_tag5">所属行业</el-menu-item>
			<el-menu-item index="cus_tag6">客户职位</el-menu-item>
			<el-menu-item index="cus_tag7">客户收入</el-menu-item>
			<el-menu-item index="cus_tag8">兴趣爱好</el-menu-item>
			<el-menu-item index="cus_tag9">文化程度</el-menu-item>
			<el-menu-item index="cus_tag10">客户国籍</el-menu-item>
		</el-menu>
		<div class="customerSettingsMain">
			<div class="tableContainer" v-if="cus_activeIndex === 'cus_tag1'">
				<el-form ref="form1" :model="form1" label-width="1.5rem" size="small" :rules="rules" :inline="true">
					<el-form-item label="客户来源：" prop="source">
						<el-input placeholder="请输入客户来源" v-model="form1.source"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button @click="add(1)" type="primary">添加</el-button>
					</el-form-item>
				</el-form>

				<div class="tableStyle">
					<el-table :data="tableData1" style="width: 50%" :stripe="true" size="mini"
						header-cell-class-name="headerClassName" :cell-style="{ 'text-align': 'center' }" :border="true"
						:fit="true">
						<!-- <el-table-column prop="id" label="ID">
						</el-table-column> -->
						<el-table-column prop="source" label="客户来源">
							<template slot-scope="scope">
								<el-input v-model="scope.row.source" size="mini" v-if="scope.row.edit"
									@blur="updateDataBlur(scope.row, 1)"
									@keyup.enter.native="updateData(scope.row, 1)"></el-input>
								<span v-else>{{ scope.row.source }}</span>
							</template>
						</el-table-column>
						<el-table-column label="操作">
							<template slot-scope="scope">
								<el-button @click="handleClick(scope.$index, scope.row, 1)" type="text"
									size="small">修改</el-button>
								<el-button @click="del(scope.row, 1)" type="text" size="small">刪除</el-button>
							</template>
						</el-table-column>
					</el-table>
					<el-pagination background layout="prev, pager, next" @current-change="changePage1"
						:current-page.sync="table1.page" :page-size="table1.pageSize" :total="table1.total">
					</el-pagination>
				</div>
			</div>
			<div class="tableContainer" v-if="cus_activeIndex === 'cus_tag2'">
				<el-form ref="form2" :model="form2" label-width="1.5rem" size="small" :rules="rules" :inline="true">
					<el-form-item label="客户性质：" prop="source">
						<el-input placeholder="请输入客户性质" v-model="form2.source"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button @click="add(2)" type="primary">添加</el-button>
					</el-form-item>
				</el-form>

				<div class="tableStyle">
					<el-table :data="tableData2" style="width: 50%" :stripe="true" size="mini"
						header-cell-class-name="headerClassName" :cell-style="{ 'text-align': 'center' }" :border="true"
						:fit="true">
						<!-- <el-table-column prop="id" label="ID">
						</el-table-column> -->
						<el-table-column prop="source" label="客户性质">
							<template slot-scope="scope">
								<el-input v-model="scope.row.source" size="mini" v-if="scope.row.edit"
									@blur="updateDataBlur(scope.row, 2)"
									@keyup.enter.native="updateData(scope.row, 2)"></el-input>
								<span v-else>{{ scope.row.source }}</span>
							</template>
						</el-table-column>
						<el-table-column label="操作">
							<template slot-scope="scope">
								<el-button @click="handleClick(scope.$index, scope.row, 2)" type="text"
									size="small">修改</el-button>
								<el-button @click="del(scope.row, 2)" type="text" size="small">刪除</el-button>
							</template>
						</el-table-column>
					</el-table>
					<el-pagination background layout="prev, pager, next" @current-change="changePage2"
						:current-page.sync="table2.page" :page-size="table2.pageSize" :total="table2.total">
					</el-pagination>
				</div>
			</div>
			<div class="tableContainer" v-if="cus_activeIndex === 'cus_tag3'">
				<el-form ref="form3" :model="form3" label-width="1.5rem" size="small" :rules="rules" :inline="true">
					<el-form-item label="客户分类：" prop="source">
						<el-input placeholder="请输入客户分类" v-model="form3.source"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button @click="add(3)" type="primary">添加</el-button>
					</el-form-item>
				</el-form>

				<div class="tableStyle">
					<el-table :data="tableData3" style="width: 50%" :stripe="true" size="mini"
						header-cell-class-name="headerClassName" :cell-style="{ 'text-align': 'center' }" :border="true"
						:fit="true">
						<!-- <el-table-column prop="id" label="ID">
						</el-table-column> -->
						<el-table-column prop="source" label="客户分类">
							<template slot-scope="scope">
								<el-input v-model="scope.row.source" size="mini" v-if="scope.row.edit"
									@blur="updateDataBlur(scope.row, 3)"
									@keyup.enter.native="updateData(scope.row, 3)"></el-input>
								<span v-else>{{ scope.row.source }}</span>
							</template>
						</el-table-column>
						<el-table-column label="操作">
							<template slot-scope="scope">
								<el-button @click="handleClick(scope.$index, scope.row, 3)" type="text"
									size="small">修改</el-button>
								<el-button @click="del(scope.row, 3)" type="text" size="small">刪除</el-button>
							</template>
						</el-table-column>
					</el-table>
					<el-pagination background layout="prev, pager, next" @current-change="changePage3"
						:current-page.sync="table3.page" :page-size="table3.pageSize" :total="table3.total">
					</el-pagination>
				</div>
			</div>
			<div class="tableContainer" v-if="cus_activeIndex === 'cus_tag4'">
				<el-form ref="form4" :model="form4" label-width="1.5rem" size="small" :rules="rules2" :inline="true">
					<el-form-item label="所属市：" prop="city">
						<el-select :disabled="disabledCity" clearable v-model="form4.city" placeholder="请选择所属市"
							@change="cityChange">
							<el-option :label="item.regionName" :value="item.id" :key="item.id"
								v-for="(item, index) in cityList"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="所属区域：" prop="region">
						<el-input :placeholder="city ? '请输入所要添加的市' : '请输入所要添加的区'" v-model="form4.region"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button v-if="!editCity" @click="add(4)" type="primary">添加</el-button>
						<div v-else>
							<el-button @click="updateCity()" type="primary">确认修改</el-button>
							<el-button @click="cancelUpdateCity()" type="primary">取消</el-button>
						</div>
					</el-form-item>

				</el-form>

				<div class="tableStyle">
					<el-table :data="tableData4" style="width: 50%" :stripe="true" size="mini"
						header-cell-class-name="headerClassName" :cell-style="{ 'text-align': 'center' }" :border="true"
						:fit="true">
						<!-- <el-table-column prop="id" label="ID">
						</el-table-column> -->
						<el-table-column label="市" prop="parentId"> </el-table-column>
						<el-table-column label="区" prop="regionName"> </el-table-column>
						<el-table-column label="操作">
							<template slot-scope="scope">
								<el-button @click="handleClickByCity(scope.$index, scope.row, 4)" type="text"
									size="small">修改</el-button>
								<el-button @click="del(scope.row, 4)" type="text" size="small">刪除</el-button>
							</template>
						</el-table-column>
					</el-table>
					<el-pagination background layout="prev, pager, next" @current-change="changePage4"
						:current-page.sync="table4.page" :page-size="table4.pageSize" :total="table4.total">
					</el-pagination>
				</div>
			</div>
			<div class="tableContainer" v-if="cus_activeIndex === 'cus_tag5'">
				<el-form ref="form5" :model="form5" label-width="1.5rem" size="small" :inline="true" :rules="rules">
					<el-form-item label="所属行业：" prop="source">
						<el-input placeholder="请输入所属行业" v-model="form5.source"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button @click="add(5)" type="primary">添加</el-button>
					</el-form-item>

				</el-form>

				<div class="tableStyle">
					<el-table :data="tableData5" style="width: 50%" :stripe="true" size="mini"
						header-cell-class-name="headerClassName" :cell-style="{ 'text-align': 'center' }" :border="true"
						:fit="true">
						<!-- <el-table-column prop="id" label="ID">
						</el-table-column> -->
						<el-table-column prop="source" label="所属行业">
							<template slot-scope="scope">
								<el-input v-model="scope.row.source" size="mini" v-if="scope.row.edit"
									@blur="updateDataBlur(scope.row, 5)"
									@keyup.enter.native="updateData(scope.row, 5)"></el-input>
								<span v-else>{{ scope.row.source }}</span>
							</template>
						</el-table-column>
						<el-table-column label="操作">
							<template slot-scope="scope">
								<el-button @click="handleClick(scope.$index, scope.row, 5)" type="text"
									size="small">修改</el-button>
								<el-button @click="del(scope.row, 5)" type="text" size="small">刪除</el-button>
							</template>
						</el-table-column>
					</el-table>
					<el-pagination background layout="prev, pager, next" @current-change="changePage5"
						:current-page.sync="table5.page" :page-size="table5.pageSize" :total="table5.total">
					</el-pagination>
				</div>
			</div>
			<div class="tableContainer" v-if="cus_activeIndex === 'cus_tag6'">
				<el-form ref="form6" :model="form6" label-width="1.5rem" :inline="true" size="small" :rules="rules">
					<el-form-item label="客户职位：" prop="source">
						<el-input placeholder="请输入客户职位" v-model="form6.source"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button @click="add(6)" type="primary">添加</el-button>
					</el-form-item>

				</el-form>

				<div class="tableStyle">
					<el-table :data="tableData6" style="width: 50%" :stripe="true" size="mini"
						header-cell-class-name="headerClassName" :cell-style="{ 'text-align': 'center' }" :border="true"
						:fit="true">
						<!-- <el-table-column prop="id" label="ID">
						</el-table-column> -->
						<el-table-column prop="source" label="客户职位">
							<template slot-scope="scope">
								<el-input v-model="scope.row.source" size="mini" v-if="scope.row.edit"
									@blur="updateDataBlur(scope.row, 6)"
									@keyup.enter.native="updateData(scope.row, 6)"></el-input>
								<span v-else>{{ scope.row.source }}</span>
							</template>
						</el-table-column>
						<el-table-column label="操作">
							<template slot-scope="scope">
								<el-button @click="handleClick(scope.$index, scope.row, 6)" type="text"
									size="small">修改</el-button>
								<el-button @click="del(scope.row, 6)" type="text" size="small">刪除</el-button>
							</template>
						</el-table-column>
					</el-table>
					<el-pagination background layout="prev, pager, next" @current-change="changePage6"
						:current-page.sync="table6.page" :page-size="table6.pageSize" :total="table6.total">
					</el-pagination>
				</div>
			</div>
			<div class="tableContainer" v-if="cus_activeIndex === 'cus_tag7'">
				<el-form ref="form7" :model="form7" label-width="1.5rem" size="small" :rules="rules" :inline="true">
					<el-form-item label="客户收入：" prop="source">
						<el-input placeholder="请输入客户收入" v-model="form7.source"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button @click="add(7)" type="primary">添加</el-button>
					</el-form-item>

				</el-form>

				<div class="tableStyle">
					<el-table :data="tableData7" style="width: 50%" :stripe="true" size="mini"
						header-cell-class-name="headerClassName" :cell-style="{ 'text-align': 'center' }" :border="true"
						:fit="true">
						<!-- <el-table-column prop="id" label="ID">
						</el-table-column> -->
						<el-table-column prop="source" label="客户收入">
							<template slot-scope="scope">
								<el-input v-model="scope.row.source" size="mini" v-if="scope.row.edit"
									@blur="updateDataBlur(scope.row, 7)"
									@keyup.enter.native="updateData(scope.row, 7)"></el-input>
								<span v-else>{{ scope.row.source }}</span>
							</template>
						</el-table-column>
						<el-table-column label="操作">
							<template slot-scope="scope">
								<el-button @click="handleClick(scope.$index, scope.row, 7)" type="text"
									size="small">修改</el-button>
								<el-button @click="del(scope.row, 7)" type="text" size="small">刪除</el-button>
							</template>
						</el-table-column>
					</el-table>
					<el-pagination background layout="prev, pager, next" @current-change="changePage7"
						:current-page.sync="table7.page" :page-size="table7.pageSize" :total="table7.total">
					</el-pagination>
				</div>
			</div>
			<div class="tableContainer" v-if="cus_activeIndex === 'cus_tag8'">
				<el-form ref="form8" :model="form8" label-width="1.5rem" :inline="true" size="small" :rules="rules">
					<el-form-item label="兴趣爱好：" prop="source">
						<el-input placeholder="请输入兴趣爱好" v-model="form8.source"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button @click="add(8)" type="primary">添加</el-button>
					</el-form-item>
				</el-form>

				<div class="tableStyle">
					<el-table :data="tableData8" style="width: 50%" :stripe="true" size="mini"
						header-cell-class-name="headerClassName" :cell-style="{ 'text-align': 'center' }" :border="true"
						:fit="true">
						<!-- <el-table-column prop="id" label="ID">
						</el-table-column> -->
						<el-table-column prop="source" label="兴趣爱好">
							<template slot-scope="scope">
								<el-input v-model="scope.row.source" size="mini" v-if="scope.row.edit"
									@blur="updateDataBlur(scope.row, 8)"
									@keyup.enter.native="updateData(scope.row, 8)"></el-input>
								<span v-else>{{ scope.row.source }}</span>
							</template>
						</el-table-column>
						<el-table-column label="操作">
							<template slot-scope="scope">
								<el-button @click="handleClick(scope.$index, scope.row, 8)" type="text"
									size="small">修改</el-button>
								<el-button @click="del(scope.row, 8)" type="text" size="small">刪除</el-button>
							</template>
						</el-table-column>
					</el-table>
					<el-pagination background layout="prev, pager, next" @current-change="changePage8"
						:current-page.sync="table8.page" :page-size="table8.pageSize" :total="table8.total">
					</el-pagination>
				</div>
			</div>
			<div class="tableContainer" v-if="cus_activeIndex === 'cus_tag9'">
				<el-form ref="form9" :model="form9" label-width="1.5rem" :inline="true" size="small" :rules="rules">
					<el-form-item label="文化程度：" prop="source">
						<el-input placeholder="请输入文化程度" v-model="form9.source"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button @click="add(9)" type="primary">添加</el-button>
					</el-form-item>

				</el-form>

				<div class="tableStyle">
					<el-table :data="tableData9" style="width: 50%" :stripe="true" size="mini"
						header-cell-class-name="headerClassName" :cell-style="{ 'text-align': 'center' }" :border="true"
						:fit="true">
						<!-- <el-table-column prop="id" label="ID">
						</el-table-column> -->
						<el-table-column prop="source" label="文化程度">
							<template slot-scope="scope">
								<el-input v-model="scope.row.source" size="mini" v-if="scope.row.edit"
									@blur="updateDataBlur(scope.row, 9)"
									@keyup.enter.native="updateData(scope.row, 9)"></el-input>
								<span v-else>{{ scope.row.source }}</span>
							</template>
						</el-table-column>
						<el-table-column label="操作">
							<template slot-scope="scope">
								<el-button @click="handleClick(scope.$index, scope.row, 9)" type="text"
									size="small">修改</el-button>
								<el-button @click="del(scope.row, 9)" type="text" size="small">刪除</el-button>
							</template>
						</el-table-column>
					</el-table>
					<el-pagination background layout="prev, pager, next" @current-change="changePage9"
						:current-page.sync="table9.page" :page-size="table9.pageSize" :total="table9.total">
					</el-pagination>
				</div>
			</div>
			<div class="tableContainer" v-if="cus_activeIndex === 'cus_tag10'">
				<el-form ref="form10" :model="form10" label-width="1.5rem" :inline="true" size="small" :rules="rules">
					<el-form-item label="客户国籍：" prop="source">
						<el-input placeholder="请输入客户国籍" v-model="form10.source"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button @click="add(10)" type="primary">添加</el-button>
					</el-form-item>

				</el-form>

				<div class="tableStyle">
					<el-table :data="tableData10" style="width: 50%" :stripe="true" size="mini"
						header-cell-class-name="headerClassName" :cell-style="{ 'text-align': 'center' }" :border="true"
						:fit="true">
						<!-- <el-table-column prop="id" label="ID">
						</el-table-column> -->
						<el-table-column prop="source" label="客户国籍">
							<template slot-scope="scope">
								<el-input v-model="scope.row.source" size="mini" v-if="scope.row.edit"
									@blur="updateDataBlur(scope.row, 10)"
									@keyup.enter.native="updateData(scope.row, 10)"></el-input>
								<span v-else>{{ scope.row.source }}</span>
							</template>
						</el-table-column>
						<el-table-column label="操作">
							<template slot-scope="scope">
								<el-button @click="handleClick(scope.$index, scope.row, 10)" type="text"
									size="small">修改</el-button>
								<el-button @click="del(scope.row, 10)" type="text" size="small">刪除</el-button>
							</template>
						</el-table-column>
					</el-table>
					<el-pagination background layout="prev, pager, next" @current-change="changePage10"
						:current-page.sync="table10.page" :page-size="table10.pageSize" :total="table10.total">
					</el-pagination>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import { addClient, delClient, getClientList, updateClient, getCityList, getClientListByCity, addClientByCity, delClientByCity, updateClientByCity } from '@/api/systemOption/systemSettings';

export default {
	components: {

	},
	data() {
		return {
			cus_defaultIndex: 'cus_tag1',
			cus_activeIndex: 'cus_tag1',
			tableData1: [],
			tableData2: [],
			tableData3: [],
			tableData4: [],
			tableData5: [],
			tableData6: [],
			tableData7: [],
			tableData8: [],
			tableData9: [],
			tableData10: [],
			form1: {
				source: ''
			},
			form2: {
				source: ''
			},
			form3: {
				source: ''
			},
			form4: {
				city: '',
				region: ''
			},
			form5: {
				source: ''
			},
			form6: {
				source: ''
			},
			form7: {
				source: ''
			},
			form8: {
				source: ''
			},
			form9: {
				source: ''
			},
			form10: {
				source: ''
			},
			rules: {
				source: [{ required: true, message: '请输入添加名称', trigger: 'blur' }]
			},
			rules2: {
				region: [{ required: true, message: '请输入区域名称', trigger: 'blur' }],
				city: [{ required: true, message: '请输入市区名称', trigger: 'blur' }]
			},
			table1: {
				total: 0,
				page: 1,
				pageSize: 10
			},
			table2: {
				total: 0,
				page: 1,
				pageSize: 10
			},
			table3: {
				total: 0,
				page: 1,
				pageSize: 10
			},
			table4: {
				total: 0,
				page: 1,
				pageSize: 10
			},
			table5: {
				total: 0,
				page: 1,
				pageSize: 10
			},
			table6: {
				total: 0,
				page: 1,
				pageSize: 10
			},
			table7: {
				total: 0,
				page: 1,
				pageSize: 10
			},
			table8: {
				total: 0,
				page: 1,
				pageSize: 10
			},
			table9: {
				total: 0,
				page: 1,
				pageSize: 10
			},
			table10: {
				total: 0,
				page: 1,
				pageSize: 10
			},
			type: '0',
			cityList: [],
			city: true,
			editCity: false,
			disabledCity: false,
		}
	},
	computed: {
	},
	mounted() {
		this.getData(1)
	},
	methods: {
		cityChange(e) {
			if (e == '') {
				this.city = true
			} else {
				this.city = false
			}
		},
		getCity(cityId) {
			getCityList({ id: '' }).then(res => {
				if (res.state === '200') {
					this.cityList = res.data
				}
			})

		},
		cus_handleSelect(key, keyPath) {
			this.cus_activeIndex = key

			switch (key) {
				case 'cus_tag1':
					this.type = '0'
					this.getData(1)
					break;
				case 'cus_tag2':
					this.type = '1'
					this.getData(2)
					break;
				case 'cus_tag3':
					this.type = '2'
					this.getData(3)
					break;
				case 'cus_tag4':
					this.type = '3'
					this.editCity = false
					this.getData(4)
					this.getCity()
					break;
				case 'cus_tag5':
					this.type = '4'
					this.getData(5)
					break;
				case 'cus_tag6':
					this.type = '5'
					this.getData(6)
					break;
				case 'cus_tag7':
					this.type = '6'
					this.getData(7)
					break;
				case 'cus_tag8':
					this.type = '7'
					this.getData(8)
					break;
				case 'cus_tag9':
					this.type = '8'
					this.getData(9)
					break;
				case 'cus_tag10':
					this.type = '9'
					this.getData(10)
					break;
				default:
					break;
			}


		},
		setData(data) {
			switch (this.cus_activeIndex) {
				case 'cus_tag1':
					this.tableData1 = data.records
					this.table1.total = data.total
					break;
				case 'cus_tag2':
					this.tableData2 = data.records
					this.table2.total = data.total
					break;
				case 'cus_tag3':
					this.tableData3 = data.records
					this.table3.total = data.total
					break;
				case 'cus_tag4':
					this.tableData4 = data.records
					this.table4.total = data.total
					break;
				case 'cus_tag5':
					this.tableData5 = data.records
					this.table5.total = data.total
					break;
				case 'cus_tag6':
					this.tableData6 = data.records
					this.table6.total = data.total
					break;
				case 'cus_tag7':
					this.tableData7 = data.records
					this.table7.total = data.total
					break;
				case 'cus_tag8':
					this.tableData8 = data.records
					this.table8.total = data.total
					break;
				case 'cus_tag9':
					this.tableData9 = data.records
					this.table9.total = data.total
					break;
				case 'cus_tag10':
					this.tableData10 = data.records
					this.table10.total = data.total
					break;
				default:
					break;
			}
		},
		getData(n) {
			const table = 'table' + n
			let data
			if (n == 4) {
				data = { page: this[table].page, pageSize: this[table].pageSize }
				getClientListByCity(data).then(res => {
					if (res.state == '200') {
						this.tableData4 = res.data.records
						this.setData(res.data)
					}
				})
			} else {
				data = { type: this.type, page: this[table].page, pageSize: this[table].pageSize }
				getClientList(data).then(res => {
					if (res.state == '200') {
						this.setData(res.data)
					}
				})
			}

		},
		add(n) {
			const form = 'form' + n
			this.$refs[form].validate(valid => {
				if (valid) {
					let data
					if (n == 4) {
						data = {
							// city为空时：添加市    city不为空：添加区
							parentId: this[form].city == '' ? '0' : this[form].city,
							regionName: this[form].region,
							grade: this[form].city == '' ? '1' : '2'
						}
						addClientByCity(data).then(res => {
							if (res.state === '200') {
								this.$message({
									message: '添加成功！',
									type: 'success'
								});
								this.form4 = {
									city: '',
									region: ''
								}
								this.getData(n)
								// this.getCity()

							}
						})
					} else {
						data = { type: this.type, source: this[form].source }
						addClient(data).then(res => {
							if (res.state === '200') {
								this.$message({
									message: '添加成功！',
									type: 'success'
								});
								this.$refs[form].resetFields()
								this.getData(n)

							}
						})
					}

				}
			})

		},
		changePage1(e) {
			this.table1.page = e
			this.getData(1)
		},
		changePage2(e) {
			this.table2.page = e
			this.getData(2)
		},
		changePage3(e) {
			this.table3.page = e
			this.getData(3)
		},
		changePage4(e) {
			this.table4.page = e
			this.getData(4)
		},
		changePage5(e) {
			this.table5.page = e
			this.getData(5)
		},
		changePage6(e) {
			this.table6.page = e
			this.getData(6)
		},
		changePage7(e) {
			this.table7.page = e
			this.getData(7)
		},
		changePage8(e) {
			this.table8.page = e
			this.getData(8)
		},
		changePage9(e) {
			this.table9.page = e
			this.getData(9)
		},
		changePage10(e) {
			this.table10.page = e
			this.getData(10)
		},
		del(row, n) {
			this.delMsg(this).then(() => {
				if (n == 4) {
					delClientByCity({ id: row.id }).then(res => {
						if (res.state === '200') {
							this.$message({
								message: '删除成功！',
								type: 'success'
							});
							this.table4.page = 1
							this.getData(n)
							// this.getCity()
						}
					})
				} else {
					delClient({ id: row.id }).then(res => {
						if (res.state === '200') {
							this.$message({
								message: '删除成功！',
								type: 'success'
							});
							const tableds = 'tableData' + n
							const tables = 'table' + n
							if ((this[tableds].length - 1) == 0 && this[tables].page > 1) {
								this[tables].page--
							}
							this.getData(n)
						}
					})
				}
			}).catch(() => {
				this.$message({
					type: 'warn',
					message: '删除失败！'
				});
			});

		},
		handleClick(index, row, n) {
			row.edit = true;
			const tableData = 'tableData' + n
			this.$set(this[tableData], index, row);
		},

		updateData(row, n) {
			row.edit = false;
			updateClient({ type: this.type, ...row }).then(res => {
				if (res.state === '200') {
					this.getData(n)
				}
			})
		},
		updateDataBlur(row, n) {
			row.edit = false;
			updateClient({ type: this.type, ...row }).then(res => {
				if (res.state === '200') {
					this.$message({
						message: '修改成功！',
						type: 'success'
					});
					this.getData(n)
				}
			})
		},
		handleClickByCity(index, row, n) {
			if (row.parentId == '0') {
				//修改市，下拉框禁用
				this.disabledCity = true
			}
			this.form4 = {
				city: row.parentId == '0' ? '' : row.parentId,
				region: row.regionName,
				id: row.id
			}
			this.editCity = true

		},
		updateCity() {
			updateClientByCity({
				id: this.form4.id,
				parentId: this.disabledCity ? '0' : this.form4.city,
				regionName: this.form4.region,
				grade: this.form4.city == '' ? '1' : '2'
			}).then(res => {
				if (res.state == '200') {
					this.$message({
						message: '修改成功！',
						type: 'success'
					});
					this.disabledCity = false
					this.editCity = false
					this.form4 = {
						city: '',
						region: ''
					}
					this.getData(4)
					// this.getCity()
				}
			})
		},
		cancelUpdateCity() {
			this.editCity = false
			this.form4 = {
				city: '',
				region: ''
			}
		}

	}
}
</script>

<style scoped>
.tableContainer {
	padding: .125rem .25rem;
}

.tableContainer .el-table {
	width: 5rem;
}
</style>