<template>
  <div>
    <el-dialog custom-class="packageDetailDialog" :visible.sync="dialogVisible" @close="cancel()" :modal="true"
      :append-to-body="true" :close-on-click-modal="false">
      <el-form ref="form" :model="form" label-width="1.5rem" size="mini" :rules="rules">
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="所属套餐" prop="packageId">
              <el-select placeholder="请选择" v-model="form.packageId">
                <el-option :label="item.name" :value="item.id" :key="item.id"
                  v-for="(item, index) in packageList"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="服务项目" prop="itemName">
              <el-input v-model="form.itemName" placeholder="请输入服务项目"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="服务次数" prop="num">
              <el-input v-model="form.num" placeholder="请输入服务次数"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="项目单价" prop="itemPrice">
              <el-input v-model="form.itemPrice" placeholder="请输入项目单价"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="项目说明" prop="remark">
              <el-input v-model="form.remark" type="textarea" :rows="2" placeholder="请输入项目说明"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="title" class="dialog-title">
        <span>{{ edit ? '添加套餐明细' : '修改套餐明细' }}</span>
      </span>
      <span slot="footer" class="dialog-footer">
        <div slot="footer" class="dialog-footer">
          <el-button size="small" @click="cancel()">取 消</el-button>
          <el-button size="small" type="primary" @click="save()">确 定</el-button>
        </div>
      </span>
    </el-dialog>
  </div>
</template>  
  
<script>
import { addPackageDetail, getPackageListNoPage, updatePackageDetail } from '@/api/systemOption/systemSettings';

export default {
  data() {
    return {
      edit: false,
      dialogVisible: false,
      regionValue: '',
      form: {
        packageId: '',
        itemName: '',
        num: '',
        itemPrice: '',
        remark: ''
      },
      rules: {
        packageId: [{ required: true, message: '请选择套餐', trigger: 'blur' }],
        itemName: [{ required: true, message: '请输入项目名称', trigger: 'blur' }],
        num: [{ required: true, message: '请输入服务次数', trigger: 'blur' }],
        itemPrice: [{ required: true, message: '请输入项目单价', trigger: 'blur' }],
      },
      packageList: []
    };
  },
  methods: {
    openDialog(data) {
      // 打开弹出框，并触发父组件的事件传递参数  
      this.dialogVisible = true;
      if (data) {
        this.edit = true
        this.form = data
      } else {
        this.edit = false
        this.reset()
      }
      getPackageListNoPage().then(res => {
        if (res.state === '200') {
          this.packageList = res.data
        }
      })
    },
    reset() {
      this.form = {
        packageId: '',
        itemName: '',
        num: '',
        itemPrice: '',
        remark: ''
      }
    },
    cancel() {
      // 关闭弹出框  
      // this.$refs.form.resetFields()
      this.dialogVisible = false;
    },
    save() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.edit) {
            updatePackageDetail(this.form).then(res => {
              if (res.state === '200') {
                this.$message({
                  message: '修改成功！',
                  type: 'success'
                });
                this.dialogVisible = false
                this.$emit('child2-event')
              }
            })
          } else {
            addPackageDetail(this.form).then(res => {
              if (res.state === '200') {
                this.$message({
                  message: '添加成功！',
                  type: 'success'
                });
                this.dialogVisible = false
                this.$emit('child2-event')
              }
            })
          }
        }
      })
    }
  }
};
</script>
<style>
.dialog-footer {
  text-align: center;
}

.packageDetailDialog {
  margin-top: 20vh !important;
  width: 25%;
}
</style>
