<template>
    <div>
        <el-dialog custom-class="addGoods" :visible.sync="dialogVisible" :modal="true" :append-to-body="true"
            :close-on-click-modal="false">
            <el-form ref="formGoods" :model="form" label-width="1.5rem" size="mini">
                <el-row :gutter="20">
                    <el-col :span="14">
                        <el-form-item label="商品名称" prop="name" :rules="[
                            { required: true, message: '商品名称不能为空', trigger: 'blur' }
                        ]">
                            <el-input placeholder="输入商品名称，30字以内" v-model="form.name"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="14">
                        <el-form-item label="所属门店" prop="storeId" :rules="[
                            { required: true, message: '门店不能为空', trigger: 'change' }
                        ]">
                            <el-select placeholder="请选择" v-model="form.storeId" disabled>
                                <el-option v-for="(item, index) in storeList" :key="index" :label="item.name"
                                    :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="14">
                        <el-form-item label="商品分类" prop="classifyId" :rules="[
                            { required: true, message: '商品分类不能为空', trigger: 'change' }
                        ]">
                            <el-select placeholder="请选择" v-model="form.classifyId">
                                <el-option v-for="(item, index) in Category" :key="index" :label="item.name"
                                    :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="14">
                        <el-form-item label="排序">
                            <el-input placeholder="数字越小越靠前" v-model="form.sort"></el-input>
                            <span class="remark">数字越小越靠前</span>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="14">
                        <el-form-item label="积分" prop="credit">
                            <el-input v-model="form.credit"></el-input>
                            <span class="remark">积分和价格，必须填写其中一种</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="10">
                        <el-form-item class="formItem">
                            <span class="fenAndYuan">分</span>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="14">
                        <el-form-item label="价格" prop="money">
                            <el-input-number v-model="form.money" :min="0"></el-input-number>
                            <br>
                            <!-- <el-input v-model="form.money" type="number"></el-input> -->
                            <span class="remark">3种兑换方式：积分+现金、积分、现金</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="10">
                        <el-form-item class="formItem">
                            <span class="fenAndYuan">元</span>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="14">
                        <el-form-item label="商品库存" prop="inventory" :rules="[
                            { required: true, message: '商品库存不能为空', trigger: 'blur' }
                        ]">
                            <el-input placeholder="请输入商品库存" v-model="form.inventory" type="number"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="14">
                        <el-form-item label="已售数量">
                            <el-input :value="form.sales" readonly disabled></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label="缩略图">
                            <el-upload class="upload-demo" list-type="picture-card" ref="upload" action="" multiple
                                :limit="1" :file-list="fileList" name="uploadFile" :http-request="upload"
                                :on-success="handleAvatarSuccess" :before-upload="onprogress" :on-exceed="limitChange">
                                <i class="el-icon-camera avatar-uploader-icon"></i>
                            </el-upload>
                            <span class="remark">图片大小在600K以内</span>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label="商品详情" prop="details" :rules="[
                            { required: true, message: '商品详情不能为空', trigger: 'blur' }
                        ]">
                            <quill-editor class="editor" v-model="form.details" ref="customQuillEditor"
                                :options="editorOption" style="height: 4rem;">
                            </quill-editor>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="title" class="dialog-title">
                <span>添加商品</span>
            </span>
            <span slot="footer" class="dialog-footer">
                <div slot="footer" class="dialog-footer">
                    <el-button size="small" type="primary" @click="addGoods">确认保存</el-button>
                </div>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { getClassifyList, addVipManage, getStoreList, updateVipManage, shorten } from '@/api/memberConsume/index';
import { getImage } from '@/api/memberManage/index'
import axios from 'axios';

//引入以及工具栏配置
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import quillConfig from '@/utils/quill-config';


// 工具栏配置

// const toolbarOptions = [
//     ['bold', 'italic'], // 加粗 斜体 下划线 删除线
//     [{ list: 'ordered' }, { list: 'bullet' }], // 有序、无序列表
//     [{ size: ['small', false, 'large', 'huge'] }], // 字体大小
//     [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
//     [{ align: [] }], // 对齐方式
//     ['clean'], // 清除文本格式
//     ['image']
// ]
export default {
    components: { quillEditor },
    data() {
        return {
            dialogVisible: false, // 控制弹出框的显示与隐藏  
            form: {
                name: "",
                classifyId: "",
                credit: 0,
                money: 0,
                sales: "",
                inventory: "",
                sort: 0,
                storeId: "",
                thumbnail: "",
                details: ""
            },
            // editorOption: {
            //     modules: {
            //         toolbar: {
            //             container: toolbarOptions, //工具栏相关配置
            //             handlers: handlers
            //         },
            //     },
            // },
            editorOption: quillConfig,
            Category: [],
            storeList: [],
            filename: '',
            fileList: []
        };
    },
    methods: {
        openDialog(row) {
            // 打开弹出框，并触发父组件的事件传递参数  
            this.dialogVisible = true;
            this.fileList = []
            this.getCategory()
            this.getStore()
            if (row) {
                this.form = row
                // this.fileList.push({ name: '图片', url: img })
                if (this.form.thumbnail && this.form.thumbnail !== '') {
                    this.fileList.push({ name: '图片', url: this.form.thumbnail })
                    // const formData = new FormData();
                    // formData.append('imageUrl', this.form.thumbnail);
                    // getImage(formData).then(response => {
                    //     var img = 'data:image/jpeg;base64,' + this.arrayBufferToBase64(response)
                    //     // this.imgsrc = qrUrl;
                    //     this.fileList.push({ name: '图片', url: img })
                    // })
                }

            } else {
                var obj = {
                    name: "",
                    classifyId: "",
                    credit: 0,
                    money: 0,
                    sales: "",
                    inventory: "",
                    sort: 0,
                    storeId: "",
                    thumbnail: "",
                    details: ""
                }
                this.form = this.shenjiankaobe(obj)
                this.resetForm('formGoods')
                console.log('NEW_BASE:', sessionStorage.getItem('accountUrl'));
                const accountUrl = sessionStorage.getItem('accountUrl')
                shorten({ longUrl: `${accountUrl}#/pages/introduce/introduce` }).then(res => {
                    console.log(res)
                    if (res.data) {
                        this.form.shareLink = res.data.shortLink
                        this.form.longUrl = res.data.longUrl
                    }
                })
            }
        },

        closeDialog() {
            // 关闭弹出框  
            this.dialogVisible = false;
        },
        getCategory() {
            getClassifyList({}).then(res => {
                this.Category = res.data
            })
        },
        //获取商家门店列表
        getStore() {
            getStoreList({}).then(res => {
                this.storeList = res.data
                let curStore = res.data.find(item => item.isStore)
                this.form.storeId = curStore.id
            })
        },
        resetForm(formName) {
            this.$nextTick(() => {
                this.$refs[formName].resetFields();
            })
        },
        limitChange(file) {
            console.log(file)
            if (this.fileList.length == 1) {
                this.$message.error('请先删除上传的图片')
                return
            }
        },
        addGoods() {
            if (this.form.credit == '' && this.form.money == '') {
                this.$message.error('请填写积分或价格')
                return
            }
            if (this.form.extensionUrl) {
                delete this.form.extensionUrl
            }
            this.$refs["formGoods"].validate((valid) => {
                if (valid) {
                    if (this.form.id) {
                        delete this.form.shareCode
                        updateVipManage(this.form).then(res => {
                            if (res.state == '200') {
                                this.$message.success('修改成功')
                                this.dialogVisible = false
                                this.$emit('child-event', 'Hello from child');
                            }
                        })
                    } else {
                        addVipManage(this.form).then(res => {
                            if (res.state == '200') {
                                this.$message.success('添加成功')
                                this.dialogVisible = false
                                this.$emit('child-event', 'Hello from child');
                            }
                        })
                    }

                } else {

                }
            });
        },
        // 深拷贝
        shenjiankaobe(obj) {
            let _obj = JSON.stringify(obj),
                objClone = JSON.parse(_obj);
            return objClone
        },
        upload(file) {
            console.log(file, process.env.VUE_APP_BASE_API, '导入')
            if (file.file.size > 614400) {
                this.$message.error('文件超出限制，最大为600k')
                return
            }
            this.fileList = []
            this.form.thumbnail = ''
            // this.submitFilx = file
            const formData = new FormData()
            formData.append('image', file.file)
            // 多文件上传带其他参数，传递formdata和data不能一起传递，要传递formdata   就不能有data
            axios.post(process.env.VUE_APP_BASE_API + "/sys/imageUpload/imageUpload", formData, {
                headers: {
                    "Content-type": "multipart/form-data"
                }
            }).then(res => {
                console.log(res, 'resss')
                if (res.data.state !== '200') {
                    this.$message({
                        message: res.data.message,
                        type: 'error',
                        duration: 5000
                    });
                    return
                }
                this.$message({
                    message: '上传成功',
                    type: 'success',
                });
                this.form.thumbnail = res.data.data.imageUrl
                const formData = new FormData();
                formData.append('imageUrl', res.data.data.imageUrl);
                var img = res.data.data.imageUrl
                this.fileList.push({ name: this.filename, url: img })
                // getImage(formData).then(response => {
                // var img = 'data:image/jpeg;base64,' + this.arrayBufferToBase64(response)
                // this.imgsrc = qrUrl;
                //     this.fileList.push({ name: this.filename, url: img })
                // })
                this.handleAvatarSuccess(res.data)
            }).catch(res => {

                console.log(res, '失败')
            })
        },
        arrayBufferToBase64(buffer) {
            var binary = ''
            var bytes = new Uint8Array(buffer)
            var len = bytes.byteLength
            for (var i = 0; i < len; i++) {
                binary += String.fromCharCode(bytes[i])
            }
            return window.btoa(binary)
        },
        handleAvatarSuccess(res, file) {
            console.log(res)
            this.$refs.upload.clearFiles();
        },
        onprogress(file, fileList) {
            this.fileList = []
            this.filename = file.name
            var str = file.name.split('.')
            console.log(str)
            // this.extName = str[str.length - 1]
        }
    }
};
</script>
<style scoped>
::v-deep .formItem .el-form-item__content {
    margin-left: 0rem !important;
}

::v-deep .el-dialog {
    width: 40vw !important;
    margin-top: 10vh !important;
}

.el-select {
    width: 100%;
}

.fenAndYuan {
    font-size: .225rem;
    font-weight: bold;
    color: rgb(79, 79, 79);
    margin-right: .125rem;
}

.remark {
    font-size: .15rem;
    color: rgb(156, 156, 156);
}

.addGoods {
    margin-top: 10vh !important;
}
</style>