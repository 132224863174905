<template>
	<div>
		<el-menu :default-active="pro_defaultIndex" class="el-menu-demo" mode="horizontal" @select="pro_handleSelect">
			<el-menu-item index="pro_tag1">赠送项目信息</el-menu-item>
			<el-menu-item index="pro_tag2">按会员级别自定义赠送项目</el-menu-item>
			<el-menu-item index="pro_tag3">赠送项目成本信息</el-menu-item>
		</el-menu>
		<div class="tableContainer" v-if="pro_activeIndex === 'pro_tag1'">
			<el-button type="primary" @click="openProjectDialog" size="small">添加项目</el-button>
			<div class="tableStyle">
				<el-table :data="tableData1" style="width: 70%" :stripe="true" size="mini"
					header-cell-class-name="headerClassName" :cell-style="{ 'text-align': 'center' }" :border="true"
					:fit="true" :current-page="table1.page">
					<!-- <el-table-column prop="id" label="ID">
					</el-table-column> -->
					<el-table-column prop="name" label="项目名称">
					</el-table-column>
					<el-table-column prop="term" label="有效期(月)">
					</el-table-column>
					<el-table-column label="操作">
						<template slot-scope="scope">
							<el-button @click="editPro1(scope.row)" type="text" size="small">修改</el-button>
							<el-button @click="delPro1(scope.row)" type="text" size="small">刪除</el-button>
						</template>
					</el-table-column>
				</el-table>
				<el-pagination background layout="prev, pager, next" :total="table1.total" :current-page.sync="table1.page"
					:page-size="table1.pageSize" @current-change="changePage1">
				</el-pagination>
			</div>
		</div>
		<div class="tableContainer" v-if="pro_activeIndex === 'pro_tag2'">
			<el-button type="primary" @click="openProjectBySelfDialog" size="small">添加项目</el-button>
			<div class="tableStyle">
				<el-table :data="tableData2" style="width: 70%" :stripe="true" size="mini"
					header-cell-class-name="headerClassName" :cell-style="{ 'text-align': 'center' }" :border="true"
					:fit="true">
					<!-- <el-table-column prop="id" label="ID">
					</el-table-column> -->
					<el-table-column prop="vipLevelId" label="会员级别">
						<template slot-scope="scope">
							<span>{{ vip(scope.row.vipLevelId) }}</span>
						</template>
					</el-table-column>
					<el-table-column prop="itemName" label="项目名称">
					</el-table-column>
					<el-table-column prop="term" label="有效期(月)">
					</el-table-column>
					<el-table-column prop="num" label="赠送次数">
					</el-table-column>
					<el-table-column label="操作">
						<template slot-scope="scope">
							<el-button @click="editPro2(scope.row)" type="text" size="small">修改</el-button>
							<el-button @click="delPro2(scope.row)" type="text" size="small">刪除</el-button>
						</template>
					</el-table-column>
				</el-table>
				<el-pagination background layout="prev, pager, next" :total="table2.total" :current-page.sync="table2.page"
					:page-size="table2.pageSize" @current-change="changePage2">
				</el-pagination>
			</div>
		</div>
		<div class="tableContainer" v-if="pro_activeIndex === 'pro_tag3'">
			<el-button type="primary" @click="openProjectByCostDialog" size="small">添加项目</el-button>
			<div class="tableStyle">
				<el-table :data="tableData3" style="width: 70%" :stripe="true" size="mini"
					header-cell-class-name="headerClassName" :cell-style="{ 'text-align': 'center' }" :border="true"
					:fit="true">
					<!-- <el-table-column prop="id" label="ID">
					</el-table-column> -->
					<el-table-column prop="donationId" label="项目名称">
						<template slot-scope="scope">
							<span>{{ project(scope.row.id) }}</span>
						</template>
					</el-table-column>
					<el-table-column prop="carId" label="车辆型号">
						<template slot-scope="scope">
							<span>{{ car(scope.row.carId) }}</span>
						</template>
					</el-table-column>
					<el-table-column prop="cost" label="项目成本">
					</el-table-column>
					<el-table-column label="操作">
						<template slot-scope="scope">
							<el-button @click="editPro3(scope.row)" type="text" size="small">修改</el-button>
							<el-button @click="delPro3(scope.row)" type="text" size="small">刪除</el-button>
						</template>
					</el-table-column>
				</el-table>
				<el-pagination background layout="prev, pager, next" :total="table3.total" :current-page.sync="table3.page"
					:page-size="table1.pageSize" @current-change="changePage3">
				</el-pagination>
			</div>
		</div>
		<ProjectByCostModal ref="projectByCostModal" @child-event3="handleChildEvent3" />
		<ProjectBySelfModal ref="projectBySelfModal" @child-event2="handleChildEvent2" />
		<ProjectModal ref="projectModal" @child-event1="handleChildEvent1" />
	</div>
</template>
<script>
import { cbProList, delzsPro, zdyProList, zsProList, delPro2, delPro3, getCarList, getProNoPage, getVipListNoPage } from '@/api/systemOption/systemSettings'
import ProjectByCostModal from '../../../systemOptionModal/ProjectByCostModal.vue'
import ProjectBySelfModal from '../../../systemOptionModal/ProjectBySelfModal.vue'
import ProjectModal from '../../../systemOptionModal/ProjectModal.vue'
export default {
	components: {
		ProjectByCostModal,
		ProjectBySelfModal,
		ProjectModal
	},
	data() {
		return {
			pro_defaultIndex: 'pro_tag1',
			pro_activeIndex: 'pro_tag1',
			tableData1: [],
			tableData2: [],
			tableData3: [],
			table1: {
				page: 1,
				pageSize: 10,
				total: 0,
			},
			table2: {
				page: 1,
				pageSize: 10,
				total: 0,
			},
			table3: {
				page: 1,
				pageSize: 10,
				total: 0,
			},
			carList: [],
			projectList: [],
			vipList: []
		}
	},
	computed: {
		project() {
			return (id) => {
				if (this.tableData3) {
					const pac = this.tableData3.find(item => item.id === id)
					return pac?.donationName || ''
				}
			}
		},
		car() {
			return (id) => {
				if (this.carList) {
					const pac = this.carList.find(item => item.id === id)
					return pac.model || ''
				}
			}
		},
		vip() {
			return (id) => {
				const vip = this.vipList.find(item => item.id === id)
				return vip.name || ''
			}
		}
	},
	mounted() {
		this.getPro1List()
		getCarList({}).then(res => {
			if (res.state === '200') {
				this.carList = res.data
				console.log(this.carList)
			}
		})
		getProNoPage().then(res => {
			if (res.state === '200') {
				this.projectList = res.data
			}
		})
		getVipListNoPage().then(res => {
			let { data } = res
			this.vipList = data
		})
	},
	methods: {
		openProjectDialog() {
			this.$refs.projectModal.openDialog()
		},
		openProjectByCostDialog() {
			this.$refs.projectByCostModal.openDialog()
		},
		openProjectBySelfDialog() {
			this.$refs.projectBySelfModal.openDialog()
		},
		pro_handleSelect(key, keyPath) {
			this.pro_activeIndex = key
			switch (key) {
				case 'pro_tag1':
					this.getPro1List()
					break;
				case 'pro_tag2':
					this.getPro2List()
					break;
				case 'pro_tag3':
					this.getPro3List()
					break;
				default:
					break;
			}
		},
		getPro1List() {
			zsProList({ page: this.table1.page, pageSize: this.table1.pageSize }).then(res => {
				if (res.state === '200') {
					this.tableData1 = res.data.records
					this.table1.total = res.data.total
				}
			})
		},
		getPro2List() {
			zdyProList({ page: this.table2.page, pageSize: this.table2.pageSize }).then(res => {
				if (res.state === '200') {
					this.tableData2 = res.data.records
					this.table2.total = res.data.total
				}
			})
		},
		getPro3List() {
			cbProList({ page: this.table3.page, pageSize: this.table3.pageSize }).then(res => {
				if (res.state === '200') {
					this.tableData3 = res.data.records
					this.table3.total = res.data.total
				}
			})
		},
		handleChildEvent1() {
			this.getPro1List()
		},
		handleChildEvent2() {
			this.getPro2List()
		},
		handleChildEvent3() {
			this.getPro3List()
		},
		changePage1(e) {
			this.page = e
			this.getPro1List()
		},
		changePage2(e) {
			this.page = e
			this.getPro2List()
		},
		changePage3(e) {
			this.page = e
			this.getPro3List()
		},
		editPro1(row) {
			this.$refs.projectModal.openDialog(row)
		},
		editPro2(row) {
			this.$refs.projectBySelfModal.openDialog(row)
		},
		editPro3(row) {
			this.$refs.projectByCostModal.openDialog(row)
		},
		delPro1(row) {
			this.delMsg(this).then(() => {
				delzsPro({ id: row.id }).then(res => {
					if (res.state === '200') {
						this.$message({
							message: '删除成功！',
							type: 'success'
						});
						if ((this.tableData1.length - 1) == 0 && this.table1.page > 1) {
							this.table1.page--
						}
						this.getPro1List()
					}
				})
			}).catch(() => {
				this.$message({
					type: 'warn',
					message: '删除失败！'
				});
			});

		},
		delPro2(row) {
			this.delMsg(this).then(() => {
				delPro2({ id: row.id }).then(res => {
					if (res.state === '200') {
						this.$message({
							message: '删除成功！',
							type: 'success'
						});
						if ((this.tableData2.length - 1) == 0 && this.table2.page > 1) {
							this.table2.page--
						}
						this.getPro2List()
					}
				})
			}).catch(() => {
				this.$message({
					type: 'warn',
					message: '删除失败！'
				});
			});
		},
		delPro3(row) {
			this.delMsg(this).then(() => {
				delPro3({ id: row.id }).then(res => {
					if (res.state === '200') {
						this.$message({
							message: '删除成功！',
							type: 'success'
						});
						if ((this.tableData3.length - 1) == 0 && this.table3.page > 1) {
							this.table3.page--
						}
						this.getPro3List()
					}
				})
			}).catch(() => {
				this.$message({
					type: 'warn',
					message: '删除失败！'
				});
			});
		}
	}
}
</script>
<style scoped>
.tableContainer {
	padding: .125rem .25rem;
}

.tableContainer .el-table {
	width: 5rem;
}
</style>