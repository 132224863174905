<template>
    <div class="login">

        <div class="img_box">
            <img src="../../assets/login.jpg" class="bgt" alt="">
        </div>
        <div class="logindiv">
            <!-- <div class="jh" v-if="codeStatus == 0 || codeStatus == 2">
                <el-button type="text" @click="openActive">点击激活</el-button>

            </div> -->
            <div class="top">
                <div class="title">
                    <span @click="openActive">盈科</span>
                    <span>私域会员管理系统</span>
                </div>
                <div class="dl">
                    登录
                </div>
            </div>

            <div class="login_box">
                <div class="box">
                    <img src="@/assets/user.png" style="width: .25rem;height: .25rem;">
                    <el-input v-model="ruleForm.username" placeholder="请输入内容"></el-input>
                </div>
                <div class="box">
                    <img src="@/assets/password.png" style="width: .25rem;height: .25rem;">
                    <el-input v-model="ruleForm.passwd" :type="type" placeholder="请输入内容">
                        <div slot="suffix" style="display: flex;align-items: center;height: 100%;">
                            <i style="font-size: 22px;margin-right: 10px;cursor: pointer;" class="el-icon-view"
                                @click="isShow"></i>
                        </div>
                    </el-input>
                </div>
                <div class="btn" @click="getUsers()">
                    登 录
                </div>
            </div>
        </div>
        <el-dialog custom-class="change_dialog" :visible.sync="dialogVisible" width="30%" :close-on-click-modal="false">
            <el-form ref="form" label-position="right" label-width="1.2rem" :model="activeForm" :rules="rule">
                <el-form-item label="激活码" prop="activationCode">
                    <el-input v-model="activeForm.activationCode"></el-input>
                </el-form-item>
                <el-form-item>
                    <div style="display: flex;flex-direction: column;">
                        <span v-if="activationCode && activationCode != ''">激活码：{{ activationCode }}</span>
                        <span v-if="expirationDate && expirationDate != ''">过期时间：{{ expirationDate }}</span>
                        <div>
                            状态：
                            <el-tag type="danger" v-if="codeStatus == 2">已到期</el-tag>
                            <el-tag type="danger" v-if="codeStatus == 1">未激活</el-tag>
                        </div>
                    </div>
                </el-form-item>
            </el-form>
            <span slot="title" class="dialog-title">
                <span>激活</span>
            </span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="cancel()">取 消</el-button>
                <el-button type="primary" @click="active">激 活</el-button>
            </span>
        </el-dialog>
    </div>
</template>
 
<script>
import { login, saveActivationCode, getActivationCode } from '@/api/login/index'
import axios from 'axios'
export default {
    data() {
        return {
            type: "password",
            show: false,
            ruleForm: {
                username: "",
                passwd: ""
            },
            rules: {
                username: [
                    { required: true, message: '请输入账号', trigger: 'blur' }
                ],
                passwd: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                    { min: 6, message: '密码长度最小为6个字符', trigger: 'blur' }
                ]
            },
            activeForm: {
                activationCode: ''
            },
            dialogVisible: false,
            rule: {
                activationCode: [{ required: true, message: '请输入激活码', trigger: 'blur' }],
            },
            activationCode: '',
            apiUrl: '',
            expirationDate: '',
            // 0未激活 1已激活 2已到期
            codeStatus: 0
        }
    },
    created() {
        this.getCode()
    },
    methods: {
        getCode() {
            getActivationCode().then(res => {
                if (res.state == '200') {
                    if (res.data.expirationDate) {
                        this.activationCode = res.data.activationCode
                        this.apiUrl = res.data.apiUrl
                        this.expirationDate = res.data.expirationDate
                        // 1激活或2过期 
                        this.codeStatus = res.data.status
                    } else {
                        this.codeStatus = 0
                    }
                }
            })
        },
        // 是否显示密码
        isShow() {
            this.show = !this.show
            // 是则显示密码
            if (this.show) {
                this.type = "text"
            } else { // 否则不显示
                this.type = "password"
            }
        },

        // 登录
        getUsers(formName) {
            if (this.ruleForm.username == '') {
                this.$message.error('请填写用户名')
                return
            }
            if (this.ruleForm.passwd == '') {
                this.$message.error('请填写密码')
                return
            }
            login({ login: this.ruleForm.username, password: this.ruleForm.passwd }).then(res => {
                const { data } = res
                if (res.state == '200') {
                    this.$message({
                        message: '登录成功！',
                        type: 'success'
                    });
                    sessionStorage.setItem('login', data.login)
                    sessionStorage.setItem('token', data.token)
                    sessionStorage.removeItem('routeIndex')
                    this.$router.push('/workContent')
                }
            }, err => {
            })

            localStorage.clear()
        },
        changeTime(inputDate) {
            // 将日期字符串转换为Date对象  
            const dateObj = new Date(inputDate);

            // 获取时间戳（毫秒）  
            const timestamp = dateObj.getTime();

            // 转换格式 
            const outputDate = new Date(timestamp + 8 * 60 * 60 * 1000).toISOString().replace('.000Z', '.995Z');
            return outputDate
        },
        active() {
            let url = process.env.VUE_APP_BASE_API + '/'
            console.log(process.env.VUE_APP_ACTIVE_CODE_API + 'activation/checkActivationCodeInfo');
            this.$refs.form.validate(valid => {
                if (valid) {
                    console.log(1111);
                    axios({
                        method: "post",
                        url: process.env.VUE_APP_ACTIVE_CODE_API + 'activation/checkActivationCodeInfo',
                        data: { ...this.activeForm, apiUrl: url },
                    }).then((response) => {
                        let res = response.data
                        if (res.state == '10014') {
                            this.$message.error(res.error)
                        } else if (res.state == '0') {
                            this.activationCode = res.data.activationCode
                            this.apiUrl = res.data.apiUrl
                            this.expirationDate = res.data.expirationDate

                            this.getCode()
                            this.$message.success('激活成功')

                            saveActivationCode({
                                id: res.data.id,
                                activationCode: res.data.activationCode,
                                expirationDate: this.changeTime(res.data.expirationDate)
                            }).then(res => {
                                if (res.state == '200') {
                                    console.log('保存成功');

                                }
                            })
                        }
                        this.cancel()
                        // console.log(res);
                    });

                }
            })
        },
        cancel() {
            this.dialogVisible = false
            this.activeForm = {
                activationCode: ''
            }
        },
        openActive() {
            if (!this.codeStatus || this.codeStatus == 0 || this.codeStatus == 2) {
                this.dialogVisible = true
            }

        }

    },
    mounted() {
        console.log('query==', this.$route.query);
    }
}
</script>
 
<style scoped>
.btn {
    width: 100%;
    height: .5rem;
    background-color: #3761d7;
    border-radius: .5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    /* margin-top: .375rem; */
    margin-top: .8rem;
    cursor: pointer;
}

.box {
    display: flex;
    border-bottom: 1px solid #ccc;
    align-items: center;
    margin-bottom: .25rem;

}

.box img {
    margin-right: .125rem;
}

.login_box {
    width: 65%;
}

.login_box .item {
    border-bottom: 1px solid #ccc;
    /* display: flex;
    align-items: center; */
}

.login_box ::v-deep input.el-input__inner {
    border: none;
}

.login {
    width: 100vw;
    height: 100vh;
    display: flex;

}

.bgt {
    position: absolute;
    object-fit: cover;
    margin-left: -2.2rem;
    width: 100%;
    height: -webkit-fill-available;
    z-index: -100;
}

.heads {
    height: 1rem;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 24px;
    color: #2d6fe9;
    padding-left: 30px;
    border-bottom: 1px solid #f0eeee;
}

.box_login {
    display: flex;
    height: calc(100vh - 1rem);

}

.img_box {
    width: 70vw;
    height: 100%;
}

.jh {
    display: flex;
    flex-direction: column;
}

.top {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: .375rem;
    margin-top: 1rem;
}

.logindiv {
    background-color: #fff;
    width: 35vw;
    height: 100%;
    display: flex;
    /* justify-content: center; */
    flex-direction: column;
    align-items: center;
    /* padding: .5rem; */
    padding: 1rem .125rem;
}

.title {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: .5rem;
}

.title span {
    font-size: .375rem;
    color: #2d6fe9;
    cursor: pointer;
}

.dl {
    width: 1rem;
    font-size: 20px;
    font-weight: 600;
    color: #000;
    /* padding: 30px 10px; */
    margin: .5rem 0;
    border-bottom: 2px solid #3761d7;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>