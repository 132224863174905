<template>
    <div>
        <el-upload class="upload-demo" list-type="picture-card" ref="upload" action="" multiple :limit="1"
            :file-list="fileList" name="uploadFile" :http-request="upload" :on-success="handleAvatarSuccess"
            :before-upload="onprogress" :on-exceed="limitChange" :disabled="disabled">
            <i class="el-icon-camera avatar-uploader-icon"></i>
            <!-- <div slot="tip" class="el-upload__tip"> 图片上传 </div> -->
            <!-- <div slot="file" slot-scope="{file}">
                                    <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
                                </div> -->
        </el-upload>
    </div>
</template>
<script>
import { getImage } from '@/api/memberManage/index'
import axios from 'axios';
export default {
    props: {
        image: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            fileList: [],
            filename: ''
        }
    },
    watch: {
        image(newVal, oldVal) {
            console.log(newVal, 'xinshuju')
            this.fileList = []
            if (newVal && newVal != null && newVal != '') {
                // const formData = new FormData();
                // formData.append('imageUrl', newVal);
                // getImage(formData).then(response => {
                //     var img = 'data:image/jpeg;base64,' + this.arrayBufferToBase64(response)
                //     // this.imgsrc = qrUrl;
                //     // console.log(img)
                //     this.fileList.push({ name: '图片', url: img })

                // })
                this.fileList.push({ name: '图片', url: newVal })
            } else {
                this.fileList = []
            }
            // sessionStorage.setItem('menu',JSON.stringify(newVal))
        }
    },
    mounted() {
        console.log(this.image, 'diyici')
        this.fileList = []
        if (this.image && this.image != null && this.image != '') {
            console.log('image:', this.image);
            // const formData = new FormData();
            // formData.append('imageUrl', this.image);
            // getImage(formData).then(response => {
            //     var img = 'data:image/jpeg;base64,' + this.arrayBufferToBase64(response)
            //     // this.imgsrc = qrUrl;
            //     this.fileList.push({ name: '图片', url: img })

            // })
            this.fileList.push({ name: '图片', url: this.image })
        }
    },
    methods: {
        limitChange(file) {
            console.log(file)
            if (this.fileList.length == 1) {
                this.$message.error('请先删除上传的图片')
                return
            }
        },
        upload(file) {
            console.log(file, process.env.VUE_APP_BASE_API, '导入')
            if (file.file.size / 1024 > 600) {
                this.$message.error('文件超出限制，最大为600k')
                return
            }
            this.fileList = []
            // this.submitFilx = file
            const formData = new FormData()
            formData.append('image', file.file)
            // 多文件上传带其他参数，传递formdata和data不能一起传递，要传递formdata   就不能有data
            axios.post(process.env.VUE_APP_BASE_API + "/sys/imageUpload/imageUpload", formData, {
                headers: {
                    "Content-type": "multipart/form-data"
                }
            }).then(res => {
                console.log(res, 'resss')
                console.log(res.data.message);
                if (res.data.state !== '200') {
                    console.log(2222222222);
                    this.$message({
                        message: res.data.message,
                        type: 'error',
                        duration: 5000
                    });
                    return
                }
                this.$message({
                    message: '上传成功',
                    type: 'success',
                });
                this.image = res.data.data.imageUrl
                this.$emit('custom-event', res.data.data.imageUrl);
                // this.fileList.push({ name: this.filename, url: this.img })
                // const formData = new FormData();
                // formData.append('imageUrl', res.data.data.imageUrl);
                // getImage(formData).then(response => {
                //     var img = 'data:image/jpeg;base64,' + this.arrayBufferToBase64(response)
                //     // this.imgsrc = qrUrl;
                //     this.fileList.push({ name: this.filename, url: img })
                // })
                this.handleAvatarSuccess(res.data)
            }).catch(res => {

                console.log(res, '失败')
            })
        },
        arrayBufferToBase64(buffer) {
            var binary = ''
            var bytes = new Uint8Array(buffer)
            var len = bytes.byteLength
            for (var i = 0; i < len; i++) {
                binary += String.fromCharCode(bytes[i])
            }
            return window.btoa(binary)
        },
        handleAvatarSuccess(res, file) {
            console.log(res)
            this.$refs.upload.clearFiles();
        },
        onprogress(file, fileList) {
            this.fileList = []
            this.filename = file.name
            var str = file.name.split('.')
            console.log(str)
            // this.extName = str[str.length - 1]
        }
    }
}
</script>
<style scoped></style>