<template>
    <div class="platformContainer">
        <div class="systemSettingsNav">
            <el-menu :default-active="ml_defaultIndex" class="el-menu-demo" mode="horizontal" @select="ml_handleSelect">
                <el-menu-item index="ml_tag1">会员冻结</el-menu-item>
            </el-menu>
        </div>
        <div class="sf_main" v-if="ml_activeIndex === 'ml_tag1'">
            <div class="sf_des">
                <el-descriptions class="margin-top" :column="5" size="mini" :contentStyle="content_style" :colon="false"
                    :labelStyle="label_style">
                    <el-descriptions-item label="手机号码">
                        <el-input ref="teleRef" v-model="formSearch.telephone" placeholder="手机号搜索" :size="size"></el-input>
                    </el-descriptions-item>
                    <el-descriptions-item label="姓名">
                        <el-input v-model="formSearch.name" :size="size" placeholder="姓名搜索"></el-input>
                    </el-descriptions-item>
                    <el-descriptions-item label="身份证号">
                        <el-input v-model="formSearch.idCard" :size="size" placeholder="身份证号搜索"></el-input>
                    </el-descriptions-item>
                    <el-descriptions-item label="客户区域(市)">
                        <el-select v-model="formSearch.city" placeholder="请选择" :size="size" clearable @change="cityChange">
                            <el-option v-for="(item, index) in cityList" :key="index" :label="item.regionName"
                                :value="item.regionName">
                            </el-option>
                        </el-select>
                    </el-descriptions-item>
                    <el-descriptions-item label="客户区域(区)">
                        <el-select v-model="formSearch.area" placeholder="请选择" :size="size" clearable>
                            <el-option v-for="(item, index) in areaList" :key="index" :label="item.regionName"
                                :value="item.regionName">
                            </el-option>
                        </el-select>
                    </el-descriptions-item>
                    <!-- <el-descriptions-item label="客户性质">
                        <el-select v-model="memberLevel" placeholder="请选择" :size="size">
                            <el-option v-for="item in memberLevelList" :key="item.memberLevel" :label="item.label"
                                :value="item.memberLevel">
                            </el-option>
                        </el-select>
                    </el-descriptions-item> -->
                    <el-descriptions-item label="会员级别">
                        <el-select v-model="formSearch.vipLevelId" placeholder="请选择" :size="size" clearable>
                            <el-option v-for="(item, index) in vipLevelList" :key="index" :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-descriptions-item>
                    <el-descriptions-item label="客户分类">
                        <el-select v-model="formSearch.attributeIdTwo" placeholder="请选择" :size="size" clearable>
                            <el-option v-for="(item, index) in customerFl" :key="index" :label="item.source"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-descriptions-item>
                    <el-descriptions-item label="客户来源">
                        <el-select v-model="formSearch.attributeIdZero" placeholder="请选择" :size="size" clearable>
                            <el-option v-for="(item, index) in customerLy" :key="index" :label="item.source"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-descriptions-item>
                    <el-descriptions-item label="介绍人">
                        <el-input v-model="formSearch.sellerId" :size="size" placeholder="介绍人搜索"></el-input>
                    </el-descriptions-item>
                    <!-- <el-descriptions-item label="所属门店">
                        <el-select v-model="formSearch.storeId" placeholder="请选择" :size="size" clearable>
                            <el-option v-for="(item, index) in StoreList" :key="index" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-descriptions-item> -->
                    <el-descriptions-item label="车牌号码">
                        <el-input v-model="formSearch.carNum" :size="size" placeholder="车牌号搜索"></el-input>
                    </el-descriptions-item>
                    <el-descriptions-item label="车架号码">
                        <el-input v-model="formSearch.carFrameNumber" :size="size" placeholder="车架号搜索"></el-input>
                    </el-descriptions-item>
                    <el-descriptions-item label="车辆系列">
                        <el-select v-model="formSearch.carSeries" placeholder="请选择" :size="size" @change="carChange"
                            clearable>
                            <el-option v-for="(item, index) in seriesList" :key="index" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-descriptions-item>
                    <el-descriptions-item label="车辆型号">
                        <el-select v-model="formSearch.carModel" placeholder="请选择" :size="size" clearable>
                            <el-option v-for="(item, index) in xhList" :key="index" :label="item.model" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-descriptions-item>
                    <el-descriptions-item label="入会时间">
                        <el-date-picker type="date" placeholder="选择日期" value-format="yyyy-MM-dd" :size="size"
                            v-model="formSearch.createTime" style="width: 100%;"></el-date-picker>
                    </el-descriptions-item>
                    <el-descriptions-item label="截止时间">
                        <el-date-picker type="date" placeholder="选择日期" value-format="yyyy-MM-dd" :size="size"
                            v-model="formSearch.expireTime" style="width: 100%;"></el-date-picker>
                    </el-descriptions-item>
                    <el-descriptions-item label="生日开始">
                        <el-date-picker type="date" placeholder="选择日期" value-format="yyyy-MM-dd" :size="size"
                            v-model="formSearch.createBirthday" style="width: 100%;"></el-date-picker>
                    </el-descriptions-item>
                    <el-descriptions-item label="生日截止">
                        <el-date-picker type="date" placeholder="选择日期" value-format="yyyy-MM-dd" :size="size"
                            v-model="formSearch.endBirthday" style="width: 100%;"></el-date-picker>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <div class="operateBtn">
                            <el-button type="primary" :size="size" @click="getMemberList">查询</el-button>
                            <el-button type="primary" :size="size" @click="exportData" v-if="isExport == 1">导出</el-button>
                        </div>
                    </el-descriptions-item>
                </el-descriptions>
            </div>
            <div class="sf_footer">
                <div class="tableStyle">
                    <el-table :data="memberList" :stripe="true" size="mini" header-cell-class-name="headerClassName"
                        :cell-style="{ 'text-align': 'center' }" :border="true" :fit="true" style="width:21rem">
                        <el-table-column prop="id" label="编号">
                            <template slot-scope="scope">
                                <span>{{ scope.$index + 1 }}</span>
                            </template>
                        </el-table-column>
                        <!-- <el-table-column prop="id" label="会员卡号"></el-table-column> -->
                        <el-table-column prop="name" label="姓名"></el-table-column>
                        <el-table-column prop="telephone" label="手机号" width="120"></el-table-column>
                        <el-table-column prop="idCard" label="身份证号" width="200"></el-table-column>
                        <el-table-column prop="birthday" label="会员生日" width="120"></el-table-column>
                        <el-table-column prop="attributeName" label="客户区域" width="200"></el-table-column>
                        <el-table-column prop="vipLevelName" label="会员等级" width="120"></el-table-column>
                        <el-table-column prop="credit" label="可用积分" width="120"></el-table-column>
                        <el-table-column prop="balance" label="可用余额" width="120"></el-table-column>
                        <el-table-column prop="carFrameNumber" label="车架号" width="200"></el-table-column>
                        <el-table-column prop="carNum" label="车牌号" width="150">
                            <template slot-scope="scope">
                                <p v-for="(item, index) in scope.row.clientManageCarDOList" :key="index">{{ item.carNum }}
                                </p>
                            </template>
                        </el-table-column>
                        <el-table-column prop="model" label="车辆型号" width="120">
                            <template slot-scope="scope">
                                <p v-for="(item, index) in scope.row.clientManageCarDOList" :key="index">{{ item.model }}
                                </p>
                            </template>
                        </el-table-column>
                        <el-table-column prop="storeName" label="开卡店"></el-table-column>
                        <el-table-column prop="cutoffTime" label="开卡到期" width="120"></el-table-column>
                        <el-table-column prop="actived" label="会员卡状态" class="statusStyle" width="130">
                            <template slot-scope="scope">
                                <p v-if="scope.row.actived == 1">正常</p>
                                <p v-if="scope.row.actived == 4">冻结</p>
                                <p v-if="scope.row.actived == 5">退卡</p>
                                <p v-if="scope.row.actived == 6">解冻</p>
                                <p v-if="scope.row.actived == 7">延期</p>
                            </template>
                        </el-table-column>
                        <el-table-column prop="createTime" label="加入开始时间" width="120"></el-table-column>
                        <el-table-column prop="cutoffTime" label="加入截止时间" width="120"></el-table-column>
                        <el-table-column label="操作">
                            <template slot-scope="scope">
                                <el-button @click="editStatus(scope.row)" type="text" size="small">编辑</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="sf_page">
                        <el-pagination background layout="total, prev, pager, next" :page-size="10"
                            :current-page.sync="formSearch.page" @current-change="getMemberList" :total="total">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
        <EditStatusModal ref="editStatusModal" @child-event="getMemberList" />
    </div>
</template>
<script>
import { carSeries, getCarList } from '@/api/systemOption/systemSettings'
import EditStatusModal from '../memberManageModal/EditStatusModal.vue'
import * as memberApi from '@/api/memberManage/index'
export default {
    components: { EditStatusModal },
    data() {
        return {
            ml_defaultIndex: 'ml_tag1',
            ml_activeIndex: 'ml_tag1',
            editStatusMessage: '',
            form: '',
            radio: '0',
            content_style: {
                'width': '15%',
            },
            label_style: {
                'width': '1.3rem',
                'justify-content': 'end'
            },
            size: 'small',
            memberList: [],
            formSearch: {
                page: 1,
                pageSize: 10,
                carModel: '',
                carSeries: '',
                telephone: '',
                name: '',
                idCard: '',
                city: '',
                area: '',
                vipLevelId: '',
                attributeIdTwo: '',
                attributeIdZero: '',
                sellerId: '',
                storeId: '',
                carNum: '',
                carFrameNumber: '',
                createTime: '',
                expireTime: '',
                createBirthday: '',
                endBirthday: ''
            },
            xl: '',
            total: 0,
            customerLy: [],
            customerFl: [],
            StoreList: [],
            carList: [],
            vipLevelList: [],
            cityList: [],
            areaList: [],
            xhList: [],
            seriesList: [],
            isExport: false
        }
    },
    methods: {
        carChange(e) {
            if (e == '') {
                this.xhList = []
            } else {
                getCarList({ seriesId: e }).then(res => {
                    if (res.state == '200') {
                        this.xhList = res.data
                    }
                })
            }
            this.formSearch.carModel = ''
        },
        ml_handleSelect(key, keyPath) {
            this.ml_activeIndex = key
        },
        editStatus(row) {
            this.$refs.editStatusModal.openDialog(row)
        },
        //获取会员列表
        getMemberList() {
            memberApi.membershipFreezeList(this.formSearch).then(res => {
                let { data } = res
                this.memberList = data.records.map(item => {
                    return {
                        ...item,
                        carFrameNumber: item.clientManageCarDOList[0].carFrameNumber || ''
                    }
                })
                this.total = data.total
            })
        },
        //获取客户属性列表
        getCustomerSX(type) {
            memberApi.attributeGetList({ type: type }).then(res => {
                let { data } = res
                if (type == 0) {
                    this.customerLy = data
                } else if (type == 2) {
                    this.customerFl = data
                }
            })
        },
        getCommn() {
            carSeries({ brandId: '' }).then(res => {
                if (res.state == '200') {
                    this.seriesList = res.data
                }
            })
            memberApi.clientVipLevelGetList({}).then(res => {
                let { data } = res
                this.vipLevelList = data
            })
            memberApi.storeGetList({}).then(res => {
                let { data } = res
                this.StoreList = data
            })
        },
        //获取城市列表
        getcityList(id) {
            memberApi.cityList({ id: id }).then(res => {
                let { data } = res
                if (id == '') {
                    this.cityList = data
                } else {
                    this.areaList = data
                }
            })
        },
        cityChange(e) {
            console.log(e)
            this.cityList.forEach(item => {
                if (item.regionName == e) {
                    console.log(item.regionName == e, '11111111111')
                    this.getcityList(item.id)
                }
            })
        },
        exportData() {
            let exportUrl = memberApi.exportFreezeList
            let fileName = '会员冻结'
            this.formSearch.carSeries = this.formSearch.carModel
            exportUrl(this.formSearch).then(res => {
                let blob = new Blob([res], {
                    // type: 'application/actet-stream;charset=utf-8'
                    type: 'application/vnd.ms-excel'
                }); // 构造一个blob对象来处理数据，并设置文件类型
                let a = document.createElement('a'); // 生成一个a标签
                let href = window.URL.createObjectURL(blob); //创建下载的链接
                a.href = href; //指定下载链接
                a.download = fileName //指定下载文件名
                // a.setAttribute('download', 'fileName')
                document.body.appendChild(a); //把a标签加到页面中
                a.click(); //触发a标签下载
                document.body.removeChild(a); //下载完成移除元素
                window.URL.revokeObjectURL(href); //释放掉blob对象
            })
        }
    },
    // 使用
    mounted() {
        this.$refs.teleRef.focus()
        this.isExport = sessionStorage.getItem('isExport')
        this.getMemberList()
        this.getCustomerSX(0)
        this.getCustomerSX(2)
        this.getCommn()
        this.getcityList('')
    }
}
</script>
<style scoped>
.statusStyle {
    color: rgb(10, 182, 0);
}

.platformContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.sf_main {
    flex: 1;
    background-color: rgb(247, 248, 250);
    padding-top: .25rem;
    display: flex;
    flex-direction: column;
}

.sf_des {
    background-color: white;
    border: 1px solid rgb(235, 235, 235);
    padding: .25rem;
    border-radius: .125rem;
}


.sf_des .el-descriptions--mini:not(.is-bordered) .el-descriptions-item__cell {
    padding-right: .25rem;
}

.sf_footer {
    flex: 1;
    margin-top: .125rem;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: .125rem;
}

.sf_footer .tableStyle {
    width: 100% !important;
    flex: 1;
    overflow-x: auto;
}

.sf_footer .show_data {
    width: 100%;
    height: 1.125rem
}

.sf_footer .summaryInfo_Bootom {
    padding: 0 .5rem;
    font-size: large;
    font-weight: bold;
    color: rgb(92, 165, 243)
}

.sf_footer .sf_page {
    display: flex;
    justify-content: flex-end;
}
</style>