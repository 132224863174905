<template>
    <div>
        <el-dialog custom-class="addCard" :visible.sync="dialogVisible" :modal="true" :append-to-body="true"
            :close-on-click-modal="false" @close="closeDialog()" width="25vw">
            <div style="padding: .25rem; height: 8vh;">
                <el-form ref="form" :model="form" label-width="100px" size="mini" :rules="rules">
                    <el-row :gutter="20">
                        <el-col :span="24">
                            <el-form-item label="编号" prop="code">
                                <el-input v-model="form.code"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20">
                        <el-col :span="24">
                            <el-form-item label="车辆颜色" prop="color">
                                <el-input v-model="form.color"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <span slot="title" class="dialog-title">
                <span><i class="el-icon-warning"></i>{{ edit ? '编辑颜色' : '添加颜色' }}</span>
            </span>
            <span slot="footer" class="dialog-footer">
                <div slot="footer" class="dialog-footer">
                    <el-button size="small" @click="closeDialog()">取 消</el-button>
                    <el-button size="small" type="primary" @click="save()">确 定</el-button>
                </div>
            </span>
        </el-dialog>
    </div>
</template>  
    
<script>
import { addColor, updateColor } from '@/api/systemOption/systemSettings';

export default {
    data() {
        return {
            edit: false,
            dialogVisible: false,
            form: {
                color: '',
                code: ''
            },
            rules: {
                color: [{ required: true, message: '请输入车辆颜色', trigger: 'blur' }],
                code: [{ required: true, message: '请输入编号', trigger: 'blur' }]
            }
        };
    },
    methods: {
        resetForm() {
            this.form = {
                color: '',
                code: ''
            }
        },
        openDialog(data) {
            console.log('222');
            // 打开弹出框，并触发父组件的事件传递参数  
            this.dialogVisible = true;
            this.edit = false
            if (data) {
                this.form = data
                this.edit = true
            } else {
                this.resetForm()
            }
        },
        closeDialog() {
            // 关闭弹出框  
            this.dialogVisible = false;
            // this.$refs.form?.resetFields()
        },
        save() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    if (this.form.id) {
                        updateColor(this.form).then(res => {
                            if (res.state === '200') {
                                this.dialogVisible = false
                                this.$message({
                                    message: '修改成功！',
                                    type: 'success'
                                });
                                this.$emit('reload')
                            }
                        })
                    } else {
                        addColor(this.form).then(res => {
                            if (res.state === '200') {
                                this.dialogVisible = false
                                this.$message({
                                    message: '添加成功！',
                                    type: 'success'
                                });
                                this.$emit('reload')
                            }
                        })
                    }

                }
            })
        }
    }
};
</script>
<style scoped>

</style>
  