<template>
    <div class="accountContainer">
        <div class="accountNav">
            <el-menu :default-active="account_defaultIndex" class="el-menu-demo" mode="horizontal"
                @select="account_handleSelect">
                <el-menu-item index="account_tag1">公众号绑定</el-menu-item>
                <el-menu-item index="account_tag2">模板消息设置</el-menu-item>
            </el-menu>
        </div>
        <div class="accountMain" v-if="account_activeIndex === 'account_tag1'">
            <el-form ref="form" label-width="2rem" size="mini" :rules="rules" :model="form">
                <span class="title">一、填写公众信息</span>
                <div class="content">
                    <el-form-item label="AppID" prop="appId">
                        <el-input v-model="form.appId"></el-input>
                        <span class="note">登录微信公众平台(mp.weixin.qq.com)[开发]-[基本配置]中查找</span>
                    </el-form-item>
                    <el-form-item label="AppSecret" prop="appSecret">
                        <el-input v-model="form.appSecret"></el-input>
                        <span class="note">登录微信公众平台(mp.weixin.q9.com)[开发]-[基本配置]中查找</span>
                    </el-form-item>
                    <el-form-item label="公众号名称" prop="name">
                        <el-input v-model="form.name"></el-input>
                    </el-form-item>
                    <el-form-item label="公众号类型" prop="wechatAccountType">
                        <el-radio-group v-model="form.wechatAccountType">
                            <el-row style="margin-bottom: 0.2rem;">
                                <el-radio :label="1">认证服务号</el-radio>
                                <el-radio :label="2">认证订阅号</el-radio>
                            </el-row>
                            <el-row>
                                <el-radio :label="3">普通服务号</el-radio>
                                <el-radio :label="4">普通订阅号</el-radio>
                            </el-row>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="公众号头像">
                        <!-- <el-upload class="upload-store" ref="upload" :data="form" :on-preview="handlePreview"
                            :auto-upload="false" :on-success="handleUploadSuccess" list-type="picture-card"
                            :multiple="false" limit="1">
                            <i slot="default" class="el-icon-plus"></i>
                        </el-upload> -->
                    </el-form-item>
                    <el-form-item label="公众号二维码">
                        <!-- <el-upload class="upload-store" ref="upload" :data="form" :on-preview="handlePreview"
                            :auto-upload="false" :on-success="handleUploadSuccess" list-type="picture-card"
                            :multiple="false" limit="1">
                            <i slot="default" class="el-icon-plus"></i>
                        </el-upload> -->
                    </el-form-item>
                </div>
                <span class="title">二、设置IP白名单及域名</span>
                <div class="content">
                    <el-form-item label="IP白名单" prop="ipWhiteList">
                        <el-input v-model="form.ipWhiteList"></el-input>
                        <span class="note">[开发]-[基本配置]-[IP白名单]中设置</span>
                    </el-form-item>
                    <el-form-item label="业务域名" prop="businessDomainName">
                        <el-input v-model="form.businessDomainName"></el-input>
                        <span class="note">[设置]-[公众号设置]-[功能设置]中设置</span>
                    </el-form-item>
                    <el-form-item label="JS接口安全域名" prop="jsName">
                        <el-input v-model="form.jsName"></el-input>
                        <span class="note">[设置]-[公众号设置]-[功能设置]中设置</span>
                    </el-form-item>
                    <el-form-item label="网页授权域名" prop="webPageAuthorization">
                        <el-input v-model="form.webPageAuthorization"></el-input>
                        <span class="note">[设置]-[公众号设置]-[功能设置]中设置</span>
                    </el-form-item>
                </div>
                <span class="title">三、配置服务器域名</span>
                <div class="content">
                    <span class="title2">登录微信公众平台(mp.weixin.qq.com)在[开发]-[基本配置]-[服务器配置]，配置以下信息:</span>
                    <span class="title2" style="color: red;">注意:填写完成以下信息后需先点击下方保存按钮，否则会校验失败再在公众平台点击提交按钮，否则会校验失败</span>
                    <el-form-item label="URL(服务器地址)" prop="url">
                        <el-input v-model="form.url"></el-input>
                    </el-form-item>
                    <el-form-item label="Token(令牌)" prop="token">
                        <el-input v-model="form.token"></el-input>
                    </el-form-item>
                    <el-form-item label="EncodingAESKey(消息加密密钥)" prop="encodingAESKey">
                        <el-input v-model="form.encodingAESKey"></el-input>
                    </el-form-item>
                    <el-form-item label="消息加密方式" prop="encodingType">
                        <el-select v-model="form.encodingType" placeholder="请选择">
                            <el-option label="明文模式" value="1"></el-option>
                            <el-option label="兼容模式" value="2"></el-option>
                            <el-option label="安全模式" value="3"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="save()">保存</el-button>
                        <el-button>上传公众号接口校验文件</el-button>
                    </el-form-item>
                </div>
            </el-form>
        </div>

        <div class="accountMain" v-if="account_activeIndex === 'account_tag2'">

            <el-form ref="form1" label-width="2.5rem" size="small">
                <div v-for="(item, index) in formList" :key="index">
                    <el-form-item label="模版开启状态" v-if="item.type == 12">
                        <el-row :gutter="10">
                            <el-col :span="20">
                                <el-switch v-model="item.templateId" active-color="#13ce66" inactive-color="#ff4949"
                                    active-value="1" inactive-value="0">
                                </el-switch>
                            </el-col>
                        </el-row>
                    </el-form-item>
                    <el-form-item v-if="item.type != 12" :label="item.name">
                        <el-row :gutter="10">
                            <el-col :span="20">
                                <el-input placeholder="请输入模版ID" v-model="item.templateId"></el-input>
                            </el-col>
                        </el-row>
                    </el-form-item>
                    <el-form-item v-if="item.type != 12" label="模版字段">
                        <el-row :gutter="10">
                            <el-col :span="5" v-for="(items, indexs) in item.templateFieldConfigDOList" :key="indexs">
                                <div style="display: flex;align-items: center">
                                    <label style="color:#606266;margin-right: .125rem;">{{ items.fieldNameChinese }}</label>
                                    <el-input placeholder="例 thing1.DATA 填写thing1即可" style="flex:1;"
                                        v-model="items.fieldNameEnglish"></el-input>
                                </div>
                            </el-col>
                        </el-row>
                    </el-form-item>
                </div>
                <el-form-item>
                    <el-button style="background-color: #009688;color: white;" @click="saveConfig()">保存</el-button>
                </el-form-item>
            </el-form>

        </div>
    </div>
</template>
<script>
import { getWechatAccount, configWechatAccount, getTemplateConfig, configTemplateConfig } from '../../../api/systemOption/officialAccountSettings/index'
export default {
    data() {
        return {
            account_defaultIndex: 'account_tag1',
            account_activeIndex: 'account_tag1',
            file: '',
            file2: '',
            form: {
                appId: '',
                appSecret: '',
                name: '',
                wechatAccountType: '',
                ipWhiteList: '',
                businessDomainName: '',
                jsName: '',
                webPageAuthorization: '',
                url: '',
                token: '',
                encodingAESKey: '',
                encodingType: ''
            },
            rules: {
                appId: [{ required: true, message: '请输入AppID', trigger: 'blur' }],
                appSecret: [{ required: true, message: '请输入AppSecret', trigger: 'blur' }],
                name: [{ required: true, message: '请输入公众号名称', trigger: 'blur' }],
                wechatAccountType: [{ required: true, message: '请选择公众号类型', trigger: 'blur' }],
                ipWhiteList: [{ required: true, message: '请输入IP白名单', trigger: 'blur' }],
                businessDomainName: [{ required: true, message: '请输入业务域名', trigger: 'blur' }],
                jsName: [{ required: true, message: '请输入JS接口安全域名', trigger: 'blur' }],
                webPageAuthorization: [{ required: true, message: '请输入网页授权域名', trigger: 'blur' }],
                url: [{ required: true, message: '请输入URL(服务器地址)', trigger: 'blur' }],
                token: [{ required: true, message: '请输入Token(令牌)', trigger: 'blur' }],
                encodingAESKey: [{ required: true, message: '请输入EncodingAESKey(消息加密密钥)', trigger: 'blur' }],
                encodingType: [{ required: true, message: '请输入消息加密方式', trigger: 'blur' }],
            },
            formList: []
        }
    },
    mounted() {
        this.setData()
        this._getTemplateConfig()
    },
    methods: {
        account_handleSelect(key, keyPath) {
            this.account_activeIndex = key
        },
        handleRemove1() {
            this.file1 = ''
        },
        handleRemove2() {
            this.file2 = ''
        },
        setData() {
            getWechatAccount().then(res => {
                if (res.state == '200') {
                    let encodingType = this.form.encodingType
                    this.form.encodingType = encodingType.toString()
                    this.form = res.data
                }
            })
        },
        save() {
            let encodingType = this.form.encodingType
            this.form.encodingType = Number(encodingType)
            this.$refs.form.validate(val => {
                if (val) {
                    configWechatAccount(this.form).then(res => {
                        if (res.state == '200') {
                            this.$message.success('保存成功')
                            this.setData()
                        }
                    })
                } else {
                    return false
                }
            })
        },
        _getTemplateConfig() {
            getTemplateConfig().then(res => {
                console.log(res)
                if (res.state == '200') {
                    res.data.forEach(item => {
                        switch (item.type) {
                            case 1:
                                item.name = '保养维修项目充值成功通知'
                                break;
                            case 2:
                                item.name = '余额充值成功通知'
                                break;
                            case 3:
                                item.name = '项目消费成功通知'
                                break;
                            case 4:
                                item.name = '账户余额扣除成功提醒'
                                break;
                            case 5:
                                item.name = '用户注册审核结果提醒'
                                break;
                            case 6:
                                item.name = '套餐充值成功通知'
                                break;
                            case 7:
                                item.name = '套餐消费成功通知'
                                break;
                            case 8:
                                item.name = '过户成功通知'
                                break;
                            case 9:
                                item.name = '积分商品兑换提醒'
                                break;
                            case 10:
                                item.name = '会员等级变更通知'
                                break;
                            case 11:
                                item.name = '积分变动提醒'
                                break;
                        }
                    })
                    this.formList = res.data
                }
            })
        },
        saveConfig() {
            this.formList.forEach(item => {
                item.templateFieldConfigDTOList = item.templateFieldConfigDOList
            })
            configTemplateConfig(this.formList).then(res => {
                if (res.state == '200') {
                    this.$message.success('保存成功')
                    this._getTemplateConfig()
                }
            })
        }
    }
}
</script>
<style scoped>
.accountContainer {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.el-col {
    margin-bottom: .15rem;
}

.accountMain {
    background-color: #fff;
    width: 100%;
    padding: .125rem .25rem;
    flex: 1;
    margin-top: .25rem;
    display: flex;
    flex-direction: column;
    padding: .25rem;

}

.title {
    display: block;
    color: #7d7e80;
    font-size: .2rem !important;
    margin-bottom: .25rem;
}

.note {
    font-size: .15rem;
    color: rgb(124, 136, 169);
}

.title2 {
    /* font-size: ; */
    display: block;
    margin-bottom: .25rem;
}
</style>