<template>
    <div>
        <el-dialog class='editStatus' :visible.sync="dialogVisible" :modal="true" :append-to-body="true"
            :close-on-click-modal="false">
            <el-form ref="form" :model="form" label-width="100px" size="mini">
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label="会员卡状态:">
                            <el-radio-group v-model="form.actived" @input="change">
                                <el-row>
                                    <el-radio :label="4">冻结</el-radio>
                                    <el-radio :label="5">退卡</el-radio>
                                </el-row>
                                <el-row>
                                    <el-radio :label="6">解冻</el-radio>
                                    <el-radio :label="7">延期</el-radio>
                                </el-row>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label="设置延期时间:">
                            <el-date-picker :disabled="disabled" type="date" v-model="form.cutoffTime"
                                value-format="yyyy-MM-dd" placeholder="选择日期" size="small"></el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="title" class="dialog-title">
                <span><i class="el-icon-warning"></i>会员卡冻结</span>
            </span>
            <span slot="footer" class="dialog-footer">
                <div slot="footer" class="dialog-footer">
                    <el-button size="small" @click="closeDialog">取 消</el-button>
                    <el-button size="small" type="primary" @click="sure()">保 存</el-button>
                </div>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import * as memberApi from '@/api/memberManage/index'
export default {
    data() {
        return {
            dialogVisible: false, // 控制弹出框的显示与隐藏  
            form: {
                actived: '',
                cutoffTime: ''
            },
            disabled: true
        };
    },
    methods: {
        openDialog(row) {
            // 打开弹出框，并触发父组件的事件传递参数  
            this.dialogVisible = true;
            this.form.id = row.id
            this.form.actived = row.actived
            this.form.cutoffTime = row.cutoffTime
            this.disabled = true

        },
        change(e) {
            console.log(e, this.form.actived)
            if (e != 7) {
                this.disabled = true
            } else {
                this.disabled = false
            }
        },
        closeDialog() {
            // 关闭弹出框  
            this.dialogVisible = false;
            // this.form = {}
            this.form.actived = ''
        },
        sure() {
            memberApi.membershipFreezeStatus(this.form).then(res => {
                if (res.state == '200') {
                    this.$message.success('操作成功')
                    this.dialogVisible = false;
                    this.$emit('child-event', 'Hello from child');
                }
            })
        }
    },
    watch: {
        initialMessage(newValue) {
            // 监听父组件传递的参数变化，并更新子组件的数据  
            this.message = newValue;
        }
    }
};
</script>
<style scoped>
/*以下是关于弹出框的样式*/

.dialog-footer {
    text-align: center;
}

/*dialog主体的宽*/
::v-deep .el-dialog {
    width: 23vw !important;
    margin-top: 20vh !important;
    max-height: 85vh;
}

.dialog-title {
    width: 100%;
    font-size: .1875rem !important;
    color: white;
    font-weight: bold;

}

.el-dialog__header {
    padding-top: .125rem !important;
    background-image: linear-gradient(to right, rgb(254, 91, 75), rgb(249, 200, 38));
    border-radius: .125rem .125rem 0 0 !important;
}

/*改变dialog高度*/
.dialogContent {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.el-dialog {
    border-radius: .125rem !important;
}

.el-dialog__body {
    padding: .25rem;
}

.editStatus .el-row {
    margin-bottom: .125rem;
}
</style>