<template>
    <div class="memberInfoContainer">
        <div class="aside">
            <div class="user_info">
                <div class="user_image">
                    <el-avatar :size="50" :src="detailsInfo.headimgurl"></el-avatar>
                </div>
                <div class="user_detail_info">
                    <div class="user user_name">
                        <span>{{ detailsInfo.name || '默认昵称' }}</span>
                        <el-tag type="warning" effect="plain" size="mini"> {{ detailsInfo.vipLevelName || '普通用户' }}</el-tag>
                    </div>
                    <div class="user user_phone">
                        <i class="el-icon-phone">{{ detailsInfo.telephone }}</i>
                    </div>
                    <div class="user user_time">
                        <span>注册时间：{{ detailsInfo.createTime }}</span>
                    </div>
                </div>
            </div>
            <div class="own_info">
                <div class="own_info_tag">
                    <span>自费储值余额</span>
                    <span class="own_info_number">{{ detailsInfo.selfBalance ? detailsInfo.selfBalance : 0 }}</span>
                </div>
                <div class="own_info_tag">
                    <span>销售储值余额</span>
                    <span class="own_info_number">{{ detailsInfo.salesBalance ? detailsInfo.salesBalance : 0 }}</span>
                </div>
                <div class="own_info_tag">
                    <span>售后储值余额</span>
                    <span class="own_info_number">{{ detailsInfo.afterBalance ? detailsInfo.afterBalance : 0 }}</span>
                </div>
                <div class="own_info_tag">
                    <span>保险储值余额</span>
                    <span class="own_info_number">{{ detailsInfo.insuranceBalance ? detailsInfo.insuranceBalance : 0
                    }}</span>
                </div>
                <div class="own_info_tag">
                    <span>可用积分</span>
                    <span class="own_info_number">{{ detailsInfo.credit ? detailsInfo.credit : 0 }}</span>
                </div>
                <div class="own_info_tag">
                    <span>套餐数量</span>
                    <span class="own_info_number">{{ detailsInfo.holdSetmeal ? detailsInfo.holdSetmeal : 0 }}张</span>
                </div>
                <div class="own_info_tag">
                    <span>项目数量</span>
                    <span class="own_info_number">{{ detailsInfo.holdProject ? detailsInfo.holdProject : 0 }}张</span>
                </div>

            </div>
        </div>
        <div class="main">
            <el-menu :default-active="ma_defaultIndex" class="el-menu-demo" mode="horizontal" @select="ma_handleSelect">
                <el-menu-item index="ma_tag1">会员资产</el-menu-item>
                <el-menu-item index="ma_tag2" v-if="isAdmin">自费储值退款</el-menu-item>
            </el-menu>
            <div class="ma_table_Style">
                <div class="ma_memberInfoMain" v-if="ma_activeIndex === 'ma_tag1'">
                    <div class="ma_search">
                        <el-form ref="form" :model="formQuery" label-width="1.25rem" size="small">
                            <el-form-item label="手机号：">
                                <el-input ref="teleRef" v-model="formQuery.telephone" placeholder="输入手机号查询"></el-input>
                            </el-form-item>
                        </el-form>
                        <el-button type="primary" size="small" @click="search" style="margin-left: .125rem;">搜索</el-button>
                    </div>
                    <div class="ma_table">
                        <div class="giftPackage borderStyle">
                            <div class="ma_title">开通套餐</div>
                            <div class="tableStyle" style="flex: 1;overflow: auto;">
                                <el-table
                                    :data="detailsInfo.membershipPackageDOList ? detailsInfo.membershipPackageDOList : []"
                                    :stripe="true" size="mini" header-cell-class-name="headerClassName"
                                    :cell-style="{ 'text-align': 'center' }" :border="true" :fit="true" height="210">
                                    <el-table-column prop="createTime" label="开卡日期">
                                    </el-table-column>
                                    <el-table-column prop="serviceItemName" label="套餐名称">
                                    </el-table-column>
                                    <el-table-column prop="itemName" label="服务项目">
                                    </el-table-column>
                                    <el-table-column prop="num" label="可用次数">
                                    </el-table-column>
                                    <el-table-column prop="price" label="项目单价">
                                    </el-table-column>
                                    <el-table-column prop="term" label="有效期">
                                    </el-table-column>
                                </el-table>
                            </div>
                        </div>
                        <div class="giftProject borderStyle">
                            <div class="ma_title">赠送项目</div>
                            <div class="tableStyle" style="flex: 1;overflow: auto;">
                                <el-table
                                    :data="detailsInfo.membershipDonationDOList ? detailsInfo.membershipDonationDOList : []"
                                    :stripe="true" size="mini" header-cell-class-name="headerClassName"
                                    :cell-style="{ 'text-align': 'center' }" :border="true" :fit="true" height="210">
                                    <el-table-column prop="createTime" label="赠送日期">
                                    </el-table-column>
                                    <el-table-column prop="donationItemName" label="项目名称">
                                    </el-table-column>
                                    <el-table-column prop="num" label="总次数">
                                    </el-table-column>
                                    <el-table-column prop="availableTimes" label="剩余次数">
                                    </el-table-column>
                                    <el-table-column prop="term" label="有效期">
                                    </el-table-column>
                                </el-table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="ma_memberInfoMain" v-if="ma_activeIndex == 'ma_tag2' && isAdmin">
                    <div class="box">
                        <el-form ref="form" label-width="2rem" size="small">
                            <el-form-item label="会员名称">
                                <div class="r_item">
                                    <el-input disabled v-model="userInfo.name"></el-input>
                                </div>
                            </el-form-item>
                            <el-form-item label="自费储值余额">
                                <div class="r_item">
                                    <el-input disabled v-model="userInfo.selfBalance"></el-input>
                                </div>
                            </el-form-item>
                            <el-form-item label="手 机 号">
                                <div class="r_item">
                                    <el-input v-model="phone" placeholder="输入手机号查询"></el-input>
                                    <el-button type="primary" size="small" @click="search2"
                                        style="margin-left: .125rem;">搜索</el-button>
                                </div>
                            </el-form-item>
                            <el-form-item label="退款金额" size="small">
                                <div class="r_item">
                                    <el-input v-model="refuned" placeholder="退款金额"></el-input>
                                    <el-button type="primary" size="small" @click="refunedOpe" style="margin-left: .125rem;"
                                        :disabled="!userInfo.id">退款</el-button>
                                </div>
                            </el-form-item>
                        </el-form>


                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
import { selectAssetDetailById, selfRefund } from '@/api/memberManage/index'
import { getUserInfo } from '@/api/workContent'
export default {
    data() {
        return {
            ma_defaultIndex: 'ma_tag1',
            ma_activeIndex: 'ma_tag1',
            form: {},
            addCarMessage: '',
            formQuery: {},
            detailsInfo: {},
            refuned: 0,
            userInfo: {},
            phone: '',
            hyName: '',
            isAdmin: false
        }
    },
    mounted() {
        this.isAdminF()
        this.$refs.teleRef.focus()
    },
    methods: {
        isAdminF() {
            const login = sessionStorage.getItem('login')

            if (login == 'admin') {
                this.isAdmin = true
                console.log('isAdmin=', this.isAdmin);
            }
        },
        ma_handleSelect(key, keyPath) {
            this.ma_activeIndex = key
        },
        search() {
            selectAssetDetailById(this.formQuery).then(res => {
                let { data } = res
                this.detailsInfo = data
            })
        },
        search2() {
            getUserInfo({ telephone: this.phone }).then(res => {
                if (res) {
                    if (res.state == '200') {
                        if (res.data) {
                            this.userInfo = res.data
                        } else {
                            this.$message.error('暂时没有此用户信息')
                        }
                    }
                }

            })
        },
        refunedOpe() {
            this.$confirm('是否确认退款?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                selfRefund({ refundAmount: this.refuned, id: this.userInfo.id }).then(res => {
                    if (res.state == '200') {
                        this.$message.success('退款成功')
                        this.search2()
                        this.refuned = 0
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消退款'
                });
            });

        }
    },
    components: {
    }
}
</script>
<style scoped>
::v-deep .el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
    margin-bottom: 0rem !important;
}

.user_name .el-tag {
    margin-left: .375rem !important;
}

.memberInfoContainer {
    width: 100%;
    height: 100%;
    display: flex;
    padding-bottom: .125rem;
    overflow: auto;

}

.aside {
    width: 4.375rem;
    display: flex;
    flex-direction: column;
    height: 100% !important;
    border-right: 1px solid rgba(212, 212, 212, 0.5);

}

.own_info {
    padding: 0 .125rem;
}

.main {
    flex: 1;
    padding: .125rem .125rem 0 .125rem;
    display: flex;
    flex-direction: column;

}

.main .el-form-item__label {
    font-size: .2rem;
}

.user_info {
    width: 100%;
    display: flex;
    height: 1.75rem;
    align-items: center;
    border: none;
    border-bottom: 1px solid rgb(212, 212, 212);
    margin-bottom: .125rem;
    /* padding-top: .5rem; */

}

.user_image {
    width: 1.5rem;
    height: .75rem;
    display: flex;
    justify-content: center;
}

.own_info_tag {
    width: 100%;
    height: .875rem;
    background: rgb(231, 236, 255);
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: .125rem;
}

.own_info_tag span {
    display: inline-block;
}

.own_info_number {
    font-weight: bold;
    color: #ea8419;
}

.poi_title .el-divider__text.is-left {
    left: 0;
}

.poi_title .el-divider__text {
    font-weight: bold;
}

.ma_table_Style {
    border-radius: .125rem;
    flex: 1;
    padding: .25rem .25rem 0 .25rem;
    display: flex;
    flex-direction: column;
}

.ma_memberInfoMain {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.ma_memberInfoMain .el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
    margin-bottom: .125rem;
}

.save {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.ma_table {
    display: flex;
    flex-direction: column;
    /* height: 100%; */
    border: none !important;
    flex: 1;
}

.ma_title {
    font-size: .1875rem;
    font-weight: bold;
}

.borderStyle {
    border: 1px solid rgb(212, 212, 212);
    flex: 1;
    padding: .25rem;
    border-radius: .125rem;
    overflow: hidden;


}

.giftPackage {
    margin-bottom: .125rem;
    display: flex;
    flex-direction: column;
}

.ma_search {
    margin-bottom: .125rem;
    padding: .125rem;
    border: 1px solid rgb(212, 212, 212);
    border-radius: .125rem;
    display: flex;
}

.giftProject {
    display: flex;
    flex-direction: column;
}

.box {
    display: flex;
    margin-bottom: .25rem;
}

.r_item {
    display: flex;
    margin-bottom: .25rem;
}
</style>