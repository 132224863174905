<template>
	<div class="memberLevelContainer">
		<el-button type="primary" @click="openAddMemberCardDialog()" size="small">添加会员卡</el-button>
		<div class="tableStyle">
			<el-table :data="tableData" style="width: 100%" :stripe="true" size="mini"
				header-cell-class-name="headerClassName" :cell-style="{ 'text-align': 'center' }" :border="true"
				:fit="true">
				<!-- <el-table-column prop="id" label="编号">
				</el-table-column> -->
				<el-table-column prop="name" label="会员卡名称">
				</el-table-column>
				<el-table-column prop="level" label="会员卡级别">
				</el-table-column>
				<el-table-column prop="discountWorkFee" label="工时折扣(折)">
				</el-table-column>
				<el-table-column prop="discountMaterialFee" label="材料折扣">
				</el-table-column>
				<el-table-column prop="autoUpgrade" label="是否自动升级">
					<template slot-scope="scope">
						<span v-if="scope.row.autoUpgrade == '0'">否</span>
						<span v-if="scope.row.autoUpgrade == '1'">是</span>
					</template>
				</el-table-column>
				<el-table-column prop="rule" label="升级规则">
					<template slot-scope="scope">
						<span v-if="scope.row.rule == '1'">累计积分</span>
						<span v-if="scope.row.rule == '2'">一次性充值</span>
						<span v-if="scope.row.rule == '3'">累计消费</span>
					</template>
				</el-table-column>
				<el-table-column prop="upgradation" label="升级条件(分/元)">
				</el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<el-button  type="text" size="small" @click="edit(scope.row)">编辑</el-button>
						<el-button v-if="scope.row.actived == '1'" type="text" size="small" style="color:green;"
							@click="changeState(scope.row)">启用</el-button>
						<el-button v-if="scope.row.actived == '0'" type="text" size="small" style="color:red"
							@click="changeState(scope.row)">停用</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination background layout="prev, pager, next" :total="total" :current-page.sync="page"
				:page-size="pageSize" @current-change="changePage">
			</el-pagination>
		</div>
		<MemberLevelModal ref="memberLevelModal"  @child-event="handleChildEvent" />
	</div>

</template>
<script>
	import MemberLevelModal from '../../../systemOptionModal/MemberLevelModal.vue';
	import {
		changeStates,
		vipList
	} from '@/api/systemOption/systemSettings';
	export default {
		components: {
			MemberLevelModal
		},
		data() {
			return {
				projectMessage: '',
				tableData: [],
				pageSize: 10,
				page: 1,
				total: 0
			}
		},
		mounted() {
			this.getVipList()
		},
		methods: {
			openAddMemberCardDialog() {
				this.$refs.memberLevelModal.openDialog()
			},
			changeState(row) {
				changeStates({
					id: row.id,
					actived: row.actived === '1' ? '0' : '1'
				}).then(res => {
					if (res.state === '200') {
						this.getVipList()
						let msg = row.actived == '1' ? '启用成功' : '停用成功'
						this.$message({
							type: 'success',
							message: msg
						});
					}
				})
			},
			getVipList() {
				vipList({
					// 一页十条
					pageSize: this.pageSize,
					// 第n页
					page: this.page
				}).then(res => {
					if (res.state === '200') {
						this.total = res.data.total
						this.tableData = res.data.records
					}
				})
			},
			changePage(e) {
				this.page = e
				this.getVipList()
			},
			handleChildEvent() {
				this.getVipList()
			},
			edit(row){
				this.$refs.memberLevelModal.openDialog(row)
			}
		}
	}
</script>
<style scoped></style>