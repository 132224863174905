<template>
    <div>
        <el-dialog :visible.sync="dialogVisible" :modal="true" :append-to-body="true" :close-on-click-modal="false"
            :before-close="handleClose" width="70%" top="5vh">
            <div class="container">
                <div class="left">
                    <div class="left_top">
                        <div class="item" @click="getImageList(-1)"
                            :style="{ backgroundColor: selectIndex == -1 ? '#f7f7f7' : '#fff' }">全部({{ photoTotal }})</div>
                        <div class="item" v-for="(item, index) in groupList" @click="getImageList(item.id)" :key="item.id"
                            :style="{ backgroundColor: selectIndex == item.id ? '#f7f7f7' : '#fff' }">{{ item.name }}({{
                                item.count || 0 }})</div>
                    </div>
                    <div class="left_bottom">
                        <el-button size="small" @click="addGroup">添加分组</el-button>
                    </div>
                </div>
                <div class="divider"></div>
                <div class="right" v-loading="loading" element-loading-text="上传中" element-loading-spinner="el-icon-loading"
                    element-loading-background="rgba(f, f, f, 0.8)">
                    <div class="right_title">
                        <span>图片大小不超过5M</span>
                        <el-upload class="upload-demo" ref="upload" action="" :http-request="upload" multiple :limit="1"
                            :show-file-list="false" :on-exceed="handleExceed" :file-list="fileList" :on-success="success"
                            :before-upload="beforeUp">
                            <el-button style="margin-left: .125rem;" size="small"><i
                                    class="el-icon-plus"></i>添加图片</el-button>
                        </el-upload>
                    </div>
                    <div class="box">
                        <div class="imgbox" v-for="(item, index) in imgList" @click="setImg(item)"
                            :style="{ border: selectImg.id == item.id ? '2px solid #409eff' : 'none' }">
                            <img :src="item.url" class="img">
                            <div class="imgtitle">{{ item.name }}</div>
                            <i v-if="selectImg.id == item.id" class="el-icon-error delicon"></i>
                        </div>
                    </div>
                    <div class="fy">
                        <el-pagination layout="prev, pager, next" :page-size="pageForm.pageSize"
                            :current-page.sync="pageForm.page" @current-change="getList" :total="total">
                        </el-pagination>
                    </div>

                </div>
            </div>
            <span slot="title" class="dialog-title">
                <span class="title">选择素材</span>
            </span>
            <span slot="footer" class="dialog-footer">
                <div slot="footer" class="dialog-footer">
                    <el-button size="small" @click="handleClose">取消</el-button>
                    <el-button size="small" type="primary" @click="save">确定</el-button>
                </div>
            </span>
        </el-dialog>
        <el-dialog :visible.sync="visible" width="20%" :before-close="handleClose">
            <div class="groupbox">
                <el-form :inline="true" :model="formInline" :rules="rules" class="demo-form-inline" label-position="top">
                    <el-form-item label="请输入分组名称" prop="groupname">
                        <el-input v-model="formInline.groupname"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="title" class="dialog-title">
                <span class="title">创建分组</span>
            </span>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="visible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="saveGroup">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>  
    
<script>
import axios from 'axios';
import * as api from '../../../api/memberManage/index'
export default {
    data() {
        return {
            dialogVisible: false, // 控制弹出框的显示与隐藏  
            selectIndex: -1,
            visible: false,
            formInline: {
                groupname: ''
            },
            rules: {
                groupname: [{ required: true, message: '请输入分组名称', trigger: 'blur' }]
            },
            fileList: [],
            pageForm: {
                page: 1,
                pageSize: 10
            },
            total: 0,
            photoTotal: 0,
            groupId: -1,
            groupList: [],
            imgList: [],
            selectImg: '',
            waid: '',
            caid: '',
            loading: false,
            img: '',
        };
    },
    created() {
    },
    methods: {
        saveGroup() {
            let waid = this.waid == 'undefined'
            api.addImagesGroup({ name: this.formInline.groupname, admin: waid }).then(res => {
                if (res.state == '200') {
                    this.$message.success('添加成功')
                    this.getImageList(this.groupId)
                    this.visible = false
                }
            })
        },
        getCwid() {
            api.getWaidAndCaid().then(res => {
                if (res.state == '200') {
                    this.waid = res.data.waid
                    this.caid = res.data.caid
                }
            })
        },
        upload() {
            this.loading = true
            var url = process.env.VUE_APP_BASE_API + '/scrm/uploadImages'
            var formData = new FormData();
            formData.append('image', this.img);
            formData.append('fileapi', Date.now());
            formData.append('group', this.groupId);
            axios({
                method: 'post',
                url,
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data', // 关键
                    'AuthorizationToken': sessionStorage.getItem('token')
                }
            }).then(res => {
                this.loading = false
                this.$message.success('上传成功')
                console.log(this.groupId);
                this.getImageList(this.groupId)
            }, err => {
                this.loading = false
                this.$message.error('上传失败')
            })
        },
        beforeUp(event) {
            if (!event) { return false };
            var file = event
            if (file.size / 1024 / 1024 > 5) {
                this.$message.error('文件大小超出限制！')
                return false;
            }
            if (file.type != "image/png" && file.type != "image/jpeg" && file.type != "image/jpg") {
                this.$message.error('图片类型必须是jpeg,jpg,png中的一种')
                return false
            }
            this.img = event
            return true
        },
        setImg(item) {
            this.selectImg = item
        },
        handleExceed(e) {
            console.log('超出', e);
            if (e.length > 1) {
                this.$message.error('只能单文件上传')
            }
        },
        success() {
            console.log('成功');
        },
        openDialog(data) {
            
            this.getList()
            this.getCwid()
            // 打开弹出框，并触发父组件的事件传递参数  
            if (data && data.length > 0) {
                this.selectList = data
            }
            this.dialogVisible = true;


            // this.$emit('child-event', 'Hello from child');
        },
        handleClose() {
            this.dialogVisible = false;
            this.selectList = []
        },
        getList() {
            api.getImagesList({ page: this.pageForm.page, groupid: -1 }).then(res => {
                if (res.state == '200') {
                    this.groupList = res.data.groups
                    this.imgList = res.data.photos[0].data
                    this.photoTotal = res.data.photoTotal
                    this.total = res.data.photos[0].per_page
                }
            })
        },
        getImageList(groupid) {
            console.log(groupid, 555);
            this.selectIndex = groupid
            this.groupId = groupid
            api.getImagesList({ page: this.pageForm.page, groupid }).then(res => {
                if (res.state == '200') {
                    this.groupList = res.data.groups
                    this.imgList = res.data.photos[0].data
                    this.photoTotal = res.data.photoTotal
                    this.total = res.data.photos[0].per_page
                }
            })
        },
        addpic() {

        },
        save() {
            this.$emit('setPicture', this.selectImg)
            this.dialogVisible = false
        },
        addGroup() {
            this.visible = true

        }

    }
};
</script>
<style scoped>
/* .progress-wrap {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, .9);


}

.progress-box {
    width: 300px;
    height: 300px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #1FC372;
} */

/* .progress-box>span {
    margin-bottom: 30px;
} */

::v-deep .el-input__inner {
    width: 100% !important;
}

.container {
    display: flex;
    width: 100%;
    height: 65vh;

}

.divider {
    border: 2px solid #f1f1f1;
    margin: 0 .125rem;
}

.left {
    flex: 1;
    display: flex;
    /* align-items: stretch; */
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}

.left_top {
    /* flex: 1; */
    padding-bottom: .5rem;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 90%;
    overflow: auto;
}

.right {
    flex: 3;
    padding: 0 .5rem;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
}

.fy {
    display: flex;
    justify-content: flex-end;
    /* align-self: flex-end; */
}

.right_title {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
}

.item {
    padding: .125rem .25rem;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.item:hover {
    background-color: #f7f7f7;
}

.img {
    width: 100%;
    /* margin: .125rem; */

}

.box {
    display: flex;
    flex-wrap: wrap;
}

.imgbox {
    width: 17%;
    height: 2.6rem;
    background-color: #b6b6b6;
    padding: .25rem;
    margin: .125rem;
    position: relative;
    overflow: hidden;
    cursor: pointer;
}

.delicon {
    position: absolute;
    right: .04rem;
    top: .04rem;
    color: #409eff;
    font-size: .25rem;
}

.left_bottom {
    position: absolute;
    bottom: 0;
    margin: 0 auto;
}

.imgtitle {
    width: 100%;
    position: absolute;
    background-color: #6d6d6d;
    height: .4rem;
    line-height: .4rem;
    color: #fff;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    /* padding: 0 .25rem; */
    font-size: .2rem;
    display: -webkit-box;
    overflow: hidden;
    word-break: break-all;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    /* white-space: break-all; */
}

.groupbox {
    width: 100%;
}

::v-deep .el-upload {
    border: none;
}
</style>
  