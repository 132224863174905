<template>
  <div class="dialogContainer">
    <el-dialog custom-class="recordModals" :visible.sync="dialogVisible" :modal="true" :append-to-body="true"
      :close-on-click-modal="false">
      <div class="dialogContent">
        <div class="dialogContentTop dialogpart">
          <div>
            <el-descriptions class="margin-top dialogDes" :column="4" size="mini" :colon="false">
              <el-descriptions-item label="时间:" :span="3">
                <el-date-picker size="small" v-model="storageTime" type="daterange" range-separator="至"
                  start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                  style="width:5.5rem;" @change="dateChange">
                </el-date-picker>
                <el-button size="small" type="primary" :size="size" style="margin-left: 0.125rem;"
                  @click="searchList">查询</el-button>
                <el-button size="small" type="primary" :size="size" v-if="type !== 0 && isExport == 1"
                  @click="exportRecord">导出</el-button>
              </el-descriptions-item>
            </el-descriptions>
          </div>
        </div>
        <div class="dialogTable">

          <!-- 客户自费维保储值记录 -->
          <el-table size="small" :height="tableHeight" :data="tableData" border style="width: 100%;" v-if="type === 0">
            <el-table-column prop="id" label="编号" width="150" align="center">
              <template slot-scope="scope">
                <span>{{ scope.$index + 1 }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="telephone" label="手机号" width="120" align="center">
            </el-table-column>
            <el-table-column prop="vipLevel" label="会员级别" width="150" align="center">
            </el-table-column>
            <el-table-column prop="vipName" label="会员姓名" width="150" align="center">
            </el-table-column>
            <el-table-column prop="carNum" label="车牌号码" width="150" align="center">
            </el-table-column>
            <el-table-column prop="carFrameNumber" label="车架号码" width="200" align="center">
            </el-table-column>
            <el-table-column prop="money" label="充值金额" width="150" align="center">
            </el-table-column>
            <el-table-column prop="credit" label="赠送积分" width="150" align="center">
            </el-table-column>
            <el-table-column prop="sellerId" label="介绍人" align="center">
            </el-table-column>
            <el-table-column prop="cardType" label="卡片性质" width="120" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.cardType == '0'">购买</span>
                <span v-if="scope.row.cardType == '1'">赠送</span>
              </template>
            </el-table-column>
            <el-table-column prop="hairCard" label="发卡店" align="center">
            </el-table-column>
            <el-table-column prop="operationCard" label="操作店" align="center">
            </el-table-column>
            <el-table-column prop="operationDept" label="操作部门" width="120" align="center">
            </el-table-column>
            <el-table-column prop="operationTime" label="操作时间" width="200" align="center">
            </el-table-column>
            <el-table-column prop="reMethod" label="充值方式" width="120" align="center">
            </el-table-column>
            <el-table-column prop="remarks" label="备注" width="200" align="center">
            </el-table-column>
          </el-table>

          <!-- 客户自费维保消费记录 -->
          <el-table size="small" :height="tableHeight" :data="tableData" border style="width: 100%" v-if="type === 1">
            <el-table-column prop="id" label="编号" width="150" align="center">
              <template slot-scope="scope">
                <span>{{ scope.$index + 1 }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="waterNum" label="流水单号" width="150" align="center">
            </el-table-column>
            <el-table-column prop="constNum" label="施工单号" width="150" align="center">
            </el-table-column>
            <el-table-column prop="telephone" label="手机号" width="120" align="center">
            </el-table-column>
            <el-table-column prop="vipLevel" label="会员级别" width="150" align="center">
            </el-table-column>
            <el-table-column prop="vipName" label="会员姓名" width="120" align="center">
            </el-table-column>
            <el-table-column prop="carNum" label="车牌号码" width="120" align="center">
            </el-table-column>
            <el-table-column prop="carFrameNumber" label="车架号码" width="200" align="center">
            </el-table-column>
            <el-table-column prop="serviceType" label="维修类型" width="150" align="center">
            </el-table-column>
            <el-table-column prop="conMoney" label="消费金额" width="120" align="center">
            </el-table-column>
            <el-table-column prop="credit" label="积分增加" width="120" align="center">
            </el-table-column>
            <el-table-column prop="materMoney" label="材料金额" width="120" align="center">
            </el-table-column>
            <el-table-column prop="materDeDu" label="材料折扣" width="120" align="center">
            </el-table-column>
            <el-table-column prop="hourDeDu" label="工时金额" width="120" align="center">
            </el-table-column>
            <el-table-column prop="hourMoney" label="工时折扣" width="120" align="center">
            </el-table-column>
            <el-table-column prop="favMoney" label="优惠金额" width="120" align="center">
            </el-table-column>
            <el-table-column prop="creditDiscount" label="积分抵扣" width="120" align="center">
            </el-table-column>
            <!-- 本交余额 累计积分 -->

            <el-table-column prop="sellerId" label="介绍人" align="center">
            </el-table-column>
            <el-table-column prop="hairCard" label="发卡店" align="center">
            </el-table-column>
            <el-table-column prop="operationName" label="操作员" width="130" align="center">
            </el-table-column>
            <el-table-column prop="operationCard" label="操作店" align="center">
            </el-table-column>
            <el-table-column prop="operationDept" label="操作部门" width="120" align="center">
            </el-table-column>
            <el-table-column prop="operationTime" label="操作时间" width="200" align="center">
            </el-table-column>
            <el-table-column prop="remarks" label="备注" width="200" align="center">
            </el-table-column>
          </el-table>

          <!-- 销售赠送维保储值记录 -->
          <!-- 售后赠送维保储值记录 -->
          <!-- 金融保险基金储值记录 -->
          <!-- 最大优惠金额3、5、7 -->

          <el-table size="small" :height="tableHeight" :data="tableData" border style="width: 100%"
            v-if="[2, 4, 6, 3, 5, 7, 8, 9].includes(type)">
            <el-table-column prop="id" label="编号" width="150" align="center">
              <template slot-scope="scope">
                <span>{{ scope.$index + 1 }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="waterNum" label="流水单号" v-if="[3, 5, 7].includes(type)" width="150" align="center">
            </el-table-column>
            <el-table-column prop="constNum" label="施工单号" v-if="[3, 5, 7, 8, 9].includes(type)" width="150"
              align="center">
            </el-table-column>
            <el-table-column prop="telephone" label="手机号" width="120" align="center">
            </el-table-column>
            <el-table-column prop="vipLevel" label="会员级别" width="150" align="center">
            </el-table-column>
            <el-table-column prop="vipName" label="会员姓名" width="120" align="center">
            </el-table-column>
            <el-table-column prop="carNum" label="车牌号码" width="120" align="center">
            </el-table-column>
            <!-- <el-table-column prop="carBrand" label="车辆号码" v-if="[5,8, 9].includes(type)">
            </el-table-column> -->
            <el-table-column prop="carFrameNumber" label="车架号码" width="200" align="center">
            </el-table-column>
            <el-table-column prop="money" label="充值金额" width="120" v-if="[2, 4, 6].includes(type)" align="center">
            </el-table-column>
            <!-- <el-table-column prop="credit" label="赠送积分" width="120" v-if="[2, 4, 6].includes(type)" align="center">
            </el-table-column> -->
            <el-table-column prop="reMethod" label="充值方式" width="120" v-if="[2, 4, 6].includes(type)" align="center">
            </el-table-column>
            <!-- <el-table-column prop="projectName" label="项目名称" v-if="[8, 9].includes(type)">
            </el-table-column> -->
            <el-table-column prop="projectNum" label="项目类别" width="120" v-if="type === 9" align="center">
            </el-table-column>
            <el-table-column prop="needCredit" label="所需积分" width="120" v-if="type === 9" align="center">
            </el-table-column>
            <!-- <el-table-column prop="creditRule" label="积分规则" v-if="type === 8">
            </el-table-column>
            <el-table-column prop="creditMultiple" label="积分倍数" v-if="type === 8">
            </el-table-column> -->
            <el-table-column prop="creditMoney" label="金额" v-if="type === 8" align="center">
            </el-table-column>
            <el-table-column prop="creditAdd" label="增加积分" width="120" v-if="type === 8" align="center">
            </el-table-column>
            <!-- 待修改 -->
            <el-table-column prop="isCum" label="是否累计" width="120" v-if="type === 8" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.isCum == '1'">是</span>
                <span v-else>否</span>
              </template>
            </el-table-column>
            <el-table-column prop="creditUsable" label="可用积分" width="120" v-if="type === 8" align="center">
            </el-table-column>
            <!-- 待修改 -->
            <!-- <el-table-column prop="frozen" label="冻结状态" v-if="type == 8">
            </el-table-column> -->


            <el-table-column prop="serviceType" label="维修类型" width="120" v-if="[3, 5, 7].includes(type)" align="center">
            </el-table-column>
            <el-table-column prop="conMoney" label="消费金额" width="120" v-if="[3, 5, 7].includes(type)" align="center">
            </el-table-column>
            <el-table-column prop="materMoney" label="优惠金额" width="120" v-if="[3, 5, 7].includes(type)" align="center">
            </el-table-column>
            <!-- <el-table-column prop="materdedu" label="本次余额" v-if="[3, 5, 7].includes(type)">
            </el-table-column> -->
            <!-- <el-table-column prop="creditLj" label="累积积分" v-if="[3, 5, 7].includes(type)">
            </el-table-column> -->

            <el-table-column prop="sellerId" label="介绍人" align="center">
            </el-table-column>
            <el-table-column prop="cardType" label="卡片性质" width="120" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.cardType == '0'">购买</span>
                <span v-if="scope.row.cardType == '1'">赠送</span>
              </template>
            </el-table-column>
            <el-table-column prop="hairCard" label="发卡店" align="center">
            </el-table-column>
            <el-table-column prop="operationName" label="操作员" width="130" align="center">
            </el-table-column>
            <el-table-column prop="operationCard" label="操作店" align="center">
            </el-table-column>
            <el-table-column prop="operationDept" label="操作部门" width="120" align="center">
            </el-table-column>
            <el-table-column prop="operationTime" label="操作时间" width="200" align="center">
            </el-table-column>
            <el-table-column prop="remarks" label="备注" width="200" align="center">
            </el-table-column>
          </el-table>

          <!-- 套餐开卡记录 -->
          <el-table size="small" :height="tableHeight" :data="tableData" border style="width: 100%" v-if="type === 10">
            <el-table-column prop="id" label="id" width="150" align="center">
              <template slot-scope="scope">
                <span>{{ scope.$index + 1 }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="telephone" label="手机号" width="120" align="center">
            </el-table-column>
            <el-table-column prop="vipLevel" label="会员级别" width="150" align="center">
            </el-table-column>
            <el-table-column prop="vipName" label="会员姓名" width="120" align="center">
            </el-table-column>
            <el-table-column prop="carNum" label="车牌号码" width="120" align="center">
            </el-table-column>
            <el-table-column prop="carFrameNumber" label="车架号码" width="200" align="center">
            </el-table-column>
            <el-table-column prop="packageName" label="套餐名称" width="120" align="center">
            </el-table-column>
            <el-table-column prop="packageJg" label="套餐价格" width="120" align="center">
            </el-table-column>
            <el-table-column prop="dataType" label="类型" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.dataType == '0'">购买</span>
                <span v-if="scope.row.dataType == '1'">赠送</span>
              </template>
            </el-table-column>
            <el-table-column prop="sellerId" label="介绍人" align="center">
            </el-table-column>
            <!-- <el-table-column prop="cardType" label="卡片性质">
            </el-table-column> -->
            <el-table-column prop="hairCard" label="发卡店" align="center">
            </el-table-column>
            <el-table-column prop="operationDept" label="操作部门" width="120" align="center">
            </el-table-column>
            <el-table-column prop="operationCard" label="操作店" align="center">
            </el-table-column>
            <el-table-column prop="operationName" label="操作员" width="130" align="center">
            </el-table-column>
            <el-table-column prop="operationTime" label="操作时间" width="200" align="center">
            </el-table-column>
            <el-table-column prop="remarks" label="备注" width="200" align="center">
            </el-table-column>
          </el-table>

          <!-- 查看套餐余额 -->

          <el-table size="small" :height="tableHeight" :data="tableData" border style="width: 100%" v-if="type === 11">
            <el-table-column prop="id" label="id" width="150" align="center">
              <template slot-scope="scope">
                <span>{{ scope.$index + 1 }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="telephone" label="手机号" width="120" align="center">
            </el-table-column>
            <el-table-column prop="vipLevel" label="会员级别" width="150" align="center">
            </el-table-column>
            <el-table-column prop="vipName" label="会员姓名" width="120" align="center">
            </el-table-column>
            <el-table-column prop="cardType" label="卡片性质" width="120" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.cardType == '0'">购买</span>
                <span v-if="scope.row.cardType == '1'">赠送</span>
              </template>
            </el-table-column>
            <el-table-column prop="carNum" label="车牌号码" width="120" align="center">
            </el-table-column>
            <el-table-column prop="carFrameNumber" label="车架号码" width="200" align="center">
            </el-table-column>
            <el-table-column prop="serviceItemName" label="套餐名称" width="120" align="center">
            </el-table-column>
            <el-table-column prop="itemName" label="服务项目" width="120" align="center">
            </el-table-column>
            <el-table-column prop="num" label="可用次数" width="120" align="center">
            </el-table-column>
            <el-table-column prop="price" label="项目单价" width="120" align="center">
            </el-table-column>
            <!-- <el-table-column prop="surplusPrice" label="剩余套餐价值">
            </el-table-column> -->
            <!-- <el-table-column prop="sellerId" label="介绍人">
            </el-table-column> -->
            <el-table-column prop="hairCard" label="发卡店" align="center">
            </el-table-column>
            <!-- <el-table-column prop="operationDept" label="操作部门">
            </el-table-column>
            <el-table-column prop="operationCard" label="操作店">
            </el-table-column>
            <el-table-column prop="operationName" label="操作员">
            </el-table-column>
            <el-table-column prop="operationTime" label="操作时间">
            </el-table-column> -->
            <!-- <el-table-column prop="remarks" label="备注" width="200">
            </el-table-column> -->
          </el-table>

          <!-- 套餐消费记录 -->
          <el-table size="small" :height="tableHeight" :data="tableData" border style="width: 100%" v-if="type === 12">
            <el-table-column prop="id" label="id" width="150" align="center">
              <template slot-scope="scope">
                <span>{{ scope.$index + 1 }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="telephone" label="手机号" width="120" align="center">
            </el-table-column>
            <el-table-column prop="vipLevel" label="会员级别" width="150" align="center">
            </el-table-column>
            <el-table-column prop="vipName" label="会员姓名" width="120" align="center">
            </el-table-column>
            <el-table-column prop="carNum" label="车牌号码" width="120" align="center">
            </el-table-column>
            <el-table-column prop="carFrameNumber" label="车架号码" width="200" align="center">
            </el-table-column>
            <el-table-column prop="packageName" label="套餐名称" width="120" align="center">
            </el-table-column>
            <el-table-column prop="serviceProject" label="服务项目" width="120" align="center">
            </el-table-column>
            <el-table-column prop="surplusFreq" label="剩余次数" width="120" align="center">
            </el-table-column>
            <el-table-column prop="conFreq" label="消费次数" width="120" align="center">
            </el-table-column>
            <el-table-column prop="validityPeriod" label="有效期" width="200" align="center">
            </el-table-column>
            <el-table-column prop="cardType" label="卡片性质" width="120" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.cardType == '0'">购买</span>
                <span v-if="scope.row.cardType == '1'">赠送</span>
              </template>
            </el-table-column>
            <el-table-column prop="sellerId" label="介绍人" align="center">
            </el-table-column>
            <el-table-column prop="hairCard" label="发卡店" align="center">
            </el-table-column>
            <el-table-column prop="operationDept" label="操作部门" width="120" align="center">
            </el-table-column>
            <el-table-column prop="operationCard" label="操作店" align="center">
            </el-table-column>
            <el-table-column prop="operationName" label="操作员" width="130" align="center">
            </el-table-column>
            <el-table-column prop="operationTime" label="操作时间" width="200" align="center">
            </el-table-column>
          </el-table>

          <!-- 赠送项目充值记录 -->

          <el-table size="small" :height="tableHeight" :data="tableData" border style="width: 100%" v-if="type === 13">
            <el-table-column prop="id" label="id" width="150" align="center">
              <template slot-scope="scope">
                <span>{{ scope.$index + 1 }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="telephone" label="手机号" width="120" align="center">
            </el-table-column>
            <el-table-column prop="vipLevel" label="会员级别" width="150" align="center">
            </el-table-column>
            <el-table-column prop="cardType" label="卡片性质" width="120" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.cardType == '0'">购买</span>
                <span v-if="scope.row.cardType == '1'">赠送</span>
              </template>
            </el-table-column>
            <el-table-column prop="vipName" label="会员姓名" width="120" align="center">
            </el-table-column>
            <el-table-column prop="carNum" label="车牌号码" width="120" align="center">
            </el-table-column>
            <el-table-column prop="carFrameNumber" label="车架号码" width="200" align="center">
            </el-table-column>
            <el-table-column prop="projectName" label="赠送项目" width="120" align="center">
            </el-table-column>
            <el-table-column prop="projectFreq" label="赠送次数" width="120" align="center">
            </el-table-column>
            <el-table-column prop="validityPeriod" label="有效期" width="200" align="center">
            </el-table-column>
            <el-table-column prop="sellerId" label="介绍人" align="center">
            </el-table-column>
            <el-table-column prop="dataType" label="类型" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.dataType == '0'">购买</span>
                <span v-if="scope.row.dataType == '1'">赠送</span>
              </template>
            </el-table-column>
            <el-table-column prop="hairCard" label="发卡店" align="center">
            </el-table-column>
            <el-table-column prop="operationDept" label="操作部门" width="120" align="center">
            </el-table-column>
            <el-table-column prop="operationCard" label="操作店" align="center">
            </el-table-column>
            <el-table-column prop="operationName" label="操作员" width="130" align="center">
            </el-table-column>
            <el-table-column prop="operationTime" label="操作时间" width="200" align="center">
            </el-table-column>
            <el-table-column prop="remarks" label="备注" width="200" align="center">
            </el-table-column>
          </el-table>

          <!-- 查看赠送项目余额 -->
          <el-table size="small" :height="tableHeight" :data="tableData" border style="width: 100%" v-if="type === 14">
            <el-table-column prop="id" label="id" width="150" align="center">
              <template slot-scope="scope">
                <span>{{ scope.$index + 1 }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="telephone" label="手机号" width="120" align="center">
            </el-table-column>
            <!-- <el-table-column prop="clientId" label="会员id">
            </el-table-column> -->
            <el-table-column prop="vipLevel" label="会员级别" width="150" align="center">
            </el-table-column>
            <el-table-column prop="cardType" label="卡片性质" width="120" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.cardType == '0'">购买</span>
                <span v-if="scope.row.cardType == '1'">赠送</span>
              </template>
            </el-table-column>
            <el-table-column prop="vipName" label="会员姓名" width="120" align="center">
            </el-table-column>
            <el-table-column prop="carNum" label="车牌号码" width="120" align="center">
            </el-table-column>
            <el-table-column prop="carFrameNumber" label="车架号码" width="200" align="center">
            </el-table-column>
            <el-table-column prop="donationItemName" label="赠送项目" width="120" align="center">
            </el-table-column>
            <!-- <el-table-column prop="projectFreq" label="赠送次数">
            </el-table-column> -->
            <el-table-column prop="availableTimes" label="可用次数" width="120" align="center">
            </el-table-column>
            <!-- ?? -->
            <el-table-column prop="packageCost" label="项目成本" width="120" align="center">
            </el-table-column>
            <el-table-column prop="packageSurplusCost" label="剩余合计成本" width="150" align="center">
            </el-table-column>
            <el-table-column prop="term" label="有效期" width="200" align="center">
            </el-table-column>
            <el-table-column prop="hairCard" label="发卡店" align="center">
            </el-table-column>
            <!-- <el-table-column prop="operationDept" label="操作部门">
            </el-table-column>
            <el-table-column prop="operationCard" label="操作店">
            </el-table-column>
            <el-table-column prop="operationName" label="操作员">
            </el-table-column>
            <el-table-column prop="operationTime" label="操作时间">
            </el-table-column> -->
            <el-table-column prop="remarks" label="备注" width="200" align="center">
            </el-table-column>
          </el-table>

          <!-- 赠送项目消费记录 -->
          <el-table size="small" :height="tableHeight" :data="tableData" border style="width: 100%" v-if="type === 15">
            <el-table-column prop="id" label="id" width="150" align="center">
              <template slot-scope="scope">
                <span>{{ scope.$index + 1 }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="telephone" label="手机号" width="120" align="center">
            </el-table-column>
            <el-table-column prop="vipLevel" label="会员级别" width="150" align="center">
            </el-table-column>
            <el-table-column prop="vipName" label="会员姓名" width="120" align="center">
            </el-table-column>
            <el-table-column prop="carNum" label="车牌号码" width="120" align="center">
            </el-table-column>
            <el-table-column prop="carFrameNumber" label="车架号码" width="200" align="center">
            </el-table-column>
            <el-table-column prop="projectName" label="赠送项目" width="120" align="center">
            </el-table-column>
            <el-table-column prop="consFreq" label="消费次数" width="120" align="center">
            </el-table-column>
            <el-table-column prop="projectFreq" label="项目成本" width="120" align="center">
            </el-table-column>
            <el-table-column prop="surplusFreq" label="剩余次数" width="120" align="center">
            </el-table-column>
            <el-table-column prop="validityPeriod" label="有效期" width="200" align="center">
            </el-table-column>
            <el-table-column prop="sellerId" label="介绍人" align="center">
            </el-table-column>
            <el-table-column prop="cardType" label="卡片性质" width="120" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.cardType == '0'">购买</span>
                <span v-if="scope.row.cardType == '1'">赠送</span>
              </template>
            </el-table-column>
            <el-table-column prop="hairCard" label="发卡店" align="center">
            </el-table-column>
            <el-table-column prop="operationName" label="操作员" width="130" align="center">
            </el-table-column>
            <el-table-column prop="operationCard" label="操作店" align="center">
            </el-table-column>
            <el-table-column prop="operationDept" label="操作部门" width="120" align="center">
            </el-table-column>
            <el-table-column prop="operationTime" label="操作时间" width="200" align="center">
            </el-table-column>
            <el-table-column prop="remarks" label="备注" width="200" align="center">
            </el-table-column>
          </el-table>
          <div style="display: flex;justify-content: center">
            <el-pagination layout="prev, pager, next" @current-change="searchList" :current-page.sync="pageData.page"
              :page-size="pageData.pageSize" :total="total">
            </el-pagination>
          </div>
        </div>
        <div class="summaryInfo">
          <div>
            <el-divider content-position="left">汇总信息</el-divider>
          </div>
          <div class="summaryInfo_Bootom">
            <div v-if="[0].includes(type)">
              <span class="mr10">记录数：{{ summaryInformation.countSum ? summaryInformation.countSum : 0 }}条</span>
              <span class="mr10">充值金额：{{ summaryInformation.money ? summaryInformation.money : 0 }}元</span>
              <span class="mr10">赠送积分：{{ summaryInformation.credit ? summaryInformation.credit : 0 }}分</span>
            </div>
            <div v-if="[2, 4, 6].includes(type)">
              <span class="mr10">记录数：{{ summaryInformation.countSum ? summaryInformation.countSum : 0 }}条</span>
              <span class="mr10">充值金额：{{ summaryInformation.money ? summaryInformation.money : 0 }}元</span>
              <!-- <span class="mr10">赠送积分：{{ summaryInformation.credit ? summaryInformation.credit : 0 }}分</span> -->
            </div>
            <div v-if="type === 1">
              <span class="mr10">记录数：{{ summaryInformation.countSum ? summaryInformation.countSum : 0 }}条</span>
              <span class="mr10">材料金额：{{ summaryInformation.materMoney ? summaryInformation.materMoney : 0 }}元</span>
              <span class="mr10">工时金额：{{ summaryInformation.hourDeDu ? summaryInformation.hourDeDu : 0 }}元</span>
              <span class="mr10">优惠金额：{{ summaryInformation.favMoney ? summaryInformation.favMoney : 0 }}元</span>
              <span class="mr10">累计积分：{{ summaryInformation.credit ? summaryInformation.credit : 0 }}分</span>
            </div>
            <div v-if="[3, 5].includes(type)">
              <span class="mr10">记录数：{{ summaryInformation.countSum ? summaryInformation.countSum : 0 }}条</span>
              <span class="mr10">消费金额：{{ summaryInformation.money ? summaryInformation.money : 0 }}元</span>
              <span class="mr10">优惠金额：{{ summaryInformation.favMoney ? summaryInformation.favMoney : 0 }}元</span>
              <!-- <span class="mr10">累计积分：{{ summaryInformation.credit ? summaryInformation.credit : 0 }}分</span> -->
            </div>
            <div v-if="[7].includes(type)">
              <span class="mr10">记录数：{{ summaryInformation.countSum ? summaryInformation.countSum : 0 }}条</span>
              <span class="mr10">消费金额：{{ summaryInformation.money ? summaryInformation.money : 0 }}元</span>
              <span class="mr10">优惠金额：{{ summaryInformation.favMoney ? summaryInformation.favMoney : 0 }}元</span>
              <!-- <span class="mr10">累计积分：{{ summaryInformation.credit ? summaryInformation.credit : 0 }}分</span> -->
            </div>
            <div v-if="type === 8">
              <span class="mr10">记录数：{{ summaryInformation.countSum ? summaryInformation.countSum : 0 }}条</span>
              <span class="mr10">增加积分：{{ summaryInformation.credit ? summaryInformation.credit : 0 }}分</span>
            </div>
            <div v-if="type === 9">
              <span class="mr10">记录数：{{ summaryInformation.countSum ? summaryInformation.countSum : 0 }}条</span>
              <span class="mr10">消费积分：{{ summaryInformation.credit ? summaryInformation.credit : 0 }}分</span>
            </div>
            <div v-if="type === 10">
              <span class="mr10">记录数：{{ summaryInformation.countSum ? summaryInformation.countSum : 0 }}条</span>
              <span class="mr10">套餐价值：{{ summaryInformation.money ? summaryInformation.money : 0 }}元</span>
            </div>
            <div v-if="type === 11">
              <span class="mr10">记录数：{{ summaryInformation.countSum ? summaryInformation.countSum : 0 }}条</span>
              <span class="mr10">可用次数：{{ summaryInformation.surplusFreq ? summaryInformation.surplusFreq : 0 }}次</span>
            </div>
            <div v-if="type === 12">
              <span class="mr10">记录数：{{ summaryInformation.countSum ? summaryInformation.countSum : 0 }}条</span>
              <span class="mr10">消费次数：{{ summaryInformation.surplusFreq ? summaryInformation.surplusFreq : 0 }}次</span>
            </div>
            <div v-if="type === 13">
              <span class="mr10">记录数：{{ summaryInformation.countSum ? summaryInformation.countSum : 0 }}条</span>
              <span class="mr10">赠送项目：{{ summaryInformation.surplusFreq ? summaryInformation.surplusFreq : 0 }}次</span>
            </div>
            <div v-if="type === 14 || type === 15">
              <span class="mr10">记录数：{{ summaryInformation.countSum ? summaryInformation.countSum : 0 }}条</span>
              <span class="mr10">可用次数：{{ summaryInformation.surplusFreq ? summaryInformation.surplusFreq : 0 }}次</span>
              <span class="mr10">项目成本：{{ summaryInformation.money ? summaryInformation.money : 0 }}元</span>
              <span class="mr10">剩余合计成本：{{ summaryInformation.packageSurplusCost ? summaryInformation.packageSurplusCost :
                0 }}元</span>
            </div>
          </div>
        </div>
      </div>

      <span slot="title" class="dialog-title">
        <span><i class="el-icon-info"></i>{{ item.name }}</span>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getSelf,
  getSelfSum,
  getSelfCons,
  getSelfXf,
  getRecordSales,
  getSalesCz,
  getRecordSalesCons,
  getSalesXf,
  getSales,
  getAfterCz,
  getSalesCons,
  getAfterXf,
  getAfterSales,
  getAfterSalesCz,
  getAfterSalesCons,
  getAfterSalesXf,
  getCredit,
  getCreditCz,
  getCreditCons,
  getCreditXf,
  getPackageCard,
  getPackageCardHz,
  getPackageBalance,
  getPackageBalanceHz,
  getPackageCons,
  getPackageConsHz,
  getGift,
  getGiftHz,
  getGiftBalance,
  getGiftBalanceHz,
  getGiftCons,
  getGiftConsHz,
  getMaintenanceList,
  exportSelfXf,
  exportSalesCz,
  exportSalesXf,
  exportAfterSalesXf,
  exportAfterSalesCz,
  exportInsuranceCz,
  exportInsuranceXf,
  exportCreditCz,
  exportCreditXf,
  exportOpenCz,
  exportOpenYe,
  exportOpenXf,
  exportGiftsCz,
  exportGiftsYe,
  exportGiftsXf
} from '../../../api/workContent/index'
import { download2 } from '../../../utils/request'
export default {
  name: 'recordModal',
  data() {
    return {
      formInline: {},
      type: 0,
      item: {},
      size: "small",
      content_style: {
        'width': '15%'
      },
      storageTime: [],
      value: {},
      dialogVisible: false, // 控制弹出框的显示与隐藏  
      message: this.initialMessage, // 初始化时接收父组件传递的参数  
      memberLevel: '',
      carNature: '',
      rechargeMethodList: [{
        rechargeMethod: '微信支付',
        label: '微信支付'
      }, {
        rechargeMethod: '现金支付',
        label: '现金支付'
      }],
      rechargeMethod: '',
      giveCardStore: '',
      operateDepartment: '',
      operateStore: '',
      operateStaff: '',
      tableData: [],
      total: 0,
      summaryInformation: {},
      pageData: {
        pageSize: 10,
        page: 1,
        operatingStartTime: null,
        operatingEndTime: null
      },
      maintanceList: [],
      isExport: 0,
      tableHeight: 0
    };
  },
  mounted() {
    this.isExport = sessionStorage.getItem('isExport')
    console.log(this.isExport, 555);
    this.tableHeight = document.documentElement.clientWidth / 24 * 5.8
  },
  methods: {
    openDialog(item) {
      // 打开弹出框，并触发父组件的事件传递参数  
      this.dialogVisible = true;
      this.storageTime = []
      this.pageData = {
        pageSize: 10,
        page: 1,
        operatingStartTime: null,
        operatingEndTime: null
      }
      this.item = item
      this.type = item.type
      this.searchList()
      getMaintenanceList().then(res => {
        if (res.state == '200') {
          this.maintanceList = res.data
        }
      })
    },

    closeDialog() {
      // 关闭弹出框  
      this.dialogVisible = false;
    },
    dateChange(e) {
      console.log(e)
    },
    searchList() {
      if (this.item.telephone) {
        if (this.type == 0) {
          //客户自费维保充值记录
          this._getSelf()
        } else if (this.type == 1) {
          //客户自费维保消费记录
          this._getSelfCons()
        } else if (this.type == 2) {
          //销售赠送维保储值记录
          this._getRecordSales()
        } else if (this.type == 3) {
          //销售赠送维保消费记录
          this._getRecordSalesCons()
        } else if (this.type == 4) {
          //售后赠送维保储值记录
          this._getSales()
        } else if (this.type == 5) {
          //售后赠送维保消费记录
          this._getSalesCons()
        } else if (this.type == 6) {
          //金融赠送维保储值记录
          this._getAfterSales()
        } else if (this.type == 7) {
          //金融赠送维保消费记录
          this._getAfterSalesCons()
        } else if (this.type == 8) {
          //积分增加记录
          this._getCredit()
        } else if (this.type == 9) {
          //积分消费记录
          this._getCreditCons()
        } else if (this.type == 10) {
          //套餐开卡
          this._getPackageCard()
        } else if (this.type == 11) {
          // 查看套餐余额
          this._getPackageBalance()
        } else if (this.type == 12) {
          // 套餐消费记录
          this._getPackageCons()
        } else if (this.type == 13) {
          // 赠送项目充值记录
          this._getGift()
        } else if (this.type == 14) {
          // 查看赠送项目余额
          this._getGiftBalance()
        } else if (this.type == 15) {
          // 赠送项目消费记录
          this._getGiftCons()
        }
      }
    },
    exportRecord() {
      const data = this.pageData
      delete data.pageSize
      let exportUrl = {}
      let fileName = ''
      if (this.type == 1) {
        //客户自费维保消费记录
        exportUrl = exportSelfXf
        fileName = '客户自费维保消费记录'
      } else if (this.type == 2) {
        //销售赠送维保储值记录
        exportUrl = exportSalesCz
        fileName = '销售赠送维保储值记录'
      } else if (this.type == 3) {
        //销售赠送维保消费记录
        exportUrl = exportSalesXf
        fileName = '销售赠送维保消费记录'
      } else if (this.type == 4) {
        //售后赠送维保储值记录
        exportUrl = exportAfterSalesXf
        fileName = '售后赠送维保储值记录'
      } else if (this.type == 5) {
        //售后赠送维保消费记录
        exportUrl = exportAfterSalesCz
        fileName = '售后赠送维保消费记录'
      } else if (this.type == 6) {
        //金融赠送维保储值记录
        exportUrl = exportInsuranceCz
        fileName = '金融赠送维保储值记录'
      } else if (this.type == 7) {
        //金融赠送维保消费记录
        exportUrl = exportInsuranceXf
        fileName = '金融赠送维保消费记录'
      } else if (this.type == 8) {
        //积分增加记录
        fileName = '积分增加记录'
        exportUrl = exportCreditCz
      } else if (this.type == 9) {
        //积分消费记录
        fileName = '积分消费记录'
        exportUrl = exportCreditXf
      } else if (this.type == 10) {
        //套餐开卡
        fileName = '套餐开卡'
        exportUrl = exportOpenCz
      } else if (this.type == 11) {
        // 查看套餐余额
        fileName = '查看套餐余额'
        exportUrl = exportOpenYe
      } else if (this.type == 12) {
        // 套餐消费记录
        fileName = '套餐消费记录'
        exportUrl = exportOpenXf
      } else if (this.type == 13) {
        // 赠送项目充值记录
        fileName = '赠送项目充值记录'
        exportUrl = exportGiftsCz
      } else if (this.type == 14) {
        // 查看赠送项目余额
        fileName = '查看赠送项目余额'
        exportUrl = exportGiftsYe
      } else if (this.type == 15) {
        // 赠送项目消费记录
        fileName = '赠送项目消费记录'
        exportUrl = exportGiftsXf
      }
      exportUrl(data).then(res => {
        let blob = new Blob([res], {
          // type: 'application/actet-stream;charset=utf-8'
          // type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          type: 'application/vnd.ms-excel'
        }); // 构造一个blob对象来处理数据，并设置文件类型
        let a = document.createElement('a'); // 生成一个a标签
        let href = window.URL.createObjectURL(blob); //创建下载的链接
        a.href = href; //指定下载链接
        a.download = fileName //指定下载文件名
        // a.setAttribute('download', 'fileName')
        document.body.appendChild(a); //把a标签加到页面中
        a.click(); //触发a标签下载
        document.body.removeChild(a); //下载完成移除元素
        window.URL.revokeObjectURL(href); //释放掉blob对象
      })
    },
    //客户自费维保充值记录
    _getSelf() {
      this.pageData.telephone = this.item.telephone
      let time
      if (this.storageTime && this.storageTime.length > 0) {
        this.pageData.operatingStartTime = this.storageTime[0]
        this.pageData.operatingEndTime = this.storageTime[1]

        time = {
          operatingStartTime: this.storageTime[0],
          operatingEndTime: this.storageTime[1]
        }
      } else {
        this.pageData.operatingStartTime = ''
        this.pageData.operatingEndTime = ''

        time = {
          operatingStartTime: '',
          operatingEndTime: ''
        }
      }
      getSelf(this.pageData).then(res => {
        let { data } = res
        if (res.state == '200') {
          this.tableData = data.records
          this.total = data.total
        }
      })
      getSelfSum({ telephone: this.item.telephone, ...time }).then(res => {
        let { data } = res
        if (res.state == '200') {
          this.summaryInformation = data
        }
      })
    },
    //客户自费维保消费记录
    _getSelfCons() {
      this.pageData.telephone = this.item.telephone
      let time
      if (this.storageTime && this.storageTime.length > 0) {
        this.pageData.operatingStartTime = this.storageTime[0]
        this.pageData.operatingEndTime = this.storageTime[1]

        time = {
          operatingStartTime: this.storageTime[0],
          operatingEndTime: this.storageTime[1]
        }
      } else {
        this.pageData.operatingStartTime = ''
        this.pageData.operatingEndTime = ''

        time = {
          operatingStartTime: '',
          operatingEndTime: ''
        }
      }
      getSelfCons(this.pageData).then(res => {
        let { data } = res
        if (res.state == '200') {
          this.tableData = data.records
          this.total = data.total
        }
      })
      getSelfXf({ telephone: this.item.telephone, ...time }).then(res => {
        let { data } = res
        if (res.state == '200') {
          this.summaryInformation = data
        }
      })
    },
    //销售赠送维保储值记录
    _getRecordSales() {
      this.pageData.telephone = this.item.telephone
      let time
      if (this.storageTime && this.storageTime.length > 0) {
        this.pageData.operatingStartTime = this.storageTime[0]
        this.pageData.operatingEndTime = this.storageTime[1]

        time = {
          operatingStartTime: this.storageTime[0],
          operatingEndTime: this.storageTime[1]
        }
      } else {
        this.pageData.operatingStartTime = ''
        this.pageData.operatingEndTime = ''

        time = {
          operatingStartTime: '',
          operatingEndTime: ''
        }
      }
      getRecordSales(this.pageData).then(res => {
        let { data } = res
        if (res.state == '200') {
          this.tableData = data.records
          this.total = data.total
        }
      })
      getSalesCz({ telephone: this.item.telephone, ...time }).then(res => {
        let { data } = res
        if (res.state == '200') {
          this.summaryInformation = data
        }
      })
    },
    //销售赠送维保消费记录
    _getRecordSalesCons() {
      this.pageData.telephone = this.item.telephone
      let time
      if (this.storageTime && this.storageTime.length > 0) {
        this.pageData.operatingStartTime = this.storageTime[0]
        this.pageData.operatingEndTime = this.storageTime[1]

        time = {
          operatingStartTime: this.storageTime[0],
          operatingEndTime: this.storageTime[1]
        }
      } else {
        this.pageData.operatingStartTime = ''
        this.pageData.operatingEndTime = ''

        time = {
          operatingStartTime: '',
          operatingEndTime: ''
        }
      }
      getRecordSalesCons(this.pageData).then(res => {
        let { data } = res
        if (res.state == '200') {
          this.tableData = data.records
          this.total = data.total
        }
      })
      getSalesXf({ telephone: this.item.telephone, ...time }).then(res => {
        let { data } = res
        if (res.state == '200') {
          this.summaryInformation = data
        }
      })
    },
    //售后赠送维保储值记录
    _getSales() {
      this.pageData.telephone = this.item.telephone
      let time
      if (this.storageTime && this.storageTime.length > 0) {
        this.pageData.operatingStartTime = this.storageTime[0]
        this.pageData.operatingEndTime = this.storageTime[1]

        time = {
          operatingStartTime: this.storageTime[0],
          operatingEndTime: this.storageTime[1]
        }
      } else {
        this.pageData.operatingStartTime = ''
        this.pageData.operatingEndTime = ''

        time = {
          operatingStartTime: '',
          operatingEndTime: ''
        }
      }
      getSales(this.pageData).then(res => {
        let { data } = res
        if (res.state == '200') {
          this.tableData = data.records
          this.total = data.total
        }
      })
      getAfterCz({ telephone: this.item.telephone, ...time }).then(res => {
        let { data } = res
        if (res.state == '200') {
          this.summaryInformation = data
        }
      })
    },
    //售后赠送维保消费记录
    _getSalesCons() {
      this.pageData.telephone = this.item.telephone
      let time
      if (this.storageTime && this.storageTime.length > 0) {
        this.pageData.operatingStartTime = this.storageTime[0]
        this.pageData.operatingEndTime = this.storageTime[1]

        time = {
          operatingStartTime: this.storageTime[0],
          operatingEndTime: this.storageTime[1]
        }
      } else {
        this.pageData.operatingStartTime = ''
        this.pageData.operatingEndTime = ''

        time = {
          operatingStartTime: '',
          operatingEndTime: ''
        }
      }
      getSalesCons(this.pageData).then(res => {
        let { data } = res
        if (res.state == '200') {
          this.tableData = data.records
          this.total = data.total
        }
      })
      getAfterXf({ telephone: this.item.telephone, ...time }).then(res => {
        let { data } = res
        if (res.state == '200') {
          this.summaryInformation = data
        }
      })
    },
    //金融赠送维保储值记录
    _getAfterSales() {
      this.pageData.telephone = this.item.telephone
      let time
      if (this.storageTime && this.storageTime.length > 0) {
        this.pageData.operatingStartTime = this.storageTime[0]
        this.pageData.operatingEndTime = this.storageTime[1]

        time = {
          operatingStartTime: this.storageTime[0],
          operatingEndTime: this.storageTime[1]
        }
      } else {
        this.pageData.operatingStartTime = ''
        this.pageData.operatingEndTime = ''

        time = {
          operatingStartTime: '',
          operatingEndTime: ''
        }
      }
      getAfterSales(this.pageData).then(res => {
        let { data } = res
        if (res.state == '200') {
          this.tableData = data.records
          this.total = data.total
        }
      })
      getAfterSalesCz({ telephone: this.item.telephone, ...time }).then(res => {
        let { data } = res
        if (res.state == '200') {
          this.summaryInformation = data
        }
      })
    },
    //金融赠送维保消费记录
    _getAfterSalesCons() {
      this.pageData.telephone = this.item.telephone
      let time
      if (this.storageTime && this.storageTime.length > 0) {
        this.pageData.operatingStartTime = this.storageTime[0]
        this.pageData.operatingEndTime = this.storageTime[1]

        time = {
          operatingStartTime: this.storageTime[0],
          operatingEndTime: this.storageTime[1]
        }
      } else {
        this.pageData.operatingStartTime = ''
        this.pageData.operatingEndTime = ''

        time = {
          operatingStartTime: '',
          operatingEndTime: ''
        }
      }
      getAfterSalesCons(this.pageData).then(res => {
        let { data } = res
        if (res.state == '200') {
          this.tableData = data.records
          this.total = data.total
        }
      })
      getAfterSalesXf({ telephone: this.item.telephone, ...time }).then(res => {
        let { data } = res
        if (res.state == '200') {
          this.summaryInformation = data
        }
      })
    },
    //积分增加记录
    _getCredit() {
      this.pageData.telephone = this.item.telephone
      let time
      if (this.storageTime && this.storageTime.length > 0) {
        this.pageData.operatingStartTime = this.storageTime[0]
        this.pageData.operatingEndTime = this.storageTime[1]

        time = {
          operatingStartTime: this.storageTime[0],
          operatingEndTime: this.storageTime[1]
        }
      } else {
        this.pageData.operatingStartTime = ''
        this.pageData.operatingEndTime = ''

        time = {
          operatingStartTime: '',
          operatingEndTime: ''
        }
      }
      getCredit(this.pageData).then(res => {
        let { data } = res
        if (res.state == '200') {
          this.tableData = data.records
          this.total = data.total
        }
      })
      getCreditCz({ telephone: this.item.telephone, ...time }).then(res => {
        let { data } = res
        if (res.state == '200') {
          this.summaryInformation = data
        }
      })
    },
    //积分消费记录
    _getCreditCons() {
      this.pageData.telephone = this.item.telephone
      let time
      if (this.storageTime && this.storageTime.length > 0) {
        this.pageData.operatingStartTime = this.storageTime[0]
        this.pageData.operatingEndTime = this.storageTime[1]

        time = {
          operatingStartTime: this.storageTime[0],
          operatingEndTime: this.storageTime[1]
        }
      } else {
        this.pageData.operatingStartTime = ''
        this.pageData.operatingEndTime = ''

        time = {
          operatingStartTime: '',
          operatingEndTime: ''
        }
      }
      getCreditCons(this.pageData).then(res => {
        let { data } = res
        if (res.state == '200') {
          this.tableData = data.records
          this.total = data.total
        }
      })
      getCreditXf({ telephone: this.item.telephone, ...time }).then(res => {
        let { data } = res
        if (res.state == '200') {
          this.summaryInformation = data
        }
      })
    },
    // ------------------------
    //套餐开卡
    _getPackageCard() {
      this.pageData.telephone = this.item.telephone
      let time
      if (this.storageTime && this.storageTime.length > 0) {
        this.pageData.operatingStartTime = this.storageTime[0]
        this.pageData.operatingEndTime = this.storageTime[1]

        time = {
          operatingStartTime: this.storageTime[0],
          operatingEndTime: this.storageTime[1]
        }
      } else {
        this.pageData.operatingStartTime = ''
        this.pageData.operatingEndTime = ''

        time = {
          operatingStartTime: '',
          operatingEndTime: ''
        }
      }
      getPackageCard(this.pageData).then(res => {
        let { data } = res
        if (res.state == '200') {
          this.tableData = data.records
          this.total = data.total
        }
      })
      getPackageCardHz({ telephone: this.item.telephone, ...time }).then(res => {
        let { data } = res
        if (res.state == '200') {
          this.summaryInformation = data
        }
      })
    },
    // 查看套餐余额
    _getPackageBalance() {
      this.pageData.telephone = this.item.telephone
      let time
      if (this.storageTime && this.storageTime.length > 0) {
        this.pageData.operatingStartTime = this.storageTime[0]
        this.pageData.operatingEndTime = this.storageTime[1]

        time = {
          operatingStartTime: this.storageTime[0],
          operatingEndTime: this.storageTime[1]
        }
      } else {
        this.pageData.operatingStartTime = ''
        this.pageData.operatingEndTime = ''

        time = {
          operatingStartTime: '',
          operatingEndTime: ''
        }
      }
      getPackageBalance(this.pageData).then(res => {
        let { data } = res
        if (res.state == '200') {
          this.tableData = data.records
          this.total = data.total
        }
      })
      getPackageBalanceHz({ telephone: this.item.telephone, ...time }).then(res => {
        let { data } = res
        if (res.state == '200') {
          this.summaryInformation = data
        }
      })

    },
    // 套餐消费记录
    _getPackageCons() {
      this.pageData.telephone = this.item.telephone
      let time
      if (this.storageTime && this.storageTime.length > 0) {
        this.pageData.operatingStartTime = this.storageTime[0]
        this.pageData.operatingEndTime = this.storageTime[1]

        time = {
          operatingStartTime: this.storageTime[0],
          operatingEndTime: this.storageTime[1]
        }
      } else {
        this.pageData.operatingStartTime = ''
        this.pageData.operatingEndTime = ''

        time = {
          operatingStartTime: '',
          operatingEndTime: ''
        }
      }
      getPackageCons(this.pageData).then(res => {
        let { data } = res
        if (res.state == '200') {
          this.tableData = data.records
          this.total = data.total
        }
      })
      getPackageConsHz({ telephone: this.item.telephone, ...time }).then(res => {
        let { data } = res
        if (res.state == '200') {
          this.summaryInformation = data
        }
      })
    },
    // 赠送项目充值记录
    _getGift() {
      this.pageData.telephone = this.item.telephone
      let time
      if (this.storageTime && this.storageTime.length > 0) {
        this.pageData.operatingStartTime = this.storageTime[0]
        this.pageData.operatingEndTime = this.storageTime[1]

        time = {
          operatingStartTime: this.storageTime[0],
          operatingEndTime: this.storageTime[1]
        }
      } else {
        this.pageData.operatingStartTime = ''
        this.pageData.operatingEndTime = ''

        time = {
          operatingStartTime: '',
          operatingEndTime: ''
        }
      }
      getGift(this.pageData).then(res => {
        let { data } = res
        if (res.state == '200') {
          this.tableData = data.records
          this.total = data.total
        }
      })
      getGiftHz({ telephone: this.item.telephone, ...time }).then(res => {
        let { data } = res
        if (res.state == '200') {
          this.summaryInformation = data
        }
      })
    },
    // 查看赠送项目余额
    _getGiftBalance() {
      this.pageData.telephone = this.item.telephone
      let time
      if (this.storageTime && this.storageTime.length > 0) {
        this.pageData.operatingStartTime = this.storageTime[0]
        this.pageData.operatingEndTime = this.storageTime[1]

        time = {
          operatingStartTime: this.storageTime[0],
          operatingEndTime: this.storageTime[1]
        }
      } else {
        this.pageData.operatingStartTime = ''
        this.pageData.operatingEndTime = ''

        time = {
          operatingStartTime: '',
          operatingEndTime: ''
        }
      }
      getGiftBalance(this.pageData).then(res => {
        let { data } = res
        if (res.state == '200') {
          this.tableData = data.records
          this.total = data.total
        }
      })
      getGiftBalanceHz({ telephone: this.item.telephone, ...time }).then(res => {
        let { data } = res
        if (res.state == '200') {
          this.summaryInformation = data
        }
      })

    },
    // 赠送项目消费记录
    _getGiftCons() {
      this.pageData.telephone = this.item.telephone
      let time
      if (this.storageTime && this.storageTime.length > 0) {
        this.pageData.operatingStartTime = this.storageTime[0]
        this.pageData.operatingEndTime = this.storageTime[1]

        time = {
          operatingStartTime: this.storageTime[0],
          operatingEndTime: this.storageTime[1]
        }
      } else {
        this.pageData.operatingStartTime = ''
        this.pageData.operatingEndTime = ''

        time = {
          operatingStartTime: '',
          operatingEndTime: ''
        }
      }
      getGiftCons(this.pageData).then(res => {
        let { data } = res
        if (res.state == '200') {
          this.tableData = data.records
          this.total = data.total
        }
      })
      getGiftConsHz({ telephone: this.item.telephone, ...time }).then(res => {
        let { data } = res
        if (res.state == '200') {
          this.summaryInformation = data
        }
      })
    }

  },

};
</script>
<style scoped>
.mr10 {
  margin-right: .25rem;
}

::v-deep .el-dialog.recordModals {
  width: 75% !important;
  margin-top: 8vh !important;
  height: 90vh;
}

/*dialog主体的宽*/
.dialogContainer .el-dialog {
  width: 75% !important;
  margin-top: 10vh !important;
}


.operateBtn .el-button {
  width: 1.125rem !important;
}

.dialogContentBottom {
  padding: 10px !important;
  border: 1px solid #d5d4d4;
  border-radius: .125rem;

  margin-top: .125rem;

  height: 4.375rem;
  position: relative;
}


/*dialog主体内容的padding*/
.dialogpart {
  padding: .25rem;
}

/*描述列表中input-label居中*/
.el-descriptions-item__container {
  align-items: center;
}

/*上面表格内容右边距*/
.dialogDes .el-descriptions-item__content {
  margin-right: 10px !important;
}

.summaryInfo {
  height: auto;
  position: absolute;
  bottom: .25rem;
  margin-top: .25rem;
}

.summaryInfo_Bootom {
  padding: 0 .5rem;
  font-size: large;
  font-weight: bold;
  color: rgb(92, 165, 243)
}
</style>