<template>
    <div>
        <el-dialog :visible.sync="dialogVisible" :modal="true" :append-to-body="true" :close-on-click-modal="false"
            :before-close="handleClose" width="40%">
            <div class="container">
                <el-form :model="form" class="demo-form-inline" :rules="rules" label-width="2rem">
                    <el-form-item label="链接地址：">
                        <el-input v-model="form.user" placeholder="链接请以http://或https://开头"></el-input>
                    </el-form-item>
                    <el-form-item label="链接标题：">
                        <el-input v-model="form.user" placeholder="输入链接标题"></el-input>
                    </el-form-item>
                    <el-form-item label="链接描述：">
                        <el-input v-model="form.user" placeholder="链接描述"></el-input>
                    </el-form-item>
                    <el-form-item label="链接封面：">
                        <!-- <uploadImg /> -->
                    </el-form-item>
                </el-form>
            </div>
            <span slot="title" class="dialog-title">
                <span class="title">创建链接</span>
            </span>
            <span slot="footer" class="dialog-footer">
                <div slot="footer" class="dialog-footer">
                    <el-button size="small" @click="handleClose">取消</el-button>
                    <el-button size="small" type="primary" @click="save">确定</el-button>
                </div>
            </span>
        </el-dialog>

    </div>
</template>  
    
<script>
import uploadImg from '@/views/components/uploadImg.vue';


export default {
    components: { uploadImg },
    data() {
        return {
            dialogVisible: false, // 控制弹出框的显示与隐藏  
            form: {
                user: ''
            },
            id: 0,
            memList: [],
            rules: {
                userid: [{ required: true, message: '请选择分配员工', trigger: 'blur' }]
            }
        };
    },
    created() {
    },
    methods: {
        openDialog(id) {
            // 打开弹出框，并触发父组件的事件传递参数  
            this.dialogVisible = true;
            this.getList()
            // this.$emit('child-event', 'Hello from child');
        },
        handleClose() {
            this.dialogVisible = false;
        },
        getList() {

        },

        save() {
            // this.$emit('setStaff', this.form.userid)
            this.dialogVisible = false
        }

    }
};
</script>
<style scoped>
::v-deep .el-input__inner {
    width: 100% !important;
}

.container {
    width: 100%;
}
</style>
  