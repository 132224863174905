import { listUserRoleMenu } from '@/api/login'
import { getBasicConfiguration } from '@/api/systemOption/platformConfiguration';
// import { getToken, setToken, removeToken } from '@/utils/auth'

const user = {
  state: {
    // token: getToken(),
    // name: '',
    // avatar: '',
    roles: [],
    permissions: [],
    recordList: [],
    // 客户端地址
    webUrl: '',
    // 后端接口访问地址
    apiUrl: '',
    // 手机端访问地址
    accountUrl: ''
  },

  mutations: {
    // SET_TOKEN: (state, token) => {
    //   state.token = token
    // },
    // SET_NAME: (state, name) => {
    //   state.name = name
    // },
    // SET_AVATAR: (state, avatar) => {
    //   state.avatar = avatar
    // },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions
    },
    SET_RECORD_LIST: (state, data) => {
      console.log(111, data);
      state.recordList = data
    },
    SET_WEB_URL: (state, data) => {
      console.log('webUrl=', data);
      state.webUrl = data
    },
    SET_API_URL: (state, data) => {
      console.log('apiUrl=', data);
      state.apiUrl = data
    },
    SET_ACCOUNT_URL: (state, data) => {
      console.log('accountUrl=', data);
      state.accountUrl = data
    }
  },

  actions: {
    // 获取url
    getUrl({ commit }) {
      return new Promise((resolve, reject) => {
        getBasicConfiguration().then(res => {
          if (res.state == '200') {
            sessionStorage.setItem('webUrl', res.data.webUrl)
            sessionStorage.setItem('apiUrl', res.data.apiUrl)
            sessionStorage.setItem('accountUrl', res.data.accountUrl)
            commit('SET_WEB_URL', res.data.webUrl)
            commit('SET_API_URL', res.data.apiUrl)
            commit('SET_ACCOUNT_URL', res.data.accountUrl)
            resolve()
          } else {
            reject(res.data.error)
          }
        })
      })
    },
    // 登录
    Login({ commit }, userInfo) {
      const username = userInfo.username.trim()
      const password = userInfo.password
      const code = userInfo.code
      const uuid = userInfo.uuid
      return new Promise((resolve, reject) => {
        login(username, password, code, uuid).then(res => {
          setToken(res.token)
          commit('SET_TOKEN', res.token)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 获取用户信息
    GetInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        listUserRoleMenu().then(res => {
          console.log(res)
          // const user = res.user
          // const avatar = (user.avatar == "" || user.avatar == null) ? require() : process.env.VUE_APP_BASE_API + user.avatar;
          if (res.data && res.data.length > 0) { // 验证返回的roles是否是一个非空数组
            commit('SET_PERMISSIONS', res.data)
            let data = res.data
            let menu = data.filter(item => item.id != '49')
            console.log(menu);
            commit('SET_ROLES', menu)
            sessionStorage.setItem('menu', JSON.stringify(menu))
            let isExport = data.find(item => item.id == '49')
            if (isExport) {
              sessionStorage.setItem('isExport', 1)
            } else {
              sessionStorage.setItem('isExport', 0)
            }

          } else {
            commit('SET_ROLES', ['ROLE_DEFAULT'])
          }
          // commit('SET_NAME', user.userName)
          // commit('SET_AVATAR', avatar)
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 退出系统
    LogOut() {
      return new Promise((resolve, reject) => {
        // logout(state.token).then(() => {
        // commit('SET_TOKEN', '')
        // commit('SET_ROLES', [])
        // commit('SET_PERMISSIONS', [])
        // removeToken()
        if (sessionStorage.getItem('token')) {
          sessionStorage.removeItem('token')
          resolve()
          window.location.href = 'http://121.28.102.238:15000/login?service=http://172.16.1.14:18080/login'
        } else {
          reject('错误')
        }

        // }).catch(error => {
        //   reject(error)
        // })
      })
    },

    // 前端 登出
    FedLogOut({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '')
        removeToken()
        resolve()
      })
    }
  }
}

export default user
