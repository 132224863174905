import request from '@/utils/request'

// 查询客户
export function getUserInfo(data) {
    return request({
        url: '/member/clientManage/getUserInfo',
        method: 'post',
        data: data
    })
}
// 储值方式-下拉框
export function getStoredModel(data) {
    return request({
        url: '/code/table/getStoredModel',
        method: 'post',
        data: data
    })
}
// 会员充值（自费充值，销售赠送，售后赠送，保险充值）（保存）
export function addVipSave(data) {
    return request({
        url: '/member/clientManage/addVipSave',
        method: 'post',
        data: data
    })
}
// 套餐列表
export function getServiceItem(data) {
    return request({
        url: '/user/package/getServiceItem',
        method: 'post',
        data: data
    })
}
// 套餐下的项目
export function getServicePackItem(data) {
    return request({
        url: '/user/package/getServicePackItem',
        method: 'post',
        data: data
    })
}
// 套餐保存按钮
export function addPackage(data) {
    return request({
        url: '/user/package/addPackage',
        method: 'post',
        data: data
    })
}
//项目类型下拉
export function getDonationItem(data) {
    return request({
        url: '/user/project/getDonationItem',
        method: 'post',
        data: data
    })
}
//项目添加
export function getProject(data) {
    return request({
        url: '/user/project/getProject',
        method: 'post',
        data: data
    })
}
//项目保存
export function addProject(data) {
    return request({
        url: '/user/project/addProject',
        method: 'post',
        data: data
    })
}