<template>
    <div class="mm_container">
        <div class="header_right">
            <Exit />
        </div>
        <div class="adminSettingNav">
            <el-menu :default-active="mm_defaultIndex" class="el-menu-demo" mode="horizontal" @select="mm_handleSelect">
                <el-menu-item index="mm_tag1">商品分类</el-menu-item>
                <el-menu-item index="mm_tag2">商品管理</el-menu-item>
                <el-menu-item index="mm_tag3">订单管理</el-menu-item>
            </el-menu>
        </div>
        <div class="mm_content" v-if="mm_activeIndex === 'mm_tag1'">
            <el-button type="primary" @click="addCategory" size="small">添加分类</el-button>
            <div class="tableStyle">
                <el-table :data="CategoryData" style="width: 70%" :stripe="true" size="mini"
                    header-cell-class-name="headerClassName" :cell-style="{ 'text-align': 'center' }" :border="true"
                    :fit="true">
                    <el-table-column prop="code" label="ID">
                        <template slot-scope="scope">
                            <span>{{ scope.$index + 1 }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="分类名称">
                    </el-table-column>
                    <el-table-column prop="quantity" label="商品数">
                    </el-table-column>
                    <el-table-column prop="sort" label="排序">
                    </el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <el-button @click="updateCategory(scope.row)" type="text" size="small">编辑</el-button>
                            <el-button type="text" size="small" @click="delCategory(scope.row.id)">刪除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="prev, pager, next" :current-page.sync="formQuery.page"
                    @current-change="getCategory" :page-size="5" :total="total">
                </el-pagination>
            </div>
        </div>
        <div class="mm_content" v-if="mm_activeIndex === 'mm_tag2'">

            <div class="top_search">
                <el-form ref="form" :model="form" size="small" :inline="true">
                    <el-form-item label="商品名称：">
                        <el-input v-model="form.name" placeholder="请输入商品名称"></el-input>
                    </el-form-item>
                    <el-form-item label="商家门店：">
                        <el-select placeholder="请选择" v-model="form.affStore" clearable>
                            <el-option v-for="(item, index) in StoreList" :key="index" :label="item.name"
                                :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-button type="primary" size="small" @click="getGoods">查询</el-button>
                    <el-button type="primary" @click="addGoods" size="small">添加商品</el-button>
                </el-form>
            </div>
            <div class="tableStyle">
                <el-table :data="GoodsData" :stripe="true" size="mini" header-cell-class-name="headerClassName"
                    :cell-style="{ 'text-align': 'center' }" :border="true" :fit="true" height="5.9rem">
                    <el-table-column prop="id" label="ID">
                        <template slot-scope="scope">
                            <span>{{ scope.$index + 1 }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="商品名称">
                    </el-table-column>
                    <el-table-column prop="credit" label="积分">
                    </el-table-column>
                    <el-table-column prop="money" label="现金">
                    </el-table-column>
                    <el-table-column prop="sales" label="销量">
                    </el-table-column>
                    <el-table-column prop="inventory" label="库存">
                    </el-table-column>
                    <el-table-column prop="createTime" label="添加时间">
                    </el-table-column>
                    <el-table-column prop="sort" label="排序">
                    </el-table-column>
                    <el-table-column prop="storeName" label="所属门店">
                    </el-table-column>
                    <el-table-column prop="classifyName" label="所属分类">
                    </el-table-column>
                    <el-table-column label="推广">
                        <template slot-scope="scope">
                            <!-- <img v-if="scope.row.shareCode != ''" style="width: 1rem;height: 1rem"
                                :src="`data:image/jpeg;base64,${scope.row.shareCode}`" /> -->
                            <img v-if="scope.row.shareCode != ''" style="width: 1rem;height: 1rem"
                                :src="scope.row.shareCode" />
                        </template>
                    </el-table-column>
                    <el-table-column label="链接">
                        <template slot-scope="scope">
                            <el-button type="text" size="small" @click="copyUrl(scope.row.shareLink)">复制</el-button>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <el-button type="text" size="small" @click="updateGoods(scope.row)">编辑</el-button>
                            <el-button type="text" v-if="scope.row.status == 1" size="small"
                                @click="delGoods(scope.row.id, 0)">下架</el-button>
                            <el-button type="text" v-if="scope.row.status == 0" size="small"
                                @click="delGoods(scope.row.id, 1)">上架</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="prev, pager, next" :current-page.sync="form.page"
                    @current-change="getGoods" :page-size="10" :total="totalGoods">
                </el-pagination>
            </div>
        </div>
        <div class="mm_content" v-if="mm_activeIndex === 'mm_tag3'">
            <div class="top_search">
                <el-form ref="form" :model="form" label-width="1.3rem" size="small" :inline="true">
                    <el-form-item label="客户姓名：">
                        <el-input v-model="formMall.name" placeholder="客户姓名搜索"></el-input>
                    </el-form-item>
                    <el-form-item label="手机号：">
                        <el-input v-model="formMall.telephone" placeholder="手机号搜索"></el-input>
                    </el-form-item>
                    <el-form-item label="商品名称：">
                        <el-input v-model="formMall.productName" placeholder="商品名称搜索"></el-input>
                    </el-form-item>
                    <el-form-item label="商品分类：">
                        <el-select placeholder="请选择" v-model="formMall.classifyId" clearable>
                            <el-option v-for="(item, index) in classifyList" :key="index" :label="item.name"
                                :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <!-- <el-form-item label="开卡店：">
                        <el-select placeholder="请选择" v-model="formMall.affiliatedStore" clearable>
                            <el-option v-for="(item, index) in StoreList" :key="index" :label="item.name"
                                :value="item.name"></el-option>
                        </el-select>
                    </el-form-item> -->
                    <!-- <el-form-item label="操作门店：">
                        <el-select placeholder="请选择" v-model="formMall.operationCard" clearable>
                            <el-option v-for="(item, index) in StoreList" :key="index" :label="item.name"
                                :value="item.name"></el-option>
                        </el-select>
                    </el-form-item> -->
                    <el-form-item label="操作时间：">
                        <el-date-picker size="small" clearable v-model="storageTime" type="daterange" range-separator="至"
                            start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd">
                        </el-date-picker>
                    </el-form-item>
                    <!-- <el-form-item label="操作员工：">
                        <el-input v-model="formMall.operatingName" placeholder="操作员工搜索"></el-input>
                    </el-form-item> -->
                    <el-form-item>
                        <el-button type="primary" size="small" @click="getMall">查询</el-button>
                        <el-button type="primary" size="small" @click="exportData" v-if="isExport == 1">导出</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div class="tableStyle" style="width:100%;">
                <el-table :data="mallData" style="width: 100%" :stripe="true" size="mini"
                    header-cell-class-name="headerClassName" :cell-style="{ 'text-align': 'center' }" :border="true"
                    :fit="true">
                    <el-table-column prop="id" label="兑换编号">
                        <template slot-scope="scope">
                            <span>{{ scope.$index + 1 }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="productName" label="商品名称">
                    </el-table-column>
                    <el-table-column prop="classifyName" label="商品分类">
                    </el-table-column>
                    <el-table-column prop="credit" label="消耗积分">
                    </el-table-column>
                    <el-table-column prop="money" label="余额支付">
                    </el-table-column>
                    <el-table-column prop="cashPayment" label="现金支付">
                    </el-table-column>
                    <el-table-column prop="transactionTime" label="成交时间" width="150">
                    </el-table-column>
                    <el-table-column prop="name" label="联系人">
                    </el-table-column>
                    <el-table-column prop="telephone" label="联系电话" width="150">
                    </el-table-column>
                    <el-table-column prop="vipLevelName" label="会员级别">

                    </el-table-column>
                    <el-table-column prop="isExchange" label="有无兑换">
                        <template slot-scope="scope">
                            <p v-if="scope.row.isExchange == 1" style="color: #E6A23C;">待兑换</p>
                            <p v-if="scope.row.isExchange == 2" style="color: #409EFF;">已兑换</p>
                            <p v-if="scope.row.isExchange == 3" style="color: #909399;">取消兑换</p>
                        </template>
                    </el-table-column>
                    <el-table-column prop="storeDO.name" label="所属门店">
                    </el-table-column>
                    <el-table-column prop="operationTime" label="操作时间" width="150">
                    </el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <el-button type="text" size="small" @click="checkDetails(scope.row.id)">查看</el-button>
                            <el-button type="text" size="small" :disabled="scope.row.isExchange == 2"
                                @click="getRedemption(scope.row.id)">兑换</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="footerStyle">
                    <div class="page">
                        <el-pagination background layout="prev, pager, next" :current-page.sync="formMall.page"
                            @current-change="getMall" :page-size="formMall.pageSize" :total="totalMall">
                        </el-pagination>
                    </div>
                </div>
                <div class="show_data">
                    <el-divider content-position="left" class="poi_title">汇总信息</el-divider>
                    <div class="summaryInfo_Bootom">
                        <div>消费积分：{{ summaryData.totalCredit || 0 }}分
                            消费余额：{{ summaryData.totalMoney || 0 }}元
                            消费现金：{{ summaryData.totalCashPayment || 0 }}元</div>
                    </div>
                </div>
            </div>
            <!-- <div class="totalBottom">
                <p>汇总：</p>
                <p>消费积分：<span>3000</span>分</p>
                <p>消费余额：<span>3000</span>元</p>
                <p>消费金额：<span>3000</span>元</p>
            </div> -->

        </div>
        <AddCategoryModal ref="addCategoryModal" :initial-message="addCarMessage" @child-event="handleChildEvent" />
        <AddGoodsModal ref="addGoodsModal" @child-event="handleChildEvent" />
        <GetRedemption ref="getRedemptionModal" :initial-message="addCarMessage" @child-event="getMall" />

    </div>
</template>
<script>
import AddCategoryModal from '../memberConsumModal/AddCategoryModal.vue'
import AddGoodsModal from '../memberConsumModal/AddGoodsModal.vue'
import GetRedemption from '../memberConsumModal/GetRedemption.vue'
import { getVipMall, getVipManage, removeVipMall, getStoreList, removeVipManage, getVipExchange, getClassify, getVipExpendSummary, exportVipExchangeList } from '@/api/memberConsume/index';
import Exit from '@/views/components/Exit.vue';
export default {
    components: { AddCategoryModal, AddGoodsModal, GetRedemption, Exit },
    data() {
        return {
            mm_defaultIndex: 'mm_tag1',
            mm_activeIndex: 'mm_tag1',
            summaryData: {},
            form: {
                page: 1,
                pageSize: 10
            },
            addCarMessage: {},
            mallData: [],
            totalMall: 0,
            storageTime: '',
            formMall: {
                page: 1,
                pageSize: 10,
                name: '',
                telephone: '',
                productName: '',
                classifyId: '',
            },
            CategoryData: [],
            total: 0,
            formQuery: {
                page: 1,
                pageSize: 10
            },
            GoodsData: [],
            totalGoods: 0,
            StoreList: [],
            classifyList: [],
            tableHeight: 0,
            isExport: false
        }
    },
    created() {
        this.getCategory()
        this.getGoods()
        this.getStore()
        this.getMall()
        this.getClassifyList()
        if (this.$route.query.type) {
            this.mm_defaultIndex = 'mm_tag3'
            this.mm_activeIndex = 'mm_tag3'
        }

    },
    mounted() {
        this.isExport = sessionStorage.getItem('isExport')
    },
    methods: {
        copyUrl(url) {
            console.log(url)
            // 创建一个临时的 textarea 元素。
            const el = document.createElement('textarea');
            // 将需要复制的文本内容赋值给 textarea 元素。
            el.value = url;
            //将 textarea 元素添加到页面中。
            document.body.appendChild(el);
            //选中 textarea 元素中的文本。
            el.select();
            // 执行复制命令，将选中的文本复制到剪贴板
            document.execCommand('copy');
            //从页面中移除 textarea 元素。
            document.body.removeChild(el);
            //弹出提示框，告知用户文本已复制成功。
            this.$message.success('复制成功')

        },
        exportData() {
            let fileName = '订单报表'
            if (this.storageTime && this.storageTime.length > 0) {
                this.formMall.operationStartTime = this.storageTime[0]
                this.formMall.operationEndTime = this.storageTime[1]
            } else {
                this.formMall.operationStartTime = ''
                this.formMall.operationEndTime = ''
            }
            exportVipExchangeList(this.formMall).then(res => {
                let blob = new Blob([res], {
                    // type: 'application/actet-stream;charset=utf-8'
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
                }); // 构造一个blob对象来处理数据，并设置文件类型
                let a = document.createElement('a'); // 生成一个a标签
                let href = window.URL.createObjectURL(blob); //创建下载的链接
                a.href = href; //指定下载链接
                a.download = fileName //指定下载文件名
                // a.setAttribute('download', 'fileName')
                document.body.appendChild(a); //把a标签加到页面中
                a.click(); //触发a标签下载
                document.body.removeChild(a); //下载完成移除元素
                window.URL.revokeObjectURL(href); //释放掉blob对象
            })
        },
        mm_handleSelect(key, keyPath) {
            this.mm_activeIndex = key
        },
        handleChildEvent() {
            this.getCategory()
            this.getGoods()
        },
        openAddAdminDialog() {
            this.$refs.addAdminModal.openDialog()
        },
        updateCategory(row) {
            this.$refs.addCategoryModal.openDialog(row)
        },
        out() {
            this.$confirm('确认关闭？')
                .then(_ => {
                    done()
                })
                .catch(_ => { })
        },
        addCategory() {
            this.$refs.addCategoryModal.openDialog()
        },
        addGoods() {
            this.$refs.addGoodsModal.openDialog()
        },
        checkDetails(id) {
            this.$router.push({ path: '/memberConsume/redemptionDetails?id=' + id })
        },
        getRedemption(id) {
            this.$refs.getRedemptionModal.openDialog(id)
        },
        //获取分类列表
        getClassifyList() {
            getClassify({}).then(res => {
                let { data } = res
                this.classifyList = data
            })
        },
        //获取所有分类列表
        getCategory() {
            getVipMall(this.formQuery).then(res => {
                let { data } = res
                this.CategoryData = data.records
                this.total = data.total
            })
        },
        //删除分类
        delCategory(id) {
            this.$confirm('确定删除此分类吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                removeVipMall({ id: id }).then(res => {
                    if (res.state == '200') {
                        this.$message.success('删除成功')
                        this.getCategory()
                    }
                })
            }).catch(() => {

            });
        },
        //获取商品列表
        getGoods() {
            // this.loading = true
            getVipManage(this.form).then(res => {
                let { data } = res
                this.GoodsData = data.records
                this.totalGoods = data.total
            })
        },

        arrayBufferToBase64(buffer) {
            var binary = ''
            var bytes = new Uint8Array(buffer)
            var len = bytes.byteLength
            for (var i = 0; i < len; i++) {
                binary += String.fromCharCode(bytes[i])
            }
            return window.btoa(binary)
        },
        //获取商家门店列表
        getStore() {
            getStoreList({}).then(res => {
                this.StoreList = res.data
            })
        },
        updateGoods(row) {
            this.$refs.addGoodsModal.openDialog(row)
        },
        //下架商品
        delGoods(id, status) {
            this.$confirm('确定进行此操作吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                removeVipManage({ id: id, status: status }).then(res => {
                    if (res.state == '200') {
                        this.$message.success('下架成功')
                        this.getGoods()
                    }
                })
            }).catch(() => {

            });
        },
        //获取订单列表
        getMall() {
            if (this.storageTime && this.storageTime.length > 0) {
                this.formMall.operationStartTime = this.storageTime[0]
                this.formMall.operationEndTime = this.storageTime[1]
            } else {
                this.formMall.operationStartTime = ''
                this.formMall.operationEndTime = ''
            }
            getVipExchange(this.formMall).then(res => {
                let { data } = res
                this.mallData = data.records
                this.totalMall = data.total
            })
            getVipExpendSummary(this.formMall).then(res => {
                console.log(res)
                this.summaryData = res.data
            })
        }
    },
    components: {
        AddCategoryModal,
        AddGoodsModal,
        GetRedemption,
        Exit
    }
}
</script>
<style scoped>
.header_right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.mm_container {
    width: 100%;
    height: 100%;
    background-color: rgb(247, 248, 250);
    display: flex;
    flex-direction: column;
}

.exit {
    height: .5rem;
}

.outLogin {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
}

.outLogin span {
    display: inline-block;
    margin-right: .0625rem;
    font-size: .1875rem !important;
}



.mm_content {
    background-color: #fff;
    width: 100%;
    padding: .125rem .25rem;
    flex: 1;
    margin-top: .25rem;
    position: relative;
}

.totalBottom {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    height: 1.25rem;
    padding: 0 .25rem;

}

.totalBottom p {
    font-size: .23rem;
    margin-right: .25rem;
}

.totalBottom p span {
    font-size: .28rem;
    font-weight: 500;
    color: #409EFF;
}

.summaryInfo_Bootom {
    padding: 0 .5rem;
    font-size: large;
    font-weight: bold;
    color: rgb(92, 165, 243)
}

.btn {
    width: 100%;
    padding: 10px 20px;
    padding-left: 0;
}

.top_search {
    padding: .125rem;
}

.footerStyle {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}

.page {
    display: flex;
    justify-content: flex-end;
    flex: 1;
}

.tableStyle {
    padding: .125rem 0;
    height: 80%;

}
</style>