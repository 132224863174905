import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './utils/flexible'
import './assets/icons/iconfont.css'
import './assets/style/commonStyle.css'
// import Router from 'vue-router';
import { delMessage } from './utils/tool.js'
import { flexWidth } from './utils/tool.js';



Vue.use(ElementUI);
// Vue.use(Router)
Vue.config.productionTip = false


new Vue({
  router,
  store,
  beforeCreate() {
    Vue.prototype.$flexWidth = flexWidth
    Vue.prototype.$bus = this //安装全局事件总线  用完了记得把傀儡身上的事件解绑
    Vue.prototype.delMsg = delMessage
  },
  render: h => h(App)
}).$mount('#app')
