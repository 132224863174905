var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-dialog',{attrs:{"custom-class":"addGoods","visible":_vm.dialogVisible,"modal":true,"append-to-body":true,"close-on-click-modal":false},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('el-form',{ref:"formGoods",attrs:{"model":_vm.form,"label-width":"1.5rem","size":"mini"}},[_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":14}},[_c('el-form-item',{attrs:{"label":"商品名称","prop":"name","rules":[
                        { required: true, message: '商品名称不能为空', trigger: 'blur' }
                    ]}},[_c('el-input',{attrs:{"placeholder":"输入商品名称，30字以内"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1)],1)],1),_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":14}},[_c('el-form-item',{attrs:{"label":"所属门店","prop":"storeId","rules":[
                        { required: true, message: '门店不能为空', trigger: 'change' }
                    ]}},[_c('el-select',{attrs:{"placeholder":"请选择","disabled":""},model:{value:(_vm.form.storeId),callback:function ($$v) {_vm.$set(_vm.form, "storeId", $$v)},expression:"form.storeId"}},_vm._l((_vm.storeList),function(item,index){return _c('el-option',{key:index,attrs:{"label":item.name,"value":item.id}})}),1)],1)],1)],1),_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":14}},[_c('el-form-item',{attrs:{"label":"商品分类","prop":"classifyId","rules":[
                        { required: true, message: '商品分类不能为空', trigger: 'change' }
                    ]}},[_c('el-select',{attrs:{"placeholder":"请选择"},model:{value:(_vm.form.classifyId),callback:function ($$v) {_vm.$set(_vm.form, "classifyId", $$v)},expression:"form.classifyId"}},_vm._l((_vm.Category),function(item,index){return _c('el-option',{key:index,attrs:{"label":item.name,"value":item.id}})}),1)],1)],1)],1),_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":14}},[_c('el-form-item',{attrs:{"label":"排序"}},[_c('el-input',{attrs:{"placeholder":"数字越小越靠前"},model:{value:(_vm.form.sort),callback:function ($$v) {_vm.$set(_vm.form, "sort", $$v)},expression:"form.sort"}}),_c('span',{staticClass:"remark"},[_vm._v("数字越小越靠前")])],1)],1)],1),_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":14}},[_c('el-form-item',{attrs:{"label":"积分","prop":"credit"}},[_c('el-input',{model:{value:(_vm.form.credit),callback:function ($$v) {_vm.$set(_vm.form, "credit", $$v)},expression:"form.credit"}}),_c('span',{staticClass:"remark"},[_vm._v("积分和价格，必须填写其中一种")])],1)],1),_c('el-col',{attrs:{"span":10}},[_c('el-form-item',{staticClass:"formItem"},[_c('span',{staticClass:"fenAndYuan"},[_vm._v("分")])])],1)],1),_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":14}},[_c('el-form-item',{attrs:{"label":"价格","prop":"money"}},[_c('el-input-number',{attrs:{"min":0},model:{value:(_vm.form.money),callback:function ($$v) {_vm.$set(_vm.form, "money", $$v)},expression:"form.money"}}),_c('br'),_c('span',{staticClass:"remark"},[_vm._v("3种兑换方式：积分+现金、积分、现金")])],1)],1),_c('el-col',{attrs:{"span":10}},[_c('el-form-item',{staticClass:"formItem"},[_c('span',{staticClass:"fenAndYuan"},[_vm._v("元")])])],1)],1),_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":14}},[_c('el-form-item',{attrs:{"label":"商品库存","prop":"inventory","rules":[
                        { required: true, message: '商品库存不能为空', trigger: 'blur' }
                    ]}},[_c('el-input',{attrs:{"placeholder":"请输入商品库存","type":"number"},model:{value:(_vm.form.inventory),callback:function ($$v) {_vm.$set(_vm.form, "inventory", $$v)},expression:"form.inventory"}})],1)],1)],1),_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":14}},[_c('el-form-item',{attrs:{"label":"已售数量"}},[_c('el-input',{attrs:{"value":_vm.form.sales,"readonly":"","disabled":""}})],1)],1)],1),_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":"缩略图"}},[_c('el-upload',{ref:"upload",staticClass:"upload-demo",attrs:{"list-type":"picture-card","action":"","multiple":"","limit":1,"file-list":_vm.fileList,"name":"uploadFile","http-request":_vm.upload,"on-success":_vm.handleAvatarSuccess,"before-upload":_vm.onprogress,"on-exceed":_vm.limitChange}},[_c('i',{staticClass:"el-icon-camera avatar-uploader-icon"})]),_c('span',{staticClass:"remark"},[_vm._v("图片大小在600K以内")])],1)],1)],1),_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":"商品详情","prop":"details","rules":[
                        { required: true, message: '商品详情不能为空', trigger: 'blur' }
                    ]}},[_c('quill-editor',{ref:"customQuillEditor",staticClass:"editor",staticStyle:{"height":"4rem"},attrs:{"options":_vm.editorOption},model:{value:(_vm.form.details),callback:function ($$v) {_vm.$set(_vm.form, "details", $$v)},expression:"form.details"}})],1)],1)],1)],1),_c('span',{staticClass:"dialog-title",attrs:{"slot":"title"},slot:"title"},[_c('span',[_vm._v("添加商品")])]),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"size":"small","type":"primary"},on:{"click":_vm.addGoods}},[_vm._v("确认保存")])],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }