<template>
    <div class="container">
        <div class="top">
            <el-card class="box-card">
                <div class="flex">
                    <img src="../../../assets/customerbatch.png">
                    <div class="nr">
                        <p class="title">客户群发</p>
                        <p style="font-size:small;color: #b1aeae;margin-top: .125rem;">
                            创建群发消息，通知企微员工群发给客户。注意：每位客户每天可以接收1条群发消息，不限企业发布的群发还是个人发布的群发。</p>
                    </div>
                </div>
            </el-card>
        </div>
        <div class="content">
            <el-card class="box-card">
                <div class="search">
                    <el-form :inline="true" :model="formInline" class="demo-form-inline" size="small">
                        <el-form-item label="群发名称">
                            <el-input v-model="formInline.title" placeholder="请输入群发名称关键词" clearable></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button @click="getList">搜索</el-button>
                        </el-form-item>
                    </el-form>
                    <div>
                        <el-button type="primary" @click="cjqf" size="small">创建群发</el-button>
                    </div>
                </div>
                <div class="items">
                    <div class="item" v-for="(item, index) in tableData" :key="index">
                        <el-row type="flex" class="row-bg" justify="space-between">
                            <el-col :span="1">
                                <div> <el-checkbox v-model="item.checked" @change="setKey(item.id, index)"></el-checkbox>
                                </div>
                            </el-col>
                            <el-col :span="2">
                                <div>{{ item.title }}</div>
                            </el-col>
                            <el-col :span="1">
                                <div style="color: red;">ID:{{ item.id }}</div>
                            </el-col>
                            <el-col :span="4">
                                <div>员工发送情况</div>
                            </el-col>
                            <el-col :span="4">
                                <div>送达客户情况</div>
                            </el-col>
                            <el-col :span="4">
                                <div>发送时间</div>
                            </el-col>
                            <el-col :span="4">
                                <div>操作</div>
                            </el-col>
                        </el-row>
                        <el-row type="flex" class="row-bg" justify="space-between">
                            <el-col :span="1"> </el-col>
                            <el-col :span="2">
                                <div v-html="item.content"></div>
                                <div class="text-yellow-400" v-if="item.is_media == 1 && item.is_link == 1">
                                    [文本][图片][图文]
                                </div>
                                <div class="text-yellow-400" v-else-if="item.is_media == 1">
                                    [文本][图片]
                                </div>
                                <div class="text-yellow-400" v-else-if="item.is_link == 1">
                                    [文本][图文]
                                </div>
                                <div class="text-yellow-400" v-else>
                                    [文本]
                                </div>
                            </el-col>
                            <el-col :span="1"> </el-col>
                            <el-col :span="4">
                                <el-button type="text" @click="check(item.id)">查看数据</el-button>
                            </el-col>
                            <el-col :span="4">
                                <div v-if="item.total_success && item.total_success != ''">已送达：<span
                                        style="color: green;">{{ item.total_success }}</span>人</div>
                                <div v-if="item.total_success && item.total_success != ''">未送达：<span style="color: red;">{{
                                    item.total_error }}</span>人</div>
                                <el-button v-else type="text" @click="look(item)">查看数据{{ item.total_error }}</el-button>
                            </el-col>
                            <el-col :span="4">
                                <div>{{ getTime(item.send_time * 1000) }}</div>
                            </el-col>
                            <el-col :span="4">
                                <el-button type="text" @click="remind(item)">提醒发送</el-button>
                                <el-button type="text" @click="edit(item.id)">编辑</el-button>
                                <el-button type="text" style="color: #ccc;"
                                    v-if="item.is_settime == 0 && item.is_stop == 0">已发送</el-button>
                                <el-button type="text"
                                    :style="{ color: (item.is_settime == 1 && item.is_stop == 1) ? 'grey' : 'red' }" v-else
                                    :disabled="item.is_settime == 1 && item.is_stop == 1"
                                    @click="cancelSend(item)">取消发送</el-button>
                            </el-col>
                        </el-row>
                    </div>
                </div>
                <div class="footer">
                    <div class="del">
                        <el-checkbox v-model="allSelect" @change="setKey()">全选</el-checkbox>
                        <el-button type="danger" @click="del" size="small" style="margin-left: .125rem;">删除</el-button>
                    </div>
                    <el-pagination background layout="prev, pager, next" :page-size="10" :current-page.sync="pageForm.page"
                        @current-change="getList" :total="total">
                    </el-pagination>
                </div>
            </el-card>
        </div>
        <el-drawer title="员工发送情况" size="50%" :visible.sync="drawer" :direction="direction" :before-close="handleClose">
            <div style="padding: 0 .25rem;">
                <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
                    <el-tab-pane label="未发送" name="first">
                        <el-table :data="disSendList" height="250" border style="width: 100%">
                            <el-table-column prop="nickname" label="员工">
                            </el-table-column>
                            <el-table-column prop="status" label="状态">
                                <template slot-scope="scope">
                                    <span>未发送</span>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-tab-pane>
                    <el-tab-pane label="已发送" name="second">
                        <el-table :data="sendList" height="250" border style="width: 100%">
                            <el-table-column prop="nickname" label="员工">
                            </el-table-column>
                            <el-table-column prop="status" label="状态">
                                <template slot-scope="scope">
                                    <span>已发送</span>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </el-drawer>
        <qfDialog ref="qfRef" @upload="getList" />
    </div>
</template>

<script>

import { getMassSendingList, remindMassSend, deliveryStatus, deleteMassSend, cancelMassSend, staffSendStatus } from '@/api/memberManage';
import qfDialog from '../memberManageModal/qfDialog.vue'
import { formatDate } from '../../../utils/tool'
export default {
    components: { qfDialog },
    props: {},
    data() {
        return {
            tableData: [],
            formInline: {
                title: ''
            },
            pageForm: {
                page: 1
            },
            total: 0,
            drawer: false,
            direction: 'rtl',
            activeName: 'first',
            checked: [],
            selectItems: [],
            allSelect: false,
            sendList: [],
            disSendList: []
        };
    },
    mounted() {

    },
    created() {
        this.getList()
    },
    watch: {

    },
    computed: {

    },
    methods: {
        cancelSend(item) {
            cancelMassSend({ id: item.id }).then(res => {
                if (res.state == '200') {
                    this.$message.success('取消发送成功')
                    this.getList()
                }
            })
        },
        setKey(e) {
            if (e) {
                let iii = this.selectItems.indexOf(e)
                if (iii != -1) {
                    this.selectItems.splice(iii, 1)
                } else {
                    this.selectItems.push(e)
                }
            } else {
                this.selectItems = []
                this.tableData.forEach((item, i, arr) => {
                    this.selectItems.push(item.id)
                    this.$set(arr[i], 'checked', this.allSelect)
                })
            }

        },
        getTime(time) {
            return formatDate(time)
        },
        look(item) {
            deliveryStatus({ id: item.id }).then(res => {
                if (res.state == '200') {
                    let datas = res.data.data
                    let total_success = ''
                    let total_error = ''
                    let currIndex = ''
                    this.tableData.forEach((data, index, arr) => {
                        if (data.id == item.id) {
                            // 已送达：
                            total_success = datas.total_success.toString()
                            // 未送达：
                            total_error = datas.total_error.toString()
                            currIndex = index
                        }
                    })
                    this.$set(this.tableData[currIndex], 'total_success', total_success)
                    this.$set(this.tableData[currIndex], 'total_error', total_error)
                }
            })
        },
        onSubmit() {

        },
        cjqf() {
            this.$refs.qfRef.openDialog()
        },
        del() {
            this.$confirm('确定删除吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                deleteMassSend(this.selectItems).then(res => {
                    if (res.state == '200') {
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                        this.getList()
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        getList() {
            this.selectItems = []
            getMassSendingList({ ...this.pageForm, ...this.formInline }).then(res => {
                if (res.state == '200') {
                    this.tableData = res.data.data.data.map(item => {
                        return { ...item, checked: false }
                    })
                    this.total = res.data.data.total
                }
            })
        },
        check(id) {
            this.drawer = true
            this.sendList = []
            this.disSendList = []
            staffSendStatus({ id }).then(res => {
                if (res.state == '200') {
                    let data = res.data.data.data
                    data.forEach(item => {
                        if (item.status == 2) {
                            this.sendList.push(item)
                        } else {
                            this.disSendList.push(item)
                        }
                    })
                }
            })
        },
        handleClose(done) {
            this.drawer = false
        },
        handleClick(tab, event) {
        },
        edit(id) {
            this.$refs.qfRef.openDialog(id)
        },
        remind(item) {
            this.$alert('确认后将会给所有未发送成员发送提醒通知，是否发送？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                showCancelButton: true,
                callback: action => {
                    remindMassSend({
                        id: item.id
                    }).then(res => {
                        if (res.state == '200') {
                            this.$message.success('发送成功')
                        }
                    })
                }
            })

        }
    },
};
</script>
<style scoped>
.text-yellow-400{
    color: #fbbf87;
}
.container {
    width: 100%;
    overflow-y: auto;
    padding: .25rem;
}

.top {
    margin-bottom: .25rem;
}

.box-card {
    padding: 0 .5rem;
    display: flex;
    width: 100%;
}

::v-deep .box-card .el-card__body {
    width: 100% !important;
}

.flex {
    display: flex;
}

.nr {
    padding: 0 .25rem;
}

.title {
    font-size: .275rem;
}

.search {
    display: flex;
    justify-content: space-between;
}

.content {
    width: 100%;
}

.item {
    border: 1px solid #e7e7eb;
    margin-bottom: .25rem;
    word-break: break-all;
}

.row-bg:first-child {
    border-bottom: 1px solid #e7e7eb;
    background-color: #f2f5fa;
}

.row-bg {
    width: 100%;
    align-items: center;
    /* border: 1px solid red; */
    padding: .125rem .25rem;
}

.footer {
    display: flex;
    justify-content: space-between;
}
</style>