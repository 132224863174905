<template>
    <div class="valueStorage">
        <div class="so_aside">
            <div class="user_info">
                <div class="user_image">
                    <el-avatar :size="50" :src="Customer.headimgurl"></el-avatar>
                </div>
                <div class="user_detail_info">
                    <div class="user user_name">
                        <span>{{ Customer.name ? Customer.name : '默认昵称' }}</span>
                        <el-tag type="warning" effect="plain" size="mini">
                            {{ Customer.vipLevelName ? Customer.vipLevelName : '普通用户' }}</el-tag>
                    </div>
                    <div class="user user_phone">
                        <i class="el-icon-phone">{{ Customer.telephone ? Customer.telephone : '' }}</i>
                    </div>
                    <div class="user user_time">
                        <span>注册时间：{{ Customer.joiningTime ? Customer.joiningTime : '' }}</span>
                    </div>
                </div>
            </div>
            <div class="aside_content">
                <div class="currentTimeStyle">
                    <div>当前时间：<span class="time">{{ formattedDate }}</span></div>
                    <div>流水单号：<span class="orderNumber">{{ result.serialNumber ? result.serialNumber : '' }}</span>
                    </div>
                </div>
                <div class="own_info">
                    <div class="own_info_tag">
                        <span>今日消费金额</span>
                        <span class="own_info_number">{{ result.totalConsumptionMoney ? result.totalConsumptionMoney : 0
                        }}</span>
                    </div>
                    <div class="own_info_tag">
                        <span>今日增加积分</span>
                        <span class="own_info_number">{{ result.totalAddCredit ? result.totalAddCredit : 0 }}</span>
                    </div>

                </div>
                <div class="record_info">
                    <div class="record_title">
                        当天账单（{{ Customer.operationName ? Customer.operationName : '当前操作员' }}）
                    </div>
                    <div class="record_content" v-if="recordList.length > 0">
                        <div class="record" v-for="(item, index) in recordList" :key="index">
                            <span class="record_value">【{{ item.types }}】</span>
                            <div class="record_time">
                                <span v-if="item.types.includes('充值')">共充值{{ item.count }}次</span>
                                <span v-if="item.types.includes('消费')">共消费{{ item.count }}次</span>
                                <span>累计{{ item.money }}元</span>
                            </div>
                        </div>
                    </div>
                    <div v-if="recordList.length == 0" style="width:100%;">
                        <el-empty description="搜索查看记录哦~"></el-empty>
                    </div>

                </div>
            </div>
        </div>
        <div class="vs_container">
            <el-menu :default-active="vs_defaultIndex" class="el-menu-demo" mode="horizontal" @select="vs_handleSelect">
                <el-menu-item index="vs_tag1">客户自费维保消费</el-menu-item>
                <el-menu-item index="vs_tag2">销售赠送维保消费</el-menu-item>
                <el-menu-item index="vs_tag3">售后赠送维保消费</el-menu-item>
                <el-menu-item index="vs_tag4">金融保险基金消费</el-menu-item>
            </el-menu>
            <div class="vs_main">
                <div class="search">
                    <el-form :model="formQuery" size="small" :inline="true" class="form">
                        <el-form-item label="手机号:">
                            <el-input ref="teleRef" autofocus placeholder="输入手机号查询"
                                v-model="formQuery.telephone"></el-input>
                        </el-form-item>
                        <el-form-item label="所属门店:">
                            <el-select v-model="formQuery.store" placeholder="请选择门店" @change="storeChange">
                                <el-option v-for="(item, index) in StoreList" :key="item.id" :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-form>
                    <el-button type="primary" size="small" @click="searchCustomer">搜索</el-button>
                </div>
                <div class="member_info">
                    <div class="vs_main_left">
                        <div class="des">
                            <el-descriptions class="des margin-top" :column="2" size="mini" border
                                :label_style="label_style" :contentStyle="content_style">
                                <template slot="title">
                                    <span class="title">会员基本信息</span>
                                </template>
                                <el-descriptions-item label="手机号" :span="1">
                                    <el-input class="dg" size="small" v-model="Customer.telephone" disabled></el-input>
                                </el-descriptions-item>
                                <el-descriptions-item label="客户自费" :span="1">
                                    <span class="addYellow dg">{{ Customer.selfBalance ? Customer.selfBalance : 0
                                    }}</span>
                                </el-descriptions-item>
                                <el-descriptions-item label="姓名" :span="1">
                                    <el-input class="dg" size="small" v-model="Customer.name" disabled></el-input>
                                </el-descriptions-item>
                                <el-descriptions-item label="销售赠送" :span="1">
                                    <span class="addYellow dg">{{ Customer.salesBalance ? Customer.salesBalance : 0
                                    }}</span>
                                </el-descriptions-item>
                                <el-descriptions-item label="会员卡级别" :span="1">
                                    <span class="dg"> {{ Customer.vipLevelName ? Customer.vipLevelName : '普通用户'
                                    }}</span>
                                    <!-- <el-input size="small" v-model="Customer.vipLevelId" disabled></el-input> -->
                                    <!-- <el-select placeholder="请选择" disabled v-model="Customer.vipLevel" size="small">
                                        <el-option label="区域一" value="shanghai"></el-option>
                                        <el-option label="区域二" value="beijing"></el-option>
                                    </el-select> -->
                                </el-descriptions-item>
                                <el-descriptions-item label="售后赠送" :span="1">
                                    <span class="addYellow dg">{{ Customer.afterBalance ? Customer.afterBalance : 0
                                    }}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :span="1">
                                    <template slot="label">
                                        <span style="color: red;">*</span>车牌号码
                                    </template>
                                    <el-select placeholder="请选择" v-model="carNum" size="small" @change="carChange"
                                        class="dg">
                                        <el-option v-for="(item, index) in Customer.carList" :key="index"
                                            :label="item.carNum" :value="item.carNum"></el-option>
                                    </el-select>
                                </el-descriptions-item>
                                <el-descriptions-item label="保险赠送" :span="1">
                                    <span class="addYellow dg">{{ Customer.insuranceBalance ? Customer.insuranceBalance
                                        : 0
                                    }}</span>
                                </el-descriptions-item>
                                <el-descriptions-item label="车型" :span="1">
                                    <span class="dg"> {{ type }}</span>
                                </el-descriptions-item>
                                <el-descriptions-item label="可用积分" :span="1">
                                    <span class="addYellow dg">{{ Customer.credit ? Customer.credit : 0 }}</span>
                                </el-descriptions-item>
                                <el-descriptions-item label="介绍人" :span="1">
                                    <!-- <el-select placeholder="请选择" disabled v-model="Customer.storeId" size="small">
                                        <el-option label="区域一" value="shanghai"></el-option>
                                        <el-option label="区域二" value="beijing"></el-option>
                                    </el-select> -->
                                    <span class="dg"> {{ Customer.sellerId }}</span>
                                </el-descriptions-item>
                                <el-descriptions-item label="发卡门店" :span="1">
                                    <!-- <el-select placeholder="请选择" disabled v-model="value" size="small">
                                        <el-option label="区域一" value="shanghai"></el-option>
                                        <el-option label="区域二" value="beijing"></el-option>
                                    </el-select> -->
                                    <span class="dg"> {{ Customer.storeDO && Customer.storeDO.name ?
                                        Customer.storeDO.name :
                                        '' }}</span>

                                </el-descriptions-item>
                                <el-descriptions-item label="备注" :span="2">
                                    <el-input class="dg" size="small" disabled v-model="Customer.remarks"></el-input>
                                </el-descriptions-item>
                            </el-descriptions>
                        </div>
                        <div class="orderAndTIme">
                            <div>流水单号：<span>{{ result.serialNumber ? result.serialNumber : '' }}</span></div>
                            <div>下一次维修到期时间：<span>{{ form.nextServiceTime ? form.nextServiceTime : '' }}</span></div>
                        </div>
                        <div class="left_table">
                            <el-descriptions class="des margin-top" :column="2" size="mini" border
                                :labelStyle="label_style1">
                                <el-descriptions-item label="施工单号" :span="1">
                                    <el-input class="dg" size="small" v-model="form.constNum"
                                        placeholder="请输入施工单号"></el-input>
                                </el-descriptions-item>
                                <el-descriptions-item label="维修时间" :span="1">
                                    <el-date-picker size="small" v-model="form.serviceTime" value-format="yyyy-MM-dd"
                                        type="date" placeholder="选择日期" @change="TimeChange" class="dg">
                                    </el-date-picker>
                                </el-descriptions-item>
                                <el-descriptions-item :span="1">
                                    <template slot="label">
                                        <span style="color: red;">*</span>
                                        维修类型
                                    </template>
                                    <el-select placeholder="请选择" v-model="form.serviceType" size="small" class="dg">
                                        <el-option v-for="(item, index) in serviceTypeList" :label="item.name"
                                            :value="item.id" :key="index"></el-option>
                                    </el-select>
                                </el-descriptions-item>
                                <el-descriptions-item label="保养间隔天数" :span="1">
                                    <!-- <span class="addYellow">400</span> -->
                                    <el-select @change="changeDay" placeholder="请选择" v-model="form.serviceDay" size="small"
                                        class="dg">
                                        <el-option :label="item.value" :value="item.id"
                                            v-for="(item, index) in serviceDayList" :key="index"></el-option>
                                        <!-- <el-option label="区域二" value="beijing"></el-option> -->
                                    </el-select>
                                </el-descriptions-item>
                                <el-descriptions-item label="消费备注" :span="2">
                                    <el-input size="small" v-model="form.remarks" class="dg"></el-input>
                                </el-descriptions-item>
                            </el-descriptions>
                        </div>
                        <div class="btn">
                            <el-button type="primary" @click="save(vs_activeIndex)" size="small"
                                :disabled="isDj">保存</el-button>
                        </div>
                    </div>
                    <div class="vs_main_right" v-if="vs_activeIndex === 'vs_tag1'">
                        <div class="right_top">
                            <el-descriptions class="des margin-top" :column="1" size="mini" border
                                :label_style="label_style">
                                <template slot="title">
                                    <span class="title">结账操作</span>
                                </template>
                                <el-descriptions-item label="材料费" :span="1">
                                    <!-- <span >400</span> -->
                                    <el-input size="small" class="addBlack dg" type="number"
                                        v-model="selfFrom.materialCost"></el-input>
                                </el-descriptions-item>
                                <el-descriptions-item label="材料折扣" :span="1">
                                    <el-input size="small" class="addRed dg" type="number" value="400"
                                        v-model="selfFrom.materialDiscount" @blur="vaildMaterial"></el-input>
                                </el-descriptions-item>
                                <el-descriptions-item label="工时费" :span="1">
                                    <el-input size="small" class="addBlack dg" type="number" value="400"
                                        v-model="selfFrom.laborCost"></el-input>
                                </el-descriptions-item>
                                <el-descriptions-item label="工时折扣" :span="1">
                                    <el-input size="small" class="addRed dg" type="number" value="400"
                                        v-model="selfFrom.laborDiscount" @blur="vaildLabor"></el-input>
                                </el-descriptions-item>
                                <el-descriptions-item label="优惠金额" :span="1">
                                    <el-input size="small" class="addGreen dg" type="number" value="400"
                                        v-model="selfFrom.discountAmount"></el-input>
                                </el-descriptions-item>
                                <el-descriptions-item label="积分抵扣" :span="1">
                                    <el-input size="small" class="addGreen dg" type="number" value="400"
                                        v-model="selfFrom.pointDiscount"></el-input>
                                </el-descriptions-item>
                            </el-descriptions>
                            <div class="compute">
                                <el-button size="small" @click="resetSelf" :disabled="isDj">清除</el-button>
                                <el-button size="small" type="primary" :disabled="disabledBtn || disabledBtn1 || isDj"
                                    @click="calculate(1)">计算</el-button>
                            </div>
                        </div>
                        <div class="right_bottom">
                            <div class="bo_top">
                                <div style="background-color:rgb(255,182,82)"><span>消费金额</span><span class="addRed">{{
                                    result.money ? result.money : 0 }}</span></div>
                                <div style="background-color:rgb(136,213,255)"><span>增加积分</span><span class="addGreen">{{
                                    result.credit ? result.credit : 0 }}</span>
                                </div>
                            </div>
                            <div class="bo_bottom">
                                <span>积分增加关联操作--金额分配</span>
                                <span>注：系统设置--积分管理 设置</span>
                            </div>
                        </div>
                    </div>
                    <div class="vs_main_right" v-if="vs_activeIndex === 'vs_tag2'">
                        <div class="right_top">
                            <el-descriptions class="des margin-top" :column="1" size="mini" border
                                :label_style="label_style">
                                <template slot="title">
                                    <span class="title">结账操作</span>
                                </template>
                                <el-descriptions-item label="消费金额" :span="1">
                                    <el-input size="small" class="addRed dg" type="number"
                                        v-model="selfFrom.cost"></el-input>
                                </el-descriptions-item>
                                <el-descriptions-item label="优惠金额" :span="1">
                                    <el-input size="small" class="addRed dg" type="number"
                                        v-model="selfFrom.discountAmount"></el-input>
                                </el-descriptions-item>
                            </el-descriptions>
                            <div class="compute">
                                <el-button size="small" @click="resetSelf" :disabled="isDj">清除</el-button>
                                <el-button size="small" type="primary" @click="calculate(2)" :disabled="isDj">计算</el-button>
                            </div>
                        </div>
                        <div class="right_bottom">
                            <div class="bo_top">
                                <div style="background-color:rgb(255,182,82)"><span>消费金额</span><span class="addRed">{{
                                    result.money ? result.money : 0 }}</span></div>
                            </div>
                            <!-- <div class="bo_bottom">
                            <span>积分增加关联操作--金额分配</span>
                            <span>注：系统设置--积分管理 设置</span>
                        </div> -->
                        </div>
                    </div>
                    <div class="vs_main_right" v-if="vs_activeIndex === 'vs_tag3'">
                        <div class="right_top">
                            <el-descriptions class="des margin-top" :column="1" size="mini" border
                                :label_style="label_style">
                                <template slot="title">
                                    <span class="title">结账操作</span>
                                </template>
                                <el-descriptions-item label="消费金额" :span="1">
                                    <el-input size="small" class="addRed dg" type="number"
                                        v-model="selfFrom.cost"></el-input>
                                </el-descriptions-item>
                                <el-descriptions-item label="优惠金额" :span="1">
                                    <el-input size="small" class="addRed dg" type="number"
                                        v-model="selfFrom.discountAmount"></el-input>
                                </el-descriptions-item>
                            </el-descriptions>
                            <div class="compute">
                                <el-button size="small" @click="resetSelf" :disabled="isDj">清除</el-button>
                                <el-button size="small" type="primary" @click="calculate(3)" :disabled="isDj">计算</el-button>
                            </div>
                        </div>
                        <div class="right_bottom">
                            <div class="bo_top">
                                <div style="background-color:rgb(255,182,82)"><span>消费金额</span><span class="addRed">{{
                                    result.money ? result.money : 0 }}</span></div>
                            </div>
                            <!-- <div class="bo_bottom">
                            <span>积分增加关联操作--金额分配</span>
                            <span>注：系统设置--积分管理 设置</span>
                        </div> -->
                        </div>
                    </div>
                    <div class="vs_main_right" v-if="vs_activeIndex === 'vs_tag4'">
                        <div class="right_top">
                            <el-descriptions class="des margin-top" :column="1" size="mini" border
                                :label_style="label_style">
                                <template slot="title">
                                    <span class="title">结账操作</span>
                                </template>
                                <el-descriptions-item label="消费金额" :span="1">
                                    <el-input size="small" class="addRed dg" type="number"
                                        v-model="selfFrom.cost"></el-input>
                                </el-descriptions-item>
                                <el-descriptions-item label="优惠金额" :span="1">
                                    <el-input size="small" class="addRed dg" type="number"
                                        v-model="selfFrom.discountAmount"></el-input>
                                </el-descriptions-item>
                            </el-descriptions>
                            <div class="compute">
                                <el-button size="small" @click="resetSelf" :disabled="isDj">清除</el-button>
                                <el-button size="small" type="primary" @click="calculate(3)" :disabled="isDj">计算</el-button>
                            </div>
                        </div>
                        <div class="right_bottom">
                            <div class="bo_top">
                                <div style="background-color:rgb(255,182,82)"><span>消费金额</span><span class="addRed">{{
                                    result.money ? result.money : 0 }}</span></div>
                            </div>
                            <!-- <div class="bo_bottom">
                            <span>积分增加关联操作--金额分配</span>
                            <span>注：系统设置--积分管理 设置</span>
                        </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <safeAuth ref="safeAuthRef" @search-customer="searchCustomer"></safeAuth>
    </div>
</template>
<script>
import * as memberApi from '@/api/memberManage/index'
import { addCrossStoreConsumptionRecord, getVipExpend, getRecordTask, addRecordTask, getNextTime, getMaintenanceTypeList, getCeIntervalDayList, materialDiscount, workingHours } from '@/api/memberConsume/index'
import axios from 'axios';
import safeAuth from '../memberConsumModal/safeAuth.vue';
export default {
    components: { safeAuth },
    data() {
        return {
            vs_defaultIndex: 'vs_tag1',
            vs_activeIndex: 'vs_tag1',
            content_style_right: {
                'width': '60%',
                'text-align': 'center'
            },
            label_style: {},
            label_style1: {
                'width': '2rem'
            },
            formQuery: {},
            Customer: {},
            value: '',
            form: {},
            selfFrom: {
                materialCost: 0,
                materialDiscount: 100,
                laborCost: 0,
                laborDiscount: 100,
                discountAmount: 0,
                pointDiscount: 0
            },
            result: {
                totalAddCredit: 0,
                totalConsumptionMoney: 0,
                nextServiceTime: 0,
                pointDiscount: 0,
                serialNumber: ''
            },
            type: 0,
            content_style: {
                'width': '3rem'
            },
            date: new Date(),
            carNum: '',
            type: '',
            carEngineNum: '',
            carFrameNumber: '',
            serviceTypeList: [],
            serviceDayList: [],
            disabledBtn: false,
            disabledBtn1: false,
            recordList: [],
            isCalculate: true,
            StoreList: [],
            baseUrl: '',
            isCurrentStore: '',
            isDj: false,
            types: '',
            totalConsumptionDOList: []
        }
    },
    mounted() {
        this.$refs.teleRef.focus()
        var _this = this;
        this.getSoreList()
        this.timer = setInterval(function () {
            _this.date = new Date();
        }, 1000);

    },
    computed: {
        formattedDate: function () {
            var date = this.date;
            var year = date.getFullYear();
            var month = (date.getMonth() + 1).toString().padStart(2, '0');
            var day = date.getDate().toString().padStart(2, '0');
            var hours = date.getHours().toString().padStart(2, '0');
            var minutes = date.getMinutes().toString().padStart(2, '0');
            var seconds = date.getSeconds().toString().padStart(2, '0');

            return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
        }
    },
    methods: {
        isTokenGq() {
            sessionStorage.removeItem(this.formQuery.store)
            this.$message.error('token已过期，请重新登录认证')
            this.openSafeAuthDialog()
        },
        storeChange(e) {
            const nowStore = this.StoreList.find(item => item.id == this.formQuery.store)
            if (nowStore && !nowStore.isStore) {
                this.baseUrl = nowStore.baseUrl
                this.isCurrentStore = false
                let kdToken = sessionStorage.getItem(e)
                if (!kdToken || kdToken == '') {
                    this.openSafeAuthDialog()
                }
            } else if (nowStore && nowStore.isStore) {
                this.isCurrentStore = true
            }
            // this.form.serviceDay = ''
            // this.form.serviceType = ''

        },
        openSafeAuthDialog() {
            this.$refs.safeAuthRef.openDialog({ baseUrl: this.baseUrl, storeId: this.formQuery.store })
        },
        getToken() {
            return sessionStorage.getItem(this.formQuery.store)
        },
        getSoreList() {
            memberApi.storeGetList({}).then(res => {
                let { data } = res
                this.StoreList = data
                data.forEach(item => {
                    if (item.isStore) {
                        this.formQuery.store = item.id
                        this.isCurrentStore = true
                        // this.getCommon()
                    }
                })
            })
        },
        vs_handleSelect(key, keyPath) {
            this.vs_activeIndex = key
            this.$refs.teleRef.focus()
            this.result = {}
            if (key == 'vs_tag1') {
                this.selfFrom = {
                    materialCost: 0,
                    materialDiscount: 100,
                    laborCost: 0,
                    laborDiscount: 100,
                    discountAmount: 0,
                    pointDiscount: 0
                }
            } else if (key == 'vs_tag2') {
                this.selfFrom = {
                    cost: 0,
                    discountAmount: 0
                }
            } else if (key == 'vs_tag3') {
                this.selfFrom = {
                    cost: 0,
                    discountAmount: 0
                }
            } else if (key == 'vs_tag4') {
                this.selfFrom = {
                    cost: 0,
                    discountAmount: 0
                }
            }
            this.setTotal()
            this.form = {}
        },
        TimeChange(e) {
            // 获取间隔天数
            let day = 0
            if (this.serviceDayList.length > 0 && this.form.serviceDay) {
                day = this.serviceDayList.find(item => item.id == this.form.serviceDay).value
            }
            getNextTime({ serviceTime: this.form.serviceTime, serviceDay: day }).then(res => {
                if (res.state == '200') {
                    this.form.nextServiceTime = res.data.nextServiceTime
                }
            })
            // this.form.nextServiceTime = this.form.serviceTime
        },
        changeDay(data) {
            if (this.form.serviceTime && this.serviceDayList.length > 0) {
                // 获取间隔天数
                const day = this.serviceDayList.find(item => item.id == this.form.serviceDay).value
                this.getNext({ serviceTime: this.form.serviceTime, serviceDay: day || 0 })
            }
        },
        vaildMaterial(e) {
            if (!this.isCurrentStore) {
                const token = this.getToken()
                if (token && token != '') {
                    const url = this.baseUrl + 'vip/expend/materialDiscount'
                    axios.post(url, { phone: this.Customer.telephone, material: this.selfFrom.materialDiscount }, {
                        headers: {
                            "AuthorizationToken": token
                        }
                    }).then(response => {
                        let res = response.data
                        if (res.state == '200') {
                            this.disabledBtn = false
                        } else if (res.state == '20002') {
                            this.disabledBtn = true
                            this.$message.error(res.error)
                        } else if (res.state == '20001') {
                            this.disabledBtn = true
                            this.isTokenGq()
                        } else {
                            this.disabledBtn = true
                            this.$message.error(res.error)
                        }
                    }, err => {
                        console.log(err);
                    })
                } else {
                    this.openSafeAuthDialog()
                }
            } else {
                materialDiscount({ phone: this.Customer.telephone, material: this.selfFrom.materialDiscount }).then(res => {
                    if (res.state == '200') {
                        this.disabledBtn = false
                    } else {
                        this.disabledBtn = true
                    }
                })
            }
            // materialDiscount({ phone: this.Customer.telephone, material: this.selfFrom.materialDiscount }).then(res => {
            //     console.log(res)
            //     if (res.state == '200') {
            //         this.disabledBtn = false
            //     } else {
            //         this.disabledBtn = true
            //     }
            // })
        },
        vaildLabor(e) {
            if (!this.isCurrentStore) {
                const token = this.getToken()
                if (token && token != '') {
                    const url = this.baseUrl + 'vip/expend/workingHours'
                    axios.post(url, { phone: this.Customer.telephone, material: this.selfFrom.laborDiscount }, {
                        headers: {
                            "AuthorizationToken": token
                        }
                    }).then(response => {
                        let res = response.data
                        if (res.state == '200') {
                            this.disabledBtn1 = false
                        } else if (res.state == '20002') {
                            this.disabledBtn1 = true
                            this.$message.error(res.error)
                        } else if (res.state == '20001') {
                            this.disabledBtn1 = true
                            this.isTokenGq()
                        } else {
                            this.disabledBtn1 = true
                            this.$message.error(res.error)
                        }
                    }, err => {
                        console.log(err);
                    })
                } else {
                    this.openSafeAuthDialog()
                }
            } else {
                workingHours({ phone: this.Customer.telephone, material: this.selfFrom.laborDiscount }).then(res => {
                    if (res.state == '200') {
                        this.disabledBtn1 = false
                    } else {
                        this.disabledBtn1 = true
                    }
                })
            }
            // workingHours({ phone: this.Customer.telephone, material: this.selfFrom.laborDiscount }).then(res => {
            //     console.log(res)
            //     if (res.state == '200') {
            //         this.disabledBtn1 = false
            //     } else {
            //         this.disabledBtn1 = true
            //     }
            // })
        },
        getNext(data) {
            getNextTime(data).then(res => {
                if (res.state == '200') {
                    this.form.nextServiceTime = res.data.nextServiceTime
                }
            })
        },
        searchCustomer() {
            if (!this.isCurrentStore) {
                const token = this.getToken()
                if (token && token != '') {
                    const url = this.baseUrl + 'vip/expend/getVipExpend'
                    axios.post(url, this.formQuery, {
                        headers: {
                            "AuthorizationToken": token
                        }
                    }).then(response => {
                        let res = response.data
                        if (res.state == '200') {
                            if (res.data.actived == 4) {
                                this.$message.info('该会员已冻结')
                                this.isDj = true
                            }
                            this.totalConsumptionDOList = res.data.totalConsumptionDOList
                            this.setTotal()
                            this.Customer = res.data
                            this.recordList = res.data.mainCzList
                            // this.result.totalAddCredit = res.data.totalAddCredit
                            // this.result.totalConsumptionMoney = res.data.totalConsumptionMoney
                            this.getCommon()
                        } else if (res.state == '20002') {
                            this.$message.error(res.error)
                        } else if (res.state == '20001') {
                            this.isTokenGq()
                        } else {
                            this.$message.error(res.error)
                        }
                    }, err => {
                        console.log(err);
                    })
                } else {
                    this.openSafeAuthDialog()
                }
            } else {
                getVipExpend(this.formQuery).then(res => {
                    // let { data, state } = res
                    if (res.state == '200') {
                        if (res.data.actived == 4) {
                            this.$message.info('该会员已冻结')
                            this.isDj = true
                        }
                        this.totalConsumptionDOList = res.data.totalConsumptionDOList
                        // this.result.totalAddCredit = res.data.totalAddCredit
                        // this.result.totalConsumptionMoney = res.data.totalConsumptionMoney
                        this.setTotal()
                        this.Customer = res.data
                        this.recordList = res.data.mainCzList
                        this.getCommon()
                    }
                })
            }
        },
        setTotal() {
            let type
            if (this.vs_activeIndex == 'vs_tag1') {
                type = 1
            } else if (this.vs_activeIndex == 'vs_tag2') {
                type = 2
            } else if (this.vs_activeIndex == 'vs_tag3') {
                type = 3
            } else if (this.vs_activeIndex == 'vs_tag4') {
                type = 4
            }
            const curTotal = this.totalConsumptionDOList?.find(item => item.type == type)
            if (curTotal) {
                this.result.totalAddCredit = curTotal.totalAddCredit
                this.result.totalConsumptionMoney = curTotal.totalConsumptionMoney
            } else {
                this.result.totalAddCredit = 0
                this.result.totalConsumptionMoney = 0
            }
        },
        getCommon() {
            // 维修类型 保养间隔天数
            if (!this.isCurrentStore) {
                const token = this.getToken()
                if (token && token != '') {
                    const typeUrl = this.baseUrl + 'sys/maintenanceType/getList'
                    const dayUrl = this.baseUrl + 'sys/maintenanceIntervalDay/getList'
                    axios.post(typeUrl, {}, {
                        headers: {
                            "AuthorizationToken": token
                        }
                    }).then(response => {
                        let res = response.data
                        if (res.state === '200') {
                            this.serviceTypeList = res.data
                        } else if (res.state == '20001') {
                            this.isTokenGq()
                        } else if (res.state == '20002') {
                            this.$message.error(res.error)
                        } else {
                            this.$message.error(res.error)
                        }
                    })
                    axios.post(dayUrl, {}, {
                        headers: {
                            "AuthorizationToken": token
                        }
                    }).then(response => {
                        let res = response.data
                        if (res.state === '200') {
                            this.serviceDayList = res.data
                        } else if (res.state == '20001') {
                            this.isTokenGq()
                        } else if (res.state == '20002') {
                            this.$message.error(res.error)
                        }
                    })
                } else {
                    this.openSafeAuthDialog()
                }
            } else {
                getMaintenanceTypeList().then(res => {
                    if (res.state === '200') {
                        this.serviceTypeList = res.data
                    }
                })
                getCeIntervalDayList().then(res => {
                    if (res.state === '200') {
                        this.serviceDayList = res.data
                    }
                })
            }
        },
        carChange(e) {
            this.Customer.carList.forEach(item => {
                if (item.carNum == e) {
                    console.log(item.carNum)
                    // item.brand = 
                    this.type = item.model
                    this.carEngineNum = item.carEngineNum
                    this.carFrameNumber = item.carFrameNumber
                }
            })
        },
        resetSelf() {
            if (this.vs_activeIndex == 'vs_tag1') {
                this.selfFrom = {
                    materialCost: 0,
                    materialDiscount: 100,
                    laborCost: 0,
                    laborDiscount: 100,
                    discountAmount: 0,
                    pointDiscount: 0
                }
            } else if (this.vs_activeIndex == 'vs_tag2') {
                this.selfFrom = {
                    cost: 0,
                    discountAmount: 0
                }
            } else if (this.vs_activeIndex == 'vs_tag3') {
                this.selfFrom = {
                    cost: 0,
                    discountAmount: 0
                }
            } else if (this.vs_activeIndex == 'vs_tag4') {
                this.selfFrom = {
                    cost: 0,
                    discountAmount: 0
                }
            }
        },
        calculate(type) {
            if (!this.Customer.telephone) {
                this.$message.error('请先输入手机号搜索')
                return
            }
            if (type == 1) {
                // console.log(this.selfFrom.materialCost === '')
                if (this.selfFrom.materialCost === '') {
                    this.selfFrom.materialCost = 0
                }
                if (this.selfFrom.materialDiscount === '') {
                    this.selfFrom.materialDiscount = 0
                }
                if (this.selfFrom.laborCost === '') {
                    this.selfFrom.laborCost = 0
                }
                if (this.selfFrom.discountAmount === '') {
                    this.selfFrom.discountAmount = 0
                }
                if (this.selfFrom.pointDiscount == '') {
                    this.selfFrom.pointDiscount = 0
                }
            } else if (type == 2) {
                if (this.selfFrom.discountAmount === '') {
                    this.selfFrom.discountAmount = 0
                }
                if (this.selfFrom.cost === '') {
                    this.selfFrom.cost = 0
                }
            } else if (type == 3) {
                if (this.selfFrom.discountAmount === '') {
                    this.selfFrom.discountAmount = 0
                }
                if (this.selfFrom.cost === '') {
                    this.selfFrom.cost = 0
                }
            } else if (type == 4) {
                if (this.selfFrom.discountAmount === '') {
                    this.selfFrom.discountAmount = 0
                }
                if (this.selfFrom.cost === '') {
                    this.selfFrom.cost = 0
                }
            }
            this.selfFrom.type = type
            this.selfFrom.telephone = this.formQuery.telephone
            this.selfFrom.vipLevel = this.Customer.vipLevelId

            if (!this.isCurrentStore) {
                const token = this.getToken()
                if (token && token != '') {
                    const url = this.baseUrl + 'vip/expend/getRecordTask'
                    axios.post(url, this.selfFrom, {
                        headers: {
                            "AuthorizationToken": token
                        }
                    }).then(response => {
                        let res = response.data
                        if (res.state == '200') {
                            this.setResult(res.data)
                            this.isCalculate = false
                        } else if (res.state == '20002') {
                            this.$message.error(res.error)
                        } else if (res.state == '20001') {
                            this.isTokenGq()
                        } else {
                            this.$message.error(res.error)
                        }
                    }, err => {
                        console.log(err);
                    })
                } else {
                    this.openSafeAuthDialog()
                }
            } else {
                getRecordTask(this.selfFrom).then(res => {
                    let { data, state } = res
                    if (state == '200') {
                        this.setResult(data)
                        this.isCalculate = false
                    }
                })
            }

        },
        setResult(data) {
            this.result.credit = data?.credit || 0
            this.result.money = data?.money || 0
            this.result.nextServiceTime = data?.nextServiceTime || 0
            this.result.pointDiscount = data?.pointDiscount || 0
            this.result.serialNumber = data?.serialNumber || ''
        },
        save(type) {
            if (this.carNum == '') {
                this.$message.error('请先选择车牌号码')
                return
            }
            if (this.isCalculate) {
                this.$message.error('请先进行结账操作')
                return
            }
            if (!this.form.serviceType || this.form.serviceType == '') {
                this.$message.error('请先选择维修类型')
                return
            }
            if (type == 'vs_tag1') {
                this.types = 1
            } else if (type == 'vs_tag2') {
                this.types = 2
            } else if (type == 'vs_tag3') {
                this.types = 3
            } else if (type == 'vs_tag4') {
                this.types = 4
            }

            this.form.type = this.types
            // this.form.name = this.Customer.name
            this.form.telephone = this.formQuery.telephone
            this.form.carNum = this.carNum
            this.form.carEngineNum = this.carEngineNum
            this.form.vipLevelId = this.Customer.vipLevelId
            this.form.conMoney = this.result.money || 0
            this.form.waterNum = this.result.serialNumber || ''
            this.form.materMoney = Number(this.selfFrom.materialCost)
            this.form.hourDeDu = this.selfFrom.laborCost
            this.form.hourMoney = this.selfFrom.laborDiscount
            this.form.favMoney = this.selfFrom.discountAmount
            this.form.conMoney = this.result.money || 0
            this.form.creditGifts = this.result.credit || 0
            this.form.carFrameNumber = this.carFrameNumber
            this.form.pointDiscount = this.selfFrom.pointDiscount
            this.form.materDeDu = this.selfFrom.materialDiscount
            this.form.constNum = this.form.constNum || ''
            this.form.serviceTime = this.form.serviceTime || ''
            this.form.nextServiceTime = this.form.nextServiceTime || ''
            this.remarks = this.Customer.remarks || ''



            if (!this.isCurrentStore) {
                const token = this.getToken()
                if (token && token != '') {
                    const url = this.baseUrl + 'vip/expend/addRecordTask'
                    axios.post(url, this.form, {
                        headers: {
                            "AuthorizationToken": token
                        }
                    }).then(response => {
                        let res = response.data
                        if (res.state == '200') {
                            this.$message.success('添加成功')

                            const serviceTypeOne = this.serviceTypeList.find(item => item.id == this.form.serviceType)
                            const dayTypeOne = this.serviceDayList.find(item => item.id == this.form.serviceDay)
                            const data = {
                                telephone: this.formQuery.telephone,
                                vipLevelName: this.Customer.vipLevelName || '普通用户',
                                vipName: this.Customer.name,
                                hairCard: this.Customer.storeDO && this.Customer.storeDO.name ? this.Customer.storeDO.name : '',
                                consumptionType: this.types,
                                selfBalance: this.Customer.selfBalance || 0,
                                salesBalance: this.Customer.salesBalance || 0,
                                afterBalance: this.Customer.afterBalance || 0,
                                insuranceBalance: this.Customer.insuranceBalance || 0,
                                credit: this.result.credit,
                                carNum: this.carNum,
                                carFrameNumber: this.carFrameNumber,
                                materialMoney: this.selfFrom.materialCost,
                                materialDiscount: this.selfFrom.materialDiscount,
                                workHoursMoney: this.selfFrom.laborCost,
                                workHoursDiscount: this.selfFrom.laborDiscount,
                                preferentialMoney: this.selfFrom.discountAmount,
                                creditDeduction: this.selfFrom.pointDiscount,
                                consumptionMoney: this.result.money,
                                addCredit: this.result.credit,
                                serialNumber: this.result.serialNumber,
                                constructionNumber: this.form.constNum || '',
                                maintenanceTime: this.form.serviceTime || '',
                                maintenanceType: serviceTypeOne?.name || '',
                                maintenanceIntervalDays: parseInt(dayTypeOne?.value) || '',
                                remarks: this.form.remarks
                            }
                            addCrossStoreConsumptionRecord(data).then(res => {
                                if (res.state == '200') {
                                    console.log('跨店记录添加成功');
                                    this.searchCustomer()
                                    this.form = {}
                                    this.type = ''
                                    this.carNum = ''
                                    if (this.vs_activeIndex == 'vs_tag1') {
                                        this.selfFrom = {
                                            materialCost: 0,
                                            materialDiscount: 100,
                                            laborCost: 0,
                                            laborDiscount: 100,
                                            discountAmount: 0,
                                            pointDiscount: 0
                                        }
                                    } else if (this.vs_activeIndex == 'vs_tag2') {
                                        this.selfFrom = {
                                            cost: 0,
                                            discountAmount: 0
                                        }
                                    } else if (this.vs_activeIndex == 'vs_tag3') {
                                        this.selfFrom = {
                                            cost: 0,
                                            discountAmount: 0
                                        }
                                    } else if (this.vs_activeIndex == 'vs_tag4') {
                                        this.selfFrom = {
                                            cost: 0,
                                            discountAmount: 0
                                        }
                                    }
                                    this.result = {}
                                }
                            })
                        } else if (res.state == '20002') {
                            this.$message.error(res.error)
                        } else if (res.state == '20001') {
                            this.isTokenGq()
                        } else {
                            this.$message.error(res.error)
                        }
                    }, err => {
                        console.log(err);
                    })
                } else {
                    this.openSafeAuthDialog()
                }
            } else {
                addRecordTask(this.form).then(res => {
                    // console.log(res)
                    if (res.state == '200') {
                        this.$message.success('添加成功')
                        this.searchCustomer()
                        this.form = {}
                        if (this.vs_activeIndex == 'vs_tag1') {
                            this.selfFrom = {
                                materialCost: 0,
                                materialDiscount: 100,
                                laborCost: 0,
                                laborDiscount: 100,
                                discountAmount: 0,
                                pointDiscount: 0
                            }
                        } else if (this.vs_activeIndex == 'vs_tag2') {
                            this.selfFrom = {
                                cost: 0,
                                discountAmount: 0
                            }
                        } else if (this.vs_activeIndex == 'vs_tag3') {
                            this.selfFrom = {
                                cost: 0,
                                discountAmount: 0
                            }
                        } else if (this.vs_activeIndex == 'vs_tag4') {
                            this.selfFrom = {
                                cost: 0,
                                discountAmount: 0
                            }
                        }
                        this.result = {}
                    }
                })
            }
        },

    },

}
</script>
<style scoped>
::v-deep .el-descriptions__header {
    margin-bottom: 0px !important;
}

.addYellow {
    color: rgb(244, 124, 0);
    font-weight: 600;
}


::v-deep .addRed .el-input__inner {
    color: red !important;
    text-align: center;
    font-weight: 600;
}

::v-deep .addBlack .el-input__inner {
    color: black !important;
    text-align: center;
    font-weight: 600;
}

::v-deep .addGreen .el-input__inner {
    color: green !important;
    text-align: center;
    font-weight: 600;
}

::v-deep .el-input__inner {
    color: #666 !important;
    font-size: .2rem;
}

.addGreen {
    color: #3CB371;
    font-weight: 600;
}

.valueStorage {
    width: 100%;
    display: flex;

}

.so_aside {
    width: 4.375rem;
    display: flex;
    flex-direction: column;
    height: 100vh !important;
    border-right: 1px solid rgba(212, 212, 212, 0.5);
}

.aside_content {
    padding: 0 .125rem;
    flex: 1;
}

.user_info {
    width: 100%;
    display: flex;
    height: 1.25rem;
    align-items: center;
    border-bottom: 1px solid rgb(212, 212, 212);
    margin-bottom: .125rem;
    margin-top: .5rem;
    /* padding-top: .5rem; */
}

.currentTimeStyle {
    width: 100%;
    padding: .5rem 0;
}

.currentTimeStyle span {
    color: rgb(0, 79, 251);
}

.user_image {
    width: 1.5rem;
    height: .75rem;
    display: flex;
    justify-content: center;
}

.user_detail_info {
    flex: 1;

}

.user {
    height: .1875rem;
    padding-bottom: .3125rem;
}

.user_name .el-tag {
    margin-left: .375rem !important;
}



.own_info_tag {
    width: 100%;
    height: .875rem;
    background: rgb(244, 244, 244);
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: .125rem;
}

.own_info_tag span {
    display: inline-block;
}

.own_info_number {
    font-weight: bold;
    color: #3CB371;
}

.record {
    margin-bottom: .125rem;
}

.record_info {
    width: 100%;
    flex: 1;
    height: 5rem;
    overflow: auto;
    border: 1px solid rgb(212, 212, 212);
    border-radius: .125rem !important;
    padding: .125rem;

}

.record_title {
    font-size: .175rem;
    border-bottom: 1px solid rgb(212, 212, 212);
    text-align: center;
    padding-bottom: .125rem;
    margin-bottom: .125rem;
}

.record_value {
    width: 100%;
    height: .3125rem;
    line-height: .3125rem;
    display: inline-block;
    background: #f2f2f2;
    font-size: .15rem;
}

.record_time {
    display: flex;
    /* justify-content: space-between; */
}

.record_time span {
    display: inline-block;
    color: rgb(0, 79, 251);
    font-size: .15rem;
    margin-right: .25rem;
}


.vs_main {
    background-color: #fff;
    width: 100%;
    padding: .125rem .25rem;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.search {
    /* width: 50%; */
    width: 100%;
    padding: 0 0 .125rem 0;
    border-radius: .125rem;
    display: flex;
}

.vs_container {
    flex: 1;
}

.vs_container .el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
    margin-bottom: 0;
}

.vs_container .el-descriptions__header {
    margin-bottom: 0;
}

.member_info {
    display: flex;
    /* flex: 1; */
    width: 100%;
}

.vs_main_left {
    width: 60%;
    margin-right: .25rem;
}

.vs_main_right {
    flex: 1;
    padding-right: .25rem;
    display: flex;
    flex-direction: column;
    text-align: center;

}

.vs_main_right .des {
    width: 80%;
}

.vs_main_left .el-descriptions__header {
    margin-bottom: 0;
}

.orderAndTIme {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: .125rem 0;

}

.orderAndTIme div {
    display: inline-block;
    font-size: large;
}

.orderAndTIme span {
    color: rgb(0, 79, 251);
}

.btn {
    width: 100%;
    padding: .125rem 0;
    text-align: center;
}

.compute {
    width: 80%;
    height: .65rem;
    background-color: rgb(242, 242, 242);
    text-align: center;
    padding: .125rem 0;
    display: flex;
    align-items: center;
    justify-content: center;

}

.right_bottom {
    width: 80%;
    display: flex;
    flex: 1;
    padding: .25rem;
    flex-direction: column;
}

.bo_top {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: .3rem;
}

.bo_top div {
    width: 3.125rem;
    display: inline-block;
    height: 1rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: .125rem;
}

.bo_top span {
    font-size: .2rem;
}

.bo_bottom {
    text-align: center;
}

.bo_bottom span {
    display: block;
    font-size: 12px;
    color: #4F4F4F;
}

.table1 .el-descriptions-item__label.is-bordered-label {
    background: rgb(242, 242, 242);
}
</style>