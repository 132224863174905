<template>
	<div class="exit_container">
		<!-- <div class="iconStyle">
            <i class="iconfont icon-yonghu"></i>
            <el-button type="text">切换用户</el-button>
        </div> -->
		<div class="iconStyle" @click="changePwd()">
			<!-- <i class="iconfont icon-yuechi"></i> -->
			<el-button type="text">密码修改</el-button>
		</div>
		<!--  <div class="iconStyle">
            <i class="iconfont icon-xitong"></i>
            <el-button type="text">系统挂起</el-button>
        </div> -->
		<el-divider direction="vertical"></el-divider>
		<div class="iconStyle" @click="out()">
			<el-button type="text">安全退出</el-button>
			<!-- <i class="iconfont icon-tuichu"></i> -->
		</div>
		<el-dialog custom-class="change_dialog" :visible.sync="dialogVisible" width="35%" :close-on-click-modal="false">
			<el-form label-position="right" label-width="2rem" :model="pwd" :rules="rule" ref="pwd">
				<el-form-item label="旧密码" prop="oldPwd">
					<el-input v-model="pwd.oldPwd" show-password></el-input>
				</el-form-item>
				<el-form-item label="新密码" prop="newPwd">
					<el-input v-model="pwd.newPwd" show-password></el-input>
				</el-form-item>
				<el-form-item label="再次输入新密码" prop="newPwd2">
					<el-input v-model="pwd.newPwd2" show-password></el-input>
				</el-form-item>
			</el-form>
			<span slot="title" class="dialog-title">
				<span>修改密码</span>
			</span>
			<span slot="footer" class="dialog-footer">
				<el-button @click="cancel()">取 消</el-button>
				<el-button type="primary" @click="save">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
import {
	changePwd, outLogin
} from '@/api/login';
export default {
	data() {
		return {
			dialogVisible: false,
			pwd: {
				oldPwd: '',
				newPwd: '',
				newPwd2: ''
			},
			rule: {
				oldPwd: [{
					required: true,
					message: '请输入旧密码',
					trigger: 'blur'
				},],
				newPwd: [{
					required: true,
					message: '请输入新密码',
					trigger: 'blur'
				},
				{
					min: 6,
					message: '密码长度不能小于6位',
					trigger: 'blur'
				}
				],
				newPwd2: [{
					required: true,
					message: '请再次输入新密码',
					trigger: 'blur'
				},
				{
					validator: this.pwdPass,
					trigger: 'blur'
				}
				]
			}
		}
	},
	methods: {
		out() {
			this.$confirm('确认关闭？')
				.then(_ => {
					done();
				})
				.catch(_ => { });
		},
		save() {
			this.$refs.pwd.validate(valid => {
				if (valid) {
					changePwd({
						oldPwd: this.pwd.oldPwd,
						newPwd: this.pwd.newPwd2
					}).then(res => {
						if (res.state === '200') {
							this.$message({
								message: '密码修改成功！',
								type: 'success'
							});
							this.dialogVisible = false
						} else {
						}
					})
				} else {
					return false
				}
			})

		},
		changePwd() {
			this.dialogVisible = true
		},
		pwdPass(rule, value, callback) {
			if (this.pwd.oldPwd === this.pwd.newPwd) {
				callback(new Error('新密码不能与旧密码相同！'))
			} else if (this.pwd.newPwd !== this.pwd.newPwd2) {
				callback(new Error('两次密码输入不一致！'))
			} else {
				callback()
			}
		},
		cancel() {
			this.$refs.pwd.resetFields()
			this.dialogVisible = false
		},
		out() {
			outLogin().then(res => {
				if (res.state === '200') {
					this.$message({
						message: '退出登录成功！',
						type: 'success'
					});
					sessionStorage.removeItem('menu')
					sessionStorage.removeItem('token')
					sessionStorage.removeItem('routeIndex')
					this.$router.push('/login')
				}
			})
		}
	}
}
</script>
<style scoped>
.exit_container {
	display: flex;
	align-items: center;
}

.exit_container .iconStyle {
	margin: 0 .1875rem;
	cursor: pointer;
}

.iconStyle .el-button {
	color: black;
}

::v-deep .change_dialog {
	margin-top: 20vh !important;
}
</style>