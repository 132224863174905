<template>
  <div>
    <el-dialog custom-class="projectBySelfDialog" :visible.sync="dialogVisible" :modal="true" :append-to-body="true"
      :close-on-click-modal="false" @close="cancel()">
      <el-tabs>
        <el-tab-pane label="按会员级别自定义赠送项目">
          <el-form ref="form" :model="form" label-width="1.5rem" size="mini" :rules="rules">
            <el-row :gutter="20">
              <el-col :span="24">
                <el-form-item label="会员级别" prop="vipLevelId">
                  <el-select placeholder="请选择" v-model="form.vipLevelId">
                    <el-option :label="item.name" :value="item.id" :key="item.id"
                      v-for="(item, index) in vipList"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="24">
                <el-form-item label="项目名称" prop="itemName">
                  <el-input placeholder="请输入项目名称" v-model="form.itemName"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="24">
                <el-form-item label="赠送次数" prop="num">
                  <el-input placeholder="请输入赠送次数" v-model="form.num"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="24">
                <el-form-item label="有效期(月)" prop="term">
                  <el-input initial-message="0" placeholder="请输入有效期" v-model="form.term"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="24">
                <span>有效期为0的标识没有有效期，不限次数项目，项目名称后(不限次数)</span>
              </el-col>
            </el-row>
          </el-form>
        </el-tab-pane>
      </el-tabs>
      <span slot="title" class="dialog-title">
        <span>赠送项目信息</span>
      </span>
      <span slot="footer" class="dialog-footer">
        <div slot="footer" class="dialog-footer">
          <el-button @click="cancel()">取 消</el-button>
          <el-button type="primary" @click="save()">确 定</el-button>
        </div>
      </span>
    </el-dialog>
  </div>
</template>  
  
<script>
import { addPro2, getVipList, updatePro2 } from '@/api/systemOption/systemSettings';

export default {
  // props: {
  //   initialMessage: {
  //     type: Object,
  //     default: {}
  //   }
  // },
  data() {
    return {
      edit: false,
      dialogVisible: false, // 控制弹出框的显示与隐藏  
      // message: this.initialMessage,// 初始化时接收父组件传递的参数  
      memberLevelValue: '',
      form: {
        vipLevelId: '',
        itemName: '',
        num: '',
        term: ''
      },
      rules: {
        vipLevelId: [{ required: true, message: '请选择会员级别', trigger: 'blur' }],
        itemName: [{ required: true, message: '请输入项目名称', trigger: 'blur' }],
        num: [{ required: true, message: '请输入赠送次数', trigger: 'blur' }],
        term: [{ required: true, message: '请输入有效期', trigger: 'blur' }],
      },
      vipList: []
    };
  },
  methods: {
    resetForm() {
      this.form = {
        vipLevelId: '',
        itemName: '',
        num: '',
        term: ''
      }
    },
    openDialog(data) {
      // 打开弹出框，并触发父组件的事件传递参数  
      this.dialogVisible = true;
      this.edit = false
      if (data) {
        this.form = data
        this.edit = true
      } else {
        this.resetForm()
      }
      this.getVip()
      // this.$emit('child-event', 'Hello from child');
    },
    closeDialog() {
      // 关闭弹出框  
      this.dialogVisible = false;
    },
    getVip() {
      getVipList().then(res => {
        if (res.state === '200') {
          this.vipList = res.data
        }
      })
    },
    save() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.edit) {
            updatePro2(this.form).then(res => {
              if (res.state === '200') {
                this.$emit('child-event2')
                this.$message({
                  message: '修改成功！',
                  type: 'success'
                });
                this.dialogVisible = false
              }
            })
          } else {
            addPro2(this.form).then(res => {
              if (res.state === '200') {
                this.$emit('child-event2')
                this.$message({
                  message: '添加成功！',
                  type: 'success'
                });
                this.dialogVisible = false
              }
            })
          }

        }
      })
    },
    cancel() {
      this.dialogVisible = false
      this.$refs.form?.resetFields();
    }
  },

};
</script>
<style>
.dialog-footer {
  text-align: center;
}

.projectBySelfDialog {
  margin-top: 20vh !important;
  width: 25%;
}
</style>
