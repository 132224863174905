<template>
	<div>
		<el-divider content-position="left" class="poi_title">客户标签设置</el-divider>
		<!-- <el-form ref="form" :model="form" label-width="1.5rem" :inline="true" size="small" :rules="rules">
			<el-form-item label="客户标签：" prop="name">
				<el-input placeholder="请输入客户标签" v-model="form.name"></el-input>
			</el-form-item>
			<el-form-item>
				<el-button @click="add()" type="primary">添加</el-button>
			</el-form-item>
		</el-form> -->
		<el-button @click="tb" type="primary" size="small" style="margin-top: .125rem;">同步标签</el-button>
		<div class="tableStyle">
			<el-table :data="tableData" style="width: 50%" :stripe="true" size="mini"
				header-cell-class-name="headerClassName" :cell-style="{ 'text-align': 'center' }" :border="true"
				:fit="true">
				<!-- <el-table-column prop="id" label="ID">
				</el-table-column> -->
				<el-table-column prop="name" label="客户标签">
					<template slot-scope="scope">
						<el-input v-model="scope.row.name" size="mini" v-if="scope.row.edit"
							@blur="updateDataBlur(scope.row)" @keyup.enter.native="updateData(scope.row)"></el-input>
						<span v-else>{{ scope.row.name }}</span>
					</template>
				</el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<el-button @click="updateRow(scope.$index, scope.row)" type="text" size="small">修改</el-button>
						<el-button @click="del(scope.row)" type="text" size="small">刪除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination background layout="prev, pager, next" @current-change="changePage"
				:current-page.sync="table.page" :page-size="table.pageSize" :total="table.total">
			</el-pagination>
		</div>
	</div>
</template>

<script>
import { addClientTag, getClientTagList, updateClientTag, delClientTag, synchronizationTag } from '@/api/systemOption/systemSettings'
export default {
	components: {
	},
	data() {
		return {
			tableData: [],
			table: {
				page: 1,
				pageSize: 10,
				total: 1
			},
			form: {
				name: ''
			},
			rules: {
				name: [{ required: true, message: '请输入客户标签', trigger: 'blur' }],
			}
		}
	},
	mounted() {
		this.getData()
	},
	methods: {
		tb() {
			synchronizationTag().then(res => {
				if (res.state === '200') {
					this.$message.success('同步成功')
					this.getData()
				}
			})
		},
		getData() {
			getClientTagList({ page: this.table.page, pageSize: this.table.pageSize }).then(res => {
				if (res.state === '200') {
					this.tableData = res.data.records
					this.table.total = res.data.total
				}
			})

		},

		updateRow(index, row) {
			row.edit = true;
			this.$set(this.tableData, index, row);
		},
		updateData(row) {
			row.edit = false;
			updateClientTag(row).then(res => {
				if (res.state === '200') {
					this.getData()
				}
			})
		},
		updateDataBlur(row) {
			row.edit = false;
			updateClientTag(row).then(res => {
				if (res.state === '200') {
					this.$message({
						message: '修改成功！',
						type: 'success'
					});
					this.getData()
				}
			})
		},
		add() {
			this.$refs.form.validate(valid => {
				if (valid) {
					addClientTag(this.form).then(res => {
						if (res.state === '200') {
							this.$message({
								message: '添加成功！',
								type: 'success'
							});

							this.$refs.form.resetFields()
							this.getData()
						}
					})
				}
			})
		},
		del(row) {
			this.delMsg(this).then(() => {
				delClientTag({ id: row.id }).then(res => {
					if (res.state === '200') {
						this.$message({
							message: '删除成功！',
							type: 'success'
						});
						if ((this.tableData.length - 1) == 0 && this.table.page > 1) {
							this.table.page--
						}
						this.getData()
					}
				})
			}).catch(() => {
				this.$message({
					type: 'warn',
					message: '删除失败！'
				});
			});
		},
		changePage(e) {
			this.table.page = e
			this.getData()
		}
	}
}
</script>

<style></style>