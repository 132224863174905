<template>
    <div><span class="valueStyle">{{ value }}</span><span>{{ dw }}</span> </div>
</template>
<script>
export default {
    name:"commonValueStyle",
    props: {
        value: {
            default: '',
            required: true
        },
        dw: {
            type: String,
            default: '元',
            required: true
        }
    }
}
</script>
<style scoped>
.valueStyle {
    color: rgb(97, 199, 254);
    font-weight: bold;
    display: inline-block;
    margin-right: 5px;
}
</style>