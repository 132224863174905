<template>
    <div class="gi_container">
        <div>
            <el-menu :default-active="gi_defaultIndex" class="el-menu-demo" mode="horizontal">
                <el-menu-item index="gi_tag1">赠送项目</el-menu-item>
            </el-menu>
        </div>
        <div class="gi_main">
            <div class="search">
                <el-form size="small" :inline="true" class="form">
                    <el-form-item label="手机号:">
                        <el-input ref="teleRef" v-model="telephone" placeholder="输入手机号查询" size="small"></el-input>
                    </el-form-item>
                </el-form>
                <el-button type="primary" size="small" @click="search">搜索</el-button>
            </div>
            <div class="member_info">
                <el-descriptions class="des margin-top" :column="3" size="mini" border :contentStyle="content_style"
                    :label_style="label_style">
                    <template slot="title">
                        <span class="title">会员基本信息</span>
                    </template>
                    <el-descriptions-item label="手机号" :span="1">
                        <span class="dg">{{ memberInfo.telephone }}</span>
                    </el-descriptions-item>
                    <el-descriptions-item label="会员卡级别" :span="1">
                        <span class="dg">{{ memberInfo.vipLevelName ? memberInfo.vipLevelName : '普通用户' }}</span>
                    </el-descriptions-item>
                    <el-descriptions-item label="客户自费" :span="1">
                        <CommonValueStyle :value="memberInfo.selfBalance ? memberInfo.selfBalance : 0" dw="元" />
                    </el-descriptions-item>
                    <el-descriptions-item label="姓名" :span="1">
                        <span class="dg">{{ memberInfo.name }}</span>
                    </el-descriptions-item>
                    <el-descriptions-item label="车牌号码" :span="1">
                        <template slot="label">
                            <span style="color: red;">*</span>车牌号码
                        </template>
                        <el-select class="dg" placeholder="请选择" v-model="carType" size="small" @change="carChange">
                            <el-option v-for="(item, index) in memberInfo.carTypeList" :key="index" :label="item.carNum"
                                :value="item.carNum"></el-option>
                        </el-select>
                    </el-descriptions-item>
                    <el-descriptions-item label="销售赠送" :span="1">
                        <CommonValueStyle :value="memberInfo.salesBalance ? memberInfo.salesBalance : 0" dw="元" />
                    </el-descriptions-item>
                    <el-descriptions-item label="身份证号" :span="1">
                        <span class="dg">{{ memberInfo.idCard }}</span>
                    </el-descriptions-item>
                    <el-descriptions-item label="车型" :span="1">
                        <span class="dg">{{ carNum }}</span>
                    </el-descriptions-item>
                    <el-descriptions-item label="售后赠送" :span="1">
                        <CommonValueStyle :value="memberInfo.afterBalance ? memberInfo.afterBalance : 0" dw="元" />
                    </el-descriptions-item>
                    <el-descriptions-item label="介绍人" :span="1">
                        <span class="dg">{{ memberInfo.sellerId }}</span>
                    </el-descriptions-item>
                    <el-descriptions-item label="发卡门店" :span="1">
                        <span class="dg">{{ memberInfo.storeDO && memberInfo.storeDO.name ? memberInfo.storeDO.name : ''
                        }}</span>
                    </el-descriptions-item>
                    <el-descriptions-item label="保险赠送" :span="1">
                        <CommonValueStyle :value="memberInfo.insuranceBalance ? memberInfo.insuranceBalance : 0" dw="元" />
                    </el-descriptions-item>
                    <el-descriptions-item label="备注" :span="2">
                        <!-- <span class="dg">{{ memberInfo.remarks }}</span> -->
                        <el-input class="dg" size="small" v-model="remark"></el-input>
                    </el-descriptions-item>
                    <el-descriptions-item label="可用积分" :span="1">
                        <CommonValueStyle :value="memberInfo.credit ? memberInfo.credit : 0" dw="分" />
                    </el-descriptions-item>
                </el-descriptions>
            </div>
            <el-divider content-position="left" class="po_title">选择项目类型</el-divider>
            <div class="member_footer">
                <div class="chosePackage">
                    <el-descriptions class="des margin-top" :column="3" size="mini" border :contentStyle="content_style"
                        :label_style="label_style" :colon="true">
                        <el-descriptions-item label="选择项目" :span="1">
                            <el-select class="dg" placeholder="请选择" v-model="value" size="small">
                                <el-option v-for="(item, index) in projectList" :key="index" :label="item.name"
                                    :value="item.id"></el-option>
                            </el-select>
                        </el-descriptions-item>
                        <el-descriptions-item label="项目类型" :span="1">
                            <el-select class="dg" placeholder="请选择" v-model="type" size="small">
                                <el-option label="赠送" :value="1"></el-option>
                                <el-option label="购买" :value="0"></el-option>
                            </el-select>
                        </el-descriptions-item>
                        <el-descriptions-item label="次数" :span="1">
                            <el-input class="dg" size="small" v-model="availableTimes" placeholder="请输入数字"></el-input>
                        </el-descriptions-item>
                        <el-descriptions-item label="有效期" :span="1">
                            <div style="display: flex;align-items: center;" class="dg">
                                <el-input size="small" v-model="term" placeholder="请输入数字" style="width: 2.5rem;" :disabled="isdisabled"></el-input>
                                <el-radio-group v-model="termType" style="margin-left: 10px;" @change="radioTerm">
                                    <el-radio :label="1" :value="1">按月份</el-radio>
                                    <el-radio :label="2" :value="2">按自然年</el-radio>
                                    <el-radio :label="3" :value="3">按天</el-radio>
                                </el-radio-group>
                            </div>

                        </el-descriptions-item>

                    </el-descriptions>
                </div>
                <div class="btn">
                    <el-button type="primary" @click="addProject" size="small" :disabled="isDj"><i
                            class="el-icon-bottom"></i>添加</el-button>
                </div>
                <div class="foot_table">
                    <span class="table_title">待增加赠送项目</span>
                    <div class="tableStyle">
                        <el-table :data="list" style="width: 100%" :stripe="true" size="mini"
                            header-cell-class-name="headerClassName" :cell-style="{ 'text-align': 'center' }" :border="true"
                            :fit="true" height="400">
                            <el-table-column type="index" label="序号">
                                <template slot-scope="scope">
                                    <span>{{ scope.$index + 1 }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="donationItemName" label="服务项目">
                            </el-table-column>
                            <el-table-column prop="availableTimes" label="服务次数">
                            </el-table-column>
                            <el-table-column prop="time" label="有效期">
                            </el-table-column>
                            <el-table-column prop="totalPrice" label="操作">
                                <template slot-scope="scope">
                                    <p style="color:#999;" @click="cancel(scope.row)">取消</p>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <div class="btn">
                    <el-button type="primary" @click="save()" size="small" :disabled="isDj">保存</el-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import PubSub from 'pubsub-js'
import CommonValueStyle from '../../components/CommonValueStyle.vue'
import { getUserInfo, getDonationItem, getProject, addProject } from '@/api/memberDeposit/index';
export default {
    data() {
        return {
            radio: 'month',
            activeName: 'first',
            input: '',
            gi_defaultIndex: 'gi_tag1',
            balance: 650,
            value: '',
            content_style: {
                'width': '25%'
            },
            remark: '',
            label_style: {
            },
            memberInfo: {},
            telephone: '',
            carNum: '',
            carType: '',
            projectList: [],
            availableTimes: 0,
            term: '',
            termType: 1,
            list: [],
            type: '',
            isDj: false,
            isdisabled:false
        }
    },
    components: { CommonValueStyle },
    mounted() {
        this.$refs.teleRef.focus()
        this.getprojectList()
    },
    methods: {
        search() {
            this.$emit('childChange', this.telephone)
            let that = this
            setTimeout(() => {
                getUserInfo({ telephone: that.telephone }).then(res => {
                    if (res.data.actived == 4) {
                        this.$message.info('该会员已冻结')
                        this.isDj = true
                    }
                    that.memberInfo = res.data
                })
            }, 1000);

        },
        radioTerm(e){
            console.log(e)
            if(e == 2){
                this.term = 1
                this.isdisabled = true
            }else{
                this.term = ''
                this.isdisabled = false
            }
        },
        carChange(e) {
            this.memberInfo.carTypeList.forEach(item => {
                if (item.carNum == e) {
                    this.carNum = item.model
                }
            })
        },
        getprojectList() {
            getDonationItem({}).then(res => {
                this.projectList = res.data
            })
        },
        addProject() {
            if (this.value == '') {
                this.$message.error('请先选择项目')
                return
            }
            if (this.type.toString() == '') {
                this.$message.error('请先选择项目类型')
                return
            }
            if (this.term == '') {
                this.$message.error('请先输入有效期')
                return
            }
            var name = ''
            this.projectList.forEach(item => {
                if (this.value == item.id) {
                    name = item.name
                }
            })
            for (var i = 0; i < this.list.length; i++) {
                console.log(this.list[i].name == name)
                if (this.list[i].donationItemName == name) {
                    this.$message.error('已经存在此项目')
                    return
                }
            }
            let params = {
                donationItemID: this.value,
                donationItemName: name,
                term: this.term,
                termType: this.termType,
                availableTimes: this.availableTimes,
                type: this.type,
                carNum:this.carType
            }

            getProject(params).then(res => {
                if (res.state == '200') {
                    this.list.push(res.data)
                    // this.$message.success('保存成功')
                }
            })
        },
        save() {
            if (this.carNum == '') {
                this.$message.error('请先选择车牌号码')
                return
            }
            if (this.type.toString() == '') {
                this.$message.error('请先选择项目类型')
                return
            }
            var name = ''
            this.projectList.forEach(item => {
                if (this.value == item.id) {
                    name = item.name
                }
            })
            this.list.forEach(item => {
                item.clientId = this.memberInfo.id
                // item.carNum = this.carType
                item.remark = this.remark
            })
            // let params = {
            //     clientId:this.memberInfo.id,
            //     donationItemID:this.value,
            //     donationItemName:name,
            //     term:this.term,
            //     termType:this.termType,
            //     availableTimes:this.availableTimes
            // }

            addProject(this.list).then(res => {
                if (res.state == '200') {
                    // this.list.push(res.data)
                    this.$message.success('保存成功')
                    this.list = []
                    this.remark = ''
                }
            })
        },
        cancel(e) {
            this.list.forEach((item, index) => {
                if (item.name == e.name) {
                    this.list.splice(index, 1)
                }
            })
        }
    }
}
</script>
<style scoped>
.btn {
    margin-top: .25rem;
}

::v-deep .el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
    margin-bottom: 0rem !important;
}

::v-deep .el-descriptions__header {
    margin-bottom: 0rem;
}

.search {
    /* width: 40%; */
    border: none !important;
    display: flex;
}

.tag_container {
    padding: 0 .25rem;
}

.member_info .title {
    margin-top: .25rem;
    display: inline-block;
    font-size: medium;
    font-weight: bold;
    color: rgb(79, 79, 79);
}

.member_info .el-descriptions__header {
    margin: .125rem 0 .0625rem;
}

.gi_main {
    background-color: #fff;
    width: 100%;
    padding: .125rem .25rem;
    flex: 1;
    display: flex;
    flex-direction: column;
}


.gi_main .po_title {
    background-color: rgb(152, 219, 255);

}

.gi_main .el-divider__text {
    color: rgb(24, 168, 255);
    font-size: large;
    font-weight: bold;
    left: 0;
}

.member_footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
}

.chosePackage {
    width: 85%;
    height: 1.125rem;
    margin-bottom: .125rem;
}

.foot_table {
    width: 70%;
}

.foot_table .tableStyle {
    padding: .125rem 0 0 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 3.75rem;
}

.foot_table .tableStyle .el-pagination {
    align-self: flex-start;
    margin: .125rem 0;
}

/*設置表头背景以及字体顏色*/
.foot_table .headerClassName {
    font-weight: bold;
    color: black;
    background-color: rgb(226, 226, 226) !important;
    text-align: center !important;
}

.foot_table .el-table__row--striped {
    background-color: rgb(242, 242, 242) !important;

}

.table_title {
    font-size: medium;
    color: rgb(79, 79, 79);
}
</style>