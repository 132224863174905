import request from '@/utils/request'

// 查询维修类型
export function getMaintenanceTypeList(data) {
    return request({
        url: '/sys/maintenanceType/getList',
        method: 'post',
        data: data
    })
}
// 查询间隔时间
export function getCeIntervalDayList(data) {
    return request({
        url: '/sys/maintenanceIntervalDay/getList',
        method: 'post',
        data: data
    })
}
// 查询下一次维修到期时间
export function getNextTime(data) {
    return request({
        url: '/vip/expend/maintenanceTime',
        method: 'post',
        data: data
    })
}
// 查询客户自费维保
export function getVipExpend(data) {
    return request({
        url: '/vip/expend/getVipExpend',
        method: 'post',
        data: data
    })
}
// 计算自费维保消费
export function getRecordTask(data) {
    return request({
        url: '/vip/expend/getRecordTask',
        method: 'post',
        data: data
    })
}
// 自费维保消费保存
export function addRecordTask(data) {
    return request({
        url: '/vip/expend/addRecordTask',
        method: 'post',
        data: data
    })
}
//#region 增加记录
// 储值消费
export function addCrossStoreConsumptionRecord(data) {
    return request({
        url: '/finance/crossStoreConsumptionRecord/addCrossStoreConsumptionRecord',
        method: 'post',
        data: data
    })
}
// 项目消费
export function addCrossStoreConsumptionDonationRecord(data) {
    return request({
        url: '/finance/crossStoreConsumptionRecord/addCrossStoreConsumptionDonationRecord',
        method: 'post',
        data: data
    })
}
// 套餐消费
export function addCrossStoreConsumptionPackageRecord(data) {
    return request({
        url: '/finance/crossStoreConsumptionRecord/addCrossStoreConsumptionPackageRecord',
        method: 'post',
        data: data
    })
}
// 积分增加
export function addCrossStoreConsumptionCreditRecord(data) {
    return request({
        url: '/finance/crossStoreConsumptionRecord/addCrossStoreConsumptionCreditRecord',
        method: 'post',
        data: data
    })
}
//#endregion
// 查询项目消费
export function getProjectCons(data) {
    return request({
        url: '/user/project/getProjectCons',
        method: 'post',
        data: data
    })
}
// 项目消费保存
export function addProjectCons(data) {
    return request({
        url: '/user/project/addProjectCons',
        method: 'post',
        data: data
    })
}
// 查询套餐消费
export function getSetMealCons(data) {
    return request({
        url: '/user/package/getSetMealCons',
        method: 'post',
        data: data
    })
}
// 套餐消费 查询 （点击套餐名称显示 套餐项目细化列表）
export function getPackageList(data) {
    return request({
        url: '/user/package/getPackageList',
        method: 'post',
        data: data
    })
}
// 套餐消费保存
export function addSetMealCons(data) {
    return request({
        url: '/user/package/addSetMealCons',
        method: 'post',
        data: data
    })
}
// 商品分类列表
export function getVipMall(data) {
    return request({
        url: '/vip/goodsClassify/getVipMall',
        method: 'post',
        data: data
    })
}
// 添加商品分类
export function addVipMall(data) {
    return request({
        url: '/vip/goodsClassify/addVipMall',
        method: 'post',
        data: data
    })
}
// 修改商品分类
export function updateVipMall(data) {
    return request({
        url: '/vip/goodsClassify/updateVipMall',
        method: 'post',
        data: data
    })
}
// 删除商品分类
export function removeVipMall(data) {
    return request({
        url: '/vip/goodsClassify/removeVipMall',
        method: 'post',
        data: data
    })
}
// 获取商品列表
export function getVipManage(data) {
    return request({
        url: '/vip/goodsManage/getVipManage',
        method: 'post',
        data: data
    })
}
// 获取商品门店下列框
export function getStoreList(data) {
    return request({
        url: '/vip/goodsManage/getStoreList',
        method: 'post',
        data: data
    })
}
// 获取商品分类
export function getClassifyList(data) {
    return request({
        url: '/vip/goodsManage/getClassifyList',
        method: 'post',
        data: data
    })
}
// 添加商品
export function addVipManage(data) {
    return request({
        url: '/vip/goodsManage/addVipManage',
        method: 'post',
        data: data
    })
}
// 修改商品
export function updateVipManage(data) {
    return request({
        url: '/vip/goodsManage/updateVipManage',
        method: 'post',
        data: data
    })
}
// 下架商品
export function removeVipManage(data) {
    return request({
        url: '/vip/goodsManage/removeVipManage',
        method: 'post',
        data: data
    })
}
// 会员商城列表
export function getVipExchange(data) {
    return request({
        url: '/vip/goodsExchange/getVipExchange',
        method: 'post',
        data: data
    })
}
//会员商城详情
export function getExchangeById(data) {
    return request({
        url: '/vip/goodsExchange/getExchangeById',
        method: 'post',
        data: data
    })
}
//积分增加 查询
export function getCredit(data) {
    return request({
        url: '/vip/expend/getCredit',
        method: 'post',
        data: data
    })
}
// 积分增加（添加按钮）
export function addCreditAdd(data) {
    return request({
        url: '/vip/expend/addCreditAdd',
        method: 'post',
        data: data
    })
}
// 积分增加（保存按钮）
export function addCreditCons(data) {
    return request({
        url: '/vip/expend/addCreditCons',
        method: 'post',
        data: data
    })
}
// 积分兑换
export function updateExchange(data) {
    return request({
        url: '/vip/goodsExchange/updateExchange',
        method: 'post',
        data: data
    })
}
// 积分取消兑换
export function updateCancelEx(data) {
    return request({
        url: '/vip/goodsExchange/updateCancelEx',
        method: 'post',
        data: data
    })
}
//查询商品分类
export function getClassify(data) {
    return request({
        url: '/code/table/getClassify',
        method: 'post',
        data: data
    })
}
//校验材料折扣
export function materialDiscount(data) {
    return request({
        url: '/vip/expend/materialDiscount',
        method: 'post',
        data: data
    })
}
//查询商品分类
export function workingHours(data) {
    return request({
        url: '/vip/expend/workingHours',
        method: 'post',
        data: data
    })
}
//生成短链接
export function shorten(data) {
    return request({
        url: '/vip/goodsManage/shorten',
        method: 'get',
        params: data
    })
}
//订单列表汇总
export function getVipExpendSummary(data) {
    return request({
        url: '/vip/goodsExchange/getVipExpendSummary',
        method: 'post',
        data: data
    })
}
//订单列表导出
export function exportVipExchangeList(data) {
    return request({
        url: '/vip/goodsExchange/exportVipExchangeList',
        method: 'post',
        data: data,
        responseType: 'blob'
    })
}
