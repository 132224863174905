<template>
    <div class="br_container">
        <div>
            <el-menu :default-active="br_defaultIndex" class="el-menu-demo" mode="horizontal" @select="br_handleSelect">
                <el-menu-item index="br_tag1">客户自费维保充值</el-menu-item>
                <el-menu-item index="br_tag2">销售赠送维保充值</el-menu-item>
                <el-menu-item index="br_tag3">售后赠送维保充值</el-menu-item>
                <el-menu-item index="br_tag4">金融保险基本充值</el-menu-item>
            </el-menu>
        </div>
        <div class="br_main">
            <div class="search">
                <el-form size="small" :inline="true" class="form">
                    <el-form-item label="手机号:">
                        <el-input ref="teleRef" v-model="telephone" placeholder="输入手机号查询" size="small"></el-input>
                    </el-form-item>
                </el-form>
                <el-button type="primary" size="small" @click="search">搜索</el-button>
            </div>
            <div class="member_info">
                <el-descriptions class="des margin-top" :column="3" size="mini" border :contentStyle="content_style"
                    :label_style="label_style">
                    <template slot="title">
                        <span class="title">会员基本信息</span>
                    </template>
                    <el-descriptions-item label="手机号" :span="1">
                        <span class="dg">{{ memberInfo.telephone }}</span>
                    </el-descriptions-item>
                    <el-descriptions-item label="会员卡级别" :span="1">
                        <span class="dg"> {{ memberInfo.vipLevelName ? memberInfo.vipLevelName : '普通用户' }}</span>
                    </el-descriptions-item>
                    <el-descriptions-item label="客户自费" :span="1">
                        <CommonValueStyle :value="memberInfo.selfBalance ? memberInfo.selfBalance : 0" dw="元" />
                    </el-descriptions-item>
                    <el-descriptions-item label="姓名" :span="1">
                        <span class="dg">{{ memberInfo.name }}</span>
                    </el-descriptions-item>
                    <el-descriptions-item label="车牌号码" :span="1">
                        <template slot="label">
                            <span style="color: red;">*</span>车牌号码
                        </template>
                        <el-select class="dg" placeholder="请选择" v-model="value" size="small" @change="carChange">
                            <el-option v-for="(item, index) in memberInfo.carTypeList" :key="index" :label="item.carNum"
                                :value="item.carNum"></el-option>
                        </el-select>
                    </el-descriptions-item>
                    <el-descriptions-item label="销售赠送" :span="1">
                        <CommonValueStyle :value="memberInfo.salesBalance ? memberInfo.salesBalance : 0" dw="元" />
                    </el-descriptions-item>
                    <el-descriptions-item label="身份证号" :span="1">
                        <span class="dg">{{ memberInfo.idCard }}</span>
                    </el-descriptions-item>
                    <el-descriptions-item label="车型" :span="1">
                        <span class="dg">{{ model }}</span>
                    </el-descriptions-item>
                    <el-descriptions-item label="售后赠送" :span="1">
                        <CommonValueStyle :value="memberInfo.afterBalance ? memberInfo.afterBalance : 0" dw="元" />
                    </el-descriptions-item>
                    <el-descriptions-item label="介绍人" :span="1">
                        <span class="dg">{{ memberInfo.sellerId }}</span>
                    </el-descriptions-item>
                    <el-descriptions-item label="发卡门店" :span="1">
                        <span class="dg">{{ memberInfo.storeDO && memberInfo.storeDO.name ? memberInfo.storeDO.name : ''
                        }}</span>
                    </el-descriptions-item>
                    <el-descriptions-item label="保险赠送" :span="1">
                        <CommonValueStyle :value="memberInfo.insuranceBalance ? memberInfo.insuranceBalance : 0" dw="元" />
                    </el-descriptions-item>



                    <el-descriptions-item :span="1">
                        <template slot="label">
                            <span style="color: red;">*</span>储值方式
                        </template>
                        <el-select class="dg" placeholder="请选择" v-model="reMethod" size="small">
                            <el-option v-for="(item, index) in Czlist" :key="index" :label="item.storedValueName"
                                :value="item.id"></el-option>
                        </el-select>
                    </el-descriptions-item>
                    <el-descriptions-item label="备注" :span="1">
                        <!-- <span class="dg">{{ memberInfo.remarks }}</span> -->
                        <el-input class="dg" size="small" v-model="remark"></el-input>
                    </el-descriptions-item>
                    <el-descriptions-item label="可用积分" :span="1">
                        <CommonValueStyle :value="memberInfo.credit ? memberInfo.credit : 0" dw="分" />
                    </el-descriptions-item>
                </el-descriptions>
            </div>
            <div class="member_footer">
                <div class="rechargeType">
                    <div v-if="br_activeIndex === 'br_tag1'">
                        <el-row>
                            <el-col :span="12">
                                <span class="addYellow">客户自费充值</span><span> - 自定义充值金额：</span>
                                <el-input v-model="num1" class="mem_input" @focus="setNum('1')" size="small"></el-input>元
                            </el-col>
                            <el-col :span="12">
                                <span class="addYellow">客户自费充值</span><span> - 赠送积分:</span>
                                <el-input v-model="credit" @focus="setNum('2')" class="mem_input" size="small"></el-input>分
                            </el-col>
                        </el-row>
                    </div>
                    <div v-if="br_activeIndex === 'br_tag2'">
                        <el-row>
                            <el-col :span="12">
                                <span class="addYellow">销售赠送充值</span><span> - 自定义充值金额：</span>
                                <el-input v-model="num1" @focus="setNum('1')" class="mem_input" size="small"></el-input>元
                            </el-col>
                        </el-row>
                    </div>
                    <div v-if="br_activeIndex === 'br_tag3'">
                        <el-row>
                            <el-col :span="12">
                                <span class="addYellow">售后赠送充值</span><span> - 自定义充值金额：</span>
                                <el-input v-model="num1" @focus="setNum('1')" class="mem_input" size="small"></el-input>元
                            </el-col>
                        </el-row>
                    </div>
                    <div v-if="br_activeIndex === 'br_tag4'">
                        <el-row>
                            <el-col :span="12">
                                <span class="addYellow">保险赠送充值</span><span> - 自定义充值金额：</span>
                                <el-input v-model="num1" @focus="setNum('1')" class="mem_input" size="small"></el-input>元
                            </el-col>
                        </el-row>
                    </div>
                </div>
                <div class="quickInput">
                    <div class="quickInputTable">
                        <span class="quickInputTitleStyle">余额快捷输入 (单位：元)</span>
                        <div class="box">
                            <div class="btn1" v-for="(num, index) in btnList" :key="index" @click="balanceEntry(num)">{{ num
                            }}</div>
                        </div>
                    </div>
                    <div class="btn">
                        <el-button type="primary" @click="addMoney()" size="small" :disabled="isDj">保存</el-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import PubSub from 'pubsub-js'
import CommonValueStyle from '../../components/CommonValueStyle.vue'
import { getUserInfo, getStoredModel, addVipSave } from '@/api/memberDeposit';
export default {
    data() {
        return {
            tag: '1',
            activeName: 'first',
            input: '',
            balance: 650,
            value: '',
            br_defaultIndex: 'br_tag1',
            br_activeIndex: 'br_tag1',
            content_style: {
                'width': '25%'
            },
            remark: '',
            label_style: {
                'width': '1.3rem',
                'justify-content': 'end'
            },
            btnList: [100, 200, 300, 500, 600, 800, 1000, 1500, 2000, 3000, 5000, 6000, 7000, 8000],
            num1: 0,
            num2: 0,
            num3: 0,
            num4: 0,
            telephone: '',
            memberInfo: {},
            carNum: '',
            Czlist: [],
            reMethod: '',
            credit: 0,
            type: '',
            model: '',
            isDj: false
        }
    },
    components: { CommonValueStyle },
    mounted() {
        this.$refs.teleRef.focus()
        this.getCZlist()
    },
    methods: {
        br_handleSelect(key) {
            this.$refs.teleRef.focus()
            this.br_activeIndex = key
            PubSub.publish('activeTag', key)
            this.reset()
        },
        setNum(data) {
            this.tag = data
        },
        balanceEntry(num) {
            num = Number(num) ? num : 0
            if (this.tag == '1') {
                this.num1 = num
            } else {
                this.credit = num
            }
        },
        search() {
            this.$emit('childChange', this.telephone)
            let that = this
            setTimeout(() => {
                getUserInfo({ telephone: that.telephone }).then(res => {
                    if (res.data.actived == 4) {
                        this.$message.info('该会员已冻结')
                        this.isDj = true
                    }
                    that.memberInfo = res.data
                    that.$store.commit('SET_RECORD_LIST', res.data.mainCzList)
                })
            }, 1000);

        },
        carChange(e) {
            this.memberInfo.carTypeList.forEach(item => {
                if (item.carNum == e) {
                    this.carNum = item.carNum
                    this.model = item.model
                    this.carFrameNumber = item.carFrameNumber
                }
            })
        },
        //储值方式
        getCZlist() {
            getStoredModel({}).then(res => {
                this.Czlist = res.data
            })
        },
        reset() {
            this.num1 = 0
            this.credit = 0
            this.reMethod = ''
            this.remark = ''
        },
        addMoney() {
            if (this.carNum == '') {
                this.$message.error('请先选择车牌号码')
                return
            }
            switch (this.br_activeIndex) {
                case 'br_tag1':
                    this.type = 1
                    break;
                case 'br_tag2':
                    this.type = 2
                    break;
                case 'br_tag3':
                    this.type = 3
                    break;
                case 'br_tag4':
                    this.type = 4
                    break;
                default:
                    break;
            }
            if (this.reMethod == '') {
                this.$message.error('请选择储值方式')
                return
            }
            if (this.carNum == '') {
                this.$message.error('请选择车牌号码')
                return
            }
            let params = {
                type: this.type,
                reMethod: this.reMethod,
                money: this.num1,
                credit: this.credit,
                telephone: this.telephone,
                carNum: this.carNum,
                remark: this.remark,
                carFrameNumber: this.carFrameNumber
            }
            addVipSave(params).then(res => {
                if (res.state == '200') {
                    this.$message.success('充值成功')
                    this.reset()
                    this.search()
                }
            })
        }
    }
}
</script>
<style scoped>
::v-deep .el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
    margin-bottom: 0rem !important;
}

::v-deep .el-descriptions__header {
    margin-bottom: 0rem;
}

.box {
    width: 8.75rem;
    height: 2.5rem;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(2, 1fr);
    justify-items: center;
    align-items: center;
    grid-row-gap: 2px;
    grid-column-gap: 2px;
    background-color: rgb(222, 222, 222);
    border: .025rem solid rgb(222, 222, 222);
}

.btn1 {
    cursor: pointer;
    color: rgb(244, 124, 0);
    font-weight: bold;
    background-color: #fff;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.search {
    /* width: 40%; */
    border: none !important;
    display: flex;
}

.tag_container {
    padding: 0 .25rem;
}

.member_info .title {
    margin-top: .25rem;
    display: inline-block;
    font-size: medium;
    font-weight: bold;
    color: rgb(79, 79, 79);
}

.member_info .el-descriptions__header {
    margin: .125rem 0 .0625rem;
}

.br_main {
    background-color: #fff;
    width: 100%;
    padding: .125rem .25rem;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.rechargeType {
    padding: .25rem 0;
    width: 80%;
    margin: auto;
    text-align: center;
}

.rechargeType span {
    font-size: .2rem !important;
}

.mem_input {
    width: 1.125rem;
    margin: 0 .125rem;
}

.addYellow {
    color: rgb(244, 124, 0);
}

.quickInput {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
}


.quickInputTable {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* .quickInputTable .el-button {
    width: 80px;
    height: 80px;
    border: 1px solid rgb(222, 222, 222);
    color: rgb(244, 124, 0);
    border-radius: 0 !important;
} */

.quickInputTable .quickInputTitleStyle {
    display: inline-block;
    color: rgb(79, 79, 79);
    align-self: flex-start;
}

.quickInput .btn {
    width: 100%;
    margin-top: 20px;
    text-align: center;
}

/* .el-button+.el-button,
.el-checkbox.is-bordered+.el-checkbox.is-bordered {
    margin: 0;
    margin-left: -1px;
} */
</style>