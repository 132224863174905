<template>
	<div>
		<el-dialog custom-class="addAdminDialog" :visible.sync="dialogVisible" :modal="true" :append-to-body="true"
			:close-on-click-modal="false">
			<el-form ref="form" :model="form" label-width="2.2rem" size="mini" :rules="rules">
				<el-row :gutter="20">
					<el-col :span="24">
						<el-form-item label="账号：" prop="login">
							<el-input placeholder="登录用户名" v-model="form.login"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row :gutter="20" v-if="!edit">
					<el-col :span="24">
						<el-form-item label="密码：" prop="pwd">
							<el-input placeholder="登录密码" v-model="form.pwd" :show-password="true">
							</el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row :gutter="20">
					<el-col :span="24">
						<el-form-item label="类型：" prop="roleType">
							<el-radio-group v-model="form.roleType" @change="typeChange">
								<el-radio :label="item.id" v-for="(item, index) in typeList" :key="index">{{ item.name
								}}</el-radio>
							</el-radio-group>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row :gutter="20" v-if="form.roleType != '1'">
					<el-col :span="24">
						<el-form-item label="最大优惠金额：" prop="discountAmount">
							<el-input-number v-model="form.discountAmount" :min="1" label="最大优惠金额"></el-input-number>
							<!-- <el-input placeholder="最大优惠金额" v-model="form.discountAmount" type="number">
							</el-input> -->
						</el-form-item>
					</el-col>
				</el-row>
				<el-row :gutter="20" v-if="form.roleType != '1'">
					<el-col :span="24">
						<el-form-item label="材料折扣区间：" prop="materialDiscount">
							<el-input placeholder="材料折扣区间：" v-model="form.materialDiscount"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row :gutter="20" v-if="form.roleType != '1'">
					<el-col :span="24">
						<el-form-item label="工时折扣区间：" prop="manHourDiscount">
							<el-input placeholder="工时折扣区间：" v-model="form.manHourDiscount"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<!-- <el-row :gutter="20">
					<el-col :span="24" v-if="form.roleType != '1'">
						<el-form-item label="所属门店：" prop="storeId">
							<el-select placeholder="请选择" v-model="form.storeId" size="small" @change="storeChange">
								<el-option :label="item.name" :value="item.id" v-for="(item, index) in storeList"
									:key="index"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row> -->
				<el-row :gutter="20">
					<el-col :span="24">
						<el-form-item label="所属角色：" prop="roleId">
							<el-select placeholder="请选择" v-model="form.roleId" size="small" @change="roleChange">
								<el-option :label="item.name" :value="item.id" :key="item.id"
									v-for="item in roleList"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>

				<el-row :gutter="20" v-if="form.roleType != '1'">
					<el-col :span="24">
						<el-form-item label="所属部门：" prop="storeDepartmentId">
							<el-select placeholder="请选择" v-model="form.storeDepartmentId" size="small">
								<el-option :label="item.name" :value="item.id" v-for="(item, index) in departmentList"
									:key="index"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row :gutter="20" v-if="form.roleType != '1'">
					<el-col :span="24">
						<el-form-item label="双重优惠：" prop="discountDeductionStatus">
							<el-switch v-model="form.discountDeductionStatus" active-color="#13ce66" inactive-color="#ccc"
								:active-value="1" :inactive-value="0">
							</el-switch>
							<div style="font-size: .125rem; color: #404040;">开启后优惠金额、积分抵扣两种方式可同时使用；关闭后仅能选择一种使用。</div>
						</el-form-item>
					</el-col>
				</el-row>

			</el-form>
			<span slot="title" class="dialog-title">
				<span>{{ edit ? '编辑管理员' : '添加管理员' }}</span>
			</span>
			<span slot="footer" class="dialog-footer">
				<div slot="footer" class="dialog-footer">
					<el-button size="mini" @click="cancel()">取 消</el-button>
					<el-button size="mini" type="primary" @click="save()">确 定</el-button>
				</div>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import {
	departmentList,
	getRole,
	getType,
	saveUser,
	storeList,
	updateUser
} from '@/api/login/index'
export default {
	props: {
		initialMessage: {
			type: Object,
			default: {},
			radio: '1',
			value: ''
		}
	},
	data() {
		return {
			id: '',
			edit: false,
			dialogVisible: false, // 控制弹出框的显示与隐藏  
			// message: this.initialMessage, // 初始化时接收父组件传递的参数  
			form: {
				discountDeductionStatus: 0,
				login: '',
				pwd: '',
				roleType: '',
				roleId: [],
				// storeId: '',
				storeDepartmentId: '',
				materialDiscount: '',
				manHourDiscount: '',
				discountAmount: 1
			},
			typeList: [],
			roleList: [],
			storeList: [],
			departmentList: [],
			rules: {
				login: [{
					required: true,
					message: '请输入账号',
					trigger: 'blur'
				}],
				pwd: [{
					required: true,
					message: '请输入密码',
					trigger: 'blur'
				},
				{
					min: 6,
					message: '密码长度最小为6个字符',
					trigger: 'blur'
				}
				],
				roleType: [{
					required: true,
					message: '请选择类型',
					trigger: 'blur'
				}],
				roleId: [{
					required: true,
					message: '请选择角色',
					trigger: 'blur'
				}],
				// storeId: [{
				// 	required: true,
				// 	message: '请选择门店',
				// 	trigger: 'blur'
				// }],
				storeDepartmentId: [{
					required: true,
					message: '请选择部门',
					trigger: 'blur'
				}],
				materialDiscount: [{
					required: true,
					message: '请输入材料折扣',
					trigger: 'blur'
				}],
				manHourDiscount: [{
					required: true,
					message: '请选择工时折扣',
					trigger: 'blur'
				}],
				discountAmount: [{
					required: true,
					message: '请输入最大优惠金额',
					trigger: 'blur'
				}]
			}
		};
	},
	methods: {
		init(value) {
			if (value) {
				this.getRoleList(value)
			} else {
				this.getRoleList(this.form.roleType)
			}

			if (this.form.roleType != '1') {
				this.getDepartmentList()
			} else {
				return
			}
		},
		cancel() {
			this.dialogVisible = false
			this.$refs.form.resetFields();
		},
		openDialog({
			edit,
			data,
			id
		}) {
			getType().then(res => {
				if (res.state === '200') {
					this.typeList = res.data
					if (!edit) {
						this.form.roleType = this.typeList[0]?.id || ''
					}
					this.init()
				}
			})

			this.edit = edit
			if (edit && id) {
				this.form = data
				console.log(this.form);
				this.id = id
				// if (this.form.roleType != '1') {
				// 	this.getStoreList()
				// }
			} else {
				this.form = {
					discountDeductionStatus: 0,
					discountAmount: 0,
					login: '',
					pwd: '',
					roleType: '',
					roleId: [],
					// storeId: '',
					storeDepartmentId: '',
					materialDiscount: '',
					manHourDiscount: '',
				}
			}
			// 打开弹出框，并触发父组件的事件传递参数  
			this.dialogVisible = true;

		},
		closeDialog() {
			// 关闭弹出框  
			this.dialogVisible = false;
		},
		// storeChange(storeId) {
		// 	this.form.storeDepartmentId = ''
		// 	this.getDepartmentList(storeId)
		// },
		getDepartmentList() {
			departmentList().then(res => {
				// this.form.storeDepartmentId=
				this.departmentList = res.data
				if (res.data.length == 0) {
					this.form.storeDepartmentId = ''
				} else {
					const depart = this.departmentList.find(item => item.id == this.form.storeDepartmentId)
					if (!depart) {
						this.form.storeDepartmentId = ''
					}
				}
			})
		},
		// getStoreList() {
		// 	storeList().then(res => {
		// 		if (res.state === '200') {
		// 			this.storeList = res.data
		// 		}
		// 	})
		// },
		getData() {
			let data = {}
			if (this.form.roleType === '1') {
				data = {
					...this.form,
					// storeId: '',
					storeDepartmentId: '',
					materialDiscount: '',
					manHourDiscount: '',
				}
				// } else if (this.form.roleType === '2') {
				// 	data = {
				// 		...this.form,
				// 		storeDepartmentId: '',
				// 	}
			} else {
				data = this.form
			}
			console.log('data=',data);
			return data
		},
		save() {
			console.log(this.form);
			this.$refs.form.validate(valid => {
				if (valid) {
					let data = this.getData()
					if (this.edit) {
						updateUser({
							...data,
							id: this.id
						}).then(res => {
							if (res.state === '200') {
								this.$message({
									message: '修改成功！',
									type: 'success'
								});
								this.dialogVisible = false;
								this.$emit('child-event');
							}
						})
					} else {
						saveUser(data).then(res => {
							if (res.state === '200') {
								this.$message({
									message: '添加成功！',
									type: 'success'
								});
								this.dialogVisible = false;
								this.$emit('child-event');
							}
						})
					}


				}
			})
		},
		typeChange(value) {
			this.form.roleId = ''
			console.log('value=', value);
			this.init(value)
		},
		roleChange(value) {
			console.log('value==', value)
		},
		//根据类型获取角色
		getRoleList(roleType) {
			getRole({
				roleType
			}).then(res => {
				if (res.state === '200') {
					this.roleList = res.data
					// if (this.form.roleType != '1') {
					// 	this.getStoreList()
					// }
				}
			})
		}
	},
	watch: {
		initialMessage(newValue) {
			// 监听父组件传递的参数变化，并更新子组件的数据 
			// this.form = newValue.row
			this.edit = newValue.edit

		}
	}
};
</script>
<style scoped>
::v-deep .el-dialog.addAdminDialog {
	margin-top: 10vh !important;
	width: 30%;
}
</style>