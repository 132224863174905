export const delMessage = (that) => {
	return that.$confirm('确认删除吗?', '提示', {
		confirmButtonText: '确定',
		cancelButtonText: '取消',
		type: 'warning',
		center: true
	})
}

export function formatDate(timestamp) {
	const date = new Date(timestamp);
	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, '0');
	const day = String(date.getDate()).padStart(2, '0');
	const hours = String(date.getHours()).padStart(2, '0');
	const minutes = String(date.getMinutes()).padStart(2, '0');
	const seconds = String(date.getSeconds()).padStart(2, '0');

	return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}


/**
 * flexWidth
 * @param prop 每列的prop 可传''
 * @param tableData 表格数据
 * @param title 标题长内容短的，传标题  可不传
 * @param num 列中有标签等加的富余量
 * @returns 列的宽度
 * 注：prop,title有一个必传
 */
export function flexWidth(prop, tableData, title, num = 0) {
	if (tableData.length === 0) {//表格没数据不做处理
		return;
	}
	let flexWidth = 0;//初始化表格列宽
	let columnContent = '';//占位最宽的内容
	let canvas = document.createElement("canvas");
	let context = canvas.getContext("2d");
	context.font = "14px Microsoft YaHei";
	if ((prop === '') && title) {//标题长内容少的，取标题的值,
		columnContent = title
	} else {// 获取该列中占位最宽的内容
		let index = 0;
		for (let i = 0; i < tableData.length; i++) {
			const now_temp = tableData[i][prop] + '';
			const max_temp = tableData[index][prop] + '';
			const now_temp_w = context.measureText(now_temp).width
			const max_temp_w = context.measureText(max_temp).width
			if (now_temp_w > max_temp_w) {
				index = i;
			}
		}
		columnContent = tableData[index][prop]
		//比较占位最宽的值跟标题、标题为空的留出四个位置
		const column_w = context.measureText(columnContent).width
		const title_w = context.measureText(title).width
		if (column_w < title_w) {
			columnContent = title || '留四个字'
		}
	}
	// 计算最宽内容的列宽
	let width = context.measureText(columnContent);
	flexWidth = width.width + 40 + num
	return flexWidth + 'px';
}
