<!-- 会员储值 -->
<template>
    <div class="memberDepositContainer">
        <div class="memberManageAside">
            <MemberManageAside />
        </div>
        <div class="memberDepositMain">
            <div class="exit">
                <Exit />
            </div>
            <div class="memberDepositContent">
                <div class="so_aside">
                    <div class="user_info">
                        <div class="user_image">
                            <el-avatar :size="50" :src="userinfo.headimgurl"></el-avatar>
                        </div>
                        <div class="user_detail_info">
                            <div class="user user_name">
                                <span>{{ userinfo.name || '默认昵称' }}</span>
                                <el-tag type="warning" effect="plain" size="mini"> {{
                                    userinfo.vipLevelName ? userinfo.vipLevelName : '普通用户' }}</el-tag>
                            </div>
                            <div class="user user_phone">
                                <i class="el-icon-phone">{{ userinfo.telephone }}</i>
                            </div>
                            <div class="user user_time">
                                <span>注册时间：{{ userinfo.telephone }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="aside_content">
                        <div class="own_info">
                            <div class="own_info_tag">
                                <span>账户余额</span>
                                <span class="own_info_number">{{ userinfo.selfBalance ? userinfo.selfBalance : 0 }}</span>
                            </div>
                            <div class="own_info_tag">
                                <span>可用积分</span>
                                <span class="own_info_number">{{ userinfo.credit ? userinfo.credit : 0 }}</span>
                            </div>
                            <div class="own_info_tag">
                                <span>持有套餐</span>
                                <span class="own_info_number">{{ userinfo.holdSetMeal ? userinfo.holdSetMeal : 0 }}张</span>
                            </div>
                            <!-- <div class="own_info_tag">
                            <span>优惠券</span>
                            <span class="own_info_number">6张</span>
                        </div> -->

                        </div>
                        <div class="record_info" v-if="flag == 'cz'">
                            <div class="record_title">
                                充值记录（{{ userinfo.operationName ? userinfo.operationName : '当前操作员' }}）
                            </div>
                            <!-- v-if="recordList && recordList.length > 0" -->
                            <div class="record_content">
                                <div class="record" v-for="(item, index) in recordList" :key="index">
                                    <span class="record_value">【{{ item.types }}】</span>
                                    <div class="record_time">
                                        <span v-if="item.types.includes('充值')">共充值{{ item.money }}元</span>
                                        <span v-if="item.types.includes('消费')">共消费{{ item.money }}元</span>
                                        <span>{{ item.time }}</span>
                                    </div>
                                </div>
                            </div>
                            <div v-if="recordList.length == 0" style="width:100%;">
                                <el-empty description="搜索查看记录哦~"></el-empty>
                            </div>

                        </div>
                        <div class="record_info" v-if="flag == 'tc'">
                            <div class="record_title">
                                套餐开卡记录（{{ userinfo.operationName ? userinfo.operationName : '当前操作员' }}）
                            </div>
                            <!-- v-if="recordList && recordList.length > 0" -->
                            <div class="record_content">
                                <div class="record" v-for="(item, index) in tcList" :key="index">
                                    <span class="record_value">【{{ item.types }}】</span>
                                    <div class="record_time">
                                        <span>{{ item.packageName }}</span>
                                        <span>{{ item.time }}</span>
                                    </div>
                                </div>
                            </div>
                            <div v-if="tcList.length == 0" style="width:100%;">
                                <el-empty description="搜索查看记录哦~"></el-empty>
                            </div>

                        </div>
                        <div class="record_info" v-if="flag == 'zs'">
                            <div class="record_title">
                                赠送项目记录（{{ userinfo.operationName ? userinfo.operationName : '当前操作员' }}）
                            </div>
                            <!-- v-if="recordList && recordList.length > 0" -->
                            <div class="record_content">
                                <div class="record" v-for="(item, index) in zsList" :key="index">
                                    <span class="record_value">【{{ item.types }}】</span>
                                    <div class="record_time">
                                        <span>{{ item.projectName }}</span>
                                        <span>{{ item.time }}</span>
                                    </div>
                                </div>
                            </div>
                            <div v-if="zsList.length == 0" style="width:100%;">
                                <el-empty description="搜索查看记录哦~"></el-empty>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="so_content">
                    <router-view @childChange="phoneChange"></router-view>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Exit from '../components/Exit.vue'
import MemberManageAside from '../components/MemberManageAside.vue'
import { getUserInfo } from '@/api/memberDeposit';
export default {
    components: { MemberManageAside, Exit },
    data() {
        return {
            tag: 'br_tag1',
            userinfo: {},
            recordList: [],
            tcList: [],
            zsList: [],
            flag: 'cz'
        }
    },
    // computed: {
    //     ...mapGetters(['recordList'])
    // },
    methods: {
        out() {
            this.$confirm('确认关闭？')
                .then(_ => {
                    done()
                })
                .catch(_ => { })
        },
        phoneChange(e) {
            getUserInfo({ telephone: e }).then(res => {
                this.userinfo = res.data
                this.recordList = res.data.mainCzList
                this.tcList = res.data.packageList
                this.zsList = res.data.donationList
            })
        },
        reset() {
            this.recordList = []
            this.zsList = []
            this.tcList = []
        }
    },
    mounted() {
        PubSub.subscribe('activeTag', (msg, data) => {
            this.tag = data
        })
    },
    watch: {
        '$route'(to, from) {
            // console.log('Route changed from', from.path, 'to', to.path);
            if (to) {
                this.reset()
                this.userinfo = {}
                this.recordList = []
                if (to.path == '/memberDeposit/balanceRecharge') {
                    this.flag = 'cz'
                } else if (to.path == '/memberDeposit/packageOpening') {
                    this.flag = 'tc'
                } else if (to.path == '/memberDeposit/giftItems') {
                    this.flag = 'zs'
                }
            }
        },
    }
}
</script>
<style scoped>
.memberDepositContainer {
    display: flex;
    background-color: rgb(247, 248, 250);
}

.memberDepositMain {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100vh;


}

.exit {
    height: .5rem;
    display: flex;
    justify-content: flex-end;
}

.outLogin {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
}

.outLogin span {
    display: inline-block;
    margin-right: .0625rem;
    font-size: .1875rem !important;
}

.memberDepositContent {
    width: 100%;
    flex: 1;
    background-color: white;
    display: flex;

}

.so_aside {
    width: 20%;
    display: flex;
    flex-direction: column;
    height: 100% !important;
    border-right: 1px solid rgba(212, 212, 212, 0.5);
}

.aside_content {
    padding: 0 .125rem;
}

.user_info {
    width: 100%;
    display: flex;
    height: 1.25rem;
    align-items: center;
    border-bottom: 1px solid rgb(212, 212, 212);
    margin-bottom: .125rem;
}

.user_image {
    width: 1.5rem;
    height: .75rem;
    display: flex;
    justify-content: center;
}

.user_detail_info {
    flex: 1;

}

.user {
    height: .1875rem;
    padding-bottom: .3125rem;
}

.user_name .el-tag {
    margin-left: .375rem !important;
}



.own_info_tag {
    width: 100%;
    height: .875rem;
    background: rgb(231, 236, 255);
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: .125rem;
}

.own_info_tag span {
    display: inline-block;
}

.own_info_number {
    font-weight: bold;
    color: #ea8419;
}

.record {
    margin-bottom: .125rem;
}

.record_info {
    width: 100%;
    flex: 1;
    height: 5rem;
    overflow: auto;
    border: 1px solid rgb(212, 212, 212);
    border-radius: .125rem !important;
    padding: .125rem;

}

.record_title {
    font-size: .175rem;
    border-bottom: 1px solid rgb(212, 212, 212);
    text-align: center;
    padding-bottom: .125rem;
    margin-bottom: .125rem;
}

.record_value {
    width: 100%;
    height: .3125rem;
    line-height: .3125rem;
    display: inline-block;
    background: #f2f2f2;
    font-size: .15rem;
}

.record_time {
    display: flex;
    justify-content: space-between;
}

.record_time span {
    display: inline-block;
    color: rgb(0, 79, 251);
    font-size: .1375rem;
}

.so_content {
    flex: 1;
    width: 80%;
}
</style>
