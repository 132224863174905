<template>
    <div class="memberInfoContainer">
        <div class="aside">
            <div class="user_info">
                <div class="user_image">
                    <el-avatar :size="50" src="../../../assets/image.jpg"></el-avatar>
                </div>
                <div class="user_detail_info">
                    <div class="user user_name">
                        <span>{{ memberInfo.wechatName }}</span>
                        <el-tag type="warning" effect="plain" size="mini"> {{ memberInfo.vipLevelName }}</el-tag>
                    </div>
                    <div class="user user_phone">
                        <i class="el-icon-phone">{{ memberInfo.telephone }}</i>
                    </div>
                    <div class="user user_time">
                        <span>注册时间：{{ memberInfo.createTime }}</span>
                    </div>
                </div>
            </div>
            <div class="own_info">
                <div class="own_info_tag">
                    <span>账户余额</span>
                    <span class="own_info_number">{{ memberInfo.selfBalance }}</span>
                </div>
                <div class="own_info_tag">
                    <span>可用积分</span>
                    <span class="own_info_number">{{ memberInfo.credit }}</span>
                </div>
                <div class="own_info_tag">
                    <span>持有套餐</span>
                    <span class="own_info_number">{{ memberInfo.holdSetmeal }}张</span>
                </div>
                <!-- <div class="own_info_tag">
                    <span>优惠券</span>
                    <span class="own_info_number">6张</span>
                </div> -->

            </div>
        </div>
        <div class="main">
            <el-menu :default-active="mi_defaultIndex" class="el-menu-demo" mode="horizontal" @select="mi_handleSelect">
                <el-menu-item index="mi_tag1">客户档案</el-menu-item>
                <el-menu-item index="mi_tag2">车辆档案</el-menu-item>
                <!-- <el-menu-item index="mi_tag3">消费记录</el-menu-item> -->
            </el-menu>
            <div class="formStyle">
                <div class="memberInfoMain" v-if="mi_activeIndex === 'mi_tag1'">
                    <el-divider content-position="left" class="poi_title">客户档案</el-divider>
                    <el-form ref="form" :model="form" label-width="110px" size="mini" :rules="rules">
                        <el-row :gutter="20">
                            <el-col :span="10" :offset="2">
                                <el-form-item label="微信昵称：" prop="wechatName">
                                    <el-input v-model="form.wechatName" disabled placeholder="请输入"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10">
                                <el-form-item label="手机号：" prop="telephone">
                                    <el-input v-model="form.telephone" :disabled="disabled" placeholder="请输入"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="20">
                            <el-col :span="10" :offset="2">
                                <el-form-item label="客户姓名：" prop="name">
                                    <el-input v-model="form.name" :disabled="disabled" placeholder="请输入"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10">
                                <el-form-item label="身份证号：" prop="idCard">
                                    <el-input v-model="form.idCard" :disabled="disabled" placeholder="请输入"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="20">
                            <el-col :span="10" :offset="2">
                                <el-form-item label="性别" prop="gender">
                                    <el-select placeholder="请选择" v-model="form.gender" size="small" :disabled="disabled">
                                        <el-option label="男" :value="1"></el-option>
                                        <el-option label="女" :value="2"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10">
                                <el-form-item label="介绍人">
                                    <el-input v-model="form.sellerId" :disabled="disabled" placeholder="请输入"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="20">
                            <el-col :span="10" :offset="2">
                                <el-form-item label="会员生日：" prop="birthday">
                                    <el-date-picker type="date" :disabled="disabled" value-format="yyyy-MM-dd"
                                        v-model="form.birthday" placeholder="选择日期" size="small">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10">
                                <el-form-item label="会员状态：">
                                    <el-button type="text" v-if="form.actived == 4">冻结</el-button>
                                    <el-button type="text" v-if="form.actived == 1">正常</el-button>
                                    <!-- <el-button type="text" v-if="form.actived == 2">待审核</el-button>
                                    <el-button type="text" v-if="form.actived == 3">驳回</el-button> -->
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="20">
                            <el-col :span="10" :offset="2">
                                <el-form-item label="会员级别">
                                    <el-select placeholder="请选择" v-model="form.vipLevelId" size="small"
                                        :disabled="disabled">
                                        <el-option v-for="(item, index) in vipLevelList" :key="index" :label="item.name"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10">
                                <el-form-item label="发卡店：" prop="storeId">
                                    <el-select placeholder="请选择" v-model="form.storeId" size="small" :disabled="disabled">
                                        <el-option v-for="(item, index) in StoreList" :key="index" :label="item.name"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="20">
                            <el-col :span="10" :offset="2">
                                <el-form-item label="客户标签：" :rules="[{ required: true }]">
                                    <el-select multiple placeholder="请选择" v-model="userLabel" size="small"
                                        :multiple-limit="3">
                                        <el-option v-for="(item, index) in customerBq" :key="index" :label="item.name"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10">
                                <el-form-item label="卡片性质：" :rules="[{ required: true }]">
                                    <el-select placeholder="请选择" v-model="form.cardType" size="small" :disabled="disabled">
                                        <el-option label="购买" value="0"></el-option>
                                        <el-option label="赠送" value="1"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <!-- </el-form> -->
                        <el-divider content-position="left" class="poi_title">客户拓展信息</el-divider>
                        <!-- <el-form ref="form" :model="form" label-width="130px" size="mini"> -->
                        <el-row :gutter="20">
                            <el-col :span="10" :offset="2">
                                <el-form-item label="所属市区：">
                                    <el-select placeholder="请选择" v-model="form.city" size="small"
                                        style="width: 49%;margin-right: 2%;" @change="cityChange" :disabled="tuoZhan">
                                        <el-option v-for="(item, index) in cityList" :key="index" :label="item.regionName"
                                            :value="item.regionName"></el-option>
                                    </el-select>
                                    <el-select placeholder="请选择" v-model="form.area" size="small" style="width: 49%;"
                                        :disabled="tuoZhan">
                                        <el-option v-for="(item, index) in areaList" :key="index" :label="item.regionName"
                                            :value="item.regionName"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10">
                                <el-form-item label="所属行业：">
                                    <el-select placeholder="请选择" v-model="form.attributeIdFour" size="small"
                                        style="width: 49%;" :disabled="tuoZhan">
                                        <el-option v-for="(item, index) in customerHy" :key="index" :label="item.source"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="20">
                            <el-col :span="10" :offset="2">
                                <el-form-item label="所属职业：">
                                    <el-select placeholder="请选择" v-model="form.attributeIdFive" size="small"
                                        style="width: 49%;" :disabled="tuoZhan">
                                        <el-option v-for="(item, index) in customerZw" :key="index" :label="item.source"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10">
                                <el-form-item label="客户性质：">
                                    <el-select placeholder="请选择" v-model="form.attributeIdOne" size="small"
                                        style="width: 49%;" :disabled="tuoZhan">
                                        <el-option v-for="(item, index) in customerXz" :key="index" :label="item.source"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="20">
                            <el-col :span="10" :offset="2">
                                <el-form-item label="客户收入：">
                                    <el-select placeholder="请选择" v-model="form.attributeIdSix" size="small"
                                        :disabled="tuoZhan">
                                        <el-option v-for="(item, index) in customerSr" :key="index" :label="item.source"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10">
                                <el-form-item label="文化程度：">
                                    <el-select placeholder="请选择" v-model="form.attributeIdEight" size="small"
                                        :disabled="tuoZhan">
                                        <el-option v-for="(item, index) in customerWh" :key="index" :label="item.source"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="20">
                            <el-col :span="10" :offset="2">
                                <el-form-item label="所属国籍：">
                                    <el-select placeholder="请选择" v-model="form.attributeIdNine" size="small"
                                        :disabled="tuoZhan">
                                        <el-option v-for="(item, index) in customerGj" :key="index" :label="item.source"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10">
                                <el-form-item label="客户分类：">
                                    <el-select placeholder="请选择" v-model="form.attributeIdTwo" size="small"
                                        :disabled="tuoZhan">
                                        <el-option v-for="(item, index) in customerFl" :key="index" :label="item.source"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="20">
                            <el-col :span="10" :offset="2">
                                <el-form-item label="兴趣爱好：">
                                    <el-select placeholder="请选择" v-model="form.attributeIdSeven" size="small"
                                        :disabled="tuoZhan">
                                        <el-option v-for="(item, index) in customerXq" :key="index" :label="item.source"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10">
                                <el-form-item label="客户来源：">
                                    <el-select placeholder="请选择" v-model="form.attributeIdZero" size="small"
                                        :disabled="tuoZhan">
                                        <el-option v-for="(item, index) in customerLy" :key="index" :label="item.source"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="20">
                            <el-col :span="10" :offset="2">
                                <el-form-item label="参加活动意愿：">
                                    <el-select placeholder="请选择" v-model="form.activity" size="small" :disabled="tuoZhan">
                                        <el-option label="否" value="0"></el-option>
                                        <el-option label="是" value="1"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="20">
                            <el-col :span="10" :offset="2">
                                <el-form-item label="入会日期：" prop="joiningTime">
                                    <el-date-picker type="date" v-model="form.joiningTime" value-format="yyyy-MM-dd"
                                        placeholder="选择日期" size="small" :disabled="tuoZhan">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10">
                                <el-form-item label="截止日期：">
                                    <el-date-picker type="date" v-model="form.cutoffTime" value-format="yyyy-MM-dd"
                                        placeholder="选择日期" size="small" :disabled="tuoZhan">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="20">
                            <el-col :span="10" :offset="2">
                                <el-form-item label="专属顾问：" prop="userid">
                                    <el-select placeholder="请选择" v-model="form.userid" size="small" :disabled="tuoZhan">
                                        <el-option v-for="(item) in memList" :key="item.user_id" :label="item.nickname"
                                            :value="item.user_id"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10">
                                <el-form-item label="备注：">
                                    <el-input type="textarea" v-model="form.remarks" :rows="2" :disabled="tuoZhan" />
                                </el-form-item>
                            </el-col>

                        </el-row>
                    </el-form>
                    <div class="save" v-if="!tuoZhan">
                        <el-button type="primary" @click="save" size="small">保存</el-button>
                    </div>
                </div>
                <div class="memberInfoMain" v-if="mi_activeIndex === 'mi_tag2'">
                    <div class="addCar">
                        <!-- <el-button v-if="!flag" type="primary" @click="openAddCarDialog" size="small">添加车辆</el-button> -->
                        <el-button v-if="flag" type="primary" @click="openAddCarDialog" size="small">添加车辆</el-button>
                    </div>
                    <div class="tableStyle" style="width: 100%;">
                        <el-table :data="carData" :stripe="true" size="mini" header-cell-class-name="headerClassName"
                            :cell-style="{ 'text-align': 'center' }" :border="true" :fit="true">
                            <el-table-column type="index" label="编号">
                            </el-table-column>
                            <el-table-column prop="carNum" label="车牌号">
                            </el-table-column>
                            <el-table-column prop="carFrameNumber" label="车架号" width="200">
                            </el-table-column>
                            <el-table-column prop="brand" label="车辆品牌">
                            </el-table-column>
                            <el-table-column prop="series" label="车辆系列">
                            </el-table-column>
                            <el-table-column prop="model" label="车辆型号">
                            </el-table-column>
                            <el-table-column prop="carColorId" label="车辆颜色">
                            </el-table-column>
                            <el-table-column prop="carProductionId" label="车辆产地">
                            </el-table-column>
                            <el-table-column prop="carPurposeId" label="车辆用途">
                            </el-table-column>
                            <el-table-column prop="ourStoreSales" label="本店销售">
                                <template slot-scope="scope">
                                    <p v-if="scope.row.ourStoreSales == 1">是</p>
                                    <p v-if="scope.row.ourStoreSales == 0">否</p>
                                </template>
                            </el-table-column>
                            <el-table-column label="操作">
                                <template slot-scope="scope">
                                    <el-button @click="updateCar(scope.row.id)" type="text" size="small">编辑</el-button>
                                    <el-button v-if="scope.row.status" @click="delCar(scope.row.id)" type="text"
                                        size="small">刪除</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                        <el-pagination background layout="prev, pager, next" :page-size="5" :current-page.sync="page"
                            @current-change="getCarData" :total="total">
                        </el-pagination>
                    </div>
                </div>
                <div class="memberInfoMain" v-if="mi_activeIndex === 'mi_tag3'">
                    <div class="check">
                        <el-form ref="form" :model="form" label-width="100px" size="mini">
                            <el-row :gutter="20">
                                <el-col :span="6">
                                    <el-form-item label="消耗类型：">
                                        <el-select placeholder="请选择" v-model="value" size="small">
                                            <el-option label="区域一" value="shanghai"></el-option>
                                            <el-option label="区域二" value="beijing"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="消费类型：">
                                        <el-select placeholder="请选择" v-model="value" size="small">
                                            <el-option label="区域一" value="shanghai"></el-option>
                                            <el-option label="区域二" value="beijing"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="时间搜索：">
                                        <el-date-picker type="date" placeholder="选择日期" size="small">
                                        </el-date-picker>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="4">
                                    <el-button type="primary">查询</el-button>
                                    <el-button type="primary">导出数据</el-button>
                                </el-col>
                            </el-row>
                        </el-form>
                    </div>

                    <div class="tableStyle">
                        <el-table :data="pointsSettingsTableData" :stripe="true" size="mini"
                            header-cell-class-name="headerClassName" :cell-style="{ 'text-align': 'center' }" :border="true"
                            :fit="true">
                            <el-table-column prop="id" label="序号">
                            </el-table-column>
                            <el-table-column prop="memberLevel" label="手机号">
                            </el-table-column>
                            <el-table-column prop="projectName" label="会员级别">
                            </el-table-column>
                            <el-table-column prop="pointsRules" label="卡片性质">
                            </el-table-column>
                            <el-table-column prop="integralNum" label="发卡店">
                            </el-table-column>
                            <el-table-column prop="amount" label="客户自费">
                            </el-table-column>
                            <el-table-column prop="integral" label="销售赠送">
                            </el-table-column>
                            <el-table-column prop="multiplierOfPoints" label="总增加积分">
                            </el-table-column>
                            <el-table-column prop="a" label="可用积分">
                            </el-table-column>
                            <el-table-column prop="b" label="冻结积分">
                            </el-table-column>
                            <el-table-column prop="multiplierOfPoints" label="客户自费(已退)">
                            </el-table-column>
                            <el-table-column prop="a" label="销售赠送(已退)">
                            </el-table-column>
                            <el-table-column prop="b" label="可用积分(已退)">
                            </el-table-column>
                            <el-table-column prop="multiplierOfPoints" label="冻结积分(已退)">
                            </el-table-column>
                            <el-table-column prop="a" label="客户姓名">
                            </el-table-column>
                            <el-table-column prop="b" label="车辆号码">
                            </el-table-column>
                            <el-table-column label="操作">
                                <template slot-scope="scope">
                                    <el-button @click="handleClick(scope.row)" type="text" size="small">编辑</el-button>
                                    <el-button type="text" size="small">刪除</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                        <el-pagination background layout="prev, pager, next" :total="1000">
                        </el-pagination>
                    </div>
                </div>
            </div>

        </div>
        <AddCarModal ref="addCarModal" @child-event="handleChildEvent" />
    </div>
</template>
<script>
import AddCarModal from '../memberManageModal/AddCarModal.vue'
import * as memberApi from '@/api/memberManage/index'
export default {
    data() {
        return {
            userLabel: [],
            mi_defaultIndex: 'mi_tag1',
            mi_activeIndex: 'mi_tag1',
            memList: [],
            form: {},
            addCarMessage: '',
            memberInfo: {},
            carData: [],
            total: 0,
            page: 1,
            pageSize: 5,
            vipLevelList: [],
            StoreList: [],
            customerLy: [],
            customerFl: [],
            customerXz: [],
            customerHy: [],
            customerZw: [],
            customerSr: [],
            customerXq: [],
            customerWh: [],
            customerGj: [],
            customerBq: [],
            rules: {
                name: [{ required: true, message: '请输入客户姓名', trigger: 'blur' }],
                idCard: [{ required: true, message: '请输入身份证号', trigger: 'blur' }],
                telephone: [{ required: true, message: '请输入客户手机号', trigger: 'blur' }],
                sellerId: [{ required: true, message: '请输入介绍人', trigger: 'blur' }],
                birthday: [{ required: true, message: '请输入客户生日', trigger: 'blur' }],
                vipLevelId: [{ required: true, message: '请选择会员级别', trigger: 'change' }],
                gender: [{ required: true, message: '请选择性别', trigger: 'change' }],
                userLabel: [{ required: true, message: '请选择客户标签', trigger: 'change' }],
                joiningTime: [{ required: true, message: '请输入客户生日', trigger: 'blur' }],
                userid: [{ required: true, message: '请选择专属顾问', trigger: 'change' }],
            },
            cityList: [],
            areaList: [],
            disabled: false,
            tuoZhan: false,

        }
    },
    created() {
        this.getMemberinfo()
        this.getCarData()
        this.getCommn()
        this.getCustomerSX(0)
        this.getCustomerSX(1)
        this.getCustomerSX(2)
        this.getCustomerSX(4)
        this.getCustomerSX(5)
        this.getCustomerSX(7)
        this.getCustomerSX(6)
        this.getCustomerSX(8)
        this.getCustomerSX(9)
        this.getcityList('')
        this.getMemberList()
        if (this.$route.query.state == 1) {
            this.disabled = true
        } else {
            this.disabled = false
        }
        if (this.$route.query.type == 'onlyRead') {
            this.disabled = true
            this.tuoZhan = true
        }
    },
    computed: {
        flag() {
            let isOpen = process.env.VUE_APP_IS_ADD_CAR == '1' ? true : false
            if (isOpen && this.carData.length == 0) {
                return true
            }
            if (!isOpen) {
                return true
            }
            return false
        }
    },
    methods: {
        mi_handleSelect(key, keyPath) {
            this.mi_activeIndex = key
        },
        handleChildEvent(childMessage) {
            // 处理子组件传递的事件和参数
            // console.log('Received message from child:', childMessage)
            this.getCarData()
            // 可以在这里执行其他逻辑或更新父组件的数据
        },
        openAddCarDialog() {
            this.$refs.addCarModal.openDialog(this.form.id, '', 'id')
        },
        getMemberList() {
            memberApi.getStaffList().then(res => {
                if (res.state == '200') {
                    this.memList = res.data
                }
            })
        },
        getMemberinfo() {
            memberApi.selectById({ telephone: this.$route.query.telephone }).then(res => {
                if (res.state == '200') {
                    this.memberInfo = res.data
                }
            })
            memberApi.getDetails({ telephone: this.$route.query.telephone }).then(res => {
                if (res.state == '200') {
                    this.form = res.data
                    if (!res.data.userLabel) {
                        this.form.userLabel = []
                        this.userLabel = []
                    } else {
                        this.userLabel = res.data.userLabel.split(',')
                    }
                }
            })
        },
        getCarData() {
            memberApi.CarselectList({ telephone: this.$route.query.telephone, page: this.page, pageSize: this.pageSize }).then(res => {
                let { data, state } = res
                this.carData = data.records
                this.total = data.total
            })
        },
        updateCar(id) {
            this.$refs.addCarModal.openDialog(id, this.form.id, 'update')
        },
        delCar(e) {
            this.$confirm('确定删除此车辆吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                memberApi.removeCarById({ id: e }).then(res => {
                    if (res.state == '200') {
                        this.$message.success('删除成功')
                        this.getCarData()
                    }
                })
            }).catch(() => {

            });
        },
        getCommn() {
            memberApi.clientVipLevelGetList({}).then(res => {
                let { data } = res
                this.vipLevelList = data
            })
            memberApi.storeGetList({}).then(res => {
                let { data } = res
                this.StoreList = data
            })
            memberApi.TaggetList({}).then(res => {
                let { data } = res
                this.customerBq = data
            })
        },
        //获取客户属性列表
        getCustomerSX(type) {
            memberApi.attributeGetList({ type: type }).then(res => {
                let { data } = res
                if (type == 0) {
                    this.customerLy = data
                } else if (type == 2) {
                    this.customerFl = data
                } else if (type == 1) {
                    this.customerXz = data
                } else if (type == 4) {
                    this.customerHy = data
                } else if (type == 5) {
                    this.customerZw = data
                } else if (type == 6) {
                    this.customerSr = data
                } else if (type == 7) {
                    this.customerXq = data
                } else if (type == 8) {
                    this.customerWh = data
                } else if (type == 9) {
                    this.customerGj = data
                }
            })
        },
        //获取城市列表
        getcityList(id) {
            memberApi.cityList({ id: id }).then(res => {
                let { data } = res
                if (id == '') {
                    this.cityList = data
                } else {
                    this.areaList = data
                }
            })
        },
        save() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.form.userLabel = this.userLabel.join(',')
                    if (this.$route.query.type) {
                        memberApi.updateImproveInformation(this.form).then(res => {
                            if (res.state == '200') {
                                this.$message.success('修改成功')
                                this.getMemberinfo()
                                this.$router.push({ path: '/memberManage/pendingMembers?type=1' })
                            }
                        })
                    } else {
                        memberApi.updateAttribute(this.form).then(res => {
                            if (res.state == '200') {
                                this.$message.success('修改成功')
                                this.getMemberinfo()
                            }
                        })
                    }


                } else {

                }
            });
        },
        cityChange(e) {
            this.form.area = ''
            this.cityList.forEach(item => {
                if (item.regionName == e) {
                    this.getcityList(item.id)
                }
            })
        }
    },
    components: {
        AddCarModal
    }
}
</script>
<style scoped>
.memberInfoContainer {
    width: 100%;
    height: 100%;
    display: flex;
    padding-bottom: .125rem;
    overflow: auto;

}

.aside {
    width: 4.375rem;
    display: flex;
    flex-direction: column;
    height: 100% !important;
    padding: 0 .125rem .25rem;
    border: 1px solid rgb(212, 212, 212);
    border-radius: .125rem;
}

.main {
    flex: 1;
    padding: .125rem .125rem 0 .125rem;
    display: flex;
    flex-direction: column;

}

.main {
    font-size: .2rem;
}

.user_info {
    width: 100%;
    display: flex;
    height: 1.75rem;
    align-items: center;
    /* border: 1px solid rgb(212, 212, 212); */
    margin-bottom: .125rem;
    padding-top: .5rem;
    border: none;
    /* justify-content: center; */
}

.user_image {
    width: 1.5rem;
    height: .75rem;
    display: flex;
    justify-content: center;
}

.user_name .el-tag {
    margin-left: .375rem !important;
}

.own_info_tag {
    width: 100%;
    height: .875rem;
    background: rgb(231, 236, 255);
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: .125rem;
}

.own_info_tag span {
    display: inline-block;
}

.own_info_number {
    font-weight: bold;
    color: #ea8419;
}

.poi_title .el-divider__text.is-left {
    left: 0;
}

.poi_title .el-divider__text {
    font-weight: bold;
}

.formStyle {
    border: 1px solid rgb(212, 212, 212);
    border-radius: .125rem;
    margin: .125rem .125rem 0 .125rem;
    flex: 1;
    padding: .25rem .25rem 0 .25rem;
}

.memberInfoMain .el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
    margin-bottom: .125rem;
}

.save {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: .125rem;
}
</style>