<template>
  <div>
    <el-dialog @close="closeDialog()" :rules="rules" custom-class="projectByCostDialog" :visible.sync="dialogVisible"
      :modal="true" :append-to-body="true" :close-on-click-modal="false">
      <el-tabs>
        <el-tab-pane label="赠送项目成本信息">
          <el-form ref="form" :model="form" label-width="1.5rem" size="mini" :rules="rules">
            <el-row :gutter="20">
              <el-col :span="24">
                <el-form-item label="赠送项目" prop="donationId">
                  <el-select placeholder="请选择"  v-model="form.donationId">
                    <el-option :label="item.name" :value="item.id" :key="item.id"
                      v-for="(item, index) in projectList"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="24">
                <el-form-item label="车辆型号" prop="carId">
                  <el-select placeholder="请选择"  v-model="form.carId">
                    <el-option :label="item.model" :value="item.id" :key="item.id"
                      v-for="(item, index) in carList"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="24">
                <el-form-item label="项目成本" prop="cost" >
                  <el-input placeholder="请输入项目成本" v-model="form.cost"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="24">
                <span>有效期为0的标识没有有效期，不限次数项目，项目名称后(不限次数)</span>
              </el-col>
            </el-row>
          </el-form>
        </el-tab-pane>
      </el-tabs>
      <span slot="title" class="dialog-title">
        <span>赠送项目信息</span>
      </span>
      <span slot="footer" class="dialog-footer">
        <div slot="footer" class="dialog-footer">
          <el-button @click="closeDialog()">取 消</el-button>
          <el-button type="primary" @click="save()">确 定</el-button>
        </div>
      </span>
    </el-dialog>
  </div>
</template>  
  
<script>
import { getCarList, getProNoPage, updatePro3, addPro3 } from '@/api/systemOption/systemSettings';
export default {
  data() {
    return {
      edit: false,
      dialogVisible: false,
      form: {
        donationId: '',
        carId: '',
        cost: ''
      },
      carList: [],
      projectList: [],
      rules: {
        donationId: [{ required: true, message: '请选择赠送项目', trigger: 'blur' }],
        carId: [{ required: true, message: '请选择车辆型号', trigger: 'blur' }],
        cost: [{ required: true, message: '请输入项目成本', trigger: 'blur' }],
      }
    };
  },
  mounted() {

  },
  methods: {
    openDialog(data) {
      // 打开弹出框，并触发父组件的事件传递参数  
      this.dialogVisible = true;
      this.edit = false
      if (data) {
        this.form = data
        this.edit = true
      }

      getCarList({}).then(res => {
        if (res.state === '200') {
          this.carList = res.data
        }
      })
      getProNoPage().then(res => {
        if (res.state === '200') {
          this.projectList = res.data
        }
      })

    },
    closeDialog() {
      this.dialogVisible = false;
      this.$refs.form?.resetFields()
    },
    save() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.edit) {
            updatePro3(this.form).then(res => {
              if (res.state === '200') {
                this.$message({
                  message: '修改成功！',
                  type: 'success'
                });
                this.$emit('child-event3')
                this.dialogVisible = false;
              }
            })
          } else {
            addPro3(this.form).then(res => {
              if (res.state === '200') {
                this.$message({
                  message: '添加成功！',
                  type: 'success'
                });
                this.$emit('child-event3')
                this.dialogVisible = false;
              }
            })
          }

        }
      })
    }
  },
};
</script>
<style>
.dialog-footer {
  text-align: center;
}

.projectByCostDialog {
  margin-top: 20vh !important;
  width: 25%;
}
</style>
