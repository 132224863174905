import request from '@/utils/request'


// 客户基本信息
export function getUserInfo(data) {
    return request({
        url: '/work/main/getUserInfo',
        method: 'post',
        data: data
    })
}
// 会员保险到期列表
export function getCarInsurance(data) {
    return request({
        url: '/work/main/getCarInsurance',
        method: 'post',
        data: data
    })
}
// 会员生日列表
export function getBirthdayRemind(data) {
    return request({
        url: '/work/main/getBirthdayRemind',
        method: 'post',
        data: data
    })
}

// 客户自费维保充值记录
export function getSelf(data) {
    return request({
        url: '/work/main/getSelf',
        method: 'post',
        data: data
    })
}
// 客户自费维保充值记录页面
export function getSelfSum(data) {
    return request({
        url: '/work/main/getSelfSum',
        method: 'post',
        data: data
    })
}
// 客户自费维保消费记录
export function getSelfCons(data) {
    return request({
        url: '/work/main/getSelfCons',
        method: 'post',
        data: data
    })
}
// 客户自费维保消费记录汇总信息
export function getSelfXf(data) {
    return request({
        url: '/work/main/getSelfXf',
        method: 'post',
        data: data
    })
}
// 销售赠送维保储值记录
export function getRecordSales(data) {
    return request({
        url: '/work/main/getRecordSales',
        method: 'post',
        data: data
    })
}
// 销售赠送维保储值记录汇总信息
export function getSalesCz(data) {
    return request({
        url: '/work/main/getSalesCz',
        method: 'post',
        data: data
    })
}
// 销售赠送维保消费记录
export function getRecordSalesCons(data) {
    return request({
        url: '/work/main/getRecordSalesCons',
        method: 'post',
        data: data
    })
}
// 销售赠送维保消费记录汇总信息
export function getSalesXf(data) {
    return request({
        url: '/work/main/getSalesXf',
        method: 'post',
        data: data
    })
}
// 售后赠送维保储值记录
export function getSales(data) {
    return request({
        url: '/work/main/getAfter',
        method: 'post',
        data: data
    })
}
// 售后赠送维保储值记录汇总信息
export function getAfterCz(data) {
    return request({
        url: '/work/main/getAfterCz',
        method: 'post',
        data: data
    })
}
// 售后赠送维保消费记录
export function getSalesCons(data) {
    return request({
        url: '/work/main/getAfterCons',
        method: 'post',
        data: data
    })
}
// 售后赠送维保消费记录汇总信息
export function getAfterXf(data) {
    return request({
        url: '/work/main/getAfterXf',
        method: 'post',
        data: data
    })
}
// 金融赠送维保储值记录
export function getAfterSales(data) {
    return request({
        url: '/work/main/getInsurance',
        method: 'post',
        data: data
    })
}
// 金融赠送维保储值记录汇总信息
export function getAfterSalesCz(data) {
    return request({
        url: '/work/main/getInsuranceCz',
        method: 'post',
        data: data
    })
}
// 金融赠送维保消费记录
export function getAfterSalesCons(data) {
    return request({
        url: '/work/main/getInsuranceCons',
        method: 'post',
        data: data
    })
}
// 金融赠送维保消费记录汇总信息
export function getAfterSalesXf(data) {
    return request({
        url: '/work/main/getInsuranceXf',
        method: 'post',
        data: data
    })
}
// 积分增加记录
export function getCredit(data) {
    return request({
        url: '/work/main/getCredit',
        method: 'post',
        data: data
    })
}
// 积分增加记录汇总信息
export function getCreditCz(data) {
    return request({
        url: '/work/main/getCreditCz',
        method: 'post',
        data: data
    })
}
// 积分消费记录
export function getCreditCons(data) {
    return request({
        url: '/work/main/getCreditCons',
        method: 'post',
        data: data
    })
}
// 积分消费记录汇总信息
export function getCreditXf(data) {
    return request({
        url: '/work/main/getCreditXf',
        method: 'post',
        data: data
    })
}


//套餐开卡
export function getPackageCard(data) {
    return request({
        url: '/work/main/getSetMeal',
        method: 'post',
        data: data
    })
}
export function getPackageCardHz(data) {
    return request({
        url: '/work/main/getSetMealCz',
        method: 'post',
        data: data
    })
}
// 查看套餐余额
export function getPackageBalance(data) {
    return request({
        url: '/work/main/getSetMealSurplus',
        method: 'post',
        data: data
    })
}
export function getPackageBalanceHz(data) {
    return request({
        url: '/work/main/getSetMealYe',
        method: 'post',
        data: data
    })
}
// 套餐消费记录
export function getPackageCons(data) {
    return request({
        url: '/work/main/getSetMealCons',
        method: 'post',
        data: data
    })
}
export function getPackageConsHz(data) {
    return request({
        url: '/work/main/getSetMealXf',
        method: 'post',
        data: data
    })
}
// 赠送项目充值记录
export function getGift(data) {
    return request({
        url: '/work/main/getProject',
        method: 'post',
        data: data
    })
}
export function getGiftHz(data) {
    return request({
        url: '/work/main/getProjectCz',
        method: 'post',
        data: data
    })
}

// ---------------------
// 查看赠送项目余额
export function getGiftBalance(data) {
    return request({
        url: '/work/main/getProjectSurplus',
        method: 'post',
        data: data
    })
}
export function getGiftBalanceHz(data) {
    return request({
        url: '/work/main/getProjectYe',
        method: 'post',
        data: data
    })
}
// 赠送项目消费记录
export function getGiftCons(data) {
    return request({
        url: '/work/main/getProjectCons',
        method: 'post',
        data: data
    })
}
export function getGiftConsHz(data) {
    return request({
        url: '/work/main/getProjectXf',
        method: 'post',
        data: data
    })
}
// 根据维修类型id获取名称
export function getMaintenance(data) {
    return request({
        url: '/sys/maintenanceType/selectById',
        method: 'post',
        data: data
    })
}
// 获取维修类型列表
export function getMaintenanceList(data) {
    return request({
        url: '/sys/maintenanceType/getList',
        method: 'post',
        data: data
    })
}
// -------------------------------------------------------------------

// 客户自费维保消费导出
export function exportSelfXf(data) {
    return request({
        url: '/work/main/exportSelfXf',
        method: 'post',
        data: data,
        responseType: 'blob'
    })
}
// 销售赠送维保储值记录导出
export function exportSalesCz(data) {
    return request({
        url: '/work/main/exportSalesCz',
        method: 'post',
        data: data,
        responseType: 'blob'
    })
}
// 销售赠送维保消费记录导出
export function exportSalesXf(data) {
    return request({
        url: '/work/main/exportSalesXf',
        method: 'post',
        data: data,
        responseType: 'blob'
    })
}
// 售后赠送维保储值记录导出
export function exportAfterSalesXf(data) {
    return request({
        url: '/work/main/exportAfterSalesXf',
        method: 'post',
        data: data,
        responseType: 'blob'
    })
}
// 售后赠送维保消费记录导出
export function exportAfterSalesCz(data) {
    return request({
        url: '/work/main/exportAfterSalesCz',
        method: 'post',
        data: data,
        responseType: 'blob'
    })
}
// 金融赠送维保储值记录导出
export function exportInsuranceCz(data) {
    return request({
        url: '/work/main/exportInsuranceCz',
        method: 'post',
        data: data,
        responseType: 'blob'
    })
}
// 金融赠送维保消费记录导出
export function exportInsuranceXf(data) {
    return request({
        url: '/work/main/exportInsuranceXf',
        method: 'post',
        data: data,
        responseType: 'blob'
    })
}
// 积分增加记录导出
export function exportCreditCz(data) {
    return request({
        url: '/work/main/exportCreditCz',
        method: 'post',
        data: data,
        responseType: 'blob'
    })
}
// 积分消费记录导出
export function exportCreditXf(data) {
    return request({
        url: '/work/main/exportCreditXf',
        method: 'post',
        data: data,
        responseType: 'blob'
    })
}
// 套餐开卡记录导出
export function exportOpenCz(data) {
    return request({
        url: '/work/main/exportOpenCz',
        method: 'post',
        data: data,
        responseType: 'blob'
    })
}
// 查看套餐余额导出
export function exportOpenYe(data) {
    return request({
        url: '/work/main/exportOpenYe',
        method: 'post',
        data: data,
        responseType: 'blob'
    })
}
// 套餐消费记录导出
export function exportOpenXf(data) {
    return request({
        url: '/work/main/exportOpenXf',
        method: 'post',
        data: data,
        responseType: 'blob'
    })
}
// 赠送项目充值记录导出
export function exportGiftsCz(data) {
    return request({
        url: '/work/main/exportGiftsCz',
        method: 'post',
        data: data,
        responseType: 'blob'
    })
}

// 查看赠送项目余额导出
export function exportGiftsYe(data) {
    return request({
        url: '/work/main/exportGiftsYe',
        method: 'post',
        data: data,
        responseType: 'blob'
    })
}
// 赠送项目消费记录导出
export function exportGiftsXf(data) {
    return request({
        url: '/work/main/exportGiftsXf',
        method: 'post',
        data: data,
        responseType: 'blob'
    })
}