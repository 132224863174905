<template>
    <div>
        <el-dialog custom-class="addCategory" :visible.sync="dialogVisible" :modal="true" :append-to-body="true"
            :close-on-click-modal="false">
            <el-form ref="form" :model="form" label-width="100px" size="mini" :rules="rules">
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label="分类名称：" prop="name">
                            <el-input placeholder="请填写分类名称" v-model="form.name"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="10">
                    <el-col :span="12">
                        <el-form-item label="排序：" prop="sort">
                            <el-input-number v-model="form.sort" @change="handleChange" :min="0" :max="999999"
                                label="描述文字"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item>
                            <span>数字越小越靠前</span>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="title" class="dialog-title">
                <span>添加分类</span>
            </span>
            <span slot="footer" class="dialog-footer">
                <div slot="footer" class="dialog-footer">
                    <el-button size="small" @click="cancel">取 消</el-button>
                    <el-button size="small" type="primary" @click="sure()">确 定</el-button>
                </div>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { addVipMall,updateVipMall } from '@/api/memberConsume/index';
export default {
    props: {
        initialMessage: {
            type: Object,
            default: {},
            radio: '1',
            value: ''
        }
    },
    data() {
        return {
            dialogVisible: false, // 控制弹出框的显示与隐藏  
            message: this.initialMessage,// 初始化时接收父组件传递的参数  
            form: {
                name: '',
                sort: ''
            },
            rules: {
                name: [
                    { required: true, message: '请输入分类名称', trigger: 'blur' }
                ],
                sort: [
                    { required: true, message: '请输入排序', trigger: 'blur' }
                ]
            }
        };
    },
    methods: {
        openDialog(row) {
            // 打开弹出框，并触发父组件的事件传递参数  
            this.dialogVisible = true;
            console.log(row)
            if(row){
                this.form.id = row.id
            this.form.name = row.name
            this.form.sort = row.sort
            }else{
                this.form={
                name: '',
                sort: ''
            }
            }
            
        },
        cancel() {
            // 关闭弹出框  
            this.dialogVisible = false;
            this.form={
                name: '',
                sort: ''
            }
        },
        handleChange(value) {
            console.log(value);
        },
        sure() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    if(this.form.id){
                        updateVipMall(this.form).then(res=>{
                        if(res.state == '200'){
                            this.$message.success('修改成功')
                            this.dialogVisible = false
                            this.$emit('child-event', 'Hello from child');
                        }
                    })
                    }else{
                        addVipMall(this.form).then(res=>{
                        if(res.state == '200'){
                            this.$message.success('添加成功')
                            this.dialogVisible = false
                            this.$emit('child-event', 'Hello from child');
                        }
                    })
                    }
                    
                } else {
                    
                }
            });
        }
    },
    watch: {
        initialMessage(newValue) {
            // 监听父组件传递的参数变化，并更新子组件的数据  
            this.message = newValue;
        }
    }
};
</script>
<style scoped>
::v-deep .el-dialog.addCategory {
    width: 30% !important;
    margin-top: 25vh !important;
}
</style>