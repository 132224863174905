<template>
	<div class="mains">
		<el-menu :default-active="pac_defaultIndex" class="el-menu-demo" mode="horizontal" @select="pac_handleSelect">
			<el-menu-item index="offerName">套餐名称</el-menu-item>
			<el-menu-item index="packageDetails">所属套餐项目明细</el-menu-item>
		</el-menu>

		<div class="tableContainer" v-if="pac_activeIndex === 'offerName'">
			<div><el-button type="primary" @click="openPackageDialog()" size="small">添加套餐名称</el-button></div>
			<div class="content">
				<div class="tableStyle2">
					<el-table :data="tableData1" style="width: 100%;cursor: pointer;" :stripe="true" size="mini"
						header-cell-class-name="headerClassName" :cell-style="{ 'text-align': 'center' }" :border="true"
						:fit="true" highlight-current-row @row-click="rowClick">
						<el-table-column prop="name" label="套餐名称">
						</el-table-column>
						<el-table-column prop="price" label="套餐价值">
						</el-table-column>
						<el-table-column prop="term" label="有效期(月)">
						</el-table-column>
						<el-table-column label="操作">
							<template slot-scope="scope">
								<!-- <el-button @click="update1(scope.row)" type="text" size="small">修改</el-button>
								<el-button @click="del1(scope.row)" type="text" size="small">刪除</el-button> -->
								<span class="txt" style="margin-right: .125rem;" @click="update1(scope.row)" type="text" size="small">修改</span>
								<span class="txt" @click="del1(scope.row)" type="text" size="small">刪除</span>
							</template>
						</el-table-column>
					</el-table>
					<el-pagination background layout="prev, pager, next" @current-change="getData1"
						:current-page.sync="table1.page" :page-size="table1.pageSize" :total="table1.total">
					</el-pagination>
				</div>
				<div class="tableStyle2" >
					<el-divider content-position="left">当前套餐包含项目（选择套餐显示对应项）</el-divider>
					<!-- <span></span> -->
					<el-table :data="pacDeData" style="width: 100%;height: 4rem;" :stripe="true" size="mini"
						header-cell-class-name="headerClassName" :cell-style="{ 'text-align': 'center' }" :border="true"
						:fit="true">
						<el-table-column prop="packageName" label="所属套餐">
						</el-table-column>
						<el-table-column prop="itemName" label="服务项目">
						</el-table-column>
						<el-table-column prop="num" label="服务次数">
						</el-table-column>
						<el-table-column prop="itemPrice" label="项目单价">
						</el-table-column>
						<el-table-column prop="remark" label="项目说明">
						</el-table-column>
					</el-table>
				</div>
			</div>
		</div>
		<div class="tableContainer" v-if="pac_activeIndex === 'packageDetails'">
			<div><el-button type="primary" @click="openPackageDetailDialog()" size="small">添加套餐明细</el-button></div>
			<div class="tableStyle">
				<el-table :data="tableData2" style="width: 70%" :stripe="true" size="mini"
					header-cell-class-name="headerClassName" :cell-style="{ 'text-align': 'center' }" :border="true"
					:fit="true">
					<!-- <el-table-column prop="id" label="ID">
					</el-table-column> -->
					<el-table-column prop="packageId" label="所属套餐">
						<template slot-scope="scope">
							<!-- <span>{{ package(scope.row.packageId) }}</span> -->
							<span>{{ scope.row.packageName }}</span>
						</template>
					</el-table-column>
					<el-table-column prop="itemName" label="服务项目">
					</el-table-column>
					<el-table-column prop="num" label="服务次数">
					</el-table-column>
					<el-table-column prop="itemPrice" label="项目单价">
					</el-table-column>
					<el-table-column prop="remark" label="项目说明">
					</el-table-column>
					<el-table-column label="操作">
						<template slot-scope="scope">
							<el-button @click="update2(scope.row)" type="text" size="small">修改</el-button>
							<el-button @click="del2(scope.row)" type="text" size="small">刪除</el-button>
						</template>
					</el-table-column>
				</el-table>
				<el-pagination background layout="prev, pager, next" @current-change="getData2"
					:current-page.sync="table2.page" :page-size="table2.pageSize" :total="table2.total">
				</el-pagination>
			</div>
		</div>


		<PackageModal ref="dialog1" @child1-event="updateTable1()" />
		<PackageDetailModal ref="dialog2" @child2-event="updateTable2()" />
	</div>
</template>
<script>
import PackageModal from '@/views/systemOption/systemOptionModal/PackageModal.vue'
import PackageDetailModal from '@/views/systemOption/systemOptionModal/PackageDetailModal.vue'
import { getPackageDetailList, getPackageList, getPackageDetailListById } from '@/api/systemOption'
import { delPackage, delPackageDetail, getPackageListNoPage } from '@/api/systemOption/systemSettings'
export default {
	components: {
		PackageModal,
		PackageDetailModal
	},
	data() {
		return {
			pac_defaultIndex: 'offerName',
			pac_activeIndex: 'offerName',
			tableData1: [],
			tableData2: [],
			table1: {
				page: 1,
				pageSize: 5,
				total: 1
			},
			table2: {
				page: 1,
				pageSize: 10,
				total: 1
			},
			dialogVisible: false,
			pacDeData: []
		}
	},
	// computed: {
	// 	package() {
	// 		return (packageId) => {
	// 			if (this.packageList) {
	// 				const pac = this.packageList.find(item => item.id === packageId)
	// 				return pac.name
	// 			}
	// 		}
	// 	}
	// },
	mounted() {
		this.getData1()
		getPackageListNoPage().then(res => {
			if (res.state === '200') {
				this.packageList = res.data
			}
		})
	},
	methods: {
		update1(row) {
			this.$refs.dialog1.openDialog(row)
		},
		update2(row) {
			this.$refs.dialog2.openDialog(row)
		},
		// changePage2(e) {
		// 	this.table2.page = e
		// 	this.getData2()
		// },
		// changePage1(e) {
		// 	this.table1.page = e
		// 	this.getData1()
		// },
		updateTable1() {
			this.getData1()
		},
		updateTable2() {
			this.getData2()
		},
		openPackageDialog() {
			this.$refs.dialog1.openDialog()
		},
		openPackageDetailDialog() {
			this.$refs.dialog2.openDialog()
		},
		pac_handleSelect(key, keyPath) {
			this.pac_activeIndex = key
			switch (key) {
				case 'offerName':
					this.getData1()
					break;
				case 'packageDetails':
					this.getData2()
					break;
				default:
					break;
			}
		},
		getData1() {
			getPackageList({ page: this.table1.page, pageSize: this.table1.pageSize }).then(res => {
				if (res.state === '200') {
					this.tableData1 = res.data.records
					this.table1.total = res.data.total
				}
			})
		},
		getData2() {
			getPackageDetailList({ page: this.table2.page, pageSize: this.table2.pageSize }).then(res => {
				if (res.state === '200') {
					this.tableData2 = res.data.records
					this.table2.total = res.data.total
				}
			})
		},
		del1(row) {
			this.delMsg(this).then(() => {
				delPackage({ id: row.id }).then(res => {
					if (res.state === '200') {
						this.$message({
							message: '删除成功！',
							type: 'success'
						});
						if ((this.tableData1.length - 1) == 0 && this.table1.page > 1) {
							this.table1.page--
						}
						this.getData1()
					}
				})
			}).catch(() => {
				this.$message({
					type: 'warn',
					message: '删除失败！'
				});
			});
		},
		del2(row) {
			this.delMsg(this).then(() => {
				delPackageDetail({ id: row.id }).then(res => {
					if (res.state === '200') {
						this.$message({
							message: '删除成功！',
							type: 'success'
						});
						if ((this.tableData2.length - 1) == 0 && this.table2.page > 1) {
							this.table2.page--
						}
						this.getData2()
					}
				})
			}).catch(() => {
				this.$message({
					type: 'warn',
					message: '删除失败！'
				});
			});
		},
		rowClick(row) {
			console.log(row);
			getPackageDetailListById({ packageId: row.id }).then(res => {
				if (res.state == '200') {
					this.pacDeData = res.data
				}
			})
		}

	}
}
</script>
<style scoped>
.txt{
	color: #409eff;
}
.mains {
	width: 100%;
	flex: 1;
	display: flex;
	flex-direction: column;;
}

.tableContainer {
	/* width: 100%; */
	flex: 1;
	padding: .125rem .25rem;
	display: flex;
	flex-direction: column;
}

.tableContainer .el-table {
	width: 5rem;
}

.content {
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: space-between;
}

.tableStyle2 {
	padding: 0.125rem 0;
	width: 55%;
}
</style>