export const ABOUT_RECORD_MODAL = 'ABOUT_RECORD_MODAL'
export const memberManageList = [
    {
        path: '/memberConsume',
        menuList: [
            {
                value: '储值消费',
                path: '/memberConsume/valueStorageConsume'
            }, {
                value: '项目消费',
                path: '/memberConsume/projectConsume'
            }, {
                value: '套餐消费',
                path: '/memberConsume/packageConsume'
            }, {
                value: '积分增加',
                path: '/memberConsume/integralIncrease'
            }, {
                value: '会员商城',
                path: '/memberConsume/memberMall'
            }
        ]
    }, {
        path: '/memberDeposit',
        menuList: [
            {
                value: '余额充值',
                path: '/memberDeposit/balanceRecharge'
            }, {
                value: '套餐开卡',
                path: '/memberDeposit/packageOpening'
            }, {
                value: '赠送项目',
                path: '/memberDeposit/giftItems'
            }
        ]
    }, {
        path: '/memberManage',
        menuList: [
            {
                value: '会员列表',
                path: '/memberManage/memberList'
            }, {
                value: '待审会员',
                path: '/memberManage/pendingMembers'
            }, {
                value: '会员过户',
                path: '/memberManage/membershipTransfer'
            }, {
                value: '会员资产',
                path: '/memberManage/memberAssets'
            }, {
                value: '会员冻结',
                path: '/memberManage/membershipFreeze'
            }, {
                value: '会员升级',
                path: '/memberManage/membershipUpgrade'
            }
        ]
    },
    {
        path: '/financialManage',
        menuList: [
            {
                value: '自费明细',
                path: '/financialManage/selfFundedDetails'
            }, {
                value: '销售明细',
                path: '/financialManage/salesDetails'
            }, {
                value: '售后明细',
                path: '/financialManage/afterSalesDetails'
            }, {
                value: '保险明细',
                path: '/financialManage/insuranceDetails'
            }, {
                value: '套餐明细',
                path: '/financialManage/packageDetails'
            }, {
                value: '项目明细',
                path: '/financialManage/projectDetails'
            }, {
                value: '积分增加',
                path: '/financialManage/integralIncrease'
            }, {
                value: '积分冻结',
                path: '/financialManage/integralFreeze'
            }, {
                value: '积分到期',
                path: '/financialManage/integralExpire'
            }, {
                value: '套餐汇总',
                path: '/financialManage/packageOpening'
            }, {
                value: '套餐项目统计',
                path: '/financialManage/packageSummary'
            }, {
                value: '赠送项目统计',
                path: '/financialManage/gitGivedSummary'
            }, {
                value: '退卡信息表',
                path: '/financialManage/cardInfoForm'
            }, {
                value: '维保金额、积分统计',
                path: '/financialManage/maintenanceAndStatistics'
            }, {
                value: '发卡信息表',
                path: '/financialManage/cardInfoTable'
            }, {
                value: '会员卡汇总',
                path: '/financialManage/memberCardSummary'
            }, {
                value: '会员卡余额统计',
                path: '/financialManage/memberCardBalanceStatistics'
            }, {
                value: '冻结操作查询',
                path: '/financialManage/freezeOperationQuery'
            }, {
                value: '会员升级信息表',
                path: '/financialManage/memberUpgradeInfoTable'
            }
        ]
    }, {
        path: '/systemOption',
        menuList: [
            {
                value: '系统设置',
                path: '/systemOption/systemSettings'
            }, {
                value: '平台配置',
                path: '/systemOption/platformConfiguration'
            }, {
                value: '管理员设置',
                path: '/systemOption/adminSettings'
            }, {
                value: '门店设置',
                path: '/systemOption/storeSettings'
            }, {
                value: '公众号设置',
                path: '/systemOption/officialAccountSettings'
            }
        ]
    }
]