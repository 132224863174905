<template>
    <div class="storeSettingContainer">
        <div class="storeSettingNav">
            <el-menu :default-active="store_defaultIndex" class="el-menu-demo" mode="horizontal"
                @select="store_handleSelect">
                <el-menu-item index="store_tag1">门店管理</el-menu-item>
                <el-menu-item index="store_tag2">部门管理</el-menu-item>
            </el-menu>
        </div>
        <div class="storeSettingMain" v-if="store_activeIndex === 'store_tag1'">
            <el-button type="primary" @click="openDialog1()" size="small">添加门店</el-button>
            <div class="tableStyle2">
                <el-table :data="tableData1" style="width: 100%" :stripe="true" size="mini"
                    header-cell-class-name="headerClassName" :cell-style="{ 'text-align': 'center' }" :border="true"
                    :fit="true">
                    <el-table-column prop="id" label="编号">
                        <template slot-scope="scope">
                            <span>{{ scope.$index + 1 }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="门店名称">
                    </el-table-column>
                    <el-table-column prop="logo" label="门店照片" width="120">
                        <template slot-scope="scope">
                            <!-- <img :src="'data:image/jpeg;base64,' + scope.row.image" alt=""
                                style="width: 1.2rem;height: 1.2rem" v-if="scope.row.logo !== '' && scope.row.logo"> -->
                            <img :src="scope.row.logo" alt="" style="width: 1.2rem;height: 1.2rem"
                                v-if="scope.row.logo !== '' && scope.row.logo">
                        </template>
                    </el-table-column>
                    <el-table-column prop="telephone" label="门店电话" width="120">
                    </el-table-column>
                    <el-table-column prop="address" label="门店地址" width="200">
                    </el-table-column>
                    <el-table-column prop="isStore" label="是否本店">
                        <template slot-scope="scope">
                            <span v-if="!scope.row.isStore">否</span>
                            <span v-if="scope.row.isStore">是</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="baseUrl" label="域名" width="200">
                    </el-table-column>
                    <el-table-column prop="customerCodeUrl" label="客服" width="120">
                        <template slot-scope="scope">
                            <img :src="scope.row.customerCodeUrl" alt="" style="width: 1.2rem;height: 1.2rem"
                                v-if="scope.row.customerCodeUrl !== '' && scope.row.customerCodeUrl">
                        </template>
                    </el-table-column>
                    <el-table-column prop="customer" label="客服信息" width="150">
                    </el-table-column>
                    <el-table-column prop="createTime" label="创建时间" width="150">
                    </el-table-column>
                    <el-table-column prop="actived" label="状态">
                        <template slot-scope="scope">
                            <span v-if="!scope.row.actived" style="color: green;">启用</span>
                            <span v-if="scope.row.actived" style="color: red;">停用</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <el-button @click="edit1(scope.row)" type="text" size="small">编辑</el-button>
                            <el-button @click="del1(scope.row)" type="text" size="small">刪除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="prev, pager, next" @current-change="changePage1"
                    :current-page.sync="table1.page" :page-size="table1.pageSize" :total="table1.total">
                </el-pagination>
            </div>
        </div>
        <div class="storeSettingMain" v-if="store_activeIndex === 'store_tag2'">
            <el-button type="primary" @click="openDialog2()" size="small">添加部门</el-button>
            <div class="tableStyle">
                <el-table :data="tableData2" style="width: 50%" :stripe="true" size="mini"
                    header-cell-class-name="headerClassName" :cell-style="{ 'text-align': 'center' }" :border="true"
                    :fit="true">
                    <el-table-column prop="id" label="编号">
                        <template slot-scope="scope">
                            <span>{{ scope.$index + 1 }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="部门名称">
                    </el-table-column>
                    <el-table-column prop="storeId" label="所属门店">
                        <template slot-scope="scope">
                            <span>{{ scope.row.storeName }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <el-button @click="edit2(scope.row)" type="text" size="small">编辑</el-button>
                            <el-button @click="del2(scope.row)" type="text" size="small">刪除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="prev, pager, next" @current-change="changePage2"
                    :current-page.sync="table2.page" :page-size="table2.pageSize" :total="table2.total">
                </el-pagination>
            </div>
        </div>
        <OpenAddStoreDialog ref="addStoreModal" @child-event1="upageRow1" />
        <OpenAddDepartmentDialog ref="addDepartMentModal" @child-event2="upageRow2" />
    </div>
</template>
<script>
import OpenAddStoreDialog from '../storeSettingModal/OpenAddStoreDialog.vue'
import OpenAddDepartmentDialog from '../storeSettingModal/OpenAddDepartmentDialog.vue'
import { getStoreList, delStore, getDepartmentList, delDepartment } from '@/api/systemOption/storeSettings/index.js'
import { getImage } from '@/api/memberManage/index'
export default {
    data() {
        return {
            store_defaultIndex: 'store_tag1',
            store_activeIndex: 'store_tag1',
            tableData1: [],
            tableData2: [],
            table1: {
                page: 1,
                pageSize: 10,
                total: 0
            },
            table2: {
                page: 1,
                pageSize: 10,
                total: 0
            }
        }
    },
    mounted() {
        this.getData1()
    },
    methods: {
        store_handleSelect(key, keyPath) {
            this.store_activeIndex = key
            switch (key) {
                case 'store_tag1':
                    this.getData1()
                    break;
                case 'store_tag2':
                    this.getData2()
                    break;
                default:
                    break;
            }
        },
        openDialog1() {
            this.$refs.addStoreModal.openDialog()
        },
        openDialog2() {
            this.$refs.addDepartMentModal.openDialog()
        },
        getData1() {
            getStoreList({ page: this.table1.page, pageSize: this.table1.pageSize }).then(res => {
                if (res.state === '200') {
                    this.tableData1 = res.data.records
                    this.table1.total = res.data.total
                }
            })
        },
        arrayBufferToBase64(buffer) {
            var binary = ''
            var bytes = new Uint8Array(buffer)
            var len = bytes.byteLength
            for (var i = 0; i < len; i++) {
                binary += String.fromCharCode(bytes[i])
            }
            return window.btoa(binary)
        },
        getData2() {
            getDepartmentList({ page: this.table2.page, pageSize: this.table2.pageSize }).then(res => {
                if (res.state === '200') {
                    this.tableData2 = res.data.records
                    this.table2.total = res.data.total
                }
            })
        },
        edit1(row) {
            if (!row.logo) {
                row.logo = ''
            }
            this.$refs.addStoreModal.openDialog(row)
        },
        edit2(row) {
            this.$refs.addDepartMentModal.openDialog(row)
        },
        del1(row) {
            this.delMsg(this).then(() => {
                delStore({ id: row.id }).then(res => {
                    if (res.state === '200') {
                        this.$message({
                            message: '删除成功！',
                            type: 'success'
                        });
                        if ((this.tableData1.length - 1) == 0 && this.table1.page > 1) {
                            this.table1.page--
                        }
                        this.getData1()
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'warn',
                    message: '删除失败！'
                });
            });
        },
        del2(row) {
            this.delMsg(this).then(() => {
                delDepartment({ id: row.id }).then(res => {
                    if (res.state === '200') {
                        this.$message({
                            message: '删除成功！',
                            type: 'success'
                        });
                        if ((this.tableData2.length - 1) == 0 && this.table2.page > 1) {
                            this.table2.page--
                        }
                        this.getData2()
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'warn',
                    message: '删除失败！'
                });
            });
        },
        changePage1(e) {
            this.table1.page = e
            this.getData1()
        },
        changePage2(e) {
            this.table2.page = e
            this.getData2()
        },
        upageRow1() {
            this.getData1()
        },
        upageRow2() {
            this.getData2()
        },
    },
    components: {
        OpenAddStoreDialog,
        OpenAddDepartmentDialog
    }
}
</script>
<style scoped>
.tableStyle2 {
    margin-top: .125rem;
}

.storeSettingContainer {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.storeSettingMain {
    background-color: #fff;
    width: 100%;
    padding: .125rem .25rem;
    flex: 1;
    margin-top: .25rem;
}

/*以下是关于弹出框的样式*/

.dialog-footer {
    text-align: center;
}
</style>