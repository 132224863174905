<template>
    <div class="pc_container">
        <el-menu :default-active="pc_defaultIndex" class="el-menu-demo" mode="horizontal" @select="pc_handleSelect">
            <el-menu-item index="pc_tag1">套餐消费</el-menu-item>
        </el-menu>
        <div class="vs_main">
            <div class="search">
                <el-form :model="formQuery" size="small" :inline="true" class="form">
                    <el-form-item label="手机号:">
                        <el-input ref="teleRef" placeholder="输入手机号查询" v-model="formQuery.telephone"></el-input>
                    </el-form-item>
                    <el-form-item label="所属门店:">
                        <el-select v-model="formQuery.store" placeholder="请选择门店" @change="storeChange">
                            <el-option v-for="(item, index) in StoreList" :key="item.id" :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
                <el-button type="primary" size="small" @click="getProject">搜索</el-button>
            </div>
            <div class="des">
                <el-descriptions class="margin-top" :column="3" size="mini" border :contentStyle="content_style"
                    :label_style="label_style">
                    <template slot="title">
                        <span class="title">会员基本信息</span>
                    </template>
                    <el-descriptions-item label="手机号" :span="1">
                        <el-input class="dg" size="small" v-model="userData.telephone" placeholder="用户昵称XXX"
                            disabled></el-input>
                    </el-descriptions-item>
                    <el-descriptions-item label="会员卡级别" :span="1">
                        <span class="dg">{{ userData.vipLevelName ? userData.vipLevelName : '普通用户' }}</span>
                    </el-descriptions-item>
                    <el-descriptions-item label="客户自费" :span="1">
                        <CommonValueStyle :value="userData.selfBalance ? userData.selfBalance : 0" dw="元" />
                    </el-descriptions-item>
                    <el-descriptions-item label="姓名" :span="1">
                        <el-input size="small" v-model="userData.name" disabled></el-input>
                    </el-descriptions-item>
                    <el-descriptions-item label="车牌号码" :span="1">
                        <template slot="label">
                            <span style="color: red;">*</span>车牌号码
                        </template>
                        <el-select class="dg" placeholder="请选择" v-model="carNum" size="small" @change="carChange">
                            <el-option v-for="(item, index) in userData.carTypeList" :key="index" :label="item.carNum"
                                :value="item.carNum"></el-option>
                        </el-select>
                    </el-descriptions-item>
                    <el-descriptions-item label="销售赠送" :span="1">
                        <CommonValueStyle :value="userData.giftsBalance ? userData.giftsBalance : 0" dw="元" />
                    </el-descriptions-item>
                    <el-descriptions-item label="身份证号" :span="1">
                        <el-input class="dg" size="small" v-model="userData.idCard" disabled></el-input>
                    </el-descriptions-item>
                    <el-descriptions-item label="车型" :span="1">
                        <el-input class="dg" size="small" v-model="model" disabled></el-input>
                    </el-descriptions-item>

                    <el-descriptions-item label="可用积分" :span="1">
                        <CommonValueStyle :value="userData.credit ? userData.credit : 0" dw="分" />
                    </el-descriptions-item>
                    <el-descriptions-item label="介绍人" :span="1">
                        <span class="dg">{{ userData.sellerId }}</span>
                    </el-descriptions-item>
                    <el-descriptions-item label="发卡门店" :span="2">
                        <span class="dg">{{ userData.storeDO && userData.storeDO.name ? userData.storeDO.name : '' }}</span>
                    </el-descriptions-item>
                    <el-descriptions-item label="套餐名称" :span="1">
                        <el-select class="dg" placeholder="请选择" v-model="packageId" size="small" @change="packageChange">
                            <el-option v-for="(item, index) in userData.membershipPackageDOList" :key="index"
                                :label="item.serviceItemName" :value="item.id"></el-option>
                        </el-select>
                    </el-descriptions-item>
                    <el-descriptions-item label="备注" :span="2">
                        <el-input class="dg" size="small" v-model="userData.remarks"></el-input>
                    </el-descriptions-item>
                </el-descriptions>
            </div>
            <el-divider style="color: rgb(79,79,79);" content-position="left" class="po_title">套餐项目消费操作</el-divider>
            <div class="vs_footer">
                <div style="flex: 1;margin-right: 20px;">
                    <el-divider style="color: rgb(79,79,79);" content-position="left" class="po_title">套餐所有项</el-divider>
                    <div class="tableStyle">
                        <el-table :data="projectData" :stripe="true" size="mini" header-cell-class-name="headerClassName"
                            :cell-style="{ 'text-align': 'center' }" :border="true" :fit="true">
                            <!-- <el-table-column prop="id" label="序号">
                            </el-table-column> -->
                            <el-table-column prop="packageName" label="套餐名称">
                            </el-table-column>
                            <el-table-column prop="itemName" label="服务项目">
                            </el-table-column>
                            <el-table-column prop="num" label="可用次数">
                            </el-table-column>
                            <el-table-column prop="itemPrice" label="项目单价">
                            </el-table-column>
                            <el-table-column prop="term" label="有效期">
                            </el-table-column>
                            <el-table-column label="操作">
                                <template slot-scope="scope">
                                    <el-button type="text" size="small" @click="addlistNum(scope.row)"
                                        :disabled="isDj">添加</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <div style="flex: 1;">
                    <el-divider style="color: rgb(79,79,79);" content-position="left" class="po_title">套餐待消费项</el-divider>
                    <div class="tableStyle">
                        <el-table :data="addlist" :stripe="true" size="mini" header-cell-class-name="headerClassName"
                            :cell-style="{ 'text-align': 'center' }" :border="true" :fit="true">
                            <!-- <el-table-column prop="id" label="序号">
                            </el-table-column> -->
                            <el-table-column prop="projectName" label="项目名称">
                            </el-table-column>
                            <el-table-column prop="packageName" label="套餐名称">
                            </el-table-column>
                            <el-table-column prop="term" label="有效期">
                            </el-table-column>
                            <el-table-column prop="conFreq" label="消费次数">
                            </el-table-column>
                            <el-table-column prop="surplusFreq" label="剩余次数">
                            </el-table-column>
                            <!-- <el-table-column prop="remark" label="备注">
                            </el-table-column> -->
                        </el-table>
                    </div>
                </div>
            </div>
            <div class="btn">
                <el-button type="primary" @click="save" size="small" :disabled="isDj">保存</el-button>
            </div>
        </div>
        <SafeAuth ref="safeAuthRef"></safeAuth>
    </div>
</template>
<script>
import { addCrossStoreConsumptionPackageRecord } from '../../../api/memberConsume/index'
import * as memberApi from '@/api/memberManage/index'
import { getSetMealCons, addSetMealCons, getPackageList } from '@/api/memberConsume/index'
import CommonValueStyle from '../../components/CommonValueStyle.vue'
import SafeAuth from '../memberConsumModal/safeAuth.vue'
import axios from 'axios'
export default {
    components: {
        CommonValueStyle,
        SafeAuth
    },
    data() {
        return {
            pc_defaultIndex: 'pc_tag1',
            pc_activeIndex: 'pc_tag1',
            content_style: {
                'width': '20%',
            },
            label_style: {
                'width': '1.5rem',
            },
            formQuery: {
                telephone: '',
                store: ''
            },
            userData: {
                telephone: ''
            },
            addlist: [],
            carNum: '',
            model: '',
            carEngineNum: '',
            packageId: '',
            projectData: [],
            StoreList: [],
            isCurrentStore: '',
            baseUrl: '',
            isDj: false
        }
    },
    mounted() {
        this.getSoreList()
        this.$refs.teleRef.focus()
    },
    methods: {
        isTokenGq() {
            sessionStorage.removeItem(this.formQuery.store)
            this.$message.error('token已过期，请重新登录认证')
            this.openSafeAuthDialog()
        },
        getSoreList() {
            memberApi.storeGetList({}).then(res => {
                let { data } = res
                this.StoreList = data
                data.forEach(item => {
                    if (item.isStore) {
                        this.formQuery.store = item.id
                        this.isCurrentStore = true
                    }
                })
            })
        },
        storeChange(e) {
            const nowStore = this.StoreList.find(item => item.id == this.formQuery.store)
            if (nowStore && !nowStore.isStore) {
                this.baseUrl = nowStore.baseUrl
                this.isCurrentStore = false
                let kdToken = sessionStorage.getItem(e)
                if (!kdToken || kdToken == '') {
                    this.openSafeAuthDialog()
                }
            } else if (nowStore && nowStore.isStore) {
                this.isCurrentStore = true
            }

        },
        openSafeAuthDialog() {
            this.$refs.safeAuthRef.openDialog({ baseUrl: this.baseUrl, storeId: this.formQuery.store })
        },
        getToken() {
            return sessionStorage.getItem(this.formQuery.store)
        },
        pc_handleSelect(key, keyPath) {
            this.pc_activeIndex = key
        },
        getProject() {
            if (!this.isCurrentStore) {
                const token = this.getToken()
                if (token && token != '') {
                    const url = this.baseUrl + 'user/package/getSetMealCons'
                    axios.post(url, this.formQuery, {
                        headers: {
                            "AuthorizationToken": token
                        }
                    }).then(response => {
                        let res = response.data
                        if (res.state == '200') {
                            if (res.data == null || res.data.length == 0) {
                                this.isDj = true
                                this.$message.info('该会员不存在')
                            } else {
                                this.userData = res.data[0]
                                this.addlist = []
                                this.carNum = ''
                                if (res.data.actived == 4) {
                                    this.$message.info('该会员已冻结')
                                    this.isDj = true
                                }
                            }

                        } else if (res.state == '20002') {
                            this.$message.error(res.error)
                        } else if (res.state == '20001') {
                            this.isTokenGq()
                        }
                    })
                } else {
                    this.openSafeAuthDialog()
                }
            } else {
                getSetMealCons(this.formQuery).then(res => {
                    let { data, state } = res
                    if (state == '200') {
                        if (res.data == null || res.data.length == 0) {
                            this.isDj = true
                            this.$message.info('该会员不存在')
                        } else {
                            this.userData = data[0]
                            this.addlist = []
                            this.carNum = ''
                            if (res.data.actived == 4) {
                                this.$message.info('该会员已冻结')
                                this.isDj = true
                            }
                        }

                    }
                })
            }
        },
        unique(arr) {
            return Array.from(new Set(arr))
        },
        packageChange(e) {
            if (!this.isCurrentStore) {
                const token = this.getToken()
                if (token && token != '') {
                    const url = this.baseUrl + 'user/package/getPackageList'
                    axios.post(url, { id: this.packageId }, {
                        headers: {
                            "AuthorizationToken": token
                        }
                    }).then(response => {
                        let res = response.data
                        if (res.state == '200') {
                            this.projectData = res.data
                        } else if (res.state == '20002') {
                            this.$message.error(res.error)
                        } else if (res.state == '20001') {
                            this.isTokenGq()
                        }
                    })
                } else {
                    this.openSafeAuthDialog()
                }
            } else {
                getPackageList({ id: this.packageId }).then(res => {
                    this.projectData = res.data
                })
            }

        },
        addlistNum(e) {
            if (e.num == 0) {
                this.$message.error('可用次数不足')
                return
            }
            const item = this.addlist.find(item => item.id == e.id)
            if (item) {
                this.$message.error('该套餐已经添加过了~')
            } else {
                this.addlist.push(e)
                var list = []
                list = this.unique(this.addlist)
                list.forEach(item => {
                    if (item.id == e.id) {
                        item.conFreq = 1
                        item.surplusFreq = e.num - 1
                        item.projectName = e.itemName
                        item.packageName = e.packageName
                    }
                })

                this.projectData.forEach(items => {
                    if (items.id == e.id) {
                        items.num--
                    }
                })
                this.addlist = [...list]
            }
            // if (this.addlist.length == 0) {
            //     this.addlist.push(e)
            // } else {
            //     this.addlist.forEach(item => {
            //         if (item.id !== e.id) {
            //             this.addlist.push(e)
            //         } else {
            //             this.$message.error('该套餐已经添加过了~')
            //         }
            //     })
            // }


        },
        carChange(e) {
            if (!this.isCurrentStore) {
                const token = this.getToken()
                if (token && token != '') {
                    const url = this.baseUrl + 'user/package/getSetMealCons'
                    axios.post(url, { ...this.formQuery, carNum: this.carNum }, {
                        headers: {
                            "AuthorizationToken": token
                        }
                    }).then(response => {
                        let res = response.data
                        if (res.state == '200') {
                            this.userData = res.data[0]
                            this.addlist = []
                            this.packageId = ''
                            this.projectData = []
                        } else if (res.state == '20002') {
                            this.$message.error(res.error)
                        } else if (res.state == '20001') {
                            this.isTokenGq()
                        }
                    })
                } else {
                    this.openSafeAuthDialog()
                }
            } else {
                getSetMealCons({ ...this.formQuery, carNum: this.carNum }).then(res => {
                    let { data, state } = res
                    if (state == '200') {
                        this.userData = data[0]
                        this.addlist = []
                        // this.carNum = '' 
                    }
                })
            }
            // getSetMealCons({ ...this.formQuery, carNum: this.carNum }).then(res => {
            //     let { data, state } = res
            //     if (state == '200') {
            //         this.userData = data[0]
            //         this.addlist = []
            //         this.packageId = ''
            //         this.projectData = []
            //     }
            // })
            this.userData.carTypeList.forEach(item => {
                if (item.carNum == e) {
                    this.carNum = item.carNum
                    this.carFrameNumber = item.carFrameNumber
                    this.model = item.model

                }
            })
        },
        save() {
            if (this.carNum == '') {
                this.$message.error('请先选择车牌号码')
                return
            }
            this.userData.list = this.addlist
            this.userData.carFrameNumber = this.carFrameNumber
            this.userData.carNum = this.carNum
            // this.userData.conFreq = this.consFreq

            if (!this.isCurrentStore) {
                const token = this.getToken()
                if (token && token != '') {
                    const url = this.baseUrl + 'user/package/addSetMealCons'
                    axios.post(url, this.userData, {
                        headers: {
                            "AuthorizationToken": token
                        }
                    }).then(response => {
                        console.log('response:', response);
                        let res = response.data
                        if (res.state == '200') {
                            let params = this.addlist.map(item => {
                                return {
                                    telephone: this.userData.telephone || '', //手机号
                                    vipLevelName: this.userData.vipLevelName || '普通用户', //会员卡名称
                                    vipName: this.userData.name || '', //会员姓名
                                    carNum: this.carNum || '', //车牌号
                                    carFrameNumber: this.carFrameNumber || '', //车架号
                                    hairCard: this.userData.storeDO.name || '', //发卡店
                                    itemName: item.projectName, //项目名称
                                    packageName: item.packageName,//套餐名称
                                    itemPrice: item.itemPrice,//项目单价
                                    termTime: item.term, //有效期
                                    consumptionNum: item.conFreq, //消费次数
                                    residueNum: item.surplusFreq, //剩余次数
                                    remarks: this.userData.remarks || ''
                                }
                            })
                            addCrossStoreConsumptionPackageRecord(params).then(res => {
                                if (res.state == '200') {
                                    console.log('套餐消费跨店消费记录添加成功');
                                    this.$message.success('保存成功')
                                    this.addlist = []
                                }
                            })

                        } else if (res.state == '20002') {
                            this.$message.error(res.error)
                        } else if (res.state == '20001') {
                            this.isTokenGq()
                        }
                    })
                } else {
                    this.openSafeAuthDialog()
                }
            } else {
                addSetMealCons(this.userData).then(res => {
                    if (res.state == '200') {
                        this.$message.success('保存成功')
                        this.addlist = []
                    }
                })
            }
        }
    },

}
</script>
<style scoped>
::v-deep .el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
    margin-bottom: 0rem !important;
}

::v-deep .el-descriptions__header {
    margin-bottom: 0px !important;
}

.addYellow {
    color: rgb(244, 124, 0);
    font-weight: 600;
}

.addRed {
    color: red;
    font-weight: 600;
}

.addGreen {
    color: #3CB371;
    font-weight: 600;
}

.pc_container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.vs_main {
    background-color: #fff;
    width: 100%;
    padding: .125rem .25rem;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.search {
    width: 64%;
    padding: 0 0 .125rem 0;
    border-radius: .125rem;
    display: flex;
}

.vs_main {
    width: 80%;
}


.vs_main .el-divider__text {
    color: rgb(79, 79, 79);
    font-size: medium;
    font-weight: bold;
    left: 0;
}

.vs_footer {
    width: 100%;
    flex: 1;
    display: flex;
    justify-content: space-between;
}

.btn {
    width: 100%;
    text-align: center;
    padding: .25rem 0;
}

.tableStyle {
    padding: 0 0 .125rem 0;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.tableStyle .el-pagination {
    align-self: flex-start;
    margin: .125rem 0;
}

/*設置表头背景以及字体顏色*/
.headerClassName {
    font-weight: bold;
    color: black;
    background-color: rgb(226, 226, 226) !important;
    text-align: center !important;
}

.el-table__row--striped {
    background-color: rgb(242, 242, 242) !important;

}

.des .el-descriptions__header {
    margin-bottom: 0;
}
</style>