<template>
    <div class="pendingMembersContainer">
        <el-menu :default-active="pm_defaultIndex" class="el-menu-demo" mode="horizontal" @select="pm_handleSelect">
            <el-menu-item index="pm_tag1">待审会员</el-menu-item>
            <el-menu-item index="pm_tag2">完善资料</el-menu-item>
        </el-menu>
        <div class="pendingMain" v-if="pm_activeIndex === 'pm_tag1'">
            <el-form ref="form" :inline="true" :model="form" size="small">
                <el-form-item label="手机号：">
                    <el-input ref="teleRef" v-model="form.telephone" placeholder="手机号搜索"></el-input>
                </el-form-item>
                <el-form-item label="姓名：">
                    <el-input v-model="form.name" placeholder="姓名搜索"></el-input>
                </el-form-item>
                <el-form-item label="车牌号：">
                    <el-input v-model="form.carNum" placeholder="车牌号搜索"></el-input>
                </el-form-item>
                <!-- <el-form-item label="开卡店：">
                    <el-select placeholder="请选择" v-model="form.storeId">
                        <el-option v-for="(item, index) in StoreList" :key="index" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item> -->
                <el-form-item label="介绍人：">
                    <el-input v-model="form.sellerId" placeholder="介绍人搜索"></el-input>
                </el-form-item>
                <el-form-item label="会员级别：">
                    <el-select placeholder="请选择" v-model="form.vipLevelId">
                        <el-option v-for="(item, index) in vipLevelList" :key="index" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="注册开始时间：">
                    <el-date-picker type="date" placeholder="选择日期" v-model="form.createTime"></el-date-picker>
                </el-form-item>
                <el-form-item label="注册结束时间：">
                    <el-date-picker type="date" placeholder="选择日期" v-model="form.expireTime"></el-date-picker>
                </el-form-item>
                <el-form-item>
                    <div class="ma_btn">
                        <el-button type="primary" @click="getselectPendingReviewList" size="small">查询</el-button>
                        <el-button type="primary" @click="exporter" size="small" v-if="isExport == 1">导出数据</el-button>
                    </div>
                </el-form-item>
            </el-form>
            <div class="tableStyle">
                <el-table :data="pointsSettingsTableData" :stripe="true" size="mini"
                    header-cell-class-name="headerClassName" :cell-style="{ 'text-align': 'center' }" :border="true"
                    :fit="true">
                    <el-table-column prop="id" label="编号">
                        <template slot-scope="scope">
                            <span>{{ scope.$index + 1 }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="telephone" label="手机号">
                    </el-table-column>
                    <el-table-column prop="name" label="姓名">
                    </el-table-column>
                    <el-table-column prop="birthday" label="会员生日">
                    </el-table-column>
                    <el-table-column prop="carNum" label="车牌号">
                        <template slot-scope="scope">
                            <p v-for="(item, index) in scope.row.clientManageCarDOList" :key="index">{{ item.carNum }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column prop="storeName" label="开卡店">
                    </el-table-column>
                    <el-table-column prop="multiplierOfPoints" label="查看资料">
                        <template slot-scope="scope">
                            <p style="cursor: pointer;" @click="completeInfo(scope.row.telephone, 'onlyRead')">查看</p>
                        </template>
                    </el-table-column>
                    <el-table-column prop="multiplierOfPoints" label="状态">
                        <template slot-scope="scope">
                            <p v-if="scope.row.actived == 1">正常</p>
                            <p v-if="scope.row.actived == 2">待审核</p>
                            <p v-if="scope.row.actived == 3">驳回</p>
                            <p v-if="scope.row.actived == 4">冻结</p>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <el-button @click="audit(scope.row.id)" type="text" size="small"
                                v-if="scope.row.actived == 2">审核</el-button>
                            <el-button type="text" size="small" @click="delCar(scope.row.id)">刪除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="prev, pager, next" :page-size="5" :current-page.sync="form.page"
                    @current-change="getselectPendingReviewList" :total="total">
                </el-pagination>
            </div>
        </div>
        <div class="pendingMain" v-if="pm_activeIndex === 'pm_tag2'">
            <el-form ref="form" :model="form" size="small" :inline="true">
                <el-form-item label="手机号：">
                    <el-input ref="teleRef" v-model="form.telephone" placeholder="手机号搜索"></el-input>
                </el-form-item>
                <el-form-item label="姓名">
                    <el-input v-model="form.name" placeholder="姓名搜索"></el-input>
                </el-form-item>
                <el-form-item label="车牌号：">
                    <el-input v-model="form.carNum" placeholder="车牌号搜索"></el-input>
                </el-form-item>
                <!-- <el-form-item label="开卡店：">
                    <el-select placeholder="请选择" v-model="form.storeId" size="small">
                        <el-option v-for="(item, index) in StoreList" :key="index" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item> -->
                <el-form-item label="介绍人：">
                    <el-input v-model="form.sellerId" placeholder="介绍人搜索"></el-input>
                </el-form-item>
                <el-form-item label="会员级别：">
                    <el-select placeholder="请选择" v-model="form.vipLevelId" size="small">
                        <el-option v-for="(item, index) in vipLevelList" :key="index" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="注册开始时间：">
                    <el-date-picker type="date" placeholder="选择日期" size="small" v-model="form.createTime"></el-date-picker>
                </el-form-item>
                <el-form-item label="注册结束时间：">
                    <el-date-picker type="date" placeholder="选择日期" size="small" v-model="form.expireTime"></el-date-picker>
                </el-form-item>
                <el-form-item>
                    <div class="ma_btn">
                        <el-button type="primary" @click="getselectPendingReviewList" size="small">查询</el-button>
                        <el-button type="primary" @click="exports" size="small" v-if="isExport == 1">导出数据</el-button>
                    </div>
                </el-form-item>
            </el-form>
            <div class="tableStyle">
                <el-table :data="memberList" :stripe="true" size="mini" header-cell-class-name="headerClassName"
                    :cell-style="{ 'text-align': 'center' }" :border="true" :fit="true">
                    <el-table-column prop="id" label="编号">
                        <template slot-scope="scope">
                            <span>{{ scope.$index + 1 }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="telephone" label="手机号">
                    </el-table-column>
                    <el-table-column prop="name" label="姓名">
                    </el-table-column>
                    <el-table-column prop="birthday" label="会员生日">
                    </el-table-column>
                    <el-table-column prop="carNum" label="车牌号">
                        <template slot-scope="scope">
                            <p v-for="(item, index) in scope.row.clientManageCarDOList" :key="index">{{ item.carNum }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column prop="carFrameNumber" label="车架号码" width="200">
                        <template slot-scope="scope">
                            <p v-for="(item, index) in scope.row.clientManageCarDOList" :key="index">{{ item.carFrameNumber
                            }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column prop="carTypeName" label="车型">
                        <template slot-scope="scope">
                            <p v-for="(item, index) in scope.row.clientManageCarDOList" :key="index">{{ item.model }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column prop="storeName" label="所属门店">
                    </el-table-column>
                    <!-- <el-table-column prop="state" label="状态">
                        <template slot-scope="scope">
                            <p v-if="scope.row.state == 0">待完善资料</p>
                            <p v-if="scope.row.state == 1">已完善资料</p>
                        </template>
                    </el-table-column> -->
                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <el-button @click="completeInfo(scope.row.telephone, 'ws')" type="text"
                                size="small">完善资料</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="prev, pager, next" :page-size="5" :current-page.sync="form.page"
                    @current-change="getmemberListToBeImproved" :total="total1">
                </el-pagination>
            </div>
        </div>
        <AuditModal ref="auditModal" @child-event="handleChildEvent" />
    </div>
</template>
<script>
import { createLogger } from 'vuex'
import AuditModal from '../memberManageModal/AuditModal.vue'
import * as memberApi from '@/api/memberManage/index'
export default {
    data() {
        return {
            pm_defaultIndex: 'pm_tag1',
            pm_activeIndex: 'pm_tag1',
            addAdminMessage: '',
            form: {
                page: 1,
                pageSize: 5
            },
            pointsSettingsTableData: [],
            vipLevelList: [],
            StoreList: [],
            total: 0,
            memberList: [],
            total1: 0,
            isExport: 0
        }
    },
    created() {
        this.getCommn()
        this.getselectPendingReviewList()
        if (this.$route.query.type) {
            this.pm_defaultIndex = 'pm_tag2'
            this.pm_activeIndex = 'pm_tag2'
            this.getmemberListToBeImproved()
        }
    },
    mounted() {
        this.$refs.teleRef.focus()
        this.isExport = sessionStorage.getItem('isExport')
    },
    methods: {
        pm_handleSelect(key, keyPath) {

            this.form = {
                page: 1,
                pageSize: 5
            }
            if (key == 'pm_tag1') {
                this.getselectPendingReviewList()
            } else {
                this.getmemberListToBeImproved()
            }
            this.pm_activeIndex = key
            this.$nextTick(() => {
                this.$refs.teleRef.focus()
            })
        },
        handleChildEvent() {
            this.getselectPendingReviewList()
        },
        completeInfo(row, type) {
            this.$router.push({ path: '/memberManage/memberInfo?telephone=' + row + '&type=' + type })
        },
        audit(row) {
            this.$refs.auditModal.openDialog(row)
        },
        getCommn() {
            memberApi.clientVipLevelGetList({}).then(res => {
                let { data } = res
                this.vipLevelList = data
            })
            memberApi.storeGetList({}).then(res => {
                let { data } = res
                this.StoreList = data
            })
        },
        getselectPendingReviewList() {
            memberApi.selectPendingReviewList(this.form).then(res => {
                console.log(222);
                let { data } = res
                this.pointsSettingsTableData = data.records
                this.total = data.total
            })
        },
        getmemberListToBeImproved() {
            memberApi.memberListToBeImproved(this.form).then(res => {
                let { data } = res
                this.memberList = data.records
                this.total1 = data.total
            })
        },
        exporter() {
            memberApi.exportSelectPendingReviewList(this.form).then(res => {
                let blob = new Blob([res], {
                    // type: 'application/actet-stream;charset=utf-8'
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                }); // 构造一个blob对象来处理数据，并设置文件类型
                let a = document.createElement('a'); // 生成一个a标签
                let href = window.URL.createObjectURL(blob); //创建下载的链接
                a.href = href; //指定下载链接
                a.download = '待审会员列表' //指定下载文件名
                // a.setAttribute('download', 'fileName')
                document.body.appendChild(a); //把a标签加到页面中
                a.click(); //触发a标签下载
                document.body.removeChild(a); //下载完成移除元素
                window.URL.revokeObjectURL(href); //释放掉blob对象
            })
        },
        exports() {
            memberApi.exportMemberListToBeImproved(this.form).then(res => {
                let blob = new Blob([res], {
                    // type: 'application/actet-stream;charset=utf-8'
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                }); // 构造一个blob对象来处理数据，并设置文件类型
                let a = document.createElement('a'); // 生成一个a标签
                let href = window.URL.createObjectURL(blob); //创建下载的链接
                a.href = href; //指定下载链接
                a.download = '待完善会员列表' //指定下载文件名
                // a.setAttribute('download', 'fileName')
                document.body.appendChild(a); //把a标签加到页面中
                a.click(); //触发a标签下载
                document.body.removeChild(a); //下载完成移除元素
                window.URL.revokeObjectURL(href); //释放掉blob对象
            })
        },
        delCar(e) {
            this.$confirm('确定删除此会员吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                memberApi.removeClient({ id: e }).then(res => {
                    console.log('res=', res);
                    if (res.state == '200') {
                        this.getselectPendingReviewList()
                        this.$message.success('删除成功')
                    }
                })
            }).catch(() => {

            });
        },

    },
    components: {
        AuditModal
    }
}
</script>
<style scoped>
.pendingMembersContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
    width: auto;
}

::v-deep .el-input--small .el-input__inner {
    width: 2.3rem;
}

::v-deep .el-select>.el-input .el-input__inner {
    width: 2.3rem;
}

.ma_btn .el-button {
    margin-right: 5px;
}

.pendingMain {
    width: 100%;
    flex: 1;
    padding: .25rem;

}
</style>