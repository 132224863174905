<template>
    <div class="memberManageFlex">
        <div class="memberManageTitle">
            <span>会员管理</span>
        </div>
        <div class="memberManageMenu">
            <ul>
                <!-- {{ menuList }} -->
                <li v-for="(item, index) in menuList" :key="index" :class="selected === index ? 'active' : ''"
                    @click="go(item.path, index)">
                    <span v-if="item.menuName != '车辆删除'"> {{ item.menuName }}</span>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
import { memberManageList } from '../contants/constants'
export default {
    data() {
        return {
            menuList: [],
            AllMenulist: [],
            selected: 0
        }
    },
    computed: {
        menu() {
            return this.$store.state.user.roles
        }
    },
    watch: {
        menu(newVal) {
            console.log(newVal)
            // sessionStorage.setItem('menu',JSON.stringify(newVal))
        }
    },
    created() {
        this.getMenuList()
    },
    methods: {
        getMenuList() {
            if (memberManageList) {
                let menus = JSON.parse(sessionStorage.getItem('menu'))
                this.menuList = menus.find((item, index) => {
                    // 获取本页面路由
                    const currentRoute = this.$route.path.split('/')[1]
                    return item.path === ('/' + currentRoute)
                }).children
                console.log(this.menuList)
                const currentPath = this.$route.path
                if (sessionStorage.getItem('memberManageSelectedIndex')) {
                    const index = sessionStorage.getItem('memberManageSelectedIndex')
                    this.selected = parseInt(index)
                } else {
                    this.menuList.forEach((item, index) => {
                        if (item.path === currentPath) {
                            this.selected = index
                        }
                    })
                }

            }
        },
        go(e, index) {
            this.selected = index
            sessionStorage.setItem('memberManageSelectedIndex', index)
            this.$router.push(e)
        },
    },
    mounted() {
        // this.getMenuList()

    },
}
</script>
<style scoped>
.memberManageFlex {
    display: flex;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    margin-right: .125rem;
    /* height: 100%; */
    min-height: 100%;
    height: auto;
}

.memberManageTitle {
    height: 1.125rem;
    width: 100%;
    text-align: center;
    line-height: 1.125rem;
    font-size: .225rem !important;
    font-weight: bold;
}


.memberManageMenu {
    width: 1.5rem;
    text-align: center;
    /* font-size: small; */
    display: block;
    line-height: 100%;

}

.memberManageMenu li {
    line-height: .375rem;
    border-top: 1px solid rgb(212, 212, 212);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.memberManageMenu li:hover {
    color: rgb(82, 198, 255);
    cursor: pointer;
}

.memberManageMenu li.active {
    color: rgb(82, 198, 255) !important;
}
</style>