import request from '@/utils/request'

// 查询车辆保险列表
export function selectInsuranceList(data) {
    return request({
        url: '/member/clientCar/selectInsuranceList',
        method: 'post',
        data: data
    })
}

// 导出车辆保险列表
export function exportInsuranceList(data) {
    return request({
        url: '/member/clientCar/exportInsuranceList',
        method: 'post',
        data: data,
        responseType: 'blob'
    })
}

// 查询会员列表
export function selectList(data) {
    return request({
        url: '/member/clientManage/selectList',
        method: 'post',
        data: data
    })
}
// 导出会员列表
export function exportClientList(data) {
    return request({
        url: '/member/clientManage/exportClientList',
        method: 'post',
        data: data,
        responseType: 'blob'
    })
}

// 导出会员冻结
export function exportFreezeList(data) {
    return request({
        url: '/member/clientManage/exportFreezeList',
        method: 'post',
        data: data,
        responseType: 'blob'
    })
}

// 会员个人信息（过户、升级）
export function selectById(data) {
    return request({
        url: '/member/clientManage/selectById',
        method: 'post',
        data: data
    })
}
// 会员管理客户档案保存按钮
export function updateAttribute(data) {
    return request({
        url: '/member/clientManage/updateAttribute',
        method: 'post',
        data: data
    })
}
// 会员档案详细信息
export function getDetails(data) {
    return request({
        url: '/member/clientManage/details',
        method: 'post',
        data: data
    })
}
// 获取客户属性列表
export function attributeGetList(data) {
    return request({
        url: '/sys/attribute/getList',
        method: 'post',
        data: data
    })
}
// 获取会员级别列表
export function clientVipLevelGetList(data) {
    return request({
        url: '/sys/clientVipLevel/getList',
        method: 'post',
        data: data
    })
}
// 获取车辆类型下拉框列表
export function CarTypeGetList(data) {
    return request({
        url: '/sys/CarType/getList',
        method: 'post',
        data: data
    })
}
// 获取门店列表
export function storeGetList(data) {
    return request({
        url: '/sys/store/getList',
        method: 'post',
        data: data
    })
}
// 会员资产页面个人信息
export function selectAssetDetailById(data) {
    return request({
        url: '/member/clientManage/selectAssetDetailById',
        method: 'post',
        data: data
    })
}
// 会员资产退款
export function selfRefund(data) {
    return request({
        url: '/member/clientManage/selfRefund',
        method: 'post',
        data: data
    })
}
// 车辆信息
export function CarselectList(data) {
    return request({
        url: '/member/clientCar/selectList',
        method: 'post',
        data: data
    })
}
// 添加车辆信息
export function savaCar(data) {
    return request({
        url: '/member/clientCar/savaCar',
        method: 'post',
        data: data
    })
}
// 修改车辆信息
export function updateCar(data) {
    return request({
        url: '/member/clientCar/updateCar',
        method: 'post',
        data: data
    })
}
// 删除车辆信息
export function removeCarById(data) {
    return request({
        url: '/member/clientCar/removeById',
        method: 'post',
        data: data
    })
}
// 根据车辆信息id查找
export function CarselectById(data) {
    return request({
        url: '/member/clientCar/selectById',
        method: 'post',
        data: data
    })
}
// 待审会员列表
export function selectPendingReviewList(data) {
    return request({
        url: '/member/clientManage/selectPendingReviewList',
        method: 'post',
        data: data
    })
}
// 客户标签下拉列表
export function TaggetList(data) {
    return request({
        url: '/sys/tag/getList',
        method: 'post',
        data: data
    })
}
//会员审核详情
export function memberReviewDetails(data) {
    return request({
        url: '/member/clientManage/memberReviewDetails',
        method: 'post',
        data: data
    })
}
//会员审核
export function memberReview(data) {
    return request({
        url: '/member/clientManage/memberReview',
        method: 'post',
        data: data
    })
}
//待完善会员列表
export function memberListToBeImproved(data) {
    return request({
        url: '/member/clientManage/memberListToBeImproved',
        method: 'post',
        data: data
    })
}
//待完善会员保存
export function updateImproveInformation(data) {
    return request({
        url: '/member/clientManage/updateImproveInformation',
        method: 'post',
        data: data
    })
}
// 待审会员导出
export function exportSelectPendingReviewList(data) {
    return request({
        url: '/member/clientManage/exportSelectPendingReviewList',
        method: 'post',
        data: data,
        responseType: 'blob'
    })
}
// 待完善会员列表导出
export function exportMemberListToBeImproved(data) {
    return request({
        url: '/member/clientManage/exportMemberListToBeImproved',
        method: 'post',
        data: data,
        responseType: 'blob'
    })
}
// 待审核会员列表删除
export function removeClient(data) {
    return request({
        url: '/member/clientManage/removeClient',
        method: 'post',
        data: data,
        // responseType: 'blob'
    })
}
// 会员过户详请
export function transferDetails(data) {
    return request({
        url: '/member/membershipTransfer/transferDetails',
        method: 'post',
        data: data
    })
}
// 城市列表
export function cityList(data) {
    return request({
        url: '/sys/city/cityList',
        method: 'post',
        data: data
    })
}
// 会员升级（查询）
export function getVipUpgrade(data) {
    return request({
        url: '/vip/save/getVipUpgrade',
        method: 'post',
        data: data
    })
}
// 会员冻结列表
export function membershipFreezeList(data) {
    return request({
        url: '/member/clientManage/membershipFreezeList',
        method: 'post',
        data: data
    })
}
//会员冻结会员卡状态操作
export function membershipFreezeStatus(data) {
    return request({
        url: '/member/clientManage/membershipFreezeStatus',
        method: 'post',
        data: data
    })
}
//会员升级查询页-保存按钮
export function addVipClient(data) {
    return request({
        url: '/vip/save/addVipClient',
        method: 'post',
        data: data
    })
}
//会员升级-升级记录
export function getVipClientList(data) {
    return request({
        url: '/vip/save/getVipClientList',
        method: 'post',
        data: data
    })
}
// 获取颜色
export function getColor(data) {
    return request({
        url: '/sys/carColor/getList',
        method: 'post',
        data: data
    })
}
// 获取产地
export function getProduction(data) {
    return request({
        url: '/sys/carProduction/getList',
        method: 'post',
        data: data
    })
}
// 获取用途
export function getPurpose(data) {
    return request({
        url: '/sys/carPurpose/getList',
        method: 'post',
        data: data
    })
}

// 获取系列车型
export function getCarInfo(data) {
    return request({
        url: '/sys/CarType/getList',
        method: 'post',
        data: data
    })
}
// 获取会员过户记录
export function getTransferList(data) {
    return request({
        url: '/member/membershipTransfer/transfer/list',
        method: 'post',
        data: data
    })
}

// 生成二维码
export function getQrCode(data) {
    return request({
        url: '/sys/imageUpload/getQrCode',
        method: 'post',
        data: data,
        upload: true
    })
}
//生成二维码文件流
export function getImage(data) {
    return request({
        url: '/sys/imageUpload/getImage',
        method: 'post',
        data: data,
        upload: true,
        responseType: 'arraybuffer'
    })
}
//生成过户二维码文件流
export function getQrCodeTemporary(data) {
    return request({
        url: '/sys/imageUpload/getQrCodeTemporary',
        method: 'post',
        data: data,
        upload: true,
        responseType: 'arraybuffer'
    })
}

//#region  scrm
// 标签列表
export function getTagList(data) {
    return request({
        url: '/scrm/getTagList',
        method: 'post',
        data: data
    })
}
// 提醒发送
export function remindMassSend(data) {
    return request({
        url: '/scrm/remindMassSend',
        method: 'post',
        data: data
    })
}
// 获取员工列表
export function getStaffList(data) {
    return request({
        url: '/scrm/getStaffList',
        method: 'post',
        data: data
    })
}
// 创建群发
export function createMassSend(data) {
    return request({
        url: '/scrm/createMassSend',
        method: 'post',
        data: data
    })
}
// 群发列表
export function getMassSendingList(data) {
    return request({
        url: '/scrm/getMassSendingList',
        method: 'post',
        data: data
    })
}
// 已送达 未送达
export function deliveryStatus(data) {
    return request({
        url: '/scrm/deliveryStatus',
        method: 'post',
        data: data
    })
}
// 删除
export function deleteMassSend(data) {
    return request({
        url: '/scrm/deleteMassSend',
        method: 'post',
        data: data
    })
}
// 预计发送情况
export function expectMassSendStatus(data) {
    return request({
        url: '/scrm/expectMassSendStatus',
        method: 'post',
        data: data
    })
}
// 取消发送
export function cancelMassSend(data) {
    return request({
        url: '/scrm/cancelMassSend',
        method: 'get',
        data: data
    })
}
// 员工发送情况
export function staffSendStatus(data) {
    return request({
        url: '/scrm/staffSendStatus',
        method: 'post',
        data: data
    })
}
// 根据id获取群发信息
export function getMassSendDetail(data) {
    return request({
        url: '/scrm/getMassSendDetail',
        method: 'get',
        data: data
    })
}
// 根据id获取标签名
export function getTagListNoPage(data) {
    return request({
        url: '/scrm/getTagListNoPage',
        method: 'get',
        data: data
    })
}

// 获取图片以及分组
export function getImagesList(data) {
    return request({
        url: '/scrm/getImagesList',
        method: 'get',
        data: data
    })
}

// 获取waid caid
export function getWaidAndCaid(data) {
    return request({
        url: '/scrm/getWaidAndCaid',
        method: 'get',
        data: data
    })
}

// 添加图片分组
export function addImagesGroup(data) {
    return request({
        url: '/scrm/addImagesGroup',
        method: 'post',
        data: data
    })
}




//#endregion