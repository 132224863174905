import request from '@/utils/request'
// 获取客户标签
export function synchronizationTag(data) {
    return request({
        url: '/sys/tag/synchronizationTag',
        method: 'post',
        data: data
    })
}
// #region 车辆品牌
export function saveCarBrand(data) {
    return request({
        url: '/sys/carBrand/saveCarBrand',
        method: 'post',
        data: data
    })
}
export function brandListBypage(data) {
    return request({
        url: '/sys/carBrand/selectList',
        method: 'post',
        data: data
    })
}
export function brandList(data) {
    return request({
        url: '/sys/carBrand/getList',
        method: 'post',
        data: data
    })
}
// 车辆系列
export function carSeriesByPage(data) {
    return request({
        url: '/sys/carSeries/selectList',
        method: 'post',
        data: data
    })
}
// 添加系列
export function saveCarSeries(data) {
    return request({
        url: '/sys/carSeries/saveCarSeries',
        method: 'post',
        data: data
    })
}
// 编辑系列
export function updateCarSeries(data) {
    return request({
        url: '/sys/carSeries/updateCarSeries',
        method: 'post',
        data: data
    })
}
// 删除系列
export function removeSeries(data) {
    return request({
        url: '/sys/carSeries/removeById',
        method: 'post',
        data: data
    })
}
// 系列下拉
export function carSeries(data) {
    return request({
        url: '/sys/carSeries/getList',
        method: 'post',
        data: data
    })
}
// 车型


// 获取车辆型号列表
export function getCarList(data) {
    return request({
        url: '/sys/CarType/getList',
        method: 'post',
        data: data
    })
}
export function delBrand(data) {
    return request({
        url: '/sys/carBrand/removeById',
        method: 'post',
        data: data
    })
}
export function updateCarBrand(data) {
    return request({
        url: '/sys/carBrand/updateCarBrand',
        method: 'post',
        data: data
    })
}
export function getCarSeriesList(data) {
    return request({
        url: '/sys/CarType/getCarSeriesList',
        method: 'post',
        data: data
    })
}
// 根据seriesId获取类型列表
export function getCarModelList(data) {
    return request({
        url: '/sys/CarType/getCarModelList',
        method: 'post',
        data: data
    })
}
export function getCarTypeDetail(data) {
    return request({
        url: '/sys/CarType/getCarTypeDetail',
        method: 'post',
        data: data
    })
}
//#endregion

// #region 会员级别
export function vipList(data) {
    return request({
        url: '/sys/clientVipLevel/selectList',
        method: 'post',
        data: data
    })
}
//会员卡启用按钮
export function changeStates(data) {
    return request({
        url: '/sys/clientVipLevel/updateStatus',
        method: 'post',
        data: data
    })
}
// 添加
export function addVip(data) {
    return request({
        url: '/sys/clientVipLevel/savaClientVipLevel',
        method: 'post',
        data: data
    })
}
// 修改
export function updateVip(data) {
    return request({
        url: '/sys/clientVipLevel/updateClientVipLevel',
        method: 'post',
        data: data
    })
}
// 删除
export function delVip(data) {
    return request({
        url: '/sys/clientVipLevel/updateStatus',
        method: 'post',
        data: data
    })
}
// #endregion

// #region 套餐名称列表
export function packageList(data) {
    return request({
        url: '/sys/serviceItem/selectList',
        method: 'post',
        data: data
    })
}
export function addPackage(data) {
    return request({
        url: '/sys/serviceItem/savaServiceItem',
        method: 'post',
        data: data
    })
}
export function updatePackage(data) {
    return request({
        url: '/sys/serviceItem/updateServiceItem',
        method: 'post',
        data: data
    })
}
export function delPackage(data) {
    return request({
        url: '/sys/serviceItem/removeById',
        method: 'post',
        data: data
    })
}
// #endregion

// #region 所属套餐项目明细
export function packageDetailList(data) {
    return request({
        url: '/sys/servicePackageItem/selectList',
        method: 'post',
        data: data
    })
}
export function addPackageDetail(data) {
    return request({
        url: '/sys/servicePackageItem/savaServicePackageItem',
        method: 'post',
        data: data
    })
}
export function updatePackageDetail(data) {
    return request({
        url: '/sys/servicePackageItem/updateServicePackageItem',
        method: 'post',
        data: data
    })
}
export function delPackageDetail(data) {
    return request({
        url: '/sys/servicePackageItem/removeById',
        method: 'post',
        data: data
    })
}
export function getPackage(data) {
    return request({
        url: '/sys/serviceItem/selectById',
        method: 'post',
        data: data
    })
}
export function getPackageListNoPage(data) {
    return request({
        url: '/sys/serviceItem/getList',
        method: 'post',
        data: data
    })
}
// #endregion

// #region 赠送项目列表
export function zsProList(data) {
    return request({
        url: '/sys/donationItem/selectList',
        method: 'post',
        data: data
    })
}


// 添加赠送项目列表
export function addzsPro(data) {
    return request({
        url: '/sys/donationItem/savaDonationItem',
        method: 'post',
        data: data
    })
}
// 根据项目id查找
export function getzsPro(data) {
    return request({
        url: '/sys/donationItem/selectById',
        method: 'post',
        data: data
    })
}
// 修改
export function updatezsPro(data) {
    return request({
        url: '/sys/donationItem/updateDonationItem',
        method: 'post',
        data: data
    })
}
// 删除
export function delzsPro(data) {
    return request({
        url: '/sys/donationItem/removeById',
        method: 'post',
        data: data
    })
}
// #endregion

// #region 按会员级别自定义项目
export function zdyProList(data) {
    return request({
        url: '/sys/Donation/selectList',
        method: 'post',
        data: data
    })
}
// 添加
export function addPro2(data) {
    return request({
        url: '/sys/Donation/savaDonation',
        method: 'post',
        data: data
    })
}
// 修改
export function updatePro2(data) {
    return request({
        url: '/sys/Donation/updateDonation',
        method: 'post',
        data: data
    })
}
// 删除
export function delPro2(data) {
    return request({
        url: '/sys/Donation/removeById',
        method: 'post',
        data: data
    })
}
export function getVipListNoPage(data) {
    return request({
        url: '/sys/clientVipLevel/getList',
        method: 'post',
        data: data
    })
}
// #endregion

// #region 赠送项目成本信息
export function cbProList(data) {
    return request({
        url: '/sys/donationCost/selectList',
        method: 'post',
        data: data
    })
}
// 添加
export function addPro3(data) {
    return request({
        url: '/sys/donationCost/savaDonationCost',
        method: 'post',
        data: data
    })
}
// 修改
export function updatePro3(data) {
    return request({
        url: '/sys/donationCost/updateDonationCost',
        method: 'post',
        data: data
    })
}
// 删除
export function delPro3(data) {
    return request({
        url: '/sys/donationCost/removeById',
        method: 'post',
        data: data
    })
}
// 获取会员卡级别
export function getVipList(data) {
    return request({
        url: '/sys/clientVipLevel/getList',
        method: 'post',
        data: data
    })
}

// 获取赠送项目
export function getProNoPage(data) {
    return request({
        url: '/sys/donationItem/getList',
        method: 'post',
        data: data
    })
}
// #endregion

// #region 积分设置
export function getJfList(data) {
    return request({
        url: '/sys/integralRule/selectList',
        method: 'post',
        data: data
    })
}
export function addJf(data) {
    return request({
        url: '/sys/integralRule/savaIntegralRule',
        method: 'post',
        data: data
    })
}
export function updateJf(data) {
    return request({
        url: '/sys/integralRule/updateIntegralRule',
        method: 'post',
        data: data
    })
}
export function delJf(data) {
    return request({
        url: '/sys/integralRule/removeById',
        method: 'post',
        data: data
    })
}

// #endregion

// #region 维修列表
export function getrepaireList(data) {
    return request({
        url: '/sys/maintenanceType/selectList',
        method: 'post',
        data: data
    })
}
export function addRep(data) {
    return request({
        url: '/sys/maintenanceType/savaMaintenanceType',
        method: 'post',
        data: data
    })
}
export function updateRep(data) {
    return request({
        url: '/sys/maintenanceType/updateMaintenanceType',
        method: 'post',
        data: data
    })
}
export function delRep(data) {
    return request({
        url: '/sys/maintenanceType/removeById',
        method: 'post',
        data: data
    })
}
// #endregion

// #region 车辆
export function getCarListPage(data) {
    return request({
        url: '/sys/CarType/selectList',
        method: 'post',
        data: data
    })
}
export function addCar(data) {
    return request({
        url: '/sys/CarType/savaCarType',
        method: 'post',
        data: data
    })
}
export function updateCar(data) {
    return request({
        url: '/sys/CarType/updateCarType',
        method: 'post',
        data: data
    })
}
export function delCar(data) {
    return request({
        url: '/sys/CarType/removeById',
        method: 'post',
        data: data
    })
}
// 颜色
export function getColorList(data) {
    return request({
        url: '/sys/carColor/selectList',
        method: 'post',
        data: data
    })
}
export function addColor(data) {
    return request({
        url: '/sys/carColor/saveCarColor',
        method: 'post',
        data: data
    })
}
export function updateColor(data) {
    return request({
        url: '/sys/carColor/updateCarColor',
        method: 'post',
        data: data
    })
}
export function delColor(data) {
    return request({
        url: '/sys/carColor/removeById',
        method: 'post',
        data: data
    })
}
// 产地
export function getRegionList(data) {
    return request({
        url: '/sys/carProduction/selectList',
        method: 'post',
        data: data
    })
}
export function addRegion(data) {
    return request({
        url: '/sys/carProduction/saveCarProduction',
        method: 'post',
        data: data
    })
}
export function updateRegion(data) {
    return request({
        url: '/sys/carProduction/updateCarProduction',
        method: 'post',
        data: data
    })
}
export function delRegion(data) {
    return request({
        url: '/sys/carProduction/removeById',
        method: 'post',
        data: data
    })
}
// 用途
export function getUsingList(data) {
    return request({
        url: '/sys/carPurpose/selectList',
        method: 'post',
        data: data
    })
}
export function addUsing(data) {
    return request({
        url: '/sys/carPurpose/saveCarPurpose',
        method: 'post',
        data: data
    })
}
export function updateUsing(data) {
    return request({
        url: '/sys/carPurpose/updateCarPurpose',
        method: 'post',
        data: data
    })
}
export function delUsing(data) {
    return request({
        url: '/sys/carPurpose/removeById',
        method: 'post',
        data: data
    })
}
// #endregion 车辆

// #region 客户设置
export function getClientList(data) {
    return request({
        url: '/sys/attribute/selectList',
        method: 'post',
        data: data
    })
}
export function getClientListByCity(data) {
    return request({
        url: '/sys/city/cityPage',
        method: 'post',
        data: data
    })
}
export function addClient(data) {
    return request({
        url: '/sys/attribute/savaAttribute',
        method: 'post',
        data: data
    })
}
export function addClientByCity(data) {
    return request({
        url: '/sys/city/savaCarType',
        method: 'post',
        data: data
    })
}
export function updateClient(data) {
    return request({
        url: '/sys/attribute/updateAttribute',
        method: 'post',
        data: data
    })
}
export function updateClientByCity(data) {
    return request({
        url: '/sys/city/updateCarType',
        method: 'post',
        data: data
    })
}
export function delClient(data) {
    return request({
        url: '/sys/attribute/removeById',
        method: 'post',
        data: data
    })
}
export function delClientByCity(data) {
    return request({
        url: '/sys/city/removeById',
        method: 'post',
        data: data
    })
}
export function getCityList(data) {
    return request({
        url: '/sys/city/cityList',
        method: 'post',
        data: data
    })
}
// #endregion

// #region 客户标签
export function getClientTagList(data) {
    return request({
        url: '/sys/tag/selectList',
        method: 'post',
        data: data
    })
}
export function addClientTag(data) {
    return request({
        url: '/sys/tag/saveTag',
        method: 'post',
        data: data
    })
}
export function updateClientTag(data) {
    return request({
        url: '/sys/tag/updateTag',
        method: 'post',
        data: data
    })
}
export function delClientTag(data) {
    return request({
        url: '/sys/tag/removeById',
        method: 'post',
        data: data
    })
}
// #endregion

// #region 保养间隔天数
export function getByList(data) {
    return request({
        url: '/sys/maintenanceIntervalDay/selectList',
        method: 'post',
        data: data
    })
}
export function addBy(data) {
    return request({
        url: '/sys/maintenanceIntervalDay/savaMaintenanceIntervalDay',
        method: 'post',
        data: data
    })
}
export function updateBy(data) {
    return request({
        url: '/sys/maintenanceIntervalDay/updateMaintenanceIntervalDay',
        method: 'post',
        data: data
    })
}
export function delBy(data) {
    return request({
        url: '/sys/maintenanceIntervalDay/removeById',
        method: 'post',
        data: data
    })
}
// #endregion

// #region 储值方式
export function getCzList(data) {
    return request({
        url: '/sys/storedValue/selectList',
        method: 'post',
        data: data
    })
}
export function addCz(data) {
    return request({
        url: '/sys/storedValue/saveStoredValue',
        method: 'post',
        data: data
    })
}
export function updateCz(data) {
    return request({
        url: '/sys/storedValue/updateStoredValue',
        method: 'post',
        data: data
    })
}
export function delCz(data) {
    return request({
        url: '/sys/storedValue/removeById',
        method: 'post',
        data: data
    })
}
// #endregion

// #region 卡片类型
export function getCardList(data) {
    return request({
        url: '/sys/cardType/selectList',
        method: 'post',
        data: data
    })
}
export function addCard(data) {
    return request({
        url: '/sys/cardType/saveCardType',
        method: 'post',
        data: data
    })
}
export function updateCard(data) {
    return request({
        url: '/sys/cardType/updateCardType',
        method: 'post',
        data: data
    })
}
export function delCard(data) {
    return request({
        url: '/sys/cardType/removeById',
        method: 'post',
        data: data
    })
}
// #endregion

// #region 积分抵扣
export function getDkList(data) {
    return request({
        url: '/sys/deduction/select',
        method: 'post',
        data: data
    })
}
export function addDk(data) {
    return request({
        url: '/sys/deduction/add',
        method: 'post',
        data: data
    })
}
export function updateDk(data) {
    return request({
        url: '/sys/deduction/update',
        method: 'post',
        data: data
    })
}
export function delDk(data) {
    return request({
        url: '/sys/deduction/delete',
        method: 'post',
        data: data
    })
}
// #endregion