<template>
    <div>
        <el-dialog :visible.sync="dialogVisible" :modal="true" :append-to-body="true" :close-on-click-modal="false"
            :before-close="handleClose">
            <div class="container">
                <div class="left">
                    <el-input size="small" v-model="form.name" placeholder="搜索员工"></el-input>
                    <div class="title">全部成员:({{ num || 0 }})</div>
                    <el-tree :data="memList" show-checkbox default-expand-all node-key="id" ref="tree" highlight-current
                        @check="check"> </el-tree>
                </div>
                <div class="divider"></div>
                <div class="right">
                    <div>已选成员:({{ selectList.length || 0 }})</div>
                    <div class="box">
                        <div class="item" v-for="(item, index) in selectList" :key="index">
                            <span>{{ item.label }}</span>
                            <i style="cursor: pointer;" @click="remove(item)" class="el-icon-close"></i>
                        </div>
                    </div>
                </div>
            </div>
            <span slot="title" class="dialog-title">
                <span class="title">选择成员</span>
            </span>
            <span slot="footer" class="dialog-footer">
                <div slot="footer" class="dialog-footer">
                    <el-button size="small" @click="handleClose">取消</el-button>
                    <el-button size="small" type="primary" @click="save">确定</el-button>
                </div>
            </span>
        </el-dialog>
    </div>
</template>  
    
<script>
import { getStaffList } from '@/api/memberManage';


export default {
    data() {
        return {
            dialogVisible: false, // 控制弹出框的显示与隐藏  
            carList: [],
            form: {},
            id: 0,
            memList: [],
            selectList: [],
            num: 0,
        };
    },
    created() {
    },
    methods: {
        openDialog(data) {
            // 打开弹出框，并触发父组件的事件传递参数  
            if (data && data.length > 0) {
                this.selectList = data
            }
            this.dialogVisible = true;
            this.getList()

            // this.$emit('child-event', 'Hello from child');
        },
        handleClose() {
            this.dialogVisible = false;
            this.selectList = []
        },
        getList() {
            getStaffList().then(res => {
                if (res.state == '200') {
                    this.num = res.data.length
                    let data = res.data.map(item => {
                        return {
                            id: item.user_id,
                            label: item.nickname
                        }
                    })
                    let list = []
                    let qyyg = {
                        id: 'qyyg',
                        label: '企业员工',
                        children: data
                    }
                    list.push(qyyg)
                    this.memList = list
                    let keys = this.selectList.map(item => item.id)
                    this.$refs.tree.setCheckedKeys(keys);
                }
            })
        },
        remove(item) {
            this.selectList = this.selectList.filter(i => i.id != item.id)
            let selsecNodes = this.$refs.tree.getCheckedNodes()
            let newChildren = []
            if (selsecNodes.length > this.memList.length) {
                newChildren = selsecNodes[0].children.filter(i => i.id != item.id)

            } else {
                newChildren = selsecNodes.filter(i => i.id != item.id)
            }
            this.$refs.tree.setCheckedNodes(newChildren)

        },
        check() {
            let newNodes = this.$refs.tree.getCheckedNodes()
            if (!this.isAll(newNodes)) {
                this.selectList = newNodes
            } else {
                this.selectList = this.isAll(newNodes)
            }
        },
        isAll(data) {
            for (const item of data) {
                if (item.id == 'qyyg') {
                    return item.children
                }
                // 正常的循环处理
            }
            return false
        },
        save() {
            this.$emit('setMem', this.selectList)
            this.dialogVisible = false
        }

    }
};
</script>
<style scoped>
.container {
    display: flex;
}

.left {
    flex: 1;

}

.right {
    flex: 1;
}

.divider {
    border: 2px solid #f1f1f1;
    margin: 0 .125rem;
}

.title {
    margin: .125rem 0;
}

.box {
    width: 100%;
    margin-top: .125rem;
}

.item {
    display: flex;
    justify-content: space-between;
    padding: .125rem;
    align-items: center;

}
</style>
  