<template>
    <div class="memberInfoContainer">
        <div class="aside">
            <div class="user_info">
                <div class="user_image">
                    <el-avatar :size="50" :src="memberInfo.headimgurl"></el-avatar>
                </div>
                <div class="user_detail_info">
                    <div class="user user_name">
                        <span>{{ memberInfo.name || '默认昵称' }}</span>
                        <el-tag type="warning" effect="plain" size="mini"> {{ memberInfo.vipLevelId || '普通用户' }}</el-tag>
                    </div>
                    <div class="user user_phone">
                        <i class="el-icon-phone">{{ memberInfo.telephone }}</i>
                    </div>
                    <div class="user user_time">
                        <span>注册时间：{{ memberInfo.createTime }}</span>
                    </div>
                </div>
            </div>
            <div class="aside_content">
                <div class="own_info">
                    <div class="own_info_tag">
                        <span>账户余额</span>
                        <span class="own_info_number">{{ memberInfo.selfBalance ? memberInfo.selfBalance : 0 }}</span>
                    </div>
                    <div class="own_info_tag">
                        <span>可用积分</span>
                        <span class="own_info_number">{{ memberInfo.credit ? memberInfo.credit : 0 }}</span>
                    </div>
                    <div class="own_info_tag">
                        <span>持有套餐</span>
                        <span class="own_info_number">{{ memberInfo.holdSetMeal ? memberInfo.holdSetMeal : 0 }}张</span>
                    </div>
                    <!-- <div class="own_info_tag">
                        <span>优惠券</span>
                        <span class="own_info_number">6张</span>
                    </div> -->

                </div>
                <div class="record_info">
                    <div class="record_title">
                        交易记录（{{ memberInfo.operationName ? memberInfo.operationName : '当前操作员' }}）
                    </div>
                    <div class="record_content" v-if="recordList.length > 0">
                        <div class="record" v-for="(item, index) in recordList" :key="index">
                            <span class="record_value">【{{ item.types }}】</span>
                            <div class="record_time">
                                <span>充值{{ item.money }}元</span>
                                <span>{{ item.time }}</span>
                            </div>
                        </div>
                    </div>
                    <div v-if="recordList.length == 0" style="width:100%;">
                        <el-empty description="搜索查看记录哦~"></el-empty>
                    </div>

                </div>
            </div>
        </div>
        <div class="main">
            <el-menu :default-active="mi_defaultIndex" class="el-menu-demo" mode="horizontal" @select="mi_handleSelect">
                <el-menu-item index="mi_tag1">会员升级</el-menu-item>
                <el-menu-item index="mi_tag2">升级记录</el-menu-item>
            </el-menu>
            <div class="memberInfoMain" v-if="mi_activeIndex === 'mi_tag1'">
                <div class="mu_search">
                    <el-form ref="form" :model="form" label-width="1.25rem" size="small">
                        <el-form-item label="手机号：">
                            <el-input ref="teleRef" v-model="form.telephone" placeholder="输入手机号查询"></el-input>
                        </el-form-item>
                    </el-form>
                    <el-button type="primary" size="small" @click="search" style="margin-left: .125rem;">搜索</el-button>
                </div>
                <div class="mu_table">
                    <div class="memberInfo">
                        <div class="mu_borderStyle">
                            <el-descriptions :contentStyle="content_style" :column="2" size="mini" border
                                :labelStyle="label_style">
                                <el-descriptions-item :span="1" label="手机号">
                                    <span class="dg">{{ memberInfo.telephone }}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :span="1" label="客户自费">
                                    <span class="dg">{{ memberInfo.selfBalance }}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :span="1" label="会员姓名">
                                    <span class="dg">{{ memberInfo.name }}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :span="1" label="销售赠送">
                                    <span class="dg">{{ memberInfo.salesBalance }}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :span="1" label="身份证号">
                                    <span class="dg">{{ memberInfo.idCard }}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :span="1" label="售后赠送">
                                    <span class="dg">{{ memberInfo.afterBalance }}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :span="1" label="会员级别">
                                    <span class="dg">{{ memberInfo.vipLevelId }}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :span="1" label="金融保险">
                                    <span class="dg">{{ memberInfo.insuranceBalance }}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :span="1" label="车型">
                                    <span class="dg">{{ carType }}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :span="1" label="可用积分">
                                    <span class="dg">{{ memberInfo.credit }}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :span="1" label="车牌号码">
                                    <template slot="label">
                                        <span style="color: red;">*</span>车牌号码
                                    </template>
                                    <el-select class="dg" placeholder="请选择" v-model="carNum" size="small"
                                        @change="carChange">
                                        <el-option v-for="(item, index) in memberInfo.carTypeList" :key="index"
                                            :label="item.carNum" :value="item.carNum"></el-option>
                                    </el-select>
                                </el-descriptions-item>
                                <el-descriptions-item :span="1" label="发卡门店">
                                    <span class="dg">{{ memberInfo.storeId }}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :span="1" label="车架号码">
                                    <span class="dg">{{ carEnum }}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :span="1" label="卡片性质">
                                    <p v-if="memberInfo.cardType == 0">购买</p>
                                    <p v-if="memberInfo.cardType == 1">赠送</p>
                                </el-descriptions-item>
                                <el-descriptions-item :span="2" label="待升级别">
                                    <el-select class="dg" placeholder="请选择" v-model="value" size="small" :disabled="!zdy"
                                        @change="change">
                                        <el-option v-for="(item, index) in memberInfo.clientVipList" :key="index"
                                            :label="item.name" :value="item.level"></el-option>
                                    </el-select>
                                </el-descriptions-item>
                                <el-descriptions-item label="备注" :span="2">
                                    <span class="dg">{{ memberInfo.remarks }}</span>
                                </el-descriptions-item>
                            </el-descriptions>
                        </div>
                        <div class="remind" style="flex-direction: column;justify-content: flex-start;">
                            <div class="tishi">
                                <span class="tishiStyle">会员卡升级操作信息提示</span>
                            </div>
                            <el-radio v-model="zdy" label="1" style="margin-top: 0.25rem;">自定义升级</el-radio>

                        </div>

                    </div>
                    <div class="save">
                        <el-button type="primary" @click="save()" size="small" :disabled="isDj">保存</el-button>
                    </div>
                </div>

            </div>
            <div class="memberInfoMain" v-if="mi_activeIndex === 'mi_tag2'">
                <div class="mu_search">
                    <el-form ref="form" :model="form" label-width="1.25rem" size="small">
                        <el-form-item label="手机号：">
                            <el-input ref="teleRef" v-model="form.telephone" placeholder="输入手机号查询"></el-input>
                        </el-form-item>
                    </el-form>
                    <el-button type="primary" size="small" @click="upList" style="margin-left: .125rem;">搜索</el-button>
                </div>
                <div class="mu_tableStyle">
                    <el-table :data="pointsSettingsTableData" :stripe="true" size="mini"
                        header-cell-class-name="headerClassName" :cell-style="{ 'text-align': 'center' }" :border="true"
                        :fit="true">
                        <el-table-column prop="id" label="编号">
                            <template slot-scope="scope">
                                <span>{{ scope.$index + 1 }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="telephone" label="手机号">
                        </el-table-column>
                        <el-table-column prop="clientName" label="会员姓名">
                        </el-table-column>
                        <el-table-column prop="name" label="现会员等级">
                        </el-table-column>
                        <el-table-column prop="oldClientVipLevelName" label="原会员等级">
                        </el-table-column>
                        <el-table-column prop="carNum" label="车牌号码">
                        </el-table-column>
                        <el-table-column prop="carFrameNumber" label="车架号码">
                        </el-table-column>
                        <el-table-column prop="storeName" label="发卡门店">
                        </el-table-column>
                        <!-- <el-table-column prop="remark" label="充值金额">
                        </el-table-column>
                        <el-table-column prop="permissionGroupName" label="充值时间">
                        </el-table-column>
                        <el-table-column prop="founder" label="充值方式">
                        </el-table-column>
                        <el-table-column prop="creationTime" label="付款单号">
                        </el-table-column>
                        <el-table-column prop="remark" label="付款时间">
                        </el-table-column> -->
                        <!-- <el-table-column prop="remark" label="状态">
                        </el-table-column> -->
                        <el-table-column label="操作员" prop="operator">
                        </el-table-column>
                        <el-table-column label="操作店" prop="operationStoreName">
                        </el-table-column>
                        <el-table-column label="升级时间" prop="createTime">
                        </el-table-column>
                    </el-table>
                    <el-pagination background layout="prev, pager, next" :page-size="5" :current-page.sync="form.page"
                        @current-change="upList" :total="total">
                    </el-pagination>
                </div>
            </div>
        </div>
        <AddCarModal ref="addCarModal" @child-event="handleChildEvent" />
    </div>
</template>
<script>
import { getVipUpgrade, addVipClient, getVipClientList } from '@/api/memberManage';
import AddCarModal from '../memberManageModal/AddCarModal.vue'
export default {
    data() {
        return {
            mi_defaultIndex: 'mi_tag1',
            mi_activeIndex: 'mi_tag1',
            form: {
                page: 1,
                pageSize: 10
            },
            addCarMessage: '',
            content_style: {
                'width': '3.75rem'
            },
            label_style: {
                'width': '1.3rem',
                'justify-content': 'end'
            },
            memberInfo: {},
            carNum: '',
            carEnum: '',
            carType: '',
            zdy: false,
            value: '',
            pointsSettingsTableData: [],
            total: 0,
            upName: '',
            clientVipId: '',
            recordList: [],
            isDj: false
        }
    },
    mounted() {
        this.$refs.teleRef.focus()
    },
    methods: {
        mi_handleSelect(key, keyPath) {
            this.mi_activeIndex = key
            if (key == 'mi_tag1') {
                this.value = ''
                this.zdy = false
            }
            this.$nextTick(() => {
                this.$refs.teleRef.focus()
            })
        },
        handleChildEvent(childMessage) {
            // 处理子组件传递的事件和参数
            console.log('Received message from child:', childMessage)
            // 可以在这里执行其他逻辑或更新父组件的数据
        },

        openAddCarDialog() {
            this.$refs.addCarModal.openDialog()
        },
        search() {
            delete this.form.page
            delete this.form.pageSize
            getVipUpgrade(this.form).then(res => {
                if (res.data) {
                    if (res.data.actived == 4) {
                        this.$message.info('该会员已冻结')
                        this.isDj = true
                    }
                    this.memberInfo = res.data
                    this.recordList = res.data.mainCzList
                }
            })
        },
        carChange(e) {
            this.memberInfo.carTypeList.forEach(item => {
                if (item.carNum == e) {
                    this.carEnum = item.carFrameNumber
                    this.carType = item.model
                }
            })
        },
        save() {
            let params = {
                clientId: this.memberInfo.id,
                clientName: this.memberInfo.name,
                telephone: this.memberInfo.telephone,
                level: this.value,
                name: this.upName,
                carNum: this.carNum,
                carFrameNumber: this.carEnum,
                model: this.carType,
                clientVipLevel: this.clientVipId
            }
            addVipClient(params).then(res => {
                if (res.state == '200') {
                    this.$message.success('操作成功')
                    this.search()
                    this.value = ''
                    this.zdy = false
                }
            })
        },
        upList() {
            getVipClientList(this.form).then(res => {
                this.pointsSettingsTableData = res.data.records
                this.total = res.data.total
            })
        },
        change(e) {
            this.memberInfo.clientVipList.forEach(item => {
                if (item.level == e) {
                    this.upName = item.name
                    this.clientVipId = item.id
                }
            })
        }
    },
    components: {
        AddCarModal
    }
}
</script>
<style scoped>
::v-deep .el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
    margin-bottom: 0rem !important;
}

.memberInfoContainer {
    width: 100%;
    height: 100%;
    display: flex;
    overflow: auto;

}

.aside {
    width: 4.375rem;
    display: flex;
    flex-direction: column;
    height: 100% !important;
    border-right: 1px solid rgba(212, 212, 212, 0.5);
    border-radius: .125rem;
}

.aside_content {
    padding: 0 .125rem;
}

.main {
    flex: 1;
    padding: .125rem .125rem 0 .125rem;
    display: flex;
    flex-direction: column;

}

.main .el-form-item__label {
    font-size: .2rem;
}

.user_info {
    width: 100%;
    display: flex;
    height: 1.75rem;
    align-items: center;
    border: none;
    border-bottom: 1px solid rgb(212, 212, 212);
    margin-bottom: .125rem;
    /* padding-top: .5rem; */

}

.user_image {
    width: 1.5rem;
    height: .75rem;
    display: flex;
    justify-content: center;
}

.own_info_tag {
    width: 100%;
    height: .875rem;
    background: rgb(231, 236, 255);
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: .125rem;
}

.own_info_tag span {
    display: inline-block;
}

.own_info_number {
    font-weight: bold;
    color: #ea8419;
}

.poi_title .el-divider__text.is-left {
    left: 0;
}

.poi_title .el-divider__text {
    font-weight: bold;
}

.formStyle {
    border: 1px solid rgb(212, 212, 212);
    border-radius: .125rem;
    margin: .125rem .125rem 0 .125rem;
    flex: 1;
    padding: .25rem .25rem 0 .25rem;
}

.memberInfoMain .el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
    margin-bottom: .125rem;
}

.save {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.record {
    margin-bottom: .125rem;
}

.record_info {
    width: 100%;
    flex: 1;
    height: 5rem;
    overflow: auto;
    border: 1px solid rgb(212, 212, 212);
    border-radius: .125rem !important;
    padding: .125rem;

}

.record_title {
    font-size: .175rem;
    border-bottom: 1px solid rgb(212, 212, 212);
    text-align: center;
    padding-bottom: .125rem;
    margin-bottom: .125rem;
}

.record_value {
    width: 100%;
    height: .3125rem;
    line-height: .3125rem;
    display: inline-block;
    background: #f2f2f2;
    font-size: .15rem;
}

.record_time {
    display: flex;
    justify-content: space-between;
}

.record_time span {
    display: inline-block;
    color: rgb(0, 79, 251);
    font-size: .1375rem;
}

.memberInfoMain {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: .25rem;

}

.ma_table {
    display: flex;
    flex-direction: column;
    border: 1px solid rgb(212, 212, 212);
    border-radius: .125rem;
    flex: 1;
}

.memberInfo {
    flex: 1;
    display: flex;

}

.mu_borderStyle {
    flex: 7;
    margin-right: .125rem;
    padding: .25rem;
}

.remind {
    flex: 4;
    display: flex;
    justify-content: center;
    padding: .25rem;

}

.tishi {

    width: 3.75rem;
    height: 3.75rem;
    border: 1px solid rgb(212, 212, 212);
}

.tishiStyle {
    font-size: 15px;

    display: block;
    width: 100%;
    font-weight: bold;

}

.mu_search {
    border: 1px solid rgb(212, 212, 212);
    border-radius: .125rem;
    margin-bottom: .125rem;
    padding: .125rem;
    display: flex;
}

.mu_tableStyle {
    padding: .125rem;
    border: 1px solid rgb(212, 212, 212);
    border-radius: .125rem;
    flex: 1;
}

.user_name .el-tag {
    margin-left: .375rem !important;
}
</style>