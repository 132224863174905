<template>
	<div>
		<el-menu :default-active="car_defaultIndex" class="el-menu-demo" mode="horizontal" @select="car_handleSelect">
			<el-menu-item index="car">车辆品牌设置</el-menu-item>
			<el-menu-item index="series">车辆系列设置</el-menu-item>
			<el-menu-item index="modal">车型设置</el-menu-item>
			<el-menu-item index="color">颜色设置</el-menu-item>
			<el-menu-item index="region">产地设置</el-menu-item>
			<el-menu-item index="using">用途设置</el-menu-item>
		</el-menu>
		<div class="content" v-if="car_activeIndex == 'car'">
			<el-button type="primary" @click="addpp" size="small">添加品牌</el-button>
			<div class="tableStyle">
				<el-table :data="brandList" style="width: 70%;cursor: pointer;" :stripe="true" size="mini"
					header-cell-class-name="headerClassName" :cell-style="{ 'text-align': 'center' }" :border="true"
					:fit="true" @row-click="selectCarpp" highlight-current-row>
					<el-table-column label="编号">
						<template slot-scope="scope">
							<span>{{ scope.$index + 1 }}</span>
						</template>
					</el-table-column>
					<el-table-column prop="brand" label="品牌"> </el-table-column>
					<el-table-column label="操作">
						<template slot-scope="scope">
							<el-button @click="addpp(scope.row)" type="text" size="small">修改</el-button>
							<el-button @click="delBrands(scope.row.id)" type="text" size="small">刪除</el-button>
						</template>
					</el-table-column>
				</el-table>
				<el-pagination background layout="prev, pager, next" @current-change="getBrandList"
					:current-page.sync="brandPage.page" :page-size="brandPage.pageSize" :total="brandPage.total">
				</el-pagination>
			</div>
		</div>
		<div class="content" v-if="car_activeIndex == 'series'">
			<el-button type="primary" @click="openAddCarDialog('series')" size="small">添加系列</el-button>
			<div class="tableStyle">
				<el-table :data="seriesList" style="width: 70%" :stripe="true" size="mini"
					header-cell-class-name="headerClassName" :cell-style="{ 'text-align': 'center' }" :border="true"
					:fit="true">
					<el-table-column label="编号">
						<template slot-scope="scope">
							<span>{{ scope.$index + 1 }}</span>
						</template>
					</el-table-column>
					<el-table-column prop="brand" label="品牌">
					</el-table-column>
					<el-table-column prop="name" label="系列">
					</el-table-column>
					<el-table-column label="操作">
						<template slot-scope="scope">
							<el-button @click="upd('series', scope.row)" type="text" size="small">修改</el-button>
							<el-button @click="delSeries(scope.row)" type="text" size="small">刪除</el-button>
						</template>
					</el-table-column>
				</el-table>
				<el-pagination background layout="prev, pager, next" @current-change="getSeriesData"
					:current-page.sync="carPage.page" :page-size="carPage.pageSize" :total="carPage.total">
				</el-pagination>
			</div>
		</div>
		<div class="content" v-if="car_activeIndex == 'modal'">
			<el-button type="primary" @click="openAddCarDialog('modal')" size="small">添加车型</el-button>
			<div class="tableStyle">
				<el-table :data="modelList" style="width: 70%" :stripe="true" size="mini"
					header-cell-class-name="headerClassName" :cell-style="{ 'text-align': 'center' }" :border="true"
					:fit="true">
					<el-table-column label="编号">
						<template slot-scope="scope">
							<span>{{ scope.$index + 1 }}</span>
						</template>
					</el-table-column>
					<el-table-column prop="brand" label="品牌">
					</el-table-column>
					<el-table-column prop="series" label="系列">
					</el-table-column>
					<el-table-column prop="model" label="车型">
					</el-table-column>
					<el-table-column label="操作">
						<template slot-scope="scope">
							<el-button @click="upd('modal', scope.row)" type="text" size="small">修改</el-button>
							<el-button @click="del(scope.row)" type="text" size="small">刪除</el-button>
						</template>
					</el-table-column>
				</el-table>
				<el-pagination background layout="prev, pager, next" @current-change="getModalData"
					:current-page.sync="carPage.page" :page-size="carPage.pageSize" :total="carPage.total">
				</el-pagination>
			</div>
		</div>
		<div class="content" v-if="car_activeIndex == 'color'">
			<el-button type="primary" @click="openAddCarDialog('color')" size="small">添加</el-button>
			<div class="tableStyle">
				<el-table :data="tableData" style="width: 70%" :stripe="true" size="mini"
					header-cell-class-name="headerClassName" :cell-style="{ 'text-align': 'center' }" :border="true"
					:fit="true">
					<!-- <el-table-column prop="id" label="ID">
					</el-table-column> -->
					<el-table-column label="编号">
						<template slot-scope="scope">
							<span>{{ scope.$index + 1 }}</span>
						</template>
					</el-table-column>
					<el-table-column prop="color" label="颜色">
					</el-table-column>
					<el-table-column label="操作">
						<template slot-scope="scope">
							<el-button @click="upd('color', scope.row)" type="text" size="small">修改</el-button>
							<el-button @click="delColor(scope.row)" type="text" size="small">刪除</el-button>
						</template>
					</el-table-column>
				</el-table>
				<el-pagination background layout="prev, pager, next" @current-change="getColorList"
					:current-page.sync="table.page" :page-size="table.pageSize" :total="table.total">
				</el-pagination>
			</div>
		</div>
		<div class="content" v-if="car_activeIndex == 'region'">
			<el-button type="primary" @click="openAddCarDialog('region')" size="small">添加</el-button>
			<div class="tableStyle">
				<el-table :data="tableData" style="width: 70%" :stripe="true" size="mini"
					header-cell-class-name="headerClassName" :cell-style="{ 'text-align': 'center' }" :border="true"
					:fit="true">
					<!-- <el-table-column prop="id" label="ID">
					</el-table-column> -->
					<el-table-column label="编号">
						<template slot-scope="scope">
							<span>{{ scope.$index + 1 }}</span>
						</template>
					</el-table-column>
					<el-table-column prop="placeProduction" label="产地">
					</el-table-column>
					<el-table-column label="操作">
						<template slot-scope="scope">
							<el-button @click="upd('region', scope.row)" type="text" size="small">修改</el-button>
							<el-button @click="delRegion(scope.row)" type="text" size="small">刪除</el-button>
						</template>
					</el-table-column>
				</el-table>
				<el-pagination background layout="prev, pager, next" @current-change="getRegionList"
					:current-page.sync="table.page" :page-size="table.pageSize" :total="table.total">
				</el-pagination>
			</div>
		</div>
		<div class="content" v-if="car_activeIndex == 'using'">
			<el-button type="primary" @click="openAddCarDialog('using')" size="small">添加</el-button>
			<div class="tableStyle">
				<el-table :data="tableData" style="width: 70%" :stripe="true" size="mini"
					header-cell-class-name="headerClassName" :cell-style="{ 'text-align': 'center' }" :border="true"
					:fit="true">
					<!-- <el-table-column prop="id" label="ID">
					</el-table-column> -->
					<el-table-column label="编号">
						<template slot-scope="scope">
							<span>{{ scope.$index + 1 }}</span>
						</template>
					</el-table-column>
					<el-table-column prop="purpose" label="用途">
					</el-table-column>
					<el-table-column label="操作">
						<template slot-scope="scope">
							<el-button @click="upd('using', scope.row)" type="text" size="small">修改</el-button>
							<el-button @click="delUsing(scope.row)" type="text" size="small">刪除</el-button>
						</template>
					</el-table-column>
				</el-table>
				<el-pagination background layout="prev, pager, next" @current-change="getUsingList"
					:current-page.sync="table.page" :page-size="table.pageSize" :total="table.total">
				</el-pagination>
			</div>
		</div>
		<AddCardModal ref="addCardModal" @child-event="getModalData()" />
		<AddColorModal ref="addColorModal" @reload="getColorList()" />
		<AddRegionModal ref="addRegionModal" @reload="getRegionList()" />
		<AddUsingModal ref="addUsingModal" @reload="getUsingList()" />
		<AddCarpp ref="addcarpp" @child-event="getBrandList()" />
		<AddCarSeries ref="addcarseries" @child-event="getSeriesData()" />
	</div>
</template>

<script>
import { delCar, removeSeries, getCarListPage, carSeriesByPage, getColorList, delColor, getRegionList, delRegion, getUsingList, delUsing, brandListBypage, getcarListByBrand, delBrand, getCarList } from '@/api/systemOption/systemSettings'
import AddCardModal from '../../../systemOptionModal/AddCardModal.vue'
import AddColorModal from '../../../systemOptionModal/AddColorModal.vue'
import AddRegionModal from '../../../systemOptionModal/AddRegionModal.vue'
import AddUsingModal from '../../../systemOptionModal/AddUsingModal.vue'
import AddCarpp from '@/views/systemOption/systemOptionModal/AddCarpp.vue'
import AddCarSeries from '@/views/systemOption/systemOptionModal/AddCarSeries.vue'
export default {
	components: {
		AddCardModal,
		AddColorModal,
		AddRegionModal,
		AddUsingModal,
		AddCarpp,
		AddCarSeries
	},
	data() {
		return {
			seriesList: [],
			modelList: [],
			tableData: [],
			brandList: [],
			carList: [],
			table: {
				total: 0,
				page: 1,
				pageSize: 10
			},
			brandPage: {
				page: 1,
				total: 0,
				pageSize: 10
			},
			carPage: {
				page: 1,
				total: 0,
				pageSize: 10
			},
			car_defaultIndex: 'car',
			car_activeIndex: 'car',
			currentRow: null,
			brandId: ''

		}
	},
	mounted() {
		this.getBrandList()

	},
	methods: {
		getSeriesData() {
			carSeriesByPage({ page: this.carPage.page, pageSize: this.carPage.pageSize }).then(res => {
				if (res.state == '200') {
					this.seriesList = res.data.records
					this.carPage.total = res.data.total
				}
			})
		},
		getModalData() {
			getCarListPage({ page: this.carPage.page, pageSize: this.carPage.pageSize }).then(res => {
				if (res.state == '200') {
					this.modelList = res.data.records
					this.carPage.total = res.data.total
				}
			})
		},
		getBrandList() {
			brandListBypage({ page: this.brandPage.page, pageSize: this.brandPage.pageSize }).then(res => {
				if (res.state == '200') {
					this.brandList = res.data.records
					this.brandPage.total = res.data.total
				}
			})
		},
		addpp(item) {
			let data = ''
			if (item) {
				data = { ...item }
			}
			this.$refs.addcarpp.openDialog(data)
		},
		selectCarpp(row, column, cell) {
			this.brandId = row.id
			this.getCarList()
		},
		getCarList() {
			getCarList({ brandId: this.brandId }).then(res => {
				if (res.state == '200') {
					this.carList = res.data
				}
			})
		},
		delBrands(id) {
			this.delMsg(this).then(() => {
				delBrand({ id }).then(res => {
					if (res.state === '200') {
						this.$message({
							message: '删除成功！',
							type: 'success'
						});
						this.getBrandList()
						this.carList = []
					}
				})
			}).catch(() => {
				this.$message({
					type: 'warn',
					message: '删除失败！'
				});
			});
		},
		car_handleSelect(key) {
			console.log(key);
			this.car_activeIndex = key
			switch (key) {
				case 'car':
					this.getData()
					break;
				case 'series':
					this.getSeriesData()
					break;
				case 'modal':
					this.getModalData()
					break;
				case 'color':
					this.getColorList()
					break;
				case 'region':
					this.getRegionList()
					break;
				case 'using':
					this.getUsingList()
					break;
				default:
					break;
			}
			this.carPage = {
				page: 1,
				total: 0,
				pageSize: 10
			}
		},
		openAddCarDialog(key) {
			switch (key) {
				case 'car':
					this.$refs.addcarpp.openDialog()
					break;
				case 'series':
					this.$refs.addcarseries.openDialog()
					break;
				case 'modal':
					console.log(111);
					this.$refs.addCardModal.openDialog()
					break;
				case 'color':
					this.$refs.addColorModal.openDialog()
					break;
				case 'region':
					this.$refs.addRegionModal.openDialog()
					break;
				case 'using':
					this.$refs.addUsingModal.openDialog()
					break;
				default:
					break;
			}

		},
		getColorList() {
			getColorList({ page: this.table.page, pageSize: this.table.pageSize }).then(res => {
				if (res.state === '200') {
					this.tableData = res.data.records
					this.table.total = res.data.total
				}
			})
		},
		getRegionList() {
			getRegionList({ page: this.table.page, pageSize: this.table.pageSize }).then(res => {
				if (res.state === '200') {
					this.tableData = res.data.records
					this.table.total = res.data.total
				}
			})
		},
		getUsingList() {
			getUsingList({ page: this.table.page, pageSize: this.table.pageSize }).then(res => {
				if (res.state === '200') {
					this.tableData = res.data.records
					this.table.total = res.data.total
				}
			})
		},
		upd(key, row) {
			switch (key) {
				case 'car':
					this.$refs.addcarpp.openDialog(row)
					break;
				case 'series':
					this.$refs.addcarseries.openDialog(row)
					break;
				case 'modal':
					this.$refs.addCardModal.openDialog(row)
					break;
				case 'color':
					this.$refs.addColorModal.openDialog(row)
					break;
				case 'region':
					this.$refs.addRegionModal.openDialog(row)
					break;
				case 'using':
					this.$refs.addUsingModal.openDialog(row)
					break;
				default:
					break;
			}

		},
		delSeries(row) {
			this.delMsg(this).then(() => {
				removeSeries({ id: row.id }).then(res => {
					if (res.state == '200') {
						this.$message({
							message: '删除成功！',
							type: 'success'
						});
						this.getSeriesData()
					}
				})
			}).catch(() => {
				this.$message({
					type: 'warn',
					message: '删除失败！'
				});
			});
		},
		del(row) {
			this.delMsg(this).then(() => {
				delCar({ id: row.id }).then(res => {
					if (res.state === '200') {
						this.$message({
							message: '删除成功！',
							type: 'success'
						});
						this.getModalData()
					}
				})
			}).catch(() => {
				this.$message({
					type: 'warn',
					message: '删除失败！'
				});
			});
		},
		delColor(row) {
			this.delMsg(this).then(() => {
				delColor({ id: row.id }).then(res => {
					if (res.state === '200') {
						this.$message({
							message: '删除成功！',
							type: 'success'
						});
						if ((this.tableData.length - 1) == 0 && this.table.page > 1) {
							this.table.page--
						}
						this.getColorList()
					}
				})
			}).catch(() => {
				this.$message({
					type: 'warn',
					message: '删除失败！'
				});
			});
		},
		delRegion(row) {
			this.delMsg(this).then(() => {
				delRegion({ id: row.id }).then(res => {
					if (res.state === '200') {
						this.$message({
							message: '删除成功！',
							type: 'success'
						});
						if ((this.tableData.length - 1) == 0 && this.table.page > 1) {
							this.table.page--
						}
						this.getRegionList()
					}
				})
			}).catch(() => {
				this.$message({
					type: 'warn',
					message: '删除失败！'
				});
			});
		},
		delUsing(row) {
			this.delMsg(this).then(() => {
				delUsing({ id: row.id }).then(res => {
					if (res.state === '200') {
						this.$message({
							message: '删除成功！',
							type: 'success'
						});
						if ((this.tableData.length - 1) == 0 && this.table.page > 1) {
							this.table.page--
						}
						this.getUsingList()
					}
				})
			}).catch(() => {
				this.$message({
					type: 'warn',
					message: '删除失败！'
				});
			});
		}
	}
}
</script>

<style scoped>
::v-deep .el-dialog.addCard {
	width: 30vw !important;
}

.content {
	padding: .125rem;
}
</style>