<template>
  <div class="systemOptionContainer">
    <div class="memberManageAside">
      <MemberManageAside />
    </div>
    <div class="systemOptionMain">
      <div class="exit">
        <Exit />
      </div>
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
import Exit from '../components/Exit.vue';
import MemberManageAside from '../components/MemberManageAside.vue'
export default {
  components: { MemberManageAside, Exit },
  methods: {
    out() {
      this.$confirm('确认关闭？')
        .then(_ => {
          done()
        })
        .catch(_ => { })
    },
  }
}
</script>
<style>
.systemOptionContainer {
  display: flex;
  background-color: rgb(247, 248, 250);

}

.memberManageAside {
  height: 100vh;
}

.systemOptionMain {
  flex: 1;
  max-height: 100vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

}

.exit {
  display: flex;
  justify-content: flex-end;
}

.exit:hover {
  cursor: pointer;
}

.outLogin {
  margin-right: .5rem;
}

.outLogin i {
  margin-left: .0625rem;
}


.el-divider__text.is-left {
  left: 0;
}

.el-divider__text {
  font-weight: bold;
  font-size: large;
}
</style>
