import request from '@/utils/request'
import axios from 'axios';


//添加会员卡保存
export function saveCard(data) {
    return request({
        url: '/sys/ClientVipLevel/savaClientVipLevel',
        method: 'post',
        data: data
    })
}
//获取套餐列表
export function getPackageList(data) {
    return request({
        url: '/sys/serviceItem/selectList',
        method: 'post',
        data: data
    })
}
//添加套餐
export function savePackage(data) {
    return request({
        url: '/sys/serviceItem/savaServicePackageItem',
        method: 'post',
        data: data
    })
}
//修改套餐
export function updatePackage(data) {
    return request({
        url: '/sys/serviceItem/updateServiceItem',
        method: 'post',
        data: data
    })
}
//删除套餐
export function delPackage(data) {
    return request({
        url: '/sys/serviceItem/removeById',
        method: 'post',
        data: data
    })
}
// 获取当前套餐下的套餐明细
export function getPackageDetailListById(data) {
    return request({
        url: '/sys/servicePackageItem/getList',
        method: 'post',
        data: data
    })
}
//获取套餐明细列表
export function getPackageDetailList(data) {
    return request({
        url: '/sys/servicePackageItem/selectList',
        method: 'post',
        data: data
    })
}
//添加套餐明细
export function savePackageDetail(data) {
    return request({
        url: '/sys/servicePackageItem/savaServicePackageItem',
        method: 'post',
        data: data
    })
}
//修改套餐明细
export function updatePackageDetail(data) {
    return request({
        url: '/sys/servicePackageItem/updateServicePackageItem',
        method: 'post',
        data: data
    })
}
//删除套餐明细
export function delPackageDetail(data) {
    return request({
        url: '/sys/servicePackageItem/removeById',
        method: 'post',
        data: data
    })
}
//获取赠送项目列表
export function getProjectList(data) {
    return request({
        url: '/sys/donationItem/selectList',
        method: 'post',
        data: data
    })
}
//添加赠送项目
export function saveProject(data) {
    return request({
        url: '/sys/donationItem/savaDonationItem',
        method: 'post',
        data: data
    })
}
//修改赠送项目
export function updateProject(data) {
    return request({
        url: '/sys/donationItem/updateDonationItem',
        method: 'post',
        data: data
    })
}
//删除赠送项目
export function delProject(data) {
    return request({
        url: '/sys/donationItem/removeById',
        method: 'post',
        data: data
    })
}
//自定义项目
// export function getProjectBySelfList(data) {
//     return request({
//         url: '/sys/donationItem/selectList',
//         method: 'post',
//         data: data
//     })
// }
// export function saveProjectBySelf(data) {
//     return request({
//         url: '/sys/donationItem/savaDonationItem',
//         method: 'post',
//         data: data
//     })
// }
// export function updateProjectBySelf(data) {
//     return request({
//         url: '/sys/donationItem/updateDonationItem',
//         method: 'post',
//         data: data
//     })
// }
// export function delProjectBySelf(data) {
//     return request({
//         url: '/sys/donationItem/removeById',
//         method: 'post',
//         data: data
//     })
// }

