<template>
    <div>
        <el-dialog custom-class="addGoods" :visible.sync="dialogVisible" :modal="true" :append-to-body="true"
            :close-on-click-modal="false" @close="resetForm">
            <el-form ref="form" :model="form" label-width="1rem" size="mini">
                <el-form-item label="账号" prop="login" :rules="[
                    { required: true, message: '账号不能为空', trigger: 'blur' }
                ]">
                    <el-input placeholder="输入账号" v-model="form.login"></el-input>
                </el-form-item>
                <el-form-item label="密码" prop="password" :rules="[
                    { required: true, message: '密码不能为空', trigger: 'blur' }
                ]">
                    <el-input placeholder="输入账号" v-model="form.password" type="password"></el-input>
                </el-form-item>
            </el-form>
            <span slot="title" class="dialog-title">
                <span>跨店消费安全认证</span>
            </span>
            <span slot="footer" class="dialog-footer">
                <div slot="footer" class="dialog-footer">
                    <el-button size="small" type="primary" @click="save">登录</el-button>
                </div>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    data() {
        return {
            dialogVisible: false,
            baseUrl: '',
            storeId: '',
            form: {
                login: '',
                password: ''
            }
        };
    },
    methods: {
        openDialog(data) {
            // 打开弹出框，并触发父组件的事件传递参数  
            this.baseUrl = data.baseUrl
            this.storeId = data.storeId
            this.dialogVisible = true;

        },
        resetForm() {
            this.form = {}
        },
        save() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    const url = this.baseUrl + 'user/login/pwd'
                    axios.post(url, this.form).then(response => {
                        let res = response.data
                        if (res.state == '200') {
                            this.dialogVisible = false
                            const storeToken = sessionStorage.getItem(this.storeId)
                            if (!storeToken || storeToken == '') {
                                sessionStorage.setItem(this.storeId, res.data.token)
                            }
                        }else{
                            this.$message.error(res.error)
                        }
                    })
                }
            })
        },

    }
};
</script>
<style scoped>
::v-deep .formItem .el-form-item__content {
    margin-left: 0rem !important;
}

::v-deep .el-dialog {
    width: 20vw !important;
    margin-top: 20vh !important;
}
</style>