<template>
	<div>
		<el-divider content-position="left" class="poi_title">积分增加项目添加</el-divider>
		<el-form ref="form" :model="form" label-width="100px" style="width:50%" size="mini" :rules="rules">
			<el-row :gutter="20">
				<el-col :span="24">
					<div class="grid-content bg-purple">
						<el-form-item label="项目名称：" prop="donationName">
							<el-input placeholder="请输入项目名称" v-model="form.donationName"></el-input>
						</el-form-item>
					</div>
				</el-col>
			</el-row>
			<el-row :gutter="20">
				<el-col :span="12">
					<div class="grid-content bg-purple">
						<el-form-item label="会员级别：" required>
							<el-select placeholder="请选择" size="small" v-model="form.vipLevelId">
								<el-option :label="item.name" :value="item.id" :key="item.id"
									v-for="(item, index) in vipList"></el-option>
							</el-select>
						</el-form-item>
					</div>
				</el-col>
				<el-col :span="12">
					<div class="grid-content bg-purple">
						<el-form-item label="积分规则：" prop="integralRule">
							<el-select placeholder="请选择活动区域" v-model="form.integralRule">
								<el-option label="按比例" value="1"></el-option>
								<el-option label="按金额" value="2"></el-option>
								<el-option label="按次数" value="3"></el-option>
							</el-select>
						</el-form-item>
					</div>
				</el-col>
			</el-row>
			<el-row :gutter="20">
				<el-col :span="24">
					<div class="grid-content bg-purple">
						<el-form-item label="按比例：" required>
							<el-row v-if="form.integralRule != 3">
								<el-col :span="8" :offset="0">
									<div class="grid-content bg-purple">{{ form.integralRule === '1' ? '每' : '满' }}
										<el-input-number v-model="form.money" controls-position="right"
											:min="0"></el-input-number>
										元
									</div>
								</el-col>
								<el-col :span="8">
									<div class="grid-content bg-purple">积
										<el-input-number v-model="form.earnPoints" controls-position="right"
											:min="0"></el-input-number>
										分
									</div>
								</el-col>
							</el-row>
							<el-row v-else>
								<el-col :span="8" :offset="0">
									<el-input-number v-model="form.integral" controls-position="right"
										:min="0"></el-input-number>分/次
								</el-col>
							</el-row>
						</el-form-item>
					</div>
				</el-col>
			</el-row>
			<el-row :gutter="20">
				<el-col :span="24">
					<div class="grid-content bg-purple">
						<el-form-item label="积分倍数：" prop="multiple" required>
							<el-input-number v-model="form.multiple" controls-position="right" :min="1"></el-input-number>
						</el-form-item>
					</div>
				</el-col>
			</el-row>
			<el-row :gutter="20">
				<el-col :span="24">
					<div class="grid-content bg-purple">
						<el-form-item label="关联操作：">
							<el-checkbox v-model="form.propertyTypeId">客户自费维保消费</el-checkbox>
							<span
								class="remarkStyle">注：关联操作后，此项目所配置的积分规则会在相应的操作界面自动增加积分(客户自费维保消费可关联多个积分增加项目，其他只能关联一个)</span>
						</el-form-item>
					</div>
				</el-col>
			</el-row>
			<el-row :gutter="20">
				<el-col :span="24">
					<el-checkbox v-model="form.isCumulative">【此项积分是否累计】（注：此积分只可用于消费，不可累计）</el-checkbox>
				</el-col>
			</el-row>
		</el-form>
		<div class="tableContainer">
			<el-button size="small" type="primary" @click="add()" v-if="!edit">添加</el-button>
			<el-button size="small" type="primary" @click="update()" v-if="edit">确认修改</el-button>
			<el-button size="small" type="primary" v-if="edit" @click="cancel()">取消</el-button>
			<div class="tableStyle">
				<el-table :data="tableData" style="width: 100%" :stripe="true" size="mini"
					header-cell-class-name="headerClassName" :cell-style="{ 'text-align': 'center' }" :border="true"
					:fit="true">
					<!-- <el-table-column prop="id" label="ID">
					</el-table-column> -->
					<el-table-column prop="vipLevelName" label="会员级别">
					</el-table-column>
					<el-table-column prop="donationName" label="项目名称">
					</el-table-column>
					<el-table-column prop="integralRule" label="积分规则">
						<template slot-scope="scope">
							<span v-if="scope.row.integralRule == '1'">按比例</span>
							<span v-if="scope.row.integralRule == '2'">按金额</span>
							<span v-if="scope.row.integralRule == '3'">按次数</span>
						</template>
					</el-table-column>
					<el-table-column prop="integral" label="积分/次">
					</el-table-column>
					<el-table-column prop="money" label="金额">
					</el-table-column>
					<el-table-column prop="earnPoints" label="积分">
					</el-table-column>
					<el-table-column prop="multiple" label="积分倍数">
					</el-table-column>
					<el-table-column prop="isCumulative" label="是否累计">
						<template slot-scope="scope">
							<span v-if="scope.row.isCumulative">是</span>
							<span v-if="!scope.row.isCumulative">否</span>
						</template>
					</el-table-column>
					<el-table-column prop="propertyTypeId" label="是否关联操作">
						<template slot-scope="scope">
							<span v-if="scope.row.propertyTypeId">是</span>
							<span v-if="!scope.row.propertyTypeId">否</span>
						</template>
					</el-table-column>
					<el-table-column label="操作">
						<template slot-scope="scope">
							<el-button @click="handleClick(scope.row)" type="text" size="small">编辑</el-button>
							<el-button @click="del(scope.row)" type="text" size="small">刪除</el-button>
						</template>
					</el-table-column>
				</el-table>
				<el-pagination background layout="prev, pager, next" @current-change="changePage"
					:current-page.sync="table.page" :page-size="table.pageSize" :total="table.total">
				</el-pagination>
			</div>
		</div>
		<div class="poi_footer">
			<span>积分到期：按照会员开卡时间，计算天数，进行冻结积分</span>
		</div>
	</div>
</template>
<script>
import { delJf, getJfList, updateJf, getVipList, addJf } from '@/api/systemOption/systemSettings'

export default {
	components: {},
	data() {
		return {
			edit: false,
			vipList: [],
			tableData: [],
			table: {
				page: 1,
				pageSize: 10,
				total: 1
			},
			form: {
				vipLevelId: '',
				donationName: '',
				integralRule: '1',
				integral: '',
				money: '',
				earnPoints: '',
				multiple: '',
				isCumulative: '',
				propertyTypeId: false
			},
			rules: {
				donationName: [{ required: true, message: '请输入项目名称', trigger: 'blur' }],
				vipLevelId: [{ required: true, message: '请选择会员级别', trigger: 'blur' }],
			}
		}
	},
	mounted() {
		this.getData()
		this.getVip()
	},
	methods: {
		cancel() {
			this.edit = false
			this.reset()
		},
		getVip() {
			getVipList().then(res => {
				if (res.state === '200') {
					this.vipList = res.data
				}
			})
		},
		getData() {
			getJfList({ page: this.table.page, pageSize: this.table.pageSize }).then(res => {
				if (res.state === '200') {
					if (res.data.records.length == 0) {
						this.table.page--
						this.getData()
					}
					this.tableData = res.data.records
					this.table.total = res.data.total
				}
			})
		},
		reset() {
			var obj = {
				vipLevelId: '',
				donationName: '',
				integralRule: '1',
				integral: '',
				money: '',
				earnPoints: '',
				multiple: '',
				isCumulative: '',
				propertyTypeId: false
			}
			this.form = this.shenjiankaobe(obj)
		},
		update() {
			this.$refs.form.validate(valid => {
				if (valid) {
					const propertyTypeId = this.form.propertyTypeId ? '1' : ''
					const isCumulative = this.form.isCumulative ? 1 : 0
					if (this.form.integralRule != '3') {
						this.form.integral = ''
					} else {
						this.form.money = ''
						this.form.earnPoints = ''
					}
					updateJf({ ...this.form, isCumulative, propertyTypeId }).then(res => {
						if (res.state === '200') {
							this.$message({
								message: '修改成功！',
								type: 'success'
							});
							this.reset()
							this.$refs.form.resetFields()
							this.edit = false
							this.getData()
						}
					})
				}
			})
		},
		add() {
			this.$refs.form.validate(valid => {
				if (valid) {
					const propertyTypeId = this.form.propertyTypeId ? '1' : ''
					const isCumulative = this.form.isCumulative ? 1 : 0
					if (this.form.integralRule != '3') {
						this.form.integral = ''
					} else {
						this.form.money = ''
						this.form.earnPoints = ''
					}
					addJf({ ...this.form, isCumulative, propertyTypeId }).then(res => {
						if (res.state === '200') {
							this.$message({
								message: '添加成功！',
								type: 'success'
							});
							this.reset()
							this.$refs.form.resetFields()
							this.getData()
						}
					})
				}
			})
		},
		del(row) {
			this.delMsg(this).then(() => {
				delJf({ id: row.id }).then(res => {
					if (res.state === '200') {
						this.$message({
							message: '删除成功！',
							type: 'success'
						});
						this.getData()
					}
				})
			}).catch(() => {
				this.$message({
					type: 'warn',
					message: '删除失败！'
				});
			});
		},
		changePage(e) {
			// this.table.page = e
			this.getData()
		},
		handleClick(row) {
			this.edit = true
			this.form = { ...row, isCumulative: row.isCumulative ? true : false, propertyTypeId: row.propertyTypeId === '1' ? true : false }
		},
		// 深拷贝
		shenjiankaobe(obj) {
			let _obj = JSON.stringify(obj),
				objClone = JSON.parse(_obj);
			return objClone
		}
	}
}
</script>
<style scoped>
.tableContainer {
	padding: .125rem .25rem;
}

.tableContainer .el-table {
	width: 5rem;
}

.remarkStyle {
	display: inline-block;
	font-size: .125rem;
	color: rgb(232, 169, 140);
}

.poi_footer {
	padding: .25rem 1rem 0;
	color: rgb(233, 32, 80);
}
</style>