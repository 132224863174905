<template>
    <div>
        <el-divider content-position="left" class="poi_title">积分抵扣规则</el-divider>
        <el-form ref="form" :model="form" label-width="100px" style="width:50%" size="mini" :rules="rules">
            <el-row :gutter="20">
                <el-col :span="24">
                    <el-form-item required label="抵扣规则：">
                        <el-row>
                            <el-col :span="8" :offset="0">
                                <div class="grid-content bg-purple">每
                                    <el-input-number v-model="form.integral" controls-position="right"
                                        :min="0"></el-input-number>
                                    积分
                                </div>
                            </el-col>
                            <el-col :span="8">
                                <div class="grid-content bg-purple">兑换
                                    <el-input-number v-model="form.money" controls-position="right"
                                        :min="0"></el-input-number>
                                    元
                                </div>
                            </el-col>
                        </el-row>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="8">
                    <el-form-item>
                        <!-- 0抵扣1不抵扣  不抵扣不选择-->
                        <el-checkbox v-model="form.status">启用结账时 积分直接抵扣金额</el-checkbox>
                    </el-form-item>
                </el-col>
                <el-col :span="16">
                    <el-form-item>
                        <el-radio-group v-model="form.deduction">
                            <el-radio label="0">只抵扣工时费</el-radio>
                            <el-radio label="1">抵扣工时费+材料费</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <div class="tableContainer">
            <el-button size="small" type="primary" @click="add()" v-if="!edit">添加</el-button>
            <el-button size="small" type="primary" @click="update()" v-if="edit">确认修改</el-button>
            <el-button size="small" type="primary" v-if="edit" @click="cancel()">取消</el-button>
            <div class="tableStyle">
                <el-table :data="tableData" style="width: 100%" :stripe="true" size="mini"
                    header-cell-class-name="headerClassName" :cell-style="{ 'text-align': 'center' }" :border="true"
                    :fit="true">
                    <!-- <el-table-column prop="id" label="ID" >
                    </el-table-column> -->
                    <el-table-column prop="integral" label="每积分">
                    </el-table-column>
                    <el-table-column prop="money" label="兑换金额（元）">
                    </el-table-column>
                    <el-table-column prop="status" label="结账是否启用积分抵扣金额">
                        <template slot-scope="scope">
                            <span v-if="scope.row.status.toString() == '1'">否</span>
                            <span v-if="scope.row.status.toString() == '0'">是</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="deduction" label="抵扣方式">
                        <template slot-scope="scope">
                            <span v-if="scope.row.deduction == 0">只抵扣工时费</span>
                            <span v-if="scope.row.deduction == 1">抵扣工时费+材料费</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <el-button @click="handleClick(scope.row)" type="text" size="small">编辑</el-button>
                            <el-button @click="del(scope.row)" type="text" size="small">刪除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </div>
</template>
<script>
import { getDkList, addDk, delDk, updateDk } from '@/api/systemOption/systemSettings'

export default {
    components: {},
    data() {
        return {
            edit: false,
            tableData: [],
            form: {
                integral: 0,
                money: 0,
                status: '1',
                integral: '0',
                deduction: '0'
            },
            rules: {
                donationName: [{ required: true, message: '请输入项目名称', trigger: 'blur' }],
                vipLevelId: [{ required: true, message: '请选择会员级别', trigger: 'blur' }],
            }
        }
    },
    mounted() {
        this.getData()
    },
    methods: {
        getData() {
            getDkList().then(res => {
                if (res.state === '200') {
                    this.tableData = res.data
                }
            })
        },
        setK() {
            this.form = {
                integral: 0,
                money: 0,
                status: '1',
                integral: '0',
                deduction: '0'
            }
        },
        update() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    const deduction = Number(this.form.deduction)
                    const status = this.form.status ? 0 : 1
                    updateDk({ ...this.form, deduction, status }).then(res => {
                        if (res.state === '200') {
                            this.$message({
                                message: '修改成功！',
                                type: 'success'
                            });
                            this.setK()
                            this.edit = false
                            this.getData()
                        }
                    })
                }
            })
        },
        updateRow(index, row) {
            row.edit = true;
            this.$set(this.tableData, index, row);
        },
        add() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    const deduction = Number(this.form.deduction)
                    const status = this.form.status ? 0 : 1
                    console.log(this.form.status)
                    addDk({ ...this.form, deduction, status }).then(res => {
                        if (res.state === '200') {
                            this.$message({
                                message: '添加成功！',
                                type: 'success'
                            });
                            this.setK()
                            this.getData()
                        }
                    })
                }
            })
        },
        del(row) {
            this.delMsg(this).then(() => {
                delDk({ id: row.id }).then(res => {
                    if (res.state === '200') {
                        this.$message({
                            message: '删除成功！',
                            type: 'success'
                        });
                        this.getData()
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'warn',
                    message: '删除失败！'
                });
            });
        },
        handleClick(row) {
            this.edit = true
            this.form = { ...row, deduction: row.deduction ? '1' : '0', status: row.status ? false : true }
        },
        cancel() {
            this.edit = false
            this.setK()
        }
    }
}
</script>
<style scoped>
.tableContainer {
    padding: .125rem .25rem;
}

.tableContainer .el-table {
    width: 5rem;
}

.remarkStyle {
    display: inline-block;
    font-size: .125rem;
    color: rgb(232, 169, 140);
}

.poi_footer {
    padding: .25rem 1rem 0;
    color: rgb(233, 32, 80);
}
</style>