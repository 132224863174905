<template>
    <div>
        <el-dialog custom-class="addStoreDialog" @close="cancel()" :visible.sync="dialogVisible" :modal="true"
            :append-to-body="true" :close-on-click-modal="false">
            <el-form ref="form" :model="form" label-width="100px" size="mini" :rules="rules" @closed="cancel">
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label="名称:" prop="name">
                            <el-input v-model="form.name"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label="是否本店:" prop="isStore">
                            <el-select v-model="form.isStore" placeholder="请选择">
                                <el-option label="否" :value="0"> </el-option>
                                <el-option label="是" :value="1"> </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label="域名:" prop="baseUrl">
                            <el-input v-model="form.baseUrl"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label="门店主图:" prop="logo">
                            <uploadImgs :image="form.logo" @custom-event="receiveFromChild"></uploadImgs>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label="详情:" prop="inscription">
                            <el-input v-model="form.inscription"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label="联系电话:" prop="telephone">
                            <el-input v-model="form.telephone"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label="地址:" prop="address">
                            <el-input v-model="form.address"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label="序号:" prop="sorting">
                            <el-input v-model="form.sorting"></el-input>
                            <span class="note">用于排序，越大越靠前</span>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label="客服:" prop="customerCodeUrl">
                            <uploadImgs :image="form.customerCodeUrl" @custom-event="receiveKf"></uploadImgs>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label="客服信息:" prop="customer">
                            <el-input v-model="form.customer"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="title" class="dialog-title">
                <span v-if="this.edit">修改门店信息</span>
                <span v-else>添加门店</span>
            </span>
            <span slot="footer" class="dialog-footer">
                <div slot="footer" class="dialog-footer">
                    <el-button @click="cancel">取 消</el-button>
                    <el-button type="primary" @click="save()">确 定</el-button>
                </div>
            </span>
        </el-dialog>
    </div>
</template>  
    
<script>
import { addStore, updateStore } from '@/api/systemOption/storeSettings';
import LocalSelected from '@/views/components/LocalSelected.vue';
import uploadImgs from '../../components/uploadImg.vue'
export default {
    components: { LocalSelected, uploadImgs },
    data() {
        return {
            edit: false,
            // visibleMap: true,
            dialogVisible: false,
            map_data: '',
            form: {
                baseUrl: '',
                isStore: 1,
                name: '',
                address: '',
                latitude: '',
                longitude: '',
                inscription: '',
                logo: '',
                telephone: '',
                // actived: '0',
                sorting: 1,
                customerCodeUrl: '',
                customer: ''
            },
            rules: {
                name: [{ required: true, message: '请输入门店名称', trigger: 'blur' }],
                address: [{ required: true, message: '请输入地址', trigger: 'blur' }],
                baseUrl: [{ required: true, message: '请输入域名', trigger: 'blur' }],
                isStore: [{ required: true, message: '请选择是否本店', trigger: 'blur' }],
                telephone: [{ required: true, message: '请输入联系电话', trigger: 'blur' }],
                sorting: [{ required: true, message: '请输入序号', trigger: 'blur' }],
            }
        };
    },
    methods: {
        openDialog(data) {
            // 打开弹出框，并触发父组件的事件传递参数  
            this.dialogVisible = true;
            this.edit = false
            this.form.logo = ''
            if (data) {
                this.form = data
                console.log('data=', data);
                console.log(this.form.logo, '表单logo')
            }

        },
        receiveFromChild(data) {
            this.form.logo = data;
        },
        receiveKf(data) {
            console.log('kf:', data);
            this.form.customerCodeUrl = data
        },
        cancel() {
            // 关闭弹出框  
            this.dialogVisible = false;
            this.form = {}
        },
        resetForm() {
            this.form = {}
        },
        getCallMap(data) {
            this.form.address = data.address
        },
        save() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    if (this.form.id) {
                        updateStore(this.form).then(res => {
                            if (res.state === '200') {
                                this.$message({
                                    message: '添加成功！',
                                    type: 'success'
                                });
                                this.$emit('child-event1')
                                this.dialogVisible = false
                            }
                        })
                    } else {
                        addStore(this.form).then(res => {
                            if (res.state === '200') {
                                this.$message({
                                    message: '修改成功！',
                                    type: 'success'
                                });
                                this.$emit('child-event1')
                                this.dialogVisible = false
                            }
                        })
                    }
                }
            })
        }
    }
};
</script>
<style scoped>
::v-deep .el-dialog {
    margin-top: 5vh !important;
    width: 30vw;
}

.el-upload__tip {
    text-align: center;
    margin-top: 0;
    color: rgb(190, 188, 195);
    font-size: .15rem;
    margin-left: .0625rem;
    width: 40%;
}

.upload-store {
    display: flex;
    flex-wrap: wrap;
}

.note {
    font-size: 12px;
    color: rgb(124, 136, 169);
}


.addStoreDialog {
    margin-top: 20vh !important;
    width: 25%;
}
</style>
  