<template>
  <div>
    <el-dialog custom-class="packageDialog" :visible="dialogVisible" :modal="true" :append-to-body="true"
      :close-on-click-modal="false" @close="cancel()">
      <el-form ref="form" :model="form" label-width="1.5rem" size="mini" :rules="rules">
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="套餐名称" prop="name">
              <el-input v-model="form.name" placeholder="请输入套餐名称"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="套餐价格" prop="price">
              <el-input v-model="form.price" placeholder="请输入套餐价格"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="有效期(月)" prop="term">
              <el-input v-model="form.term"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <span>有效期为0的标识没有有效期，不限次数项目，项目名称后(不限次数)</span>
          </el-col>
        </el-row>
      </el-form>
      <span slot="title" class="dialog-title">
        <span>添加套餐名称</span>
      </span>
      <span slot="footer" class="dialog-footer">
        <div slot="footer" class="dialog-footer">
          <el-button size="small" @click="cancel()">取 消</el-button>
          <el-button size="small" type="primary" @click="save()">确 定</el-button>
        </div>
      </span>
    </el-dialog>
  </div>
</template>  
  
<script>
import { addPackage, updatePackage } from '@/api/systemOption/systemSettings';

export default {
  data() {
    return {
      dialogVisible: false,
      form: {
        name: '',
        price: '',
        term: ''
      },
      rules: {
        name: [{ required: true, message: '请输入套餐名称', trigger: 'blur' }],
        price: [{ required: true, message: '请输入套餐价格', trigger: 'blur' }],
        term: [{ required: true, message: '请输入有效期', trigger: 'blur' }],
      }
    };
  },
  methods: {
    openDialog(data) {
      // 打开弹出框，并触发父组件的事件传递参数  
      this.dialogVisible = true;
      this.edit = false
      if (data) {
        this.form = data
        this.edit = true
      } else {
        this.reset()
      }
    },
    cancel() {
      // 关闭弹出框  
      this.dialogVisible = false;
    },
    reset() {
      this.form = {
        name: '',
        price: '',
        term: ''
      }
    },
    save() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.edit) {
            updatePackage(this.form).then(res => {
              if (res.state === '200') {
                this.$message({
                  message: '修改成功！',
                  type: 'success'
                });
                this.$emit('child1-event')
                this.dialogVisible = false
              }
            })
          } else {
            addPackage(this.form).then(res => {
              if (res.state === '200') {
                this.$message({
                  message: '添加成功！',
                  type: 'success'
                });
                this.$emit('child1-event')
                this.dialogVisible = false
              }
            })
          }
        }
      })
    }
  }
};
</script>
<style>
.dialog-footer {
  text-align: center;
}

.packageDialog {
  margin-top: 20vh !important;
  width: 25%;
}
</style>
