<template>
    <a-modal title="选择地点" @cancel="handleCancelMap" width="80%">
        <iframe width="100%" style="height: 60vh; width: 40vw; border: none;" :src="map_src"></iframe>
        <template slot="footer">
            <!-- <a-button @click="handleSureMap" type='primary'>确定</a-button> -->
            <!-- <a-button @click="handleCancelMap" type='primary'>取消</a-button> -->
        </template>
    </a-modal>
</template>
  
<script>
export default {
    name: 'mapComponents',
    // props: {
    //     visibleMap: {
    //         type: Boolean,
    //         default: false,
    //     }
    // },
    data() {
        return {
            map_src: 'https://mapapi.qq.com/web/mapComponents/locationPicker/v/index.html?search=1&type=1&key=' + 'J53BZ-TJVEW-GYQRW-YKTJP-RZ3YH-LQFMO' + '&referer=location',
            form: {
                //省市区ID
                id_area: [
                    0,
                    0,
                    0
                ],
                address: '',
                lng: '',
                lat: '',
            },
            map_data: {
                url: 'https://mapapi.qq.com/web/mapComponents/locationPicker/v/index.html?search=1&type=1&key=' + 'J53BZ-TJVEW-GYQRW-YKTJP-RZ3YH-LQFMO' + '&referer=location',
                address: '',
                lng: '',
                lat: '',
            }
        }
    },
    created() {
        let that = this
        window.addEventListener('message', function (event) {
            // 接收位置信息，用户选择确认位置点后选点组件会触发该事件，回传用户的位置信息
            var loc = event.data;
            if (loc && loc.module == 'locationPicker') { //防止其他应用也会向该页面post信息，需判断module是否为'locationPicker'
                that.map_data.address = loc.poiaddress
                that.map_data.lat = loc.latlng.lat
                that.map_data.lng = loc.latlng.lng
                that.$emit('chooseMapPoint', that.map_data)
            } else {
                that.$emit('chooseMapPoint', '选择数据为空')
            }
        }, false);
    },
    methods: {
        //地图选点确定
        handleSureMap() {
            console.log(this.map_data)
            // this.$emit('loaclData', this.map_data)
        },
        // 取消
        handleCancelMap() {
            this.$emit('cancel')
        },
    }
}
</script>
  
<style>
.location{
    width: 30vw;
}
</style>
  