<template>
	<div class="systemSettingsContainer">
		<div class="systemSettingsNav">
			<el-menu :default-active="sys_defaultIndex" class="el-menu-demo" mode="horizontal" @select="sys_handleSelect">
				<el-menu-item index="memberLevel">会员级别</el-menu-item>
				<el-menu-item index="packageSettings">套餐设置</el-menu-item>
				<el-menu-item index="projectSettings">项目设置</el-menu-item>
				<el-menu-item index="pointsSettings">积分设置</el-menu-item>
				<el-menu-item index="repairSettings">维修设置</el-menu-item>
				<el-menu-item index="customerSettings">客户设置</el-menu-item>
				<el-menu-item index="vehicleSettings">车辆设置</el-menu-item>
				<el-menu-item index="customerTags">客户标签</el-menu-item>
				<el-menu-item index="maintenanceNum">保养间隔天数</el-menu-item>
				<el-menu-item index="depositMethod">储值方式</el-menu-item>
				<el-menu-item index="cardType">卡片类型</el-menu-item>
				<el-menu-item index="IntergralDk">积分抵扣</el-menu-item>
			</el-menu>
		</div>
		<div class="systemSettingsMain">
			<!-- 会员级别 -->
			<div class="main" v-if="sys_activeIndex === 'memberLevel'">
				<MemberCard />
			</div>
			<!-- 套餐设置 -->
			<div class="main" v-if="sys_activeIndex === 'packageSettings'">
				<Package />
			</div>
			<!-- 项目设置 -->
			<div class="main" v-if="sys_activeIndex === 'projectSettings'">
				<Project />
			</div>
			<!-- 积分设置 -->
			<div class="main" v-if="sys_activeIndex === 'pointsSettings'">
				<Integral />
			</div>
			<!-- 维修设置 -->
			<div class="main" v-if="sys_activeIndex === 'repairSettings'">
				<Repaire />
			</div>
			<!-- 客户设置 -->
			<div class="main" v-if="sys_activeIndex === 'customerSettings'">
				<Customer />
			</div>
			<!-- 车辆设置 -->
			<div class="main" v-if="sys_activeIndex === 'vehicleSettings'">
				<Car />
			</div>
			<!-- 客户标签 -->
			<div class="main" v-if="sys_activeIndex === 'customerTags'">
				<CustomerTag />
			</div>
			<!-- 保养间隔天数 -->
			<div class="main" v-if="sys_activeIndex === 'maintenanceNum'">
				<BaoYang />
			</div>
			<!-- 储值方式 -->
			<div class="main" v-if="sys_activeIndex === 'depositMethod'">
				<ChuZhi />
			</div>
			<!-- 卡片类型 -->
			<div class="main" v-if="sys_activeIndex === 'cardType'">
				<Card />
			</div>
			<div class="main" v-if="sys_activeIndex === 'IntergralDk'">
				<IntergralDk />
			</div>
		</div>
	</div>
</template>
<script>
import MemberCard from './component/systemSettings/MemberCard.vue'
import Package from './component/systemSettings/Package.vue'
import Project from './component/systemSettings/Project.vue'
import Integral from './component/systemSettings/Integral.vue'
import Repaire from './component/systemSettings/Repaire.vue'
import Customer from './component/systemSettings/Customer.vue'
import Car from './component/systemSettings/Car.vue'
import CustomerTag from './component/systemSettings/CustomerTag.vue'
import BaoYang from './component/systemSettings/BaoYang.vue'
import ChuZhi from './component/systemSettings/ChuZhi.vue'
import Card from './component/systemSettings/Card.vue'
import IntergralDk from './component/systemSettings/IntergralDk.vue'
import {
	vipList,
	changeStates
} from '@/api/systemOption/systemSettings/index'

export default {
	components: {
		MemberCard,
		Package,
		Project,
		Integral,
		Repaire,
		Customer,
		Car,
		CustomerTag,
		BaoYang,
		ChuZhi,
		Card,
		IntergralDk
	},
	data() {
		return {
			data: [],
			sys_defaultIndex: 'memberLevel',
			sys_activeIndex: 'memberLevel',
		}
	},

	mounted() {


	},
	methods: {

		sys_handleSelect(key, keyPath) {
			this.sys_activeIndex = key
		},

	}
}
</script>
<style scoped>
.systemSettingsContainer {
	display: flex;
	flex-direction: column;
	/* height: 100vh; */
	flex: 1;
}

.customerSettingsMain {
	background-color: #fff;
	width: 100%;
	padding: .125rem .25rem;
	flex: 1;
}


.tableStyle {
	padding: .125rem 0;
	display: flex;
	flex-direction: column;
	width: 80%;
}

.tableStyle .el-pagination {
	align-self: flex-start;
	margin: .125rem 0;
}


/*設置表头背景以及字体顏色*/
.headerClassName {
	font-weight: bold;
	color: black;
	background-color: rgb(226, 226, 226) !important;
	text-align: center !important;
}

.el-table__row--striped {
	background-color: rgb(242, 242, 242) !important;

}

.poi_title .el-divider__text.is-left {
	left: 0;
}

.poi_title .el-divider__text {
	font-weight: bold;
}

.el-form-item__label {
	font-size: 0.175rem
		/* 14/80 */
	;
}

.el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
	margin-bottom: 0.175rem
		/* 14/80 */
	;
}

.remarkStyle {
	display: inline-block;
	font-size: .125rem;
	color: rgb(232, 169, 140);
}

.poi_footer {
	padding: .25rem 1rem 0;
	color: rgb(233, 32, 80);
}

.poi_footer span {
	font-size: .225rem;
}

/*以下是关于弹出框的样式*/

.dialog-footer {
	text-align: center;
}
.main{
	flex: 1;
	display: flex;
	flex-direction: column;
	width: 100%;
}
</style>