<template>
    <div>
        <el-dialog custom-class="addCard" :visible.sync="dialogVisible" :modal="true" :append-to-body="true"
            :close-on-click-modal="false" @close="closeDialog()" width="25vw" top="20vh">
            <el-form ref="form" :model="form" label-width="100px" size="mini" :rules="rules">
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label="车辆品牌" prop="brandId">
                            <el-select v-model="form.brandId" placeholder="请选择">
                                <el-option v-for="item in brandData" :key="item.id" :label="item.brand" :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label="车辆系列" prop="name">
                            <el-input v-model="form.name"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="title" class="dialog-title">
                <span><i class="el-icon-warning"></i>{{ edit ? '编辑车辆系列' : '添加车辆系列' }}</span>
            </span>
            <span slot="footer" class="dialog-footer">
                <div slot="footer" class="dialog-footer">
                    <el-button size="small" @click="closeDialog()">取 消</el-button>
                    <el-button size="small" type="primary" @click="save()">确 定</el-button>
                </div>
            </span>
        </el-dialog>
    </div>
</template>  
    
<script>
import { brandList, addCar, updateCar, saveCarSeries, updateCarSeries } from '@/api/systemOption/systemSettings';

export default {
    data() {
        return {
            brandData: [],
            edit: false,
            dialogVisible: false,
            form: {
                brandId: '',
                name: ''
            },
            rules: {
                brandId: [{ required: true, message: '请输入车辆品牌', trigger: 'blur' }],
                name: [{ required: true, message: '请输入车辆系列', trigger: 'blur' }]
            }
        };
    },
    methods: {
        resetForm() {
            this.form = {
                brandId: '',
                name: ''
            }
        },
        openDialog(data) {
            // 打开弹出框，并触发父组件的事件传递参数  
            this.dialogVisible = true;
            this.edit = false
            this.getBrandList()
            if (data) {
                this.form = data
                this.edit = true
            } else {
                this.resetForm()
            }
        },
        getBrandList() {
            brandList().then(res => {
                if (res.state == '200') {
                    this.brandData = res.data
                }
            })
        },
        closeDialog() {
            // 关闭弹出框  
            this.dialogVisible = false;
            this.form = {
                brandId: '',
                name: ''
            }
            this.$refs.form?.resetFields()
        },
        save() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    if (this.form.id) {
                        updateCarSeries(this.form).then(res => {
                            if (res.state === '200') {
                                this.dialogVisible = false
                                this.$message({
                                    message: '修改成功！',
                                    type: 'success'
                                });
                                this.$emit('child-event')
                            }
                        })
                    } else {
                        saveCarSeries(this.form).then(res => {
                            if (res.state === '200') {
                                this.dialogVisible = false
                                this.$message({
                                    message: '添加成功！',
                                    type: 'success'
                                });
                                this.$emit('child-event')
                            }
                        })
                    }

                }
            })
        }
    }
};
</script>
<style scoped>
/* ::v-deep .el-dialog.addCard {
    margin-top: 20vh !important;
    width: 25vw !important;
} */
</style>
  