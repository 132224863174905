<template>
    <div>
        <el-dialog :visible.sync="dialogVisible" :modal="true" :append-to-body="true" :close-on-click-modal="false">
            <el-form ref="form" :model="form" label-width="100px" size="mini">
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label="客户姓名：" :rules="[{ required: true }]">
                            <el-input v-model="form.name" disabled></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label="手机号码：" :rules="[{ required: true }]">
                            <el-input v-model="form.telephone" disabled></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label="会员生日" :rules="[{ required: true }]">
                            <el-date-picker type="date" v-model="form.birthday" disabled value-format="yyyy-MM-dd"
                                placeholder="选择日期"></el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label="客户性别：" :rules="[{ required: true }]">
                            <el-select placeholder="请选择" v-model="form.gender" disabled>
                                <el-option label="男" :value="1"></el-option>
                                <el-option label="女" :value="2"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label="身份证号：" :rules="[{ required: true }]">
                            <el-input v-model="form.idCard" disabled></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label="所属地区：" :rules="[{ required: true }]">
                            <div style="display: flex;">
                                <p v-for="(item, index) in form.clientManageCarDOList" :key="index"
                                    style="margin-right: .125rem;font-size: .175rem;"> {{ form.city }}{{ form.area }}</p>
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label="详细地址：">
                            <div style="display: flex;">
                                <p v-for="(item, index) in form.clientManageCarDOList" :key="index"
                                    style="margin-right: .125rem;font-size: .175rem;">{{ form.detailed }}</p>
                            </div>
                            <!-- <el-input v-model="initialMessage"></el-input> -->
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label="车牌号码：">
                            <div style="display: flex;">
                                <p v-for="(item, index) in form.clientManageCarDOList" :key="index"
                                    style="margin-right: .125rem;font-size: .175rem;">{{ item.carNum }}</p>
                            </div>
                            <!-- <el-input v-model="form.carNum" disabled></el-input> -->
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label="车架号码：" :rules="[{ required: true }]">
                            <div style="display: flex;">
                                <p v-for="(item, index) in form.clientManageCarDOList" :key="index"
                                    style="margin-right: .125rem;font-size: .175rem;">{{ item.carFrameNumber }}</p>
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label="车型：" :rules="[{ required: true }]">
                            <div style="display: flex;">
                                <p v-for="(item, index) in form.clientManageCarDOList" :key="index"
                                    style="margin-right: .125rem;font-size: .175rem;">{{ item.model }}</p>
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label="购车日期：" :rules="[{ required: true }]">
                            <div style="display: flex;">
                                <p v-for="(item, index) in form.clientManageCarDOList" :key="index"
                                    style="margin-right: .125rem;font-size: .175rem;">{{ item.carBuyingTime }}</p>
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label="上牌日期：" :rules="[{ required: true }]">
                            <div style="display: flex;">
                                <p v-for="(item, index) in form.clientManageCarDOList" :key="index"
                                    style="margin-right: .125rem;font-size: .175rem;">{{ item.carRegistrationTime }}</p>
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="title" class="dialog-title">
                <span><i class="el-icon-warning"></i>会员审核</span>
            </span>
            <span slot="footer" class="dialog-footer">
                <div slot="footer" class="dialog-footer">
                    <el-button @click="audit(3)">驳回</el-button>
                    <el-button type="primary" @click="audit(1)">审核通过</el-button>
                </div>
            </span>
        </el-dialog>
        <!-- <chooseSc ref="chooseRef" @setStaff="setStaff" /> -->
    </div>
</template>  
    
<script>
import { CarTypeGetList, memberReviewDetails, memberReview, getStaffList } from '@/api/memberManage/index'
import chooseSc from './chooseSc.vue';
export default {
    components: { chooseSc },
    data() {
        return {
            dialogVisible: false, // 控制弹出框的显示与隐藏  
            carList: [],
            form: {},
            id: 0,
            type: ''
        };
    },
    created() {
        this.getCarTypeGetList()
    },
    methods: {
        openDialog(id) {
            // 打开弹出框，并触发父组件的事件传递参数  
            this.dialogVisible = true;
            this.id = id
            this.getInfo(id)
            // this.$emit('child-event', 'Hello from child');
        },
        closeDialog() {
            // 关闭弹出框  
            this.dialogVisible = false;
        },
        getCarTypeGetList() {
            CarTypeGetList({}).then(res => {
                let { data } = res
                this.carList = data
            })
        },
        getInfo(id) {
            memberReviewDetails({ id: id }).then(res => {
                let { data } = res
                this.form = data
            })
        },
        // setStaff(userid) {
        //     memberReview({ id: this.id, actived: this.type, userid }).then(res => {
        //         if (res.state == '200') {
        //             this.$message.success('操作成功')
        //             this.dialogVisible = false;
        //             this.$emit('child-event', 'Hello from child');
        //         }
        //     })
        // },
        audit(e) {
            memberReview({ id: this.id, actived: e }).then(res => {
                if (res.state == '200') {
                    this.$message.success('操作成功')
                    this.dialogVisible = false;
                    this.$emit('child-event', 'Hello from child');
                }
            })
            // this.type = e
            //推送
            // this.$refs.chooseRef.openDialog()


        }
    }
};
</script>
<style scoped>
/*以下是关于弹出框的样式*/

.dialog-footer {
    text-align: center;
}

/*dialog主体的宽*/
.el-dialog {
    width: 25vw !important;
    margin-top: 10vh !important;
    max-height: 85vh;
}

.dialog-title {
    width: 100%;
    font-size: .1875rem !important;
    color: white;
    font-weight: bold;

}

.el-dialog__header {
    padding-top: .125rem !important;
    background-image: linear-gradient(to right, rgb(254, 91, 75), rgb(249, 200, 38));
    border-radius: .125rem .125rem 0 0 !important;
}

/*改变dialog高度*/
.dialogContent {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.el-dialog {
    border-radius: .125rem !important;
}

.el-dialog__body {
    padding: .25rem;
}
</style>
  