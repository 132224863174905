import request from '@/utils/request'

export function getWechatMch(data) {
    return request({
        url: '/sys/wechatMchConfig/getWechatMch',
        method: 'get',
        data: data
    })
}
export function configWechatMch(data) {
    return request({
        url: '/sys/wechatMchConfig/configWechatMch',
        method: 'post',
        upload: true,
        data: data
    })
}
export function getBasicConfiguration(data) {
    return request({
        url: '/sys/basicConfiguration/getBasicConfiguration',
        method: 'get',
        data: data
    })
}
export function configBasicConfiguration(data) {
    return request({
        url: '/sys/basicConfiguration/configBasicConfiguration',
        method: 'post',
        data: data
    })
}
export function getRegistrationAgreement(data) {
    return request({
        url: '/sys/registrationAgreement/getRegistrationAgreement',
        method: 'get',
        data: data
    })
}
export function configRegistrationAgreement(data) {
    return request({
        url: '/sys/registrationAgreement/configRegistrationAgreement',
        method: 'post',
        data: data
    })
}
export function addPrinterConfig(data) {
    return request({
        url: '/sys/printerConfig/addPrinterConfig',
        method: 'post',
        data: data
    })
}
export function getPrinterConfig(data) {
    return request({
        url: '/sys/printerConfig/getPrinterConfig',
        method: 'post',
        data: data
    })
}