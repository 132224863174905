<template>
    <div class="ii_container">
        <el-menu :default-active="ii_defaultIndex" class="el-menu-demo" mode="horizontal" @select="ii_handleSelect">
            <el-menu-item index="ii_tag1">增加积分</el-menu-item>
        </el-menu>
        <div class="vs_main">
            <div class="search">
                <el-form :model="formQuery" size="small" :inline="true" class="form">
                    <el-form-item label="手机号:">
                        <el-input ref="teleRef" placeholder="输入手机号查询" v-model="formQuery.telephone"></el-input>
                    </el-form-item>
                    <el-form-item label="所属门店:">
                        <el-select v-model="formQuery.store" placeholder="请选择门店" @change="storeChange">
                            <el-option v-for="(item, index) in StoreList" :key="item.id" :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
                <el-button type="primary" size="small" @click="search">搜索</el-button>
            </div>
            <div class="des">
                <el-descriptions class="margin-top" :column="3" size="mini" border :contentStyle="content_style"
                    :labelStyle="label_style">
                    <template slot="title">
                        <span class="title">会员基本信息</span>
                    </template>
                    <el-descriptions-item label="手机号" :span="1">
                        <span class="dg">{{ memberInfo.telephone }}</span>
                    </el-descriptions-item>
                    <el-descriptions-item label="会员卡级别" :span="1">
                        <span class="dg">{{ memberInfo.vipLevelName ? memberInfo.vipLevelName : '普通用户' }}</span>
                    </el-descriptions-item>
                    <el-descriptions-item label="客户自费" :span="1">
                        <span class="addYellow dg">{{ memberInfo.selfBalance ? memberInfo.selfBalance : 0 }}</span>
                    </el-descriptions-item>
                    <el-descriptions-item label="姓名" :span="1">
                        <span class="dg"> {{ memberInfo.name }}</span>
                    </el-descriptions-item>
                    <el-descriptions-item :span="1">
                        <template slot="label">
                            <span style="color: red;">*</span>车牌号码
                        </template>
                        <el-select class="dg" placeholder="请选择" v-model="carNum" @change="carChange" size="small">
                            <el-option v-for="(item, index) in memberInfo.carTypeList" :key="index" :label="item.carNum"
                                :value="item.carNum"></el-option>
                        </el-select>
                    </el-descriptions-item>
                    <el-descriptions-item label="销售赠送" :span="1">
                        <span class="addYellow dg">{{ memberInfo.salesBalance ? memberInfo.salesBalance : 0 }}</span>
                    </el-descriptions-item>
                    <el-descriptions-item label="身份证号" :span="1">
                        <span class="dg"> {{ memberInfo.idCard }}</span>
                    </el-descriptions-item>
                    <el-descriptions-item label="车型" :span="1">
                        <span class="dg"> {{ model }}</span>
                    </el-descriptions-item>
                    <el-descriptions-item label="可用积分" :span="1">
                        <span class="addYellow dg">{{ memberInfo.credit ? memberInfo.credit : 0 }}</span>
                    </el-descriptions-item>
                    <el-descriptions-item label="介绍人" :span="1">
                        <span class="dg">{{ memberInfo.sellerId }}</span>
                    </el-descriptions-item>
                    <el-descriptions-item label="发卡门店" :span="1">
                        <span class="dg"> {{ memberInfo.storeDO && memberInfo.storeDO.name ? memberInfo.storeDO.name : ''
                        }}</span>
                    </el-descriptions-item>
                    <el-descriptions-item label="" :span="1">

                    </el-descriptions-item>
                    <el-descriptions-item label="备注" :span="2">
                        <el-input class="dg" size="small" v-model="memberInfo.remarks"></el-input>
                    </el-descriptions-item>
                    <!-- <el-descriptions-item label="备注" :span="2">
                        <span class="dg"> {{ memberInfo.remarks }}</span>
                    </el-descriptions-item> -->
                </el-descriptions>
            </div>
            <el-divider style="color: rgb(79,79,79);" content-position="left" class="po_title">积分增加操作</el-divider>
            <div class="vs_center">
                <div style="flex: 1;">
                    <el-divider style="color: rgb(79,79,79);" content-position="left" class="po_title">积分增加所有项</el-divider>
                    <div class="tableStyle">
                        <el-table :data="memberInfo.ruleList" :stripe="true" size="mini"
                            header-cell-class-name="headerClassName" :cell-style="{ 'text-align': 'center' }" :border="true"
                            :fit="true" @cell-click="cellDj" height="400">
                            <!-- <el-table-column prop="id" label="序号">
                            </el-table-column> -->
                            <el-table-column prop="donationName" label="规则名称">
                            </el-table-column>
                            <el-table-column prop="vipLevelName" label="会员级别">
                            </el-table-column>
                            <el-table-column prop="name" label="积分规则">
                            </el-table-column>
                            <el-table-column prop="integral" label="积分/次">
                            </el-table-column>
                            <el-table-column prop="money" label="金额">
                            </el-table-column>
                            <el-table-column prop="earnPoints" label="积分">
                            </el-table-column>
                            <el-table-column prop="propertyTypeId" label="关联操作">
                                <template slot-scope="scope">
                                    <p v-if="scope.row.propertyTypeId == 1">客户自费维保消费</p>
                                </template>
                            </el-table-column>
                            <el-table-column prop="multiple" label="积分倍数">

                            </el-table-column>
                            <el-table-column prop="isCumulative" label="是否累计">
                                <template slot-scope="scope">
                                    <p v-if="scope.row.isCumulative == 1">是</p>
                                    <p v-if="scope.row.isCumulative == 0">否</p>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
            </div>
            <div class="vs-footer">
                <div class="table1">
                    <el-descriptions class="margin-top" :column="1" size="mini" border :labelStyle="label_style">
                        <el-descriptions-item label="积分规则" :span="1">
                            <span class="dg">{{ info.name }}</span>
                        </el-descriptions-item>
                        <el-descriptions-item label="积分倍数" :span="1">
                            <span class="dg"> {{ info.multiple }}</span>
                        </el-descriptions-item>
                        <el-descriptions-item label="金额" :span="1">
                            <span class="dg"> {{ info.money }}</span>
                        </el-descriptions-item>
                        <el-descriptions-item label="积分" :span="1">
                            <span class="dg"> {{ info.earnPoints }}</span>
                        </el-descriptions-item>
                        <el-descriptions-item label="消费金额" :span="1">
                            <el-input :disabled="disabled" class="dg" size="small" v-model="expendMoney"
                                type="number"></el-input>
                        </el-descriptions-item>
                    </el-descriptions>
                </div>
                <div class="btn1">
                    <div><el-button type="primary" @click="addList" size="small" :disabled="isDj">添加</el-button><i
                            class="el-icon-caret-right rowStyle"></i></div>
                    <div><el-button type="primary" @click="del" size="small" :disabled="isDj">删除</el-button><i
                            class="el-icon-caret-left rowStyle"></i>
                    </div>
                </div>
                <div class="table2">
                    <el-divider style="color: rgb(79,79,79);" content-position="left" class="po_title">套餐待消费项</el-divider>
                    <div class="tableStyle">
                        <el-table :data="list" :stripe="true" size="mini" header-cell-class-name="headerClassName"
                            :cell-style="{ 'text-align': 'center' }" :border="true" :fit="true" height="400">
                            <el-table-column type="index" label="序号"></el-table-column>
                            <el-table-column prop="donationName" label="规则名称" width="150">
                            </el-table-column>
                            <el-table-column prop="name" label="积分规则">
                            </el-table-column>
                            <el-table-column prop="money" label="金额">
                            </el-table-column>
                            <el-table-column prop="earnPoints" label="积分">
                            </el-table-column>
                            <el-table-column prop="creditAdd" label="积分增加">
                            </el-table-column>
                            <el-table-column prop="multiple" label="积分倍数">
                            </el-table-column>
                            <el-table-column prop="isCumulative" label="是否累计">
                            </el-table-column>
                        </el-table>
                    </div>
                </div>

            </div>
            <div>
                <div style="display: flex;justify-content: center;margin-top: 20px;"><el-button type="primary" @click="save"
                        size="small" :disabled="isDj">保存</el-button></div>
            </div>
        </div>
        <safeAuth ref="safeAuthRef"></safeAuth>
    </div>
</template>
<script>
import { getCredit, addCreditAdd, addCreditCons, addCrossStoreConsumptionCreditRecord } from '@/api/memberConsume';
import * as memberApi from '@/api/memberManage/index'
import safeAuth from '../memberConsumModal/safeAuth.vue';
import axios from 'axios';
export default {
    components: { safeAuth },
    data() {
        return {
            disabled: false,
            ii_defaultIndex: 'ii_tag1',
            ii_activeIndex: 'ii_tag1',
            content_style: {
                'width': '20%',
            },
            content_style_table1: {
                'width': '70%',
            },
            label_style: {
                'width': '1.5rem'
            },
            formQuery: {
                telephone: '',
                store: ''
            },
            memberInfo: {},
            carNum: '',
            info: {},
            expendMoney: '',
            list: [],
            model: '',
            StoreList: [],
            isCurrentStore: '',
            baseUrl: '',
            isDj: false
        }
    },
    mounted() {
        this.$refs.teleRef.focus()
        this.getSoreList()
    },
    methods: {
        getSoreList() {
            memberApi.storeGetList({}).then(res => {
                let { data } = res
                this.StoreList = data
                data.forEach(item => {
                    if (item.isStore) {
                        console.log('item:', item);
                        this.formQuery.store = item.id
                        this.isCurrentStore = true
                    }
                })
            })
        },
        storeChange(e) {
            const nowStore = this.StoreList.find(item => item.id == this.formQuery.store)
            if (nowStore && !nowStore.isStore) {
                this.baseUrl = nowStore.baseUrl
                this.isCurrentStore = false
                let kdToken = sessionStorage.getItem(e)
                if (!kdToken || kdToken == '') {
                    this.openSafeAuthDialog()
                }
            } else if (nowStore && nowStore.isStore) {
                this.isCurrentStore = true
            }

        },
        openSafeAuthDialog() {
            this.$refs.safeAuthRef.openDialog({ baseUrl: this.baseUrl, storeId: this.formQuery.store })
        },
        getToken() {
            return sessionStorage.getItem(this.formQuery.store)
        },
        ii_handleSelect(key, keyPath) {
            this.ii_activeIndex = key
        },
        search() {
            if (!this.isCurrentStore) {
                const token = this.getToken()
                if (token && token != '') {
                    const url = this.baseUrl + 'vip/expend/getCredit'
                    axios.post(url, this.formQuery, {
                        headers: {
                            "AuthorizationToken": token
                        }
                    }).then(response => {
                        let res = response.data
                        if (res.state == '200') {
                            if (res.data[0].actived == 4) {
                                this.$message.info('该会员已冻结')
                                this.isDj = true
                            }
                            this.memberInfo = res.data[0]
                        } else if (res.state == '20002') {
                            this.$message.error(res.error)
                        } else if (res.state == '20001') {
                            this.isTokenGq()
                        }
                    }, err => {
                        console.log(err);
                    })
                } else {
                    this.openSafeAuthDialog()
                }
            } else {
                getCredit(this.formQuery).then(res => {
                    if (res.data[0].actived == 4) {
                        this.$message.info('该会员已冻结')
                        this.isDj = true
                    }
                    this.memberInfo = res.data[0]
                })
            }
        },
        isTokenGq() {
            sessionStorage.removeItem(this.formQuery.store)
            this.$message.error('token已过期，请重新登录认证')
            this.openSafeAuthDialog()
        },
        carChange(e) {
            this.memberInfo.carTypeList.forEach(item => {
                if (item.carNum == e) {
                    this.carNum = item.carNum
                    this.carFrameNumber = item.carFrameNumber
                    this.model = item.model
                }
            })
        },
        del() {
            if (this.list.length == 0) {
                this.$message.error('请先添加')
            } else {
                this.list.splice(this.list.length - 1, 1);
            }
        },
        cellDj(e) {
            this.disabled = false
            console.log(e)
            this.info = e
            if (e.integralRule == '3') {
                // 按次数 消费金额为1
                this.expendMoney = 1
                this.disabled = true
            }

        },
        addList() {
            if (this.carNum == '') {
                this.$message.error('请先选择车牌号码')
                return
            }
            let flag = this.list.find(item => item.name == this.info.name)
            if (flag) {
                this.$message.error('不能重复添加')
                return
            }

            this.info.expendMoney = this.expendMoney
            addCreditAdd(this.info).then(res => {
                if (res.state == '200') {
                    this.list.push({ ...res.data, expendMoney: this.expendMoney })
                }
            })
        },
        save() {
            if (this.carNum == '') {
                this.$message.error('请先选择车牌号码')
                return
            }
            if (!this.isCurrentStore) {
                const token = this.getToken()
                if (token && token != '') {
                    const url = this.baseUrl + 'vip/expend/addCreditCons'
                    axios.post(url, { telephone: this.memberInfo.telephone, list: this.list, carNum: this.carNum, carFrameNumber: this.carFrameNumber, remarks: this.memberInfo?.remarks || '' }, {
                        headers: {
                            "AuthorizationToken": token
                        }
                    }).then(response => {
                        let res = response.data
                        if (res.state == '200') {
                            // const list = this.list
                            const newList = this.list.map(item => {
                                return {
                                    telephone: this.formQuery.telephone, //手机号
                                    vipLevelName: this.memberInfo.vipLevelName || '普通用户', //会员卡名称
                                    vipName: this.memberInfo.name, //会员姓名
                                    carNum: this.carNum, //车牌号
                                    carFrameNumber: this.carFrameNumber, //车架号
                                    hairCard: this.memberInfo.storeDO?.name || '', //发卡店
                                    ruleName: item.name, //规则名称
                                    rule: item.integralRule, //积分规则
                                    money: item.money || '', //金额
                                    credit: item.earnPoints || '', //积分
                                    num: item.creditAdd || '', //积分/次
                                    associationOperation: 1, //是否关联操作
                                    multiple: item.multiple || '', //积分倍数
                                    isAccumulate: item.isCumulative, //是否累计
                                    consumptionMoney: item.money * item.creditAdd || 0, //消费金额
                                    availableCredit: this.memberInfo.credit || 0, //可用积分
                                    addCredit: item.creditAdd || 0,//增加积分
                                    remarks: this.memberInfo?.remarks || ''
                                }
                            })
                            addCrossStoreConsumptionCreditRecord(newList).then(res => {
                                if (res.state == '200') {
                                    this.$message.success('操作成功')
                                    this.list = []
                                    this.search()
                                    console.log('积分增加跨店记录添加成功');
                                }
                            })
                        } else if (res.state == '20002') {
                            this.$message.error(res.error)
                        } else if (res.state == '20001') {
                            this.isTokenGq()
                        }
                    }, err => {
                        console.log(err);
                    })
                } else {
                    this.openSafeAuthDialog()
                }
            } else {
                addCreditCons({ telephone: this.memberInfo.telephone, list: this.list, carNum: this.carNum, carFrameNumber: this.carFrameNumber, remarks: this.memberInfo?.remarks || '' }).then(res => {
                    if (res.state == '200') {
                        this.$message.success('操作成功')
                        this.list = []
                        this.search()
                    }
                })
            }
        }

    },

}
</script>
<style scoped>
::v-deep .el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
    margin-bottom: 0rem !important;
}

::v-deep .el-descriptions__header {
    margin-bottom: 0px !important;
}

.addYellow {
    color: rgb(244, 124, 0);
    font-weight: 600;
}

.addRed {
    color: red;
    font-weight: 600;
}

.addGreen {
    color: #3CB371;
    font-weight: 600;
}

.ii_container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.vs_main {
    background-color: #fff;
    width: 100%;
    padding: .125rem .25rem;
    flex: 1;
    display: flex;
    flex-direction: column;
    /* overflow: auto; */
}

.search {
    width: 64%;
    padding: 0 0 .125rem 0;
    border-radius: .125rem;
    display: flex;
}

.vs_main {
    width: 80%;
}


.vs_main .el-divider__text {
    color: rgb(79, 79, 79);
    font-size: medium;
    font-weight: bold;
    left: 0;
}

.vs_footer {
    width: 100%;
    flex: 1;
    display: flex;
    justify-content: space-between;
}

.btn {
    width: 100%;
    text-align: center;
    padding: .25rem 0;
}

.tableStyle {
    padding: 0 0 .125rem 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 3.5rem;
}

.tableStyle .el-pagination {
    align-self: flex-start;
    margin: .125rem 0;
}

/*設置表头背景以及字体顏色*/
.headerClassName {
    font-weight: bold;
    color: black;
    background-color: rgb(226, 226, 226) !important;
    text-align: center !important;
}

.el-table__row--striped {
    background-color: rgb(242, 242, 242) !important;

}

.des .el-descriptions__header {
    margin-bottom: 0;
}

.vs-footer {
    flex: 1;
    display: flex;
    width: 100%;
}

.table1 {
    width: 3.75rem;
    margin-top: .625rem;
}

.btn1 {
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: .625rem;
}

.btn1 .el-button {
    margin-bottom: .125rem;
}

.rowStyle {
    font-size: large;
    color: rgb(64, 158, 255);
}

.table2 {
    flex: 1;
}
</style>