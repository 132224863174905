import request from '@/utils/request'

//#region common方法
// 获取项目名称
export function getPackageNameList(data) {
    return request({
        url: '/points/getPackageNameList',
        method: 'post',
        data: data
    })
}
// 获取用户管理员列表
export function getUserList(data) {
    return request({
        url: '/user/login/getList',
        method: 'post',
        data: data
    })
}
// 服务项目
export function donationItem(data) {
    return request({
        url: '/sys/donationItem/getList',
        method: 'post',
        data: data
    })
}
// 套餐名称
export function serviceItem(data) {
    return request({
        url: '/sys/serviceItem/getList',
        method: 'post',
        data: data
    })
}
// 根据套餐获取服务项目
export function servicePackageItem(data) {
    return request({
        url: '/sys/servicePackageItem/getList',
        method: 'post',
        data: data
    })
}

//#endregion

//#region 自费储值明细
//自费储值明细列表
export function getSelfRechargePage(data) {
    return request({
        url: '/finance/self/getSelfRechargePage',
        method: 'post',
        data: data
    })
}

//自费储值明细列表汇总信息
export function getSelfRechargeSummary(data) {
    return request({
        url: '/finance/self/getSelfRechargeSummary',
        method: 'post',
        data: data
    })
}
//自费储值明细列表导出
export function exportSelfRechargeList(data) {
    return request({
        url: '/finance/self/exportSelfRechargeList',
        method: 'post',
        data: data,
        responseType: 'blob'
    })
}
//#endregion


//#region 储值消费明细
//储值消费明细查询列表
export function getSelfConsumptionPage(data) {
    return request({
        url: '/finance/self/getSelfConsumptionPage',
        method: 'post',
        data: data
    })
}
//储值消费明细查询列表汇总信息
export function getSelfConsumptionSummary(data) {
    return request({
        url: '/finance/self/getSelfConsumptionSummary',
        method: 'post',
        data: data
    })
}
//储值消费明细查询列表
export function exportSelfConsumptionList(data) {
    return request({
        url: '/finance/self/exportSelfConsumptionList',
        method: 'post',
        data: data,
        responseType: 'blob'
    })
}

//#endregion


//#region 自费储值汇总
export function getSelfSumPage(data) {
    return request({
        url: '/finance/self/getSelfSumPage',
        method: 'post',
        data: data
    })
}
export function getSelfSumSummary(data) {
    return request({
        url: '/finance/self/getSelfSumSummary',
        method: 'post',
        data: data
    })
}
export function exportSelfSumList(data) {
    return request({
        url: '/finance/self/exportSelfSumList',
        method: 'post',
        data: data,
        responseType: 'blob'
    })
}
//#endregion

//#region 销售赠送储值明细
export function getSalesRechargePage(data) {
    return request({
        url: '/finance/sales/getSalesRechargePage',
        method: 'post',
        data: data
    })
}
export function getSalesRechargeSummary(data) {
    return request({
        url: '/finance/sales/getSalesRechargeSummary',
        method: 'post',
        data: data
    })
}
export function exportSalesRechargePage(data) {
    return request({
        url: '/finance/sales/exportSalesRechargePage',
        method: 'post',
        data: data,
        responseType: 'blob'
    })
}
//#endregion

//#region 销售赠送消费明细
export function getSalesConsumptionPage(data) {
    return request({
        url: '/finance/sales/getSalesConsumptionPage',
        method: 'post',
        data: data
    })
}
export function getSalesConsumptionSummary(data) {
    return request({
        url: '/finance/sales/getSalesConsumptionSummary',
        method: 'post',
        data: data
    })
}
export function exportSalesConsumptionPage(data) {
    return request({
        url: '/finance/sales/exportSalesConsumptionPage',
        method: 'post',
        data: data,
        responseType: 'blob'
    })
}
//#endregion

//#region 售后赠送储值明细
export function getAfterSalesRechargePage(data) {
    return request({
        url: '/finance/afterSales/getAfterSalesRechargePage',
        method: 'post',
        data: data
    })
}
export function getAfterSalesRechargeSummary(data) {
    return request({
        url: '/finance/afterSales/getAfterSalesRechargeSummary',
        method: 'post',
        data: data
    })
}
export function exportAfterSalesRechargePage(data) {
    return request({
        url: '/finance/afterSales/exportAfterSalesRechargePage',
        method: 'post',
        data: data,
        responseType: 'blob'
    })
}
//#endregion

//#region 售后赠送消费明细
export function getAfterSalesConsumptionPage(data) {
    return request({
        url: '/finance/afterSales/getAfterSalesConsumptionPage',
        method: 'post',
        data: data
    })
}
export function getAfterSalesConsumptionSummary(data) {
    return request({
        url: '/finance/afterSales/getAfterSalesConsumptionSummary',
        method: 'post',
        data: data
    })
}
export function exportAfterSalesConsumptionPage(data) {
    return request({
        url: '/finance/afterSales/exportAfterSalesConsumptionPage',
        method: 'post',
        data: data,
        responseType: 'blob'
    })
}
//#endregion

//#region 保险赠送储值明细
export function getInsurancePage(data) {
    return request({
        url: '/finance/insurance/getInsurancePage',
        method: 'post',
        data: data
    })
}
export function getInsuranceRechargeSummary(data) {
    return request({
        url: '/finance/insurance/getInsuranceRechargeSummary',
        method: 'post',
        data: data
    })
}
export function exportInsuranceRechargePage(data) {
    return request({
        url: '/finance/insurance/exportInsuranceRechargePage',
        method: 'post',
        data: data,
        responseType: 'blob'
    })
}
//#endregion

//#region 保险赠送消费明细
export function getInsuranceConsumptionSummary(data) {
    return request({
        url: '/finance/insurance/getInsuranceConsumptionSummary',
        method: 'post',
        data: data
    })
}
export function exportInsuranceConsumptionPage(data) {
    return request({
        url: '/finance/insurance/exportInsuranceConsumptionPage',
        method: 'post',
        data: data,
        responseType: 'blob'
    })
}
//#endregion

//#region 套餐开卡明细
export function getPackagePage(data) {
    return request({
        url: '/finance/package/getPackagePage',
        method: 'post',
        data: data
    })
}
export function getPackageRechargeSummary(data) {
    return request({
        url: '/finance/package/getPackageRechargeSummary',
        method: 'post',
        data: data
    })
}
export function exportPackageRechargePage(data) {
    return request({
        url: '/finance/package/exportPackageRechargePage',
        method: 'post',
        data: data,
        responseType: 'blob'
    })
}
//#endregion

//#region 套餐余额明细
export function getPackageBalancePage(data) {
    return request({
        url: '/finance/package/getPackageBalancePage',
        method: 'post',
        data: data
    })
}
export function getPackageBalanceSummary(data) {
    return request({
        url: '/finance/package/getPackageBalanceSummary',
        method: 'post',
        data: data
    })
}
export function exportPackageBalancePage(data) {
    return request({
        url: '/finance/package/exportPackageBalancePage',
        method: 'post',
        data: data,
        responseType: 'blob'
    })
}
//#endregion

//#region 套餐消费明细
export function getPackageConsumptionSummary(data) {
    return request({
        url: '/finance/package/getPackageConsumptionSummary',
        method: 'post',
        data: data
    })
}
export function exportPackageConsumptionPage(data) {
    return request({
        url: '/finance/package/exportPackageConsumptionPage',
        method: 'post',
        data: data,
        responseType: 'blob'
    })
}
//#endregion

//#region 套餐信息项目汇总
export function getPackageInformationSummaryPage(data) {
    return request({
        url: '/finance/package/getPackageInformationSummaryPage',
        method: 'post',
        data: data
    })
}
export function exportPackageInformationSummaryPage(data) {
    return request({
        url: '/finance/package/exportPackageInformationSummaryPage',
        method: 'post',
        data: data,
        responseType: 'blob'
    })
}
//#endregion

//#region 赠送项目明细
export function pager(data) {
    return request({
        url: '/give/select/pager',
        method: 'post',
        data: data
    })
}
export function total(data) {
    return request({
        url: '/give/select/total',
        method: 'post',
        data: data
    })
}
export function exportPro(data) {
    return request({
        url: '/give/select/export',
        method: 'post',
        data: data,
        responseType: 'blob'
    })
}
//#endregion

//#region 赠送项目剩余明细
export function pagerSy(data) {
    return request({
        url: '/give/surplus/select/pager',
        method: 'post',
        data: data
    })
}
export function totalSy(data) {
    return request({
        url: '/give/surplus/select/total',
        method: 'post',
        data: data
    })
}
export function exportProSy(data) {
    return request({
        url: '/give/surplus/select/export',
        method: 'post',
        data: data,
        responseType: 'blob'
    })
}
//#endregion

//#region 赠送项目消费明细
export function pagerXf(data) {
    return request({
        url: '/give/consumption/select/pager',
        method: 'post',
        data: data
    })
}
export function totalXf(data) {
    return request({
        url: '/give/consumption/select/total',
        method: 'post',
        data: data
    })
}
export function exportProXf(data) {
    return request({
        url: '/give/consumption/select/export',
        method: 'post',
        data: data,
        responseType: 'blob'
    })
}
//#endregion

//#region 积分增加和消费公用
export function pagerPoints(data) {
    return request({
        url: '/points/select/pager',
        method: 'post',
        data: data
    })
}
export function totalPoints(data) {
    return request({
        url: '/points/select/total',
        method: 'post',
        data: data
    })
}
export function exportPoints(data) {
    return request({
        url: '/points/select/export',
        method: 'post',
        data: data,
        responseType: 'blob'
    })
}
//#endregion

//#region 积分增加模块 汇总明细
export function pagerHz(data) {
    return request({
        url: '/points/select/summary/pager',
        method: 'post',
        data: data
    })
}
export function exportHz(data) {
    return request({
        url: '/points/select/summary/export',
        method: 'post',
        data: data,
        responseType: 'blob'
    })
}
//#endregion

//#region 套餐项目汇总
export function packageHz(data) {
    return request({
        url: '/gift/package/project/pager',
        method: 'post',
        data: data
    })
}
export function exportPackageHz(data) {
    return request({
        url: '/gift/package/project/export',
        method: 'post',
        data: data,
        responseType: 'blob'
    })
}
//#endregion

//#region 赠送项目分析统计
export function giftPager(data) {
    return request({
        url: '/gift/project/pager',
        method: 'post',
        data: data
    })
}
export function exportGift(data) {
    return request({
        url: '/gift/project/export',
        method: 'post',
        data: data,
        responseType: 'blob'
    })
}
//#endregion

//#region 会员卡信息（退卡）
export function cardTPager(data) {
    return request({
        url: '/refund/list/pager',
        method: 'post',
        data: data
    })
}
export function totalCardT(data) {
    return request({
        url: '/refund/list/total',
        method: 'post',
        data: data
    })
}
export function exportCardT(data) {
    return request({
        url: '/refund/export',
        method: 'post',
        data: data,
        responseType: 'blob'
    })
}
//#endregion

//#region 套餐信息（退卡）
export function packageTPager(data) {
    return request({
        url: '/refund/package/list/pager',
        method: 'post',
        data: data
    })
}
export function totalPackageT(data) {
    return request({
        url: '/refund/package/list/total',
        method: 'post',
        data: data
    })
}
export function exportPackageT(data) {
    return request({
        url: '/refund/package/list/export',
        method: 'post',
        data: data,
        responseType: 'blob'
    })
}
//#endregion

//#region 赠送项目（退卡）
export function projectTPager(data) {
    return request({
        url: '/refund/project/list/pager',
        method: 'post',
        data: data
    })
}
export function totalProjectT(data) {
    return request({
        url: '/refund/project/list/total',
        method: 'post',
        data: data
    })
}
export function exportProjectT(data) {
    return request({
        url: '/refund/project/list/export',
        method: 'post',
        data: data,
        responseType: 'blob'
    })
}
//#endregion

//#region 发卡信息表
export function cardPager(data) {
    return request({
        url: '/hairpin/list/pager',
        method: 'post',
        data: data
    })
}
export function totalfKCard(data) {
    return request({
        url: '/hairpin/summary',
        method: 'post',
        data: data
    })
}
export function exportFkCard(data) {
    return request({
        url: '/hairpin/export',
        method: 'post',
        data: data,
        responseType: 'blob'
    })
}
//#endregion

//#region 会员冻结记录列表
export function DjPager(data) {
    return request({
        url: '/frozen/list/pager',
        method: 'post',
        data: data
    })
}
export function exportDj(data) {
    return request({
        url: '/frozen/exportClientFrozenRecordList',
        method: 'post',
        data: data,
        responseType: 'blob'
    })
}
//#endregion

//#region 会员升级记录列表
export function upgradePager(data) {
    return request({
        url: '/upgrade/list/pager',
        method: 'post',
        data: data
    })
}
export function exportUpgrade(data) {
    return request({
        url: '/upgrade/exportClientUpgradeVipList',
        method: 'post',
        data: data,
        responseType: 'blob'
    })
}
//#endregion

//#region 维保金额，积分分析统计 汇总信息
export function hairpinPager(data) {
    return request({
        url: '/hairpin/maintenance/list/pager',
        method: 'post',
        data: data
    })
}
export function hairpinTotal(data) {
    return request({
        url: '/hairpin/maintenance/list/total',
        method: 'post',
        data: data
    })
}
export function hairpinExport(data) {
    return request({
        url: '/hairpin/maintenance/list/export',
        method: 'post',
        data: data,
        responseType: 'blob'
    })
}
//#endregion

//#region 自费维保 总汇总
export function selfPagerAll(data) {
    return request({
        url: '/balance/self/overall/select',
        method: 'post',
        data: data
    })
}
export function selfExportAll(data) {
    return request({
        url: '/balance/self/overall/export',
        method: 'post',
        data: data,
        responseType: 'blob'
    })
}
export function selfPagerFl(data) {
    return request({
        url: '/balance/self/classification/pager',
        method: 'post',
        data: data
    })
}
export function selfExportFl(data) {
    return request({
        url: '/balance/self/classification/export',
        method: 'post',
        data: data,
        responseType: 'blob'
    })
}
//#endregion

//#region 销售赠送 总汇总
export function salePagerAll(data) {
    return request({
        url: '/balance/sale/overall/select',
        method: 'post',
        data: data
    })
}
export function saleExportAll(data) {
    return request({
        url: '/balance/sale/overall/export',
        method: 'post',
        data: data,
        responseType: 'blob'
    })
}
export function salePagerFl(data) {
    return request({
        url: '/balance/sale/classification/pager',
        method: 'post',
        data: data
    })
}
export function saleExportFl(data) {
    return request({
        url: '/balance/sale/classification/export',
        method: 'post',
        data: data,
        responseType: 'blob'
    })
}
//#endregion

//#region 售后赠送 总汇总
export function afterPagerAll(data) {
    return request({
        url: '/balance/sales/overall/select',
        method: 'post',
        data: data
    })
}
export function afterExportAll(data) {
    return request({
        url: '/balance/sales/overall/export',
        method: 'post',
        data: data,
        responseType: 'blob'
    })
}
export function afterPagerFl(data) {
    return request({
        url: '/balance/sales/classification/pager',
        method: 'post',
        data: data
    })
}
export function afterExportFl(data) {
    return request({
        url: '/balance/sales/classification/export',
        method: 'post',
        data: data,
        responseType: 'blob'
    })
}
//#endregion

//#region 金融保险赠送 总汇总
export function financePagerAll(data) {
    return request({
        url: '/balance/finance/overall/select',
        method: 'post',
        data: data
    })
}
export function financeExportAll(data) {
    return request({
        url: '/balance/finance/overall/export',
        method: 'post',
        data: data,
        responseType: 'blob'
    })
}
//#endregion
//#region 金融保险赠送 分类汇总
export function financePagerFl(data) {
    return request({
        url: '/balance/finance/classification/pager',
        method: 'post',
        data: data
    })
}
export function financeExportFl(data) {
    return request({
        url: '/balance/finance/classification/export',
        method: 'post',
        data: data,
        responseType: 'blob'
    })
}
//#endregion

//#region 会员卡汇总 增加和消费
export function summaryPagerAll(data) {
    return request({
        url: '/summary/recharge/pager',
        method: 'post',
        data: data
    })
}
export function summaryExportAll(data) {
    return request({
        url: '/summary/recharge/export',
        method: 'post',
        data: data,
        responseType: 'blob'
    })
}
//#endregion

//#region 会员卡汇总 明细
export function detailedPagerAll(data) {
    return request({
        url: '/summary/detailed/pager',
        method: 'post',
        data: data
    })
}
export function detailedExportAll(data) {
    return request({
        url: '/summary/detailed/export',
        method: 'post',
        data: data,
        responseType: 'blob'
    })
}
//#endregion




//#region 分页查询储值消费跨店记录
export function getCrossStoreConsumptionRecordPage(data) {
    return request({
        url: '/finance/crossStoreConsumptionRecord/getCrossStoreConsumptionRecordPage',
        method: 'post',
        data: data
    })
}
export function exportCrossStoreConsumptionRecord(data) {
    return request({
        url: '/finance/crossStoreConsumptionRecord/exportCrossStoreConsumptionRecord',
        method: 'post',
        data: data,
        responseType: 'blob'
    })
}
//#endregion

//#region 项目消费跨店记录
export function getCrossStoreConsumptionDonationRecord(data) {
    return request({
        url: '/finance/crossStoreConsumptionRecord/getCrossStoreConsumptionDonationRecord',
        method: 'post',
        data: data
    })
}
export function exportCrossStoreConsumptionDonationRecord(data) {
    return request({
        url: '/finance/crossStoreConsumptionRecord/exportCrossStoreConsumptionDonationRecord',
        method: 'post',
        data: data,
        responseType: 'blob'
    })
}
//#endregion

//#region 套餐消费跨店记录
export function getCrossStoreConsumptionPackageRecord(data) {
    return request({
        url: '/finance/crossStoreConsumptionRecord/getCrossStoreConsumptionPackageRecord',
        method: 'post',
        data: data
    })
}
export function exportCrossStoreConsumptionPackageRecord(data) {
    return request({
        url: '/finance/crossStoreConsumptionRecord/exportCrossStoreConsumptionPackageRecord',
        method: 'post',
        data: data,
        responseType: 'blob'
    })
}
//#endregion

//#region 分页查询积分增加跨店记录
export function getCrossStoreConsumptionCreditRecord(data) {
    return request({
        url: '/finance/crossStoreConsumptionRecord/getCrossStoreConsumptionCreditRecord',
        method: 'post',
        data: data
    })
}
export function exportCrossStoreConsumptionCreditRecord(data) {
    return request({
        url: '/finance/crossStoreConsumptionRecord/exportCrossStoreConsumptionCreditRecord',
        method: 'post',
        data: data,
        responseType: 'blob'
    })
}
//#endregion

// 获取部门接口
export function getStoreDepartmentList(data) {
    return request({
        url: '/sys/storeDepartment/getStoreDepartmentList',
        method: 'post',
        data: data
    })
}
