<template>
    <div>
        <el-dialog custom-class="redemption" :visible.sync="dialogVisible" :modal="true" :append-to-body="true"
            :close-on-click-modal="false">
            <div class="content">
                <i class="el-icon-warning"></i>
                <span>您确认要完成兑换？</span>
            </div>
            <span slot="title" class="dialog-title">
                <span>信息提示</span>
            </span>
            <div slot="footer" class="dialog-footer">
                <el-button size="small" @click="dialogVisible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="sure()">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>  
    
<script>
import { updateExchange } from '@/api/memberConsume';

export default {
    props: {
        initialMessage: {
            type: Object,
            default: {}
        }
    },
    data() {
        return {
            dialogVisible: false, // 控制弹出框的显示与隐藏  
            message: this.initialMessage, // 初始化时接收父组件传递的参数  
            id: ''
        };
    },
    methods: {
        openDialog(id) {
            // 打开弹出框，并触发父组件的事件传递参数  
            this.dialogVisible = true;
            this.id = id
        },
        closeDialog() {
            // 关闭弹出框  
            this.dialogVisible = false;
        },
        sure() {
            updateExchange({ id: this.id }).then(res => {
                if (res.state == '200') {
                    this.$message.success('兑换成功')
                    this.dialogVisible = false;
                    this.$emit('child-event', 'Hello from child');
                }
            })
        }
    },
    watch: {
        initialMessage(newValue) {
            // 监听父组件传递的参数变化，并更新子组件的数据  
            this.message = newValue;
        }
    }
};
</script>
<style scoped>
::v-deep .el-dialog {
    margin-top: 20vh !important;
    width: 20vw;
}

::v-deep .el-icon-warning {
    font-size: .25rem;
}

.content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .25rem;
}

.content span {
    font-size: .25rem;
}

/*以下是关于弹出框的样式*/

.dialog-footer {
    text-align: center;
}

/*dialog主体的宽*/
.redemption {
    width: 10vw !important;
    margin-top: 25vh !important;
    max-height: 85vh;
}

.dialog-title {
    width: 100%;
    font-size: .1875rem !important;
    color: white;
    font-weight: bold;

}

.el-dialog__header {
    padding-top: .125rem !important;
    background-image: linear-gradient(to right, rgb(254, 91, 75), rgb(249, 200, 38));
    border-radius: .125rem .125rem 0 0 !important;
}

/*改变dialog高度*/
.dialogContent {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.el-dialog {
    border-radius: .125rem !important;
}

.el-dialog__body {
    padding: .25rem;
}
</style>
  