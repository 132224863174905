<template>
    <div>
        <el-dialog :visible.sync="dialogVisible" :modal="true" :append-to-body="true" :close-on-click-modal="false"
            @close="closeDialog">
            <div class="addCarInfoMain">
                <el-divider content-position="left" class="poi_title">车辆信息</el-divider>
                <el-form ref="form" :model="form" label-width="1.5rem" size="small" :rules="rules">
                    <el-row :gutter="10">
                        <el-col :span="8">
                            <el-form-item label="车牌号码：" prop="carNum">
                                <el-input v-model="form.carNum"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="车架号码：" prop="carFrameNumber">
                                <el-input v-model="form.carFrameNumber"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="发动机号：" prop="carEngineNum">
                                <el-input v-model="form.carEngineNum"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="10">
                        <el-col :span="8">
                            <el-form-item label="上牌日期：">
                                <el-date-picker type="date" v-model="form.carRegistrationTime" value-format="yyyy-MM-dd"
                                    placeholder="选择日期" size="small">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="购车日期：" prop="carBuyingTime">
                                <el-date-picker type="date" v-model="form.carBuyingTime" value-format="yyyy-MM-dd"
                                    placeholder="选择日期" size="small">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="车辆品牌：" prop="brand">
                                <el-select clearable placeholder="请选择" v-model="form.brand" size="small" @change="changePp">
                                    <el-option v-for="(item, index) in carList" :key="index" :label="item.brand"
                                        :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="10">
                        <el-col :span="8">
                            <el-form-item label="车辆系列：" prop="series">
                                <el-select placeholder="请选择" v-model="form.series" size="small" @change="changeSeries">
                                    <el-option v-for="(item, index) in xlList" :key="index" :label="item.name"
                                        :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="车辆型号：" prop="model">
                                <el-select placeholder="请选择" v-model="form.model" size="small">
                                    <el-option v-for="(item, index) in xhList" :key="index" :label="item.model"
                                        :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="车辆颜色：">
                                <el-select placeholder="请选择" v-model="form.carColorId" size="small">
                                    <el-option v-for="(item, index) in colorList" :key="index" :label="item.color"
                                        :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="10">
                        <el-col :span="8">
                            <el-form-item label="车辆产地：">
                                <el-select placeholder="请选择" v-model="form.carProductionId" size="small">
                                    <el-option v-for="(item, index) in productionList" :key="index"
                                        :label="item.placeProduction" :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="车辆用途：">
                                <el-select placeholder="请选择" v-model="form.carPurposeId" size="small">
                                    <el-option v-for="(item, index) in purposeList" :key="index" :label="item.purpose"
                                        :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="车厂里程：">
                                <el-input v-model="form.carDepartureMileage" type="number"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="10">
                        <el-col :span="8">
                            <el-form-item label="本店销售：">
                                <el-select placeholder="请选择" v-model="form.ourStoreSales" size="small">
                                    <el-option label="否" value="0"></el-option>
                                    <el-option label="是" value="1"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="16">
                            <el-form-item label="车辆备注：">
                                <el-input type="textarea" :rows="2" v-model="form.remark"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <!-- </el-form> -->
                    <el-divider content-position="left" class="poi_title">车辆保险</el-divider>
                    <!-- <el-form ref="form" :model="form" label-width="100px" size="mini"> -->
                    <el-row :gutter="10">
                        <el-col :span="8">
                            <el-form-item label="保单号：">
                                <el-input v-model="form.policy"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="投保公司：">
                                <el-input v-model="form.insurance"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="投保人：">
                                <el-input v-model="form.policyholder"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="10">
                        <el-col :span="8">
                            <el-form-item label="保险类型：">
                                <el-select placeholder="请选择" v-model="form.intype" size="small">
                                    <el-option label="商业险" value="0"></el-option>
                                    <el-option label="交强险" value="1"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="本店投保：">
                                <el-select placeholder="请选择" v-model="form.store" size="small">
                                    <el-option v-for="(item, index) in StoreList" :key="index" :label="item.name"
                                        :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="保险次数：">
                                <el-input v-model="form.infreq" type="number"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="10">
                        <el-col :span="8">
                            <el-form-item label="入保时间：">
                                <el-date-picker type="date" v-model="form.startTime" value-format="yyyy-MM-dd"
                                    placeholder="选择日期" size="small">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="到期日期：" prop="endTime">
                                <el-date-picker type="date" v-model="form.endTime" value-format="yyyy-MM-dd"
                                    placeholder="选择日期" size="small">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <span slot="title" class="dialog-title">
                <span>添加车辆</span>
            </span>
            <span slot="footer" class="dialog-footer">
                <div slot="footer" class="dialog-footer">
                    <el-button size="small" @click="dialogVisible = false">取 消</el-button>
                    <el-button size="small" type="primary" @click="sure()">确 定</el-button>
                </div>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { savaCar, updateCar, CarselectById, getColor, getProduction, getPurpose, getCarInfo, storeGetList } from '@/api/memberManage/index'
import { brandList, carSeries, getCarList, getCarTypeDetail } from '@/api/systemOption/systemSettings';
export default {
    data() {
        return {
            dialogVisible: false, // 控制弹出框的显示与隐藏   
            form: {
                carFrameNumber: '',
                carEngineNum: '',
                carBuyingTime: '',
                carTypeId: '',
                startTime: '',
                endTime: '',
                carColorId: '',
                carProductionId: '',
                carPurposeId: '',
                brand: '',
                series: '',
                model: '',
                policy: '',
                infreq: '',
                intype: '',
                store: '',
                policyholder: '',
                insurance: '',
                remark: '',
                ourStoreSales: '1',
                carDepartureMileage: '',
                carRegistrationTime: '',
                carNum: ''
            },
            carList: [],
            colorList: [],
            productionList: [],
            purposeList: [],
            StoreList: [],
            ppList: [],
            xlList: [],
            xhList: [],
            rules: {
                carNum: [{ required: true, message: '请输入车牌号', trigger: 'blur' }],
                carFrameNumber: [{ required: true, message: '请输入车架号码', trigger: 'blur' }],
                carEngineNum: [{ required: true, message: '请输入发动机号', trigger: 'blur' }],
                // carBuyingTime: [{ required: true, message: '请输入购车日期', trigger: 'blur' }],
                carTypeId: [{ required: true, message: '请选择车辆信息', trigger: 'change' }],
                endTime: [{ required: true, message: '请输入到期日期', trigger: 'blur' }],
                brand: [{ required: true, message: '请选择车品牌', trigger: 'blur' }],
                model: [{ required: true, message: '请选择车型', trigger: 'blur' }],
                series: [{ required: true, message: '请选择车系列', trigger: 'blur' }],
            },
        };
    },
    created() {
        this.getStoreList()
    },
    methods: {
        closeDialog() {
            this.dialogVisible = false
            this.form = {
                carFrameNumber: '',
                carEngineNum: '',
                carBuyingTime: '',
                carTypeId: '',
                startTime: '',
                endTime: '',
                carColorId: '',
                carProductionId: '',
                carPurposeId: '',
                brand: '',
                series: '',
                model: '',
                policy: '',
                infreq: '',
                intype: '',
                store: '',
                policyholder: '',
                insurance: '',
                remark: '',
                ourStoreSales: '',
                carDepartureMileage: '',
                carRegistrationTime: '',
                carNum: ''
            }
            this.resetForm('form')
        },
        changePp(id) {
            this.form.series = ''
            this.form.model = ''
            this.xlList = []
            this.xhList = []
            carSeries({ brandId: id }).then(res => {
                if (res.state == '200') {
                    this.xlList = res.data
                }
            })
        },
        getStoreList() {
            storeGetList({}).then(res => {
                let { data } = res
                this.StoreList = data
            })
        },
        changeSeries(e) {
            this.form.model = ''
            this.xhList = []
            getCarList({ seriesId: e }).then(res => {
                if (res.state == '200') {
                    this.xhList = res.data
                }
            })
        },
        openDialog(id, e, type) {
            // 打开弹出框，并触发父组件的事件传递参数  
            this.dialogVisible = true;
            if (type == 'id') {
                this.form.clientId = id
            } else {
                this.form.clientId = e
                this.getCarId(id)
            }
            getColor().then(res => {
                if (res.state == '200') {
                    this.colorList = res.data
                    console.log(this.colorList);
                }
            })
            getProduction().then(res => {
                if (res.state == '200') {
                    this.productionList = res.data
                }
            })
            getPurpose().then(res => {
                if (res.state == '200') {
                    this.purposeList = res.data
                }
            })
            brandList().then(res => {
                if (res.state == '200') {
                    this.carList = res.data
                }
            })
            console.log(id, type)
            // this.$emit('child-event', 'Hello from child');
        },

        getCarId(e) {
            CarselectById({ id: e }).then(res => {
                let { data } = res
                this.form = data

                brandList().then(res => {
                    if (res.state == '200') {
                        this.carList = res.data
                        this.form.brand = data.brandId

                    }
                })
                carSeries({ brandId: data.brandId }).then(res => {
                    if (res.state == '200') {
                        this.xlList = res.data
                        this.form.series = data.seriesId
                    }
                })
                getCarList({ seriesId: data.seriesId }).then(res => {
                    if (res.state == '200') {
                        this.xhList = res.data
                        this.form.model = data.carTypeId
                    }
                })
            })
        },
        getCarTypeId() {
            return new Promise((resolve, reject) => {
                getCarTypeDetail({ brandId: this.form.brand, series: this.form.series, model: this.form.model }).then(res => {
                    if (res.state == '200') {
                        resolve(res.data.id)
                    }
                })
            })
        },
        sure() {
            this.$refs["form"].validate(async (valid) => {
                this.form.carTypeId = this.form.model
                if (valid) {
                    if (this.form.id) {
                        updateCar(this.form).then(res => {
                            if (res.state == '200') {
                                this.$message.success('修改成功')
                                this.closeDialog()
                                this.$emit('child-event', 'Hello from child');
                            }
                        })
                    } else {
                        savaCar(this.form).then(res => {
                            if (res.state == '200') {
                                this.$message.success('添加成功')
                                this.closeDialog()
                                this.$emit('child-event', 'Hello from child');
                            }
                        })
                    }

                } else {

                }
            });
        },
        resetForm(formName) {
            this.$nextTick(() => {
                this.$refs[formName].resetFields();
            })
        },
        // 深拷贝
        shenjiankaobe(obj) {
            let _obj = JSON.stringify(obj),
                objClone = JSON.parse(_obj);
            return objClone
        }
    }
};
</script>
<style scoped>
::v-deep .el-dialog {
    width: 60vw !important;
    margin-top: 10vh !important;
}

/*以下是关于弹出框的样式*/

.dialog-footer {
    text-align: center;
}

/*dialog主体的宽*/
/* .el-dialog {
    width: 55vw !important;
    margin-top: 10vh !important;
    max-height: 85vh;
} */

.dialog-title {
    width: 100%;
    font-size: .1875rem !important;
    color: white;
    font-weight: bold;

}

.el-dialog__header {
    padding-top: .125rem !important;
    background-image: linear-gradient(to right, rgb(254, 91, 75), rgb(249, 200, 38));
    border-radius: .125rem .125rem 0 0 !important;
}

/*改变dialog高度*/
.dialogContent {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.el-dialog {
    border-radius: .125rem !important;
}

.el-dialog__body {
    padding: .25rem;
}

.poi_title .el-divider__text.is-left {
    left: 0;
}

.poi_title .el-divider__text {
    font-weight: bold;
}

.addCarInfoMain .el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
    margin-bottom: .125rem;
}
</style>