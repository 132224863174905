import request from '@/utils/request'

export function getWechatAccount(data) {
    return request({
        url: '/sys/wechatAccountConfig/getWechatAccount',
        method: 'get',
        data: data
    })
}
export function configWechatAccount(data) {
    return request({
        url: '/sys/wechatAccountConfig/configWechatAccount',
        method: 'post',
        data: data
    })
}
export function getTemplateConfig(data) {
    return request({
        url: '/sys/templateConfig/getTemplateConfig',
        method: 'post',
        data: data
    })
}
export function configTemplateConfig(data) {
    return request({
        url: '/sys/templateConfig/configTemplateConfig',
        method: 'post',
        data: data
    })
}