import request from '@/utils/request'
import axios from 'axios';

// #region 门店
export function getStoreList(data) {
    return request({
        url: '/sys/store/selectList',
        method: 'post',
        data: data
    })
}
// 添加
export function addStore(data) {
    return request({
        url: '/sys/store/saveStore',
        method: 'post',
        data: data
    })
}
// 修改
export function updateStore(data) {
    return request({
        url: '/sys/store/updateStore',
        method: 'post',
        data: data
    })
}
// 删除
export function delStore(data) {
    return request({
        url: '/sys/store/removeById',
        method: 'post',
        data: data
    })
}
// #endregion

// #region 部门
export function getDepartmentList(data) {
    return request({
        url: '/sys/storeDepartment/selectList',
        method: 'post',
        data: data
    })
}
// 添加
export function addDepartment(data) {
    return request({
        url: '/sys/storeDepartment/savaStoreDepartment',
        method: 'post',
        data: data
    })
}
// 修改
export function updateDepartment(data) {
    return request({
        url: '/sys/storeDepartment/updateStoreDepartment',
        method: 'post',
        data: data
    })
}
// 删除
export function delDepartment(data) {
    return request({
        url: '/sys/storeDepartment/removeById',
        method: 'post',
        data: data
    })
}
export function getStoreNoPage(data) {
    return request({
        url: '/sys/store/getList',
        method: 'post',
        data: data
    })
}
// #endregion
