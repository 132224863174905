<template>
    <div class="memberDepositContainer">
        <div class="memberDepositMain">
            <div class="exit">
                <Exit />
            </div>
            <div class="memberDepositContent">
                <div class="so_aside">
                    <div class="user_info">
                        <div class="user_image">
                            <el-avatar :size="50" src="../../assets/image.jpg"></el-avatar>
                        </div>
                        <div class="user_detail_info">
                            <div class="user user_name">
                                <span>{{ userinfo.name }}</span>
                                <el-tag type="warning" effect="plain" size="mini"> {{ userinfo.vipLevelId }}</el-tag>
                            </div>
                            <div class="user user_phone">
                                <i class="el-icon-phone">{{ userinfo.telephone }}</i>
                            </div>
                            <div class="user user_time">
                                <span>注册时间：{{ userinfo.joiningTime }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="own_info">
                        <div class="own_info_tag">
                            <span>账户余额</span>
                            <span class="own_info_number">{{ userinfo.selfBalance }}</span>
                        </div>
                        <div class="own_info_tag">
                            <span>可用积分</span>
                            <span class="own_info_number">{{ userinfo.credit }}</span>
                        </div>
                        <div class="own_info_tag">
                            <span>持有套餐</span>
                            <span class="own_info_number">{{ userinfo.holdSetmeal }}张</span>
                        </div>

                    </div>
                    <div class="record_info">
                        <div class="record_title">
                            兑换记录
                        </div>
                        <div class="record_content">
                            <div class="record" v-for="(item, index) in exchangeRecordList" :key="index">
                                <span class="record_value">【{{ item.exchangeName }}】</span>
                                <div class="record_time">
                                    <span>消耗积分{{ item.exchangeCredit }}分</span>
                                    <span>支付现金{{ item.exchangeMoney }}元</span>
                                </div>
                            </div>
                        </div>
                        <div v-if="exchangeRecordList.length == 0" style="width:100%;">
                            <el-empty description="搜索查看记录哦~"></el-empty>
                        </div>

                    </div>
                </div>
                <div class="so_content">
                    <div class="adminSettingNav">
                        <el-menu :default-active="rd_defaultIndex" class="el-menu-demo" mode="horizontal">
                            <el-menu-item index="rd_tag1">兑换详情</el-menu-item>
                        </el-menu>
                    </div>
                    <div class="rd_content">
                        <el-divider content-position="left" class="poi_title">客户信息</el-divider>
                        <div class="consumeInfo">
                            <el-descriptions class="margin-top" :column="2" :size="size" border
                                :labelStyle="{ 'backgroundColor': 'rgb(250,250,250)' }">
                                <el-descriptions-item>
                                    <template slot="label">
                                        微信昵称
                                    </template>
                                    {{ userinfo.wechatName ? userinfo.wechatName : '' }}
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        手机号
                                    </template>
                                    {{ userinfo.telephone }}
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        姓名
                                    </template>
                                    {{ userinfo.name }}
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        身份证号
                                    </template>
                                    <el-tag size="small">{{ userinfo.idCard }}</el-tag>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        会员生日
                                    </template>
                                    {{ userinfo.birthday }}
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        介绍人
                                    </template>
                                    {{ userinfo.sellerId }}
                                </el-descriptions-item>
                            </el-descriptions>
                        </div>

                        <el-divider content-position="left" class="poi_title">兑换详情</el-divider>
                        <div class="consumeInfo">
                            <el-descriptions class="margin-top" :column="2" :size="size" border
                                :labelStyle="{ 'backgroundColor': 'rgb(250,250,250)' }">
                                <el-descriptions-item>
                                    <template slot="label">
                                        兑换标号
                                    </template>
                                    {{ orderInfo.id }}
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        成交时间
                                    </template>
                                    {{ orderInfo.transactionTime }}
                                </el-descriptions-item>
                                <el-descriptions-item :span="2">
                                    <template slot="label">
                                        商品名称
                                    </template>
                                    <div class="goods">
                                        <!-- <img class="goods_img" src="" alt=""> -->
                                        <div class="goods_info">
                                            <span style="font-size: medium;">{{ goodsInfo.productName }}</span>
                                            <span>积分：<span style="color: green;">{{ goodsInfo.credit }}</span>分</span>
                                            <span>现金：<span style="color: red;">{{ goodsInfo.money }}</span>元</span>
                                        </div>
                                        <div class="goods_num">x1</div>
                                    </div>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        消耗积分
                                    </template>
                                    {{ orderInfo.credit }}
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        余额支付
                                    </template>
                                    ￥{{ orderInfo.money }}
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        现金支付
                                    </template>
                                    ￥{{ orderInfo.cashPayment || 0 }}
                                </el-descriptions-item>
                            </el-descriptions>
                        </div>
                        <div class="consumeInfo footer">
                            <el-button v-if="orderInfo.isExchange == 1" :size="size" type="primary"
                                @click="cancel()">取消兑换</el-button>
                            <el-button :size="size" type="" @click="go()">返回</el-button>
                            <el-button v-if="orderInfo.isExchange == 1" :size="size" type="warning"
                                @click="sure()">兑换</el-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <GetRedemption ref="getRedemptionModal" :initial-message="addCarMessage" @child-event="go" />
    </div>
</template>
<script>
import MemberManageAside from '../../components/MemberManageAside.vue'
import Exit from '../../components/Exit.vue'
import { getExchangeById, updateCancelEx } from '@/api/memberConsume/index';
import GetRedemption from '../memberConsumModal/GetRedemption.vue'
export default {
    components: { MemberManageAside, Exit, GetRedemption },
    data() {
        return {
            tag: 'br_tag1',
            rd_defaultIndex: 'rd_tag1',
            size: 'small',
            userinfo: {},
            orderInfo: {},
            goodsInfo: {},
            exchangeRecordList: []
        }
    },
    methods: {
        out() {
            this.$confirm('确认关闭？')
                .then(_ => {
                    done()
                })
                .catch(_ => { })
        },
        getIntro() {
            getExchangeById({ id: this.$route.query.id }).then(res => {
                let { data } = res
                this.userinfo = data.clientInfo
                this.orderInfo = data
                this.goodsInfo = data.goodsManageInfo
                this.exchangeRecordList = data.exchangeRecordList
            })
        },
        go() {
            this.$router.push({ path: '/memberConsume/MemberMall?type=2' })
        },
        sure() {
            this.$refs.getRedemptionModal.openDialog(this.$route.query.id)
        },
        cancel() {
            this.$confirm('确定取消兑换吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                updateCancelEx({ id: this.$route.query.id }).then(res => {
                    if (res.state == '200') {
                        this.$message.success('取消兑换成功')
                        this.$router.push({ path: '/memberConsume/MemberMall?type=2' })
                    }
                })
            }).catch(() => {

            });
        }
    },
    mounted() {
        // PubSub.subscribe('activeTag', (msg, data) => {
        //     this.tag = data
        // })
        this.getIntro()
    }
}
</script>
<style scoped>
.memberDepositContainer {
    display: flex;
    background-color: rgb(247, 248, 250);

}

.memberDepositMain {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100vh;

}

.exit {
    height: .5rem;
    display: flex;
    justify-content: flex-end;
}

.memberDepositContent {
    width: 100%;
    flex: 1;
    background-color: white;
    display: flex;

}

.so_aside {
    width: 20%;
    display: flex;
    flex-direction: column;
    height: 100% !important;
    padding: 0 .125rem .25rem;
}

.user_info {
    width: 100%;
    display: flex;
    height: 1.25rem;
    align-items: center;
    border: 1px solid rgb(212, 212, 212);
    margin-bottom: .125rem;
}

.user_image {
    width: 1.5rem;
    height: .75rem;
    display: flex;
    justify-content: center;
}

.user_detail_info {
    flex: 1;

}

.user {
    height: .1875rem;
    padding-bottom: .3125rem;
}

.user_name .el-tag {
    margin-left: .375rem !important;
}



.own_info_tag {
    width: 100%;
    height: .875rem;
    background: rgb(231, 236, 255);
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: .125rem;
}

.own_info_tag span {
    display: inline-block;
}

.own_info_number {
    font-weight: bold;
    color: #ea8419;
}

.record {
    margin-bottom: .125rem;
}

.record_info {
    width: 100%;
    /* flex: 1; */
    height: 5rem;
    overflow: auto;
    border: 1px solid rgb(212, 212, 212);
    border-radius: .125rem !important;
    padding: .125rem;

}

.record_title {
    font-size: .175rem;
    border-bottom: 1px solid rgb(212, 212, 212);
    text-align: center;
    padding-bottom: .125rem;
    margin-bottom: .125rem;
}

/* .record_info {
    width: 100%;
    flex: 1;
    height: 5rem;
    border: 1px solid rgb(212, 212, 212);
    border-radius: .125rem !important;
    padding: .125rem;

}

.record_title {
    font-size: .175rem;
    border-bottom: 1px solid rgb(212, 212, 212);
    text-align: center;
    padding-bottom: .125rem;
    margin-bottom: .125rem;
} */


.record_value {
    width: 100%;
    height: .3125rem;
    line-height: .3125rem;
    display: inline-block;
    background: #f2f2f2;
    font-size: .15rem;
}

.record_time {
    display: flex;
    justify-content: space-between;
}

.record_time span {
    display: inline-block;
    color: rgb(0, 79, 251);
    font-size: .1375rem;
}

.so_content {
    flex: 1;
    width: 80%;
    display: flex;
    flex-direction: column;
}

.rd_content {
    flex: 1;
    width: 100%;
    padding: .125rem .25rem;
}

.poi_title .el-divider__text.is-left {
    left: 0;
}

.poi_title .el-divider__text {
    font-weight: bold;
}

.consumeInfo {
    width: 100%;
    padding: .125rem .25rem;
}

.goods {
    display: flex;
    align-items: center;
}

.goods_img {
    width: .75rem;
    height: .75rem;
}

.goods_info {
    display: flex;
    flex-direction: column;
    /* margin: 0 .25rem; */
}

.goods_info span {
    display: inline-block;
}

.footer {
    display: flex;
    justify-content: center;
    padding: .375rem 0;
}

.footer .el-button {
    margin-right: .25rem;
}
</style>