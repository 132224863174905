import store from "@/store"
const data = store.getters

// export const QR_URL = 'https://mvn.l.yishilk.cn/car_shop/#/pages/transfer/transfer'
// export const XS_TYPE = '2'
// export const TYPE = '1'
// export const PRODUCT_URL = 'https://mvn.l.yishilk.cn/car_shop/#/pages/introduce/introduce'
// export const NEW_BASE = 'https://mvn.l.yishilk.cn/car_point'


export const QR_URL = `${data.accountUrl}#/pages/transfer/transfer`
export const XS_TYPE = '2'
export const TYPE = '1'
export const PRODUCT_URL = `${data.accountUrl}#/pages/introduce/introduce`
export const NEW_BASE = data.accountUrl

