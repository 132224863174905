<template>
  <div class="platformContainer">
    <div class="systemSettingsNav">
      <el-menu :default-active="ml_defaultIndex" class="el-menu-demo" mode="horizontal" @select="ml_handleSelect">
        <el-menu-item index="ml_tag1">车辆保险</el-menu-item>
      </el-menu>
    </div>
    <div class="sf_main" v-if="ml_activeIndex === 'ml_tag1'">
      <div class="sf_des">
        <el-descriptions class="margin-top" :column="5" size="mini" :contentStyle="content_style" :colon="false"
          :labelStyle="label_style">
          <el-descriptions-item label="手机号">
            <el-input ref="teleRef" v-model="formSearch.telephone" placeholder="手机号搜索" :size="size"></el-input>
          </el-descriptions-item>
          <el-descriptions-item label="姓名">
            <el-input v-model="formSearch.vipName" :size="size" placeholder="姓名搜索"></el-input>
          </el-descriptions-item>
          <el-descriptions-item label="车牌号">
            <el-input v-model="formSearch.carNum" :size="size" placeholder="车牌号搜索"></el-input>
          </el-descriptions-item>
          <el-descriptions-item label="车架号">
            <el-input v-model="formSearch.carFrameNumber" :size="size" placeholder="车架号搜索"></el-input>
          </el-descriptions-item>
          <el-descriptions-item label="保险公司">
            <el-input v-model="formSearch.insuranceCompany" :size="size" placeholder="保险公司搜索"></el-input>
          </el-descriptions-item>
          <el-descriptions-item label="入保时间" :span="2">
            <el-date-picker type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
              value-format="yyyy-MM-dd" :size="size" v-model="getInsuranceTime" style="width: 100%;"></el-date-picker>
          </el-descriptions-item>
          <el-descriptions-item label="专属顾问">
            <el-select clearable placeholder="请选择" v-model="formSearch.userId" size="small">
              <el-option v-for="(item) in memList" :key="item.user_id" :label="item.nickname"
                :value="item.user_id"></el-option>
            </el-select>
          </el-descriptions-item>
          <el-descriptions-item label="介绍人">
            <el-input v-model="formSearch.sellerId" :size="size" placeholder="介绍人搜索"></el-input>
          </el-descriptions-item>
          <el-descriptions-item label="车型">
            <el-select v-model="formSearch.carModel" placeholder="请选择" :size="size" clearable>
              <el-option v-for="(item, index) in carList" :key="index" :label="item.model" :value="item.model">
              </el-option>
            </el-select>
          </el-descriptions-item>
          <el-descriptions-item label="截止时间" :span="2">
            <el-date-picker type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
              value-format="yyyy-MM-dd" :size="size" v-model="overdueInsuranceTime" style="width: 100%;"></el-date-picker>
          </el-descriptions-item>
          <el-descriptions-item>
            <div class="operateBtn">
              <el-button type="primary" :size="size" @click="getMemberList">查询</el-button>
              <el-button type="primary" :size="size" @click="exports()" v-if="isExport == 1">导出</el-button>
            </div>
          </el-descriptions-item>
        </el-descriptions>
      </div>
      <div class="sf_footer">
        <div class="tableStyle">
          <el-table :data="memberList" :stripe="true" size="mini" header-cell-class-name="headerClassName"
            :cell-style="{ 'text-align': 'center' }" :border="true" :fit="true" style="width:21rem">
            <el-table-column prop="id" label="编号">
              <template slot-scope="scope">
                <span>{{ scope.$index + 1 }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="telephone" label="手机号" width="130"></el-table-column>
            <el-table-column prop="vipName" label="姓名"></el-table-column>
            <el-table-column prop="carNum" label="车牌号" width="150"></el-table-column>
            <el-table-column prop="carFrameNumber" label="车架号" width="200"></el-table-column>
            <el-table-column prop="idCard" label="身份证号" width="200"></el-table-column>
            <el-table-column prop="carModel" label="车辆型号"></el-table-column>
            <el-table-column prop="insuranceCompany" label="保险公司" width="120"></el-table-column>
            <el-table-column prop="customerArea" label="客户区域" width="200"></el-table-column>
            <el-table-column prop="customerType" label="客户分类" width="120"></el-table-column>
            <el-table-column prop="customerSource" label="客户来源" width="120"></el-table-column>
            <el-table-column prop="vipLevel" label="会员等级" width="120"></el-table-column>
            <el-table-column prop="userId" label="专属顾问" width="120"></el-table-column>
            <el-table-column prop="sellerId" label="介绍人"></el-table-column>
            <el-table-column prop="status" label="状态">
              <template slot-scope="scope">
                <p v-if="scope.row.status == 1">过期</p>
                <p v-if="scope.row.status == 2">正常</p>
              </template>
            </el-table-column>
            <el-table-column prop="hairCard" label="开卡店"></el-table-column>
            <el-table-column prop="startTime" label="入保时间" width="120"></el-table-column>
            <el-table-column prop="endTime" label="截止时间" width="120"> </el-table-column>
            <el-table-column prop="joinTime" label="加入开始时间" width="120"></el-table-column>
            <el-table-column prop="cutoffTime" label="加入截止时间" width="120"></el-table-column>
            <el-table-column label="操作" fixed="right">
              <template slot-scope="scope">
                <el-button @click="updateMember(scope.row.telephone, scope.row.state)" type="text"
                  size="small">编辑</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="sf_page">
            <el-pagination background layout="total, prev, pager, next" :page-size="10"
              :current-page.sync="formSearch.page" @current-change="getMemberList" :total="total">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as memberApi from '@/api/memberManage/index'
import { getCarList } from '@/api/systemOption/systemSettings'
export default {
  data() {
    return {
      memList: [],
      ml_defaultIndex: 'ml_tag1',
      ml_activeIndex: 'ml_tag1',
      form: '',
      radio: '0',
      content_style: {
        'width': '15%',
      },
      size: 'small',
      label_style: {
        'width': '1.3rem',
        'justify-content': 'end'
      },
      memberList: [],
      formSearch: {
        telephone: "", //手机号
        vipName: "", //会员姓名
        carNum: "", //车牌号
        carFrameNumber: "", //车架号
        insuranceCompany: "", //保险公司
        getInsuranceStartTime: "", //投保开始时间
        getInsuranceEndTime: "", //投保结束时间
        overdueInsuranceStartTime: "", //保险到期开始时间
        overdueInsuranceEndTime: "", //保险到期结束时间
        userId: "", //员工id
        sellerId: "", //介绍人
        carModel: "", //车型
        pageSize: 10, //分页大小
        page: 1 //分页页码，数字类型
      },
      getInsuranceTime: [],
      overdueInsuranceTime: [],
      total: 0,
      customerLy: [],
      customerFl: [],
      StoreList: [],
      carList: [],
      vipLevelList: [],
      cityList: [],
      areaList: [],
      xhList: [],
      seriesList: [],
      xl: '',
      customerBq: [],
      isExport: false
    }
  },
  created() {
    this.getMemberList()
  },
  methods: {


    ml_handleSelect(key, keyPath) {
      this.ml_activeIndex = key
    },
    //获取会员列表
    getMemberList() {
      if (this.getInsuranceTime && this.getInsuranceTime.length > 0) {
        this.formSearch.getInsuranceStartTime = this.getInsuranceTime[0]
        this.formSearch.getInsuranceEndTime = this.getInsuranceTime[1]
      } else {
        this.formSearch.getInsuranceStartTime = ''
        this.formSearch.getInsuranceEndTime = ''
      }
      if (this.overdueInsuranceTime && this.overdueInsuranceTime.length > 0) {
        this.formSearch.overdueInsuranceStartTime = this.overdueInsuranceTime[0]
        this.formSearch.overdueInsuranceEndTime = this.overdueInsuranceTime[1]
      } else {
        this.formSearch.overdueInsuranceStartTime = ''
        this.formSearch.overdueInsuranceEndTime = ''
      }
      memberApi.selectInsuranceList(this.formSearch).then(res => {
        let { data } = res
        this.memberList = data.records
        this.total = data.total
      })
    },
    //获取客户属性列表
    getCustomerSX(type) {
      memberApi.attributeGetList({ type: type }).then(res => {
        let { data } = res
        if (type == 0) {
          this.customerLy = data
        } else if (type == 2) {
          this.customerFl = data
        }
      })
    },
    getCommn() {
      memberApi.getStaffList().then(res => {
        if (res.state == '200') {
          this.memList = res.data
        }
      })
      memberApi.clientVipLevelGetList({}).then(res => {
        let { data } = res
        this.vipLevelList = data
      })
      getCarList({ seriesId: '' }).then(res => {
        if (res.state == '200') {
          this.carList = res.data
        }
      })
      memberApi.storeGetList({}).then(res => {
        let { data } = res
        this.StoreList = data
      })
    },
    //导出按钮
    exports() {
      memberApi.exportInsuranceList(this.formSearch).then(res => {
        let blob = new Blob([res], {
          // type: 'application/actet-stream;charset=utf-8'
          type: 'application/vnd.ms-excel'
        }); // 构造一个blob对象来处理数据，并设置文件类型
        let a = document.createElement('a'); // 生成一个a标签
        let href = window.URL.createObjectURL(blob); //创建下载的链接
        a.href = href; //指定下载链接
        a.download = '车辆保险' //指定下载文件名
        // a.setAttribute('download', 'fileName')
        document.body.appendChild(a); //把a标签加到页面中
        a.click(); //触发a标签下载
        document.body.removeChild(a); //下载完成移除元素
        window.URL.revokeObjectURL(href); //释放掉blob对象
      })
    },
  },
  // 使用
  mounted() {
    this.$refs.teleRef.focus()
    this.isExport = sessionStorage.getItem('isExport')
    this.getMemberList()
    this.getCustomerSX(0)
    this.getCustomerSX(2)
    this.getCommn()
  },
}
</script>
<style scoped>
.platformContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.sf_main {
  flex: 1;
  background-color: rgb(247, 248, 250);
  padding-top: .25rem;
  display: flex;
  flex-direction: column;
}

.sf_des {
  background-color: white;
  border: 1px solid rgb(235, 235, 235);
  padding: .25rem;
  border-radius: .125rem;
}


.sf_des .el-descriptions--mini:not(.is-bordered) .el-descriptions-item__cell {
  padding-right: .25rem;
}

.sf_footer {
  flex: 1;
  margin-top: .125rem;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: .125rem;
}

.sf_footer .tableStyle {
  width: 100% !important;
  flex: 1;
  overflow-x: auto;
}

.sf_footer .show_data {
  width: 100%;
  height: 1.125rem
}

.sf_footer .summaryInfo_Bootom {
  padding: 0 .5rem;
  font-size: large;
  font-weight: bold;
  color: rgb(92, 165, 243)
}

.sf_footer .sf_page {
  display: flex;
  justify-content: flex-end;
}
</style>