<template>
    <div>
        <el-dialog :visible.sync="dialogVisible" :modal="true" :append-to-body="true" :close-on-click-modal="false"
            :before-close="handleClose">
            <el-form ref="form" :model="form" label-width="2rem" size="mini" label-position="left" class="form">
                <span class="tt">基础设置</span>
                <el-divider></el-divider>
                <el-form-item label="群发名称" :rules="[{ required: true }]">
                    <el-input v-model="form.title"></el-input>
                </el-form-item>
                <el-form-item label="企业成员" :rules="[{ required: true }]">
                    <el-button @click="addMem"><i class="el-icon-plus"></i>添加成员</el-button>
                    <el-tag @close="removeMemTag(tag)" style="margin-left: .125rem;margin-bottom: .125rem;"
                        v-for="tag in selectMemList" :key="tag.id" closable type="info">
                        {{ tag.label }}
                    </el-tag>
                </el-form-item>
                <el-form-item label="客户范围" :rules="[{ required: true }]">
                    <el-radio-group v-model="form.is_tag">
                        <el-radio :label="0">全部客户</el-radio>
                        <el-radio :label="1">按条件筛选</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item v-if="form.is_tag == 1">
                    <div class="byTj">
                        <span style="font-size: 14px;"> 客户标签：</span>
                        <el-radio-group v-model="form.out_tag_swich">
                            <el-radio :label="0">筛选包含以下标签的用户</el-radio>
                            <el-radio :label="1">排除包含以下标签的用户</el-radio>
                        </el-radio-group>
                        <br>
                        <el-button @click="chooseBq" style="margin-top: .25rem;"><i
                                class="el-icon-plus"></i>选择标签</el-button>
                        <el-tag @close="removeTag(tag)" style="margin-left: .125rem;margin-bottom: .125rem;"
                            v-for="tag in tagList" :key="tag.id" closable type="info">
                            {{ tag.tag_name }}
                        </el-tag>
                    </div>
                    <div>
                        查看该消息预计送达人数：
                        <span v-if="yjPerson.toString() != ''">{{ yjPerson }}</span>
                        <el-button type="text" @click="checkYjqk" style="margin-left: .125rem;">查看</el-button>
                    </div>
                </el-form-item>
                <el-form-item label="是否群发" :rules="[{ required: true }]">
                    <el-radio-group v-model="form.is_settime">
                        <el-radio :label="0">立即群发</el-radio>
                        <el-radio :label="1">定时发送</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item v-if="form.is_settime == 1">
                    <el-date-picker value-format="timestamp" v-model="form.send_time" type="datetime" placeholder="选择日期时间">
                    </el-date-picker>
                </el-form-item>
                <span class="tt">群发内容</span>
                <el-divider></el-divider>
                <el-form-item>
                    <el-tag type="warning">注意：每位客户每天可以接收1条群发消息，不限企业发布的群发还是个人发布的群发。</el-tag>
                </el-form-item>
                <el-form-item label="群发内容" :rules="[{ required: true }]">
                    <div class="box">
                        <div class="emo">
                            <picker v-show="app" :include="['people']" :showSearch="false" :showPreview="false"
                                :showCategories="false" @select="addEmoji" class="emjBox" />
                            <img src="../../../assets/icon.png" style="width: .3rem;height: .3rem;cursor: pointer;"
                                @click="openimg">
                        </div>
                        <div class="msg">
                            <el-input type="textarea" :rows="5" placeholder="请输入内容" v-model="form.content"
                                style="border-color: transparent;">
                            </el-input>
                        </div>
                        <div class="imgbox" style="cursor: pointer;margin-left: .25rem;" v-for="(item, index) in imgList">
                            <i class="el-icon-circle-close" @click="removeImg(item)"></i>
                            <span style="margin-left: .125rem;" @click="lookImg(item)">{{ item.name || '【图片】未命名图片' }}</span>
                        </div>
                        <el-button style="margin: .25rem;" @click="go"><i class="el-icon-plus"></i>添加回复内容</el-button>
                        <!-- <el-dropdown placement="top-end">
                            <span class="el-dropdown-link">
                                <el-button style="margin: .25rem;"><i class="el-icon-plus"></i>添加回复内容</el-button>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item> <span @click="go">图片</span></el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown> -->
                    </div>
                </el-form-item>
                <el-form-item>
                    <div class="nTag">
                        <div class="tag_top">
                            <div>企业群发的操作流程</div>
                            <!-- <el-button type="text"> 查看示例</el-button> -->
                        </div>
                        <div>1、创建完群发任务后，员工将在企业微信上收到【客户联系】新消息通知</div>
                        <div>2、员工点击【客户联系】，打开待发送的企业消息，可一键将消息发送给客户</div>
                    </div>
                </el-form-item>
            </el-form>
            <div style="display: flex; justify-content: center;">
                <el-button size="small" type="primary" @click="send">通知员工发送</el-button>
            </div>
            <span slot="title" class="dialog-title">
                <span>群发</span>
            </span>
            <!-- <span slot="footer" class="dialog-footer">
                <div slot="footer" class="dialog-footer">
                    <el-button size="small" @click="audit(3)">驳回</el-button>
                    <el-button size="small" type="primary" @click="audit(1)">审核通过</el-button>
                </div>
            </span> -->
        </el-dialog>
        <el-dialog :visible.sync="visible" width="40%" :before-close="handleClose2">
            <div class="groupbox">
                <div style="margin-left: .5rem;">
                    <el-form :inline="true" class="demo-form-inline">
                        <el-form-item label="图片:">
                            <img :src="openImgUrl" style="width: 2.5rem;height: 2.5rem;">
                        </el-form-item>
                    </el-form>
                </div>
            </div>
            <span slot="title" class="dialog-title">
                <span class="title">创建图片</span>
            </span>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="visible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="visible = false">确 定</el-button>
            </span>
        </el-dialog>

        <AddMem ref="addMemRef" @setMem="setMem" />
        <bqDialog ref="bqRef" @setTags="getTagss" />
        <addPicture ref="addPic" @setPicture="setPic" />
        <addUrl ref="addUrl" />
        <addXcx ref="addXcx" />
    </div>
</template>  
    
<script>
import bqDialog from './bqDialog.vue'
import AddMem from './addMem.vue';
import addPicture from './addPicture.vue';
import addUrl from './addUrl.vue';
import addXcx from './addXcx.vue'
import { formatDate } from '@/utils/tool';
import { createMassSend, expectMassSendStatus, getMassSendDetail, getTagListNoPage } from '@/api/memberManage';
import { Picker } from "emoji-mart-vue"; //引入组件
export default {
    components: { AddMem, bqDialog, addPicture, addUrl, addXcx, Picker },
    data() {
        return {
            app: false,
            visible: false,
            dialogVisible: false, // 控制弹出框的显示与隐藏  
            carList: [],
            imgList: [],
            form: {
                title: '',
                is_tag: 0,
                is_settime: 0,
                content: '',
                out_tag_swich: 0,
                is_multiple: 1,
                is_stop: 0,
                msgid: '',
                multi_content: [],
                // 立即发送：当前时间时间戳，定时发送：定时时间时间戳
                send_time: Date.now(),
                users: [],
                tags: []
            },
            id: 0,
            textarea: '',
            radio: 5,
            checked: [],
            tagList: [],
            selectMemList: [],
            yjPerson: '',
            alltagList: [],
            openImgUrl: ''
        };
    },
    created() {
        this.getTagListByApi()
    },
    methods: {
        openimg() {
            this.app = !this.app
        },
        addEmoji(e) {
            this.form.content += e.native;
        },
        removeImg(img) {
            let imgArr = this.imgList
            imgArr.forEach((item, index) => {
                if (item.id == img.id) {
                    this.imgList.splice(index, 1)
                }
            })
        },
        lookImg(img) {
            this.openImgUrl = img.img_url
            this.visible = true
        },
        setPic(img) {
            let newImg = { ...img, img_url: img.url }
            this.imgList.push(newImg)
        },
        getTime(time) {
            return formatDate(time)
        },
        getTagss(ids) {
            this.tagList = ids
        },
        chooseBq() {
            this.$refs.bqRef.openDialog(this.tagList)
        },
        removeTag(item) {
            this.tagList = this.tagList.filter(data => data.id != item.id)
        },
        openDialog(id) {
            // 打开弹出框，并触发父组件的事件传递参数  
            if (id && id != '') {
                this.setNewData(id)
            }
            this.dialogVisible = true;

            // this.$emit('child-event', 'Hello from child');
        },
        setNewData(id) {
            getMassSendDetail({ id }).then(res => {
                if (res.state == '200') {
                    let data = res.data
                    let tags = this.getTags(data.tags)
                    this.tagList = tags
                    this.selectMemList = this.getUsers(data.users)
                    this.imgList = []
                    data.multi_content.forEach(item => {
                        let newImg = { ...item, img_url: item.img_url }
                        this.imgList.push(newImg)
                    })
                    this.form = {
                        ...res.data,
                        title: data.title,
                        is_tag: data.is_tag,
                        is_settime: data.is_settime,
                        content: data.content,
                        out_tag_swich: data.out_tag_swich,
                        is_multiple: data.is_multiple,
                        is_stop: data.is_stop,
                        msgid: data.msgid,
                        multi_content: data.multi_content,
                        // 立即发送：当前时间时间戳，定时发送：定时时间时间戳
                        send_time: data.send_time * 1000,
                        users: data.users,
                        tags: data.tags
                    }
                    // this.tagList
                }
            })

        },
        getUsers(users) {
            return users.map(item => {
                return {
                    id: item.user_id,
                    label: item.nickname
                }
            })
        },
        getTags(ids) {
            return ids.map(item => {
                return this.alltagList.find(data => data.tag_id == item)
            })
        },
        getTagListByApi() {
            getTagListNoPage().then(res => {
                let alltagList = []
                res.data.forEach(item => {
                    alltagList = alltagList.concat(item.tags)
                })
                this.alltagList = alltagList
            })

        },
        checkYjqk() {
            this.form.tags = this.tagList.map(item => item.tag_id)
            this.form.users = this.selectMemList.map(item => {
                return {
                    headimgurl: '',
                    nickname: item.label,
                    user_id: item.id
                }
            })
            expectMassSendStatus({ out_tag_swich: this.form.out_tag_swich, tags: this.form.tags, user_ids: this.form.users }).then(res => {
                if (res.state == '200') {
                    this.yjPerson = res.data.data
                }
            })
        },
        closeDialog() {
            // 关闭弹出框  
            this.dialogVisible = false;
        },
        addMem() {
            this.$refs.addMemRef.openDialog(this.selectMemList)
        },
        go(flag) {
            this.$refs.addPic.openDialog()
            // if (flag == 'tp') {
            //     this.$refs.addPic.openDialog()
            // } else if (flag == 'lj') {
            //     this.$refs.addUrl.openDialog()
            // } else if (flag == 'xcx') {
            //     this.$refs.addXcx.openDialog()
            // }
        },
        setMem(selectList) {
            this.selectMemList = selectList
        },
        removeMemTag(memTag) {
            this.selectMemList = this.selectMemList.filter(item => item.id != memTag.id)
        },
        handleClose2() {
            this.visible = false
        },
        handleClose() {

            this.form = {
                title: '',
                is_tag: 0,
                is_settime: 0,
                content: '',
                out_tag_swich: 0,
                is_multiple: 1,
                is_stop: 0,
                msgid: '',
                multi_content: [],
                // 立即发送：当前时间时间戳，定时发送：定时时间时间戳
                send_time: Date.now(),
                users: [],
                tags: []
            }
            this.selectMemList = []
            this.tagList = []
            this.dialogVisible = false
        },
        send() {
            if (!this.form.title.trim()) {
                this.$message.error('群发任务名称不能为空');
                return;
            }

            if (!this.form.content.trim()) {
                this.$message.error('群发内容不能为空');
                return;
            }
            let multi_content
            if (this.imgList.length > 0) {
                multi_content = this.imgList.map(item => {
                    return {
                        type: 2,    // 消息类型 1文本 2 图片 3 图文 4小程序 5文件  - [4,5]需指定才显示
                        text: "",
                        img_url: item.img_url,
                        file: "",   // 文件消息
                        filetype: "", // 文件类型
                        title: "",
                        description: "",
                        // link: "",
                        pic_text: {
                            title: "",
                            description: "",
                            link: ""
                        },
                        mp: {
                            appid: '',
                            app_page: '',
                            app_title: ''
                        },
                        other_supply: 0
                    }
                })
            }

            this.form.multi_content = multi_content



            this.isLoading = true;
            if (this.form.is_settime == 1) {
                // 定时发送
                this.form.send_time = Math.ceil(this.form.send_time / 1000);
            } else {
                this.form.send_time = Math.ceil(Date.now() / 1000);
            }


            this.form.users = this.selectMemList.map(item => {
                return {
                    headimgurl: '',
                    nickname: item.label,
                    user_id: item.id
                }
            })
            if (this.form.users.length == 0) {
                this.$message.error('请选择联系人');
                return;
            }
            this.form.tags = this.tagList.map(item => item.tag_id)
            if (this.form.id) {
                this.form.updated_at = this.getTime(Date.now())
            }
            createMassSend(this.form).then(res => {
                if (res.state == '200') {
                    this.$message.success('创建成功')
                    this.handleClose()
                    this.$emit('upload')
                }
            })

        }
    },
};
</script>
<style scoped>
.emjBox {
    height: 4rem !important;
    z-index: 99999;
    position: absolute;
    bottom: .5rem;

}

.tt {
    font-size: .25rem;
    font-weight: bold;
}

.form {
    padding: 0 .75rem;
}

.box {
    border: 1px solid #e7e7eb;
    border-radius: .1rem;
    background-color: #f5f6fa;
}

.emo {
    padding: .125rem .25rem;
    border-bottom: 1px solid #e7e7eb;
    height: .625rem;
    display: flex;
    align-items: center;
    background-color: #f5f6fa;
    position: relative;
}

.tag_top {
    display: flex;
    justify-content: space-between;
}

.nTag {
    border: 1px solid #faecd8;
    padding: 0 .125rem;
    background-color: #fdf6ec;
    color: #e6a23c;
    border-radius: .0625rem;

}

.tlx {
    /* width: 20px; */
    cursor: pointer;
    margin: .0625rem 0;
}

.byTj {
    background-color: #f2f5fa;
    padding: .25rem;
}
</style>
  