<template>
    <div>
        <el-dialog :visible.sync="dialogVisible" :modal="true" :append-to-body="true" :close-on-click-modal="false"
            :before-close="handleClose">
            <div class="container">
                <div class="top">
                    <el-form ref="form" :inline="true" :model="form" label-width="2rem" size="small" label-position="left"
                        class="form">
                        <el-form-item label="搜索标签">
                            <el-input v-model="form.tag_name" placeholder="请输入标签或标签组"></el-input>
                        </el-form-item>
                        <el-button size="small" @click="getList">搜索</el-button>
                    </el-form>
                    <div class="group">
                        <!-- <el-button size="small" type="primary">新建标签</el-button> -->
                        <el-button size="small" @click="refresh">刷新</el-button>
                    </div>
                </div>
                <div class="content">
                    <div v-for="(item, index) in data" :key="`${item.id}_${item.group_name}`">
                        <div class="title">{{ item.group_name }}:</div>
                        <div class="tags" v-if="item.tags.length > 0">
                            <el-tag @click="setTags(item2)" :type="getType(item2)" v-for="(item2, index2) in item.tags"
                                style="margin-right: .125rem;cursor: pointer;" :key="item.tag_id">{{
                                    item2.tag_name }}</el-tag>
                        </div>
                    </div>
                </div>
                <div class="footer">
                    <el-pagination background layout="prev, pager, next,jumper" :page-size="5"
                        :current-page.sync="pageForm.page" @current-change="getList" :total="total">
                    </el-pagination>
                </div>
            </div>
            <span slot="title" class="dialog-title">
                <span class="title">打标签</span>
            </span>
            <span slot="footer" class="dialog-footer">
                <div slot="footer" class="dialog-footer">
                    <el-button size="small" @click="handleClose">取消</el-button>
                    <el-button size="small" type="primary" @click="save">确定</el-button>
                </div>
            </span>
        </el-dialog>
    </div>
</template>  
    
<script>
import { getTagList } from '@/api/memberManage';


export default {
    data() {
        return {
            dialogVisible: false, // 控制弹出框的显示与隐藏  
            carList: [],
            form: {
                tag_name: ''
            },
            id: 0,
            pageForm: {
                page: 1
            },
            total: 0,
            data: [],
            tagIds: []
        };
    },
    created() {
    },
    methods: {
        openDialog(data) {
            // 打开弹出框，并触发父组件的事件传递参数 
            if (data && data.length > 0) {
                this.tagIds = data
            }
            this.dialogVisible = true;
            this.getList()
            // this.$emit('child-event', 'Hello from child');
        },
        getType(item) {
            let data = this.tagIds.find(i => i.tag_id == item.tag_id)
            if (data) {
                return 'success'
            } else {
                return 'info'
            }
        },
        refresh() {
            this.form.tag_name = ''
            this.getList()
        },
        setTags(item) {
            if (this.tagIds.includes(item)) {
                this.tagIds = this.tagIds.filter(tag => tag != item)
            } else {
                this.tagIds.push(item)
            }

        },
        closeDialog() {
            // 关闭弹出框  
            this.dialogVisible = false;
        },
        getList() {
            getTagList({ ...this.pageForm, ...this.form, tagids: [] }).then(res => {
                if (res.state == '200') {
                    this.data = res.data
                }
            })
        },
        handleClose() {
            this.tagIds = []
            this.dialogVisible = false
            this.form.tag_name = ''
        },
        save() {
            console.log(222);
            this.$emit('setTags', this.tagIds)
            this.handleClose()
        }

    }
};
</script>
<style scoped>
.container {
    display: flex;
    flex-direction: column;
}

.left {
    flex: 1;

}

.right {
    flex: 1;
}

.divider {
    border: 2px solid #f1f1f1;
    margin: 0 .125rem;
}

.title {
    margin: .125rem 0;
    font-size: small;
    font-weight: bold;
}

.top {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.footer {
    margin-top: .25rem;
    display: flex;
    justify-content: flex-end;
}
</style>
  