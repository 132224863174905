<template>
    <div class="platformContainer">
        <div class="systemSettingsNav">
            <el-menu :default-active="gg_defaultIndex" class="el-menu-demo" mode="horizontal" @select="gg_handleSelect">
                <el-menu-item index="gg_tag1">储值消费跨店记录</el-menu-item>
                <el-menu-item index="gg_tag2">项目消费跨店记录</el-menu-item>
                <el-menu-item index="gg_tag3">套餐消费跨店记录</el-menu-item>
                <el-menu-item index="gg_tag4">积分增加跨店记录</el-menu-item>
            </el-menu>
        </div>
        <div class="sf_main">
            <div class="sf_des">
                <el-descriptions class="margin-top" :column="5" size="mini" :labelStyle="label_style1" :colon="false">
                    <el-descriptions-item label="会员姓名" :span="1">
                        <el-input v-model="form1.vipName" placeholder="姓名搜索" :size="size"></el-input>
                    </el-descriptions-item>
                    <el-descriptions-item label="手机号" :span="1">
                        <el-input v-model="form1.telephone" placeholder="手机号搜索" :size="size"></el-input>
                    </el-descriptions-item>
                    <el-descriptions-item label="会员级别" :span="1">
                        <el-select v-model="form1.vipLevelName" placeholder="请选择" clearable :size="size">
                            <el-option v-for="(item, index) in vipLevelList" :key="index" :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-descriptions-item>
                    <el-descriptions-item label="消费类型" :span="1" v-if="gg_activeIndex == 'gg_tag1'">
                        <el-select v-model="form1.consumptionType" placeholder="请选择" clearable :size="size">
                            <el-option label="客户自费维保消费" :value="1"></el-option>
                            <el-option label="销售赠送维保消费" :value="2"></el-option>
                            <el-option label="售后赠送维保消费" :value="3"></el-option>
                            <el-option label="保险赠送维保消费" :value="4"></el-option>
                        </el-select>
                    </el-descriptions-item>
                    <el-descriptions-item label="车牌号码" :span="1">
                        <el-input v-model="form1.carNum" placeholder="车牌号搜索" :size="size"></el-input>
                    </el-descriptions-item>
                    <el-descriptions-item label="车架号" :span="1">
                        <el-input v-model="form1.carFrameNumber" placeholder="车架号搜索" :size="size"></el-input>
                    </el-descriptions-item>
                    <el-descriptions-item label="发卡店" :span="1">
                        <el-select v-model="form1.hairCard" placeholder="请选择" :size="size" clearable>
                            <el-option v-for="(item, index) in StoreList" :key="index" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-descriptions-item>
                    <el-descriptions-item label="操作部门" :span="1">
                        <el-select v-model="form1.operationDept" placeholder="请选择" :size="size">
                            <!-- <el-option v-for="item in operateDepartmentList" clearable :key="item.operateDepartment"
                                :label="item.label" :value="item.operateDepartment">
                            </el-option> -->
                        </el-select>
                    </el-descriptions-item>
                    <!-- <el-descriptions-item label="操作门店" :span="1">
                        <el-select v-model="form1.operationStore" placeholder="请选择" :size="size" clearable>
                            <el-option v-for="(item, index) in StoreList" :key="index" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-descriptions-item> -->
                    <el-descriptions-item label="操作员工" :span="1">
                        <el-select v-model="form1.operatingName" placeholder="请选择" :size="size" clearable>
                            <el-option v-for="item in userList" :key="item.id" :label="item.login" :value="item.login">
                            </el-option>
                        </el-select>
                    </el-descriptions-item>
                    <el-descriptions-item label="操作时间" :span="2">
                        <el-date-picker size="small" clearable v-model="storageTime" type="daterange" range-separator="至"
                            start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd">
                        </el-date-picker>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <div class="operateBtn">
                            <el-button type="primary" :size="size" @click="check">查询</el-button>
                            <el-button type="primary" :size="size" @click="exportData" v-if="isExport == 1">导出数据</el-button>
                        </div>
                    </el-descriptions-item>
                </el-descriptions>
            </div>
            <div class="sf_footer" v-if="gg_activeIndex === 'gg_tag1'">
                <div class="tableStyle">
                    <el-table :data="dataList" :stripe="true" size="mini" header-cell-class-name="headerClassName"
                        :cell-style="{ 'text-align': 'center' }" :border="true" :fit="true">
                        <el-table-column prop="id" label="编号" width="70">
                            <template slot-scope="scope">
                                <span>{{ scope.$index + 1 }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="vipName" label="客户名称" width="150"></el-table-column>
                        <el-table-column prop="telephone" label="手机号" width="150"></el-table-column>
                        <el-table-column prop="vipLevelName" label="会员级别" width="150"></el-table-column>
                        <el-table-column prop="hairCard" label="发卡店" width="150"></el-table-column>
                        <el-table-column prop="consumptionType" label="消费类型" width="200">
                            <template slot-scope="scope">
                                <span v-if="scope.row.consumptionType == 1">客户自费维保消费</span>
                                <span v-if="scope.row.consumptionType == 2">销售赠送维保消费</span>
                                <span v-if="scope.row.consumptionType == 3">售后赠送维保消费</span>
                                <span v-if="scope.row.consumptionType == 4">保险赠送维保消费</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="selfBalance" label="自费余额" width="100"></el-table-column>
                        <el-table-column prop="salesBalance" label="销售余额" width="100"></el-table-column>
                        <el-table-column prop="afterBalance" label="售后余额" width="100"></el-table-column>
                        <el-table-column prop="insuranceBalance" label="保险余额" width="100"></el-table-column>
                        <el-table-column prop="credit" label="可用积分" width="100"></el-table-column>
                        <el-table-column prop="carNum" label="车牌号" width="100"></el-table-column>
                        <el-table-column prop="carFrameNumber" label="车架号" width="200"></el-table-column>
                        <el-table-column prop="materialMoney" label="材料金额" width="100"></el-table-column>
                        <el-table-column prop="materialDiscount" label="材料折扣" width="100"></el-table-column>
                        <el-table-column prop="workHoursMoney" label="工时金额" width="100"></el-table-column>
                        <el-table-column prop="workHoursDiscount" label="工时折扣" width="100"></el-table-column>
                        <el-table-column prop="preferentialMoney" label="优惠金额" width="100"></el-table-column>
                        <el-table-column prop="creditDeduction" label="积分抵扣" width="100"></el-table-column>
                        <el-table-column prop="consumptionMoney" label="消费金额" width="100"></el-table-column>
                        <el-table-column prop="addCredit" label="增加积分" width="100"></el-table-column>
                        <el-table-column prop="serialNumber" label="流水单号" width="150"> </el-table-column>
                        <el-table-column prop="constructionNumber" label="施工单号" width="200"></el-table-column>
                        <el-table-column prop="maintenanceTime" label="维修时间" width="150"></el-table-column>
                        <el-table-column prop="maintenanceType" label="维修类型名称" width="150"></el-table-column>
                        <el-table-column prop="maintenanceIntervalDays" label="保养间隔天数" width="150"></el-table-column>
                        <el-table-column prop="operationTime" label="操作时间" width="150"></el-table-column>
                        <el-table-column prop="operationName" label="操作人" width="100"></el-table-column>
                        <el-table-column prop="operationDept" label="操作部门" width="150"></el-table-column>
                        <el-table-column prop="operationStore" label="操作店" width="150"></el-table-column>
                        <el-table-column prop="remarks" label="备注"></el-table-column>
                    </el-table>
                    <div class="sf_page">
                        <el-pagination background layout="prev, pager, next" :total="pageData.total"
                            :page-size="pageData.pageSize" @current-change="_getCrossStoreConsumptionRecordPage"
                            :current-page.sync="pageData.page">
                        </el-pagination>
                    </div>
                </div>
            </div>
            <div class="sf_footer" v-if="gg_activeIndex === 'gg_tag2'">
                <div class="tableStyle">
                    <el-table :data="dataList" :stripe="true" size="mini" header-cell-class-name="headerClassName"
                        :cell-style="{ 'text-align': 'center' }" :border="true" :fit="true">
                        <el-table-column prop="id" label="编号" width="70">
                            <template slot-scope="scope">
                                <span>{{ scope.$index + 1 }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="vipName" label="客户名称" width="150"></el-table-column>
                        <el-table-column prop="telephone" label="手机号" width="150"></el-table-column>
                        <el-table-column prop="vipLevelName" label="会员级别" width="150"></el-table-column>
                        <el-table-column prop="carNum" label="车牌号" width="100"></el-table-column>
                        <el-table-column prop="carFrameNumber" label="车架号" width="200"></el-table-column>
                        <el-table-column prop="hairCard" label="发卡店" width="150"></el-table-column>
                        <el-table-column prop="donationName" label="项目名称" width="200"></el-table-column>
                        <el-table-column prop="consumptionNum" label="消费次数" width="150"></el-table-column>
                        <el-table-column prop="residueNum" label="剩余次数" width="150"></el-table-column>
                        <el-table-column prop="termTime" label="有效期" width="150"></el-table-column>
                        <el-table-column prop="operationName" label="操作人" width="100"></el-table-column>
                        <el-table-column prop="operationDept" label="操作部门" width="150"></el-table-column>
                        <el-table-column prop="operationCard" label="操作店" width="150"></el-table-column>
                        <el-table-column prop="operationTime" label="操作时间" width="150"></el-table-column>
                        <el-table-column prop="remarks" label="备注"></el-table-column>
                    </el-table>
                    <div class="sf_page">
                        <el-pagination background layout="prev, pager, next" :total="pageData.total"
                            :page-size="pageData.pageSize" @current-change="_getCrossStoreConsumptionDonationRecord"
                            :current-page.sync="pageData.page">
                        </el-pagination>
                    </div>
                </div>
            </div>
            <div class="sf_footer" v-if="gg_activeIndex === 'gg_tag3'">
                <div class="tableStyle">
                    <el-table :data="dataList" :stripe="true" size="mini" header-cell-class-name="headerClassName"
                        :cell-style="{ 'text-align': 'center' }" :border="true" :fit="true">
                        <el-table-column prop="id" label="编号" width="70">
                            <template slot-scope="scope">
                                <span>{{ scope.$index + 1 }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="vipName" label="客户名称" width="150"></el-table-column>
                        <el-table-column prop="telephone" label="手机号" width="150"></el-table-column>
                        <el-table-column prop="vipLevelName" label="会员级别" width="150"></el-table-column>
                        <el-table-column prop="carNum" label="车牌号" width="100"></el-table-column>
                        <el-table-column prop="carFrameNumber" label="车架号" width="200"></el-table-column>
                        <el-table-column prop="hairCard" label="发卡店" width="150"></el-table-column>
                        <el-table-column prop="packageName" label="套餐名称" width="200"></el-table-column>
                        <el-table-column prop="itemName" label="项目名称" width="200"></el-table-column>
                        <el-table-column prop="itemPrice" label="项目单价" width="150"></el-table-column>
                        <el-table-column prop="termTime" label="有效期" width="150"></el-table-column>
                        <el-table-column prop="consumptionNum" label="消费次数" width="150"></el-table-column>
                        <el-table-column prop="residueNum" label="剩余次数" width="150"></el-table-column>
                        <el-table-column prop="operationName" label="操作人" width="100"></el-table-column>
                        <el-table-column prop="operationDept" label="操作部门" width="150"></el-table-column>
                        <el-table-column prop="operationCard" label="操作店" width="150"></el-table-column>
                        <el-table-column prop="operationTime" label="操作时间" width="150"></el-table-column>
                        <el-table-column prop="remarks" label="备注"></el-table-column>
                    </el-table>
                    <div class="sf_page">
                        <el-pagination background layout="prev, pager, next" :total="pageData.total"
                            :page-size="pageData.pageSize" @current-change="_getCrossStoreConsumptionPackageRecord"
                            :current-page.sync="pageData.page">
                        </el-pagination>
                    </div>
                </div>
            </div>
            <div class="sf_footer" v-if="gg_activeIndex === 'gg_tag4'">
                <div class="tableStyle">
                    <el-table :data="dataList" :stripe="true" size="mini" header-cell-class-name="headerClassName"
                        :cell-style="{ 'text-align': 'center' }" :border="true" :fit="true">
                        <el-table-column prop="id" label="编号" width="70">
                            <template slot-scope="scope">
                                <span>{{ scope.$index + 1 }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="vipName" label="客户名称" width="150"></el-table-column>
                        <el-table-column prop="telephone" label="手机号" width="150"></el-table-column>
                        <el-table-column prop="vipLevelName" label="会员级别" width="150"></el-table-column>
                        <el-table-column prop="carNum" label="车牌号" width="100"></el-table-column>
                        <el-table-column prop="carFrameNumber" label="车架号" width="200"></el-table-column>
                        <el-table-column prop="hairCard" label="发卡店" width="150"></el-table-column>
                        <el-table-column prop="ruleName" label="规则名称" width="150"></el-table-column>
                        <el-table-column prop="rule" label="积分规则" width="150">
                            <template slot-scope="scope">
                                <span v-if="scope.row.rule == '1'">按比例</span>
                                <span v-if="scope.row.rule == '2'">按金额</span>
                                <span v-if="scope.row.rule == '3'">按次数</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="money" label="金额" width="150"></el-table-column>
                        <el-table-column prop="credit" label="积分" width="150"></el-table-column>
                        <el-table-column prop="num" label="积分/次" width="150"></el-table-column>
                        <el-table-column prop="associationOperation" label="是否关联操作" width="150">
                            <template slot-scope="scope">
                                <span v-if="scope.row.associationOperation == '1'">是</span>
                                <span v-if="scope.row.associationOperation == '0'">否</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="multiple" label="积分倍数" width="150"></el-table-column>
                        <el-table-column prop="isAccumulate" label="是否累计" width="150">
                            <template slot-scope="scope">
                                <span v-if="scope.row.isAccumulate == '1'">是</span>
                                <span v-if="scope.row.isAccumulate == '0'">否</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="consumptionMoney" label="消费金额" width="150"></el-table-column>
                        <el-table-column prop="availableCredit" label="可用积分" width="150"></el-table-column>
                        <el-table-column prop="addCredit" label="增加积分" width="150"></el-table-column>
                        <el-table-column prop="operationName" label="操作人" width="100"></el-table-column>
                        <el-table-column prop="operationDept" label="操作部门" width="150"></el-table-column>
                        <el-table-column prop="operationCard" label="操作店" width="150"></el-table-column>
                        <el-table-column prop="operationTime" label="操作时间" width="150"></el-table-column>
                        <el-table-column prop="remarks" label="备注"></el-table-column>
                    </el-table>
                    <div class="sf_page">
                        <el-pagination background layout="prev, pager, next" :total="pageData.total"
                            :page-size="pageData.pageSize" @current-change="_getCrossStoreConsumptionCreditRecord"
                            :current-page.sync="pageData.page">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import * as api from '@/api/financialManage/selfFundedDetails'
import * as memberApi from '@/api/memberManage/index'
import { getStoredModel } from '@/api/memberDeposit';

export default {
    data() {
        return {
            gg_defaultIndex: 'gg_tag1',
            gg_activeIndex: 'gg_tag1',
            form1: {
                telephone: "", //手机号
                vipLevelName: "", //会员卡名称
                vipName: "", //会员姓名
                hairCard: "", //发卡店
                consumptionType: '', //消费类型（1.客户自费维保消费2.销售赠送维保消费3.售后赠送维保消费4.保险赠送维保消费）
                carNum: "", //车牌号
                carFrameNumber: "", //车架号
                operationStartTime: "", //操作开始时间
                operationEndTime: "", //结束时间
                operationName: "", //操作人
                operationDept: "", //操作部门
                operationStore: "", //操作店
            },
            pageData: {
                total: 0,
                pageSize: 10, //分页大小
                page: 1,//分页页码，数字类型
            },
            dataList: [],
            storageTime: '',
            vipLevelList: [],
            StoreList: [],
            userList: [],
            summaryData: {},
            Czlist: [],
            content_style: {
                'width': '15%',
            },
            size: 'small',
            label_style1: {
                'width': '1.3rem',
                'justify-content': 'end'
            },
            isExport: false

        }
    },
    // 使用
    mounted() {
        this.getCommn()
        this._getCrossStoreConsumptionRecordPage()
        this.isExport = sessionStorage.getItem('isExport')
    },
    methods: {
        resetForm() {
            this.form1 = {
                telephone: "", //手机号
                vipLevelName: "", //会员卡名称
                vipName: "", //会员姓名
                hairCard: "", //发卡店
                consumptionType: '', //消费类型（1.客户自费维保消费2.销售赠送维保消费3.售后赠送维保消费4.保险赠送维保消费）
                carNum: "", //车牌号
                carFrameNumber: "", //车架号
                operationStartTime: "", //操作开始时间
                operationEndTime: "", //结束时间
                operationName: "", //操作人
                operationDept: "", //操作部门
                operationStore: "", //操作店
            }
        },
        gg_handleSelect(key, keyPath) {
            this.gg_activeIndex = key
            this.pageData.page = 1
            this.resetForm()
            switch (key) {
                case 'gg_tag1':
                    this._getCrossStoreConsumptionRecordPage()
                    break;
                case 'gg_tag2':
                    this._getCrossStoreConsumptionDonationRecord()
                    break;
                case 'gg_tag3':
                    this._getCrossStoreConsumptionPackageRecord()
                    break;
                case 'gg_tag4':
                    this._getCrossStoreConsumptionCreditRecord()
                    break;
                default:
                    break;
            }
        },
        getCommn() {
            memberApi.clientVipLevelGetList({}).then(res => {
                let { data } = res
                this.vipLevelList = data
            })
            // memberApi.CarTypeGetList({}).then(res => {
            //     let { data } = res
            //     this.carList = data
            // })
            memberApi.storeGetList({}).then(res => {
                let { data } = res
                this.StoreList = data
            })
            getStoredModel({}).then(res => {
                this.Czlist = res.data
            })
            api.getUserList().then(res => {
                if (res.state == '200') {
                    this.userList = res.data
                }
            })
        },
        check() {
            this.pageData.page = 1
            if (this.gg_activeIndex == 'gg_tag1') {
                this._getCrossStoreConsumptionRecordPage()
            } else if (this.gg_activeIndex == 'gg_tag2') {
                this._getCrossStoreConsumptionDonationRecord()
            } else if (this.gg_activeIndex == 'gg_tag3') {
                this._getCrossStoreConsumptionPackageRecord()
            } else if (this.gg_activeIndex == 'gg_tag4') {
                this._getCrossStoreConsumptionCreditRecord()
            }
        },
        _getCrossStoreConsumptionRecordPage() {
            if (this.storageTime && this.storageTime.length > 0) {
                this.form1.operationStartTime = this.storageTime[0]
                this.form1.operationEndTime = this.storageTime[1]
            } else {
                this.form1.operationStartTime = ''
                this.form1.operationEndTime = ''
            }
            api.getCrossStoreConsumptionRecordPage({ ...this.form1, page: this.pageData.page, pageSize: this.pageData.pageSize }).then(res => {
                if (res.state == '200') {
                    this.dataList = res.data.records
                    this.pageData.total = res.data.total
                }
            })
        },
        _getCrossStoreConsumptionDonationRecord() {
            if (this.storageTime && this.storageTime.length > 0) {
                this.form1.operationStartTime = this.storageTime[0]
                this.form1.operationEndTime = this.storageTime[1]
            } else {
                this.form1.operationStartTime = ''
                this.form1.operationEndTime = ''
            }
            api.getCrossStoreConsumptionDonationRecord({ ...this.form1, page: this.pageData.page, pageSize: this.pageData.pageSize }).then(res => {
                if (res.state == '200') {
                    this.dataList = res.data.records
                    this.pageData.total = res.data.total
                }
            })
        },
        _getCrossStoreConsumptionPackageRecord() {
            if (this.storageTime && this.storageTime.length > 0) {
                this.form1.operationStartTime = this.storageTime[0]
                this.form1.operationEndTime = this.storageTime[1]
            } else {
                this.form1.operationStartTime = ''
                this.form1.operationEndTime = ''
            }
            api.getCrossStoreConsumptionPackageRecord({ ...this.form1, page: this.pageData.page, pageSize: this.pageData.pageSize }).then(res => {
                if (res.state == '200') {
                    this.dataList = res.data.records
                    this.pageData.total = res.data.total
                }
            })
        },
        _getCrossStoreConsumptionCreditRecord() {
            if (this.storageTime && this.storageTime.length > 0) {
                this.form1.operationStartTime = this.storageTime[0]
                this.form1.operationEndTime = this.storageTime[1]
            } else {
                this.form1.operationStartTime = ''
                this.form1.operationEndTime = ''
            }
            api.getCrossStoreConsumptionCreditRecord({ ...this.form1, page: this.pageData.page, pageSize: this.pageData.pageSize }).then(res => {
                if (res.state == '200') {
                    this.dataList = res.data.records
                    this.pageData.total = res.data.total
                }
            })
        },

        exportData() {
            let exportUrl
            let fileName
            switch (this.gg_activeIndex) {
                case 'gg_tag1':
                    exportUrl = api.exportCrossStoreConsumptionRecord
                    fileName = '储值消费跨店记录'
                    break;
                case 'gg_tag2':
                    exportUrl = api.exportCrossStoreConsumptionDonationRecord
                    fileName = '项目消费跨店记录'
                    break;
                case 'gg_tag3':
                    exportUrl = api.exportCrossStoreConsumptionPackageRecord
                    fileName = '套餐消费跨店记录'
                    break;
                case 'gg_tag4':
                    exportUrl = api.exportCrossStoreConsumptionCreditRecord
                    fileName = '积分增加跨店记录'
                    break;
                default:
                    break;
            }

            exportUrl({ ...this.form1, page: this.pageData.page, pageSize: this.pageData.pageSize }).then(res => {
                let blob = new Blob([res], {
                    // type: 'application/actet-stream;charset=utf-8'
                    type: 'application/vnd.ms-excel'
                }); // 构造一个blob对象来处理数据，并设置文件类型
                let a = document.createElement('a'); // 生成一个a标签
                let href = window.URL.createObjectURL(blob); //创建下载的链接
                a.href = href; //指定下载链接
                a.download = fileName //指定下载文件名
                // a.setAttribute('download', 'fileName')
                document.body.appendChild(a); //把a标签加到页面中
                a.click(); //触发a标签下载
                document.body.removeChild(a); //下载完成移除元素
                window.URL.revokeObjectURL(href); //释放掉blob对象
            })
        }
    },
}
</script>
<style scoped>
.platformContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.sf_main {
    flex: 1;
    background-color: rgb(247, 248, 250);
    padding-top: .25rem;
    display: flex;
    flex-direction: column;
}

.sf_des {
    background-color: white;
    border: 1px solid rgb(235, 235, 235);
    padding: .25rem;
    border-radius: .125rem;
}


.sf_des .el-descriptions--mini:not(.is-bordered) .el-descriptions-item__cell {
    padding-right: .25rem;
}

.sf_footer {
    flex: 1;
    margin-top: .125rem;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: .125rem;
}

.sf_footer .tableStyle {
    width: 21rem !important;
    flex: 1;
    overflow-x: auto;
}

.sf_footer .show_data {
    width: 100%;
    height: 1.125rem
}

.sf_footer .summaryInfo_Bootom {
    padding: 0 .5rem;
    font-size: large;
    font-weight: bold;
    color: rgb(92, 165, 243)
}

.sf_footer .sf_page {
    display: flex;
    justify-content: flex-end;
}
</style>