import request from '@/utils/request'
// import axios from 'axios';

// 登录
export function login(data) {
    return request({
        url: '/user/login/pwd',
        method: 'post',
        data: data
    })
}
// 修改密码
export function changePwd(data) {
    return request({
        url: '/user/update/pwd',
        method: 'post',
        data: data
    })
}
// 退出登录
export function outLogin(data) {
    return request({
        url: '/user/logout',
        method: 'post',
        data: data
    })
}
// 用户管理员列表
export function userList(data) {
    return request({
        url: '/user/login/list',
        method: 'post',
        data: data
    })
}
// 添加管理员用户
export function saveUser(data) {
    return request({
        url: '/user/add/login',
        method: 'post',
        data: data
    })
}


// 删除管理员用户
export function delUser(data) {
    return request({
        url: '/user/login/delete',
        method: 'post',
        data: data
    })
}
// 修改管理员用户
export function updateUser(data) {
    return request({
        url: '/user/login/update',
        method: 'post',
        data: data
    })
}


// 类型
export function getType(data) {
    return request({
        url: '/role/listRoleType',
        method: 'post',
        data: data
    })
}

// 角色
export function getRole(data) {
    return request({
        url: '/role/type/listRole',
        method: 'post',
        data: data
    })
}

// 角色列表
export function getRoleList(data) {
    return request({
        url: '/role/list',
        method: 'post',
        data: data
    })
}
// 权限列表
export function getMenuList(data) {
    return request({
        url: '/role/list/menu',
        method: 'post',
        data: data
    })
}
// 添加角色
export function addRole(data) {
    return request({
        url: '/role/addRole/menu',
        method: 'post',
        data: data
    })
}
// 修改角色
export function updateRole(data) {
    return request({
        url: '/role/updateRole/menu',
        method: 'post',
        data: data
    })
}
// 删除角色
export function delRole(data) {
    return request({
        url: '/role/deleteRole/menu',
        method: 'post',
        data: data
    })
}

//根据角色id查询权限和类型  编辑
export function editRoleInfo(data) {
    return request({
        url: '/role/listRole/menu',
        method: 'post',
        data: data
    })
}
//查询门店列表
export function storeList(data) {
    return request({
        url: '/role/store/list',
        method: 'post',
        data: data
    })
}
//查询部门列表
export function departmentList(data) {
    return request({
        url: '/role/store/department/list',
        method: 'post',
        data: data
    })
}
//查询部门列表
export function listUserRoleMenu(data) {
    return request({
        url: '/role/listUserRoleMenu',
        method: 'post',
        data: data
    })
}

// 跨店消费安全认证
export function userAuth(data) {
    return request({
        url: '/user/login/pwd',
        method: 'post',
        data: data
    })
}



// 获取激活码信息
export function getActivationCode(data) {
    return request({
        url: '/sys/platformActivation/getActivationCode',
        method: 'post',
        data: data
    })
}

// 保存激活码信息
export function saveActivationCode(data) {
    return request({
        url: '/sys/platformActivation/saveActivationCode',
        method: 'post',
        data: data
    })
}