<template>
    <div>
        <el-dialog :visible.sync="dialogVisible" :modal="true" :append-to-body="true" :close-on-click-modal="false"
            :before-close="handleClose" width="30%">
            <div class="container">
                <el-form :inline="true" :model="form" class="demo-form-inline" :rules="rules">
                    <el-form-item label="分配员工">
                        <el-select v-model="form.userid" placeholder="选择分配员工">
                            <el-option v-for="(item, index) in memList" :label="item.nickname" :value="item.user_id"
                                :key="item.user_id"></el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="title" class="dialog-title">
                <span class="title">选择推送员工</span>
            </span>
            <span slot="footer" class="dialog-footer">
                <div slot="footer" class="dialog-footer">
                    <el-button size="small" @click="handleClose">取消</el-button>
                    <el-button size="small" type="primary" @click="save">确定</el-button>
                </div>
            </span>
        </el-dialog>
    </div>
</template>  
    
<script>
import { getStaffList } from '@/api/memberManage';


export default {
    data() {
        return {
            dialogVisible: false, // 控制弹出框的显示与隐藏  
            form: {
                userid: ''
            },
            id: 0,
            memList: [],
            rules: {
                userid: [{ required: true, message: '请选择分配员工', trigger: 'blur' }]
            }
        };
    },
    created() {
    },
    methods: {
        openDialog(id) {
            // 打开弹出框，并触发父组件的事件传递参数  
            this.dialogVisible = true;
            this.getList()
            // this.$emit('child-event', 'Hello from child');
        },
        handleClose() {
            this.dialogVisible = false;
        },
        getList() {
            getStaffList().then(res => {
                if (res.state == '200') {
                    this.memList = res.data
                }
            })
        },

        save() {
            this.$emit('setStaff', this.form.userid)
            this.dialogVisible = false
        }

    }
};
</script>
<style scoped>
.container {
    display: flex;
}

.left {
    flex: 1;

}

.right {
    flex: 1;
}

.divider {
    border: 2px solid #f1f1f1;
    margin: 0 .125rem;
}

.title {
    margin: .125rem 0;
}

.box {
    width: 100%;
    margin-top: .125rem;
}

.item {
    display: flex;
    justify-content: space-between;
    padding: .125rem;
    align-items: center;

}
</style>
  