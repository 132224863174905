<template>
	<div>
		<el-dialog custom-class="memberLevelDialog" :visible.sync="dialogVisible" :modal="true" :append-to-body="true"
			:close-on-click-modal="false" @close="closeDialog()">
			<el-form ref="form" :model="form" label-width="2rem" size="mini" :rules="rules">
				<div class="title">基础设置</div>
				<el-row :gutter="5">
					<el-col :span="11">
						<el-form-item label="会员卡名称" prop="name">
							<el-input v-model="form.name"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row :gutter="5">
					<el-col :span="11">
						<el-form-item label="会员卡级别" prop="level">
							<el-input-number v-model="form.level" :min="1"></el-input-number>
						</el-form-item>
					</el-col>
				</el-row>
				<!-- <el-row :gutter="5">
					<el-col :span="15">
						<el-form-item >
							<el-upload action="#" limit="1" list-type="picture-card" :auto-upload="false">
								<i slot="default" class="el-icon-plus"></i>
								<div slot="file" slot-scope="{file}">
									<img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
									<span class="el-upload-list__item-actions">
										<span v-if="!disabled" class="el-upload-list__item-delete"
											@click="handleRemove(file)">
											<i class="el-icon-delete"></i>
										</span>
									</span>
								</div>
							</el-upload>
						</el-form-item>
					</el-col>
					<el-col :span="9">
						<span class="remark">建议尺寸：50x50像素</span>
					</el-col>
				</el-row> -->
				<el-row :gutter="1">
					<el-col :span="11">
						<el-form-item label="工时折扣" prop="discountWorkFee">
							<el-input-number v-model="form.discountWorkFee" :min="1" :max="100" :step="5"></el-input-number>
						</el-form-item>
					</el-col>
					<el-col :span="11">
						<el-form-item>
							<span class="remark">默认100%是无折扣，例如：90%就是九折</span>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row :gutter="5">
					<el-col :span="11">
						<el-form-item label="材料折扣" prop="discountMaterialFee">
							<el-input-number v-model="form.discountMaterialFee" :min="1" :max="100"
								:step="5"></el-input-number>
						</el-form-item>
					</el-col>
				</el-row>
				<div class="title">升级设置</div>
				<el-row :gutter="5">
					<el-col :span="11">
						<el-form-item label="升级规则" prop="rule">
							<el-col :span="20">
								<el-select placeholder="请选择" v-model="form.rule">
									<el-option label="累计积分" value="1"></el-option>
									<el-option label="一次性充值" value="2"></el-option>
									<el-option label="累计消费" value="3"></el-option>
								</el-select>
								<el-input style="margin-top:.125rem;" v-model="form.upgradation"></el-input>
							</el-col>
							<el-col :span="4">
								<span>{{ form.rule === '1' ? '分' : '元' }}</span>
							</el-col>
						</el-form-item>
					</el-col>
					<el-col :span="13">
						<el-form-item>
							<span class="remark">累计积分、一次性充值、累计消费，3种升级规则方式</span>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row :gutter="5">
					<el-col :span="11">
						<el-form-item label="是否自动升级" prop="autoUpgrade">
							<el-radio-group v-model="form.autoUpgrade">
								<el-radio label="1">是</el-radio>
								<el-radio label="0">否</el-radio>
							</el-radio-group>
						</el-form-item>
					</el-col>
					<el-col :span="13">
						<el-form-item>
							<span class="remark">默认不自动升级，开启后，达到升级规则，就自动升级</span>
						</el-form-item>
					</el-col>
				</el-row>
				<div class="title">升级设置</div>
				<el-row :gutter="5">
					<el-col :span="11">
						<el-form-item label="等待有效期" prop="term">
							<el-col :span="20">
								<el-input v-model="form.term"></el-input>
							</el-col>
							<el-col :span="4">
								<span>天</span>
							</el-col>
						</el-form-item>
					</el-col>
					<el-col :span="13">
						<el-form-item>
							<span class="remark">升级成为该等级后多少天自动变回默认等级，0表示长期</span>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row :gutter="5">
					<el-col :span="24">
						<el-form-item label="特权说明" prop="remark">
							<quill-editor class="editor" v-model="form.remark" ref="customQuillEditor"
								:options="editorOption">
							</quill-editor>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<span slot="title" class="dialog-title">
				<span>添加会员卡等级</span>
			</span>
			<span slot="footer" class="dialog-footer">
				<div slot="footer" class="dialog-footer">
					<el-button size="small" type="primary" @click="save()">提交</el-button>
				</div>
			</span>

		</el-dialog>
	</div>
</template>

<script>
//引入以及工具栏配置
import {
	quillEditor
} from "vue-quill-editor";

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { addVip, updateVip } from "@/api/systemOption/systemSettings";


// 工具栏配置

const toolbarOptions = [
	['bold', 'italic'], // 加粗 斜体 下划线 删除线
	[{
		list: 'ordered'
	}, {
		list: 'bullet'
	}], // 有序、无序列表
	[{
		size: ['small', false, 'large', 'huge']
	}], // 字体大小
	[{
		header: [1, 2, 3, 4, 5, 6, false]
	}], // 标题
	[{
		align: []
	}], // 对齐方式
	['clean'], // 清除文本格式
]
export default {
	components: {
		quillEditor
	},
	props: {
		// initialMessage: {
		// 	type: Object,
		// 	default: {},

		// }
	},
	data() {
		return {
			dialogVisible: false, // 控制弹出框的显示与隐藏  
			message: this.initialMessage, // 初始化时接收父组件传递的参数 
			form: {
				name: '',
				level: 1,
				// imageUrl: '',
				discountWorkFee: 100,
				discountMaterialFee: 100,
				rule: '1',
				upgradation: 1,
				autoUpgrade: '1',
				term: 1,
				remark: ''
			},
			radio: '1',
			value: '',
			rules: {
				name: [{ required: true, message: '请输入会员卡名称', trigger: 'blur' }],
				level: [{ required: true, message: '请输入会员卡级别', trigger: 'blur' }],
				discountWorkFee: [{ required: true, message: '请输入工时折扣', trigger: 'blur' }],
				discountMaterialFee: [{ required: true, message: '请输入材料折扣', trigger: 'blur' }],
				rule: [{ required: true, message: '请输入升级规则', trigger: 'blur' }],
				upgradation: [{ required: true, message: '请输入升级规则', trigger: 'blur' }],
				autoUpgrade: [{ required: true, message: '请选择是否自动升级', trigger: 'blur' }],
				term: [{ required: true, message: '请输入等待有效期', trigger: 'blur' }],
			},
			editorOption: {
				modules: {
					toolbar: {
						container: toolbarOptions, //工具栏相关配置
					},
					placeholder: '请输入...', //提示
				},
			}
		};
	},

	methods: {
		resetForm() {
			this.form = {
				name: '',
				level: 1,
				// imageUrl: '',
				discountWorkFee: 100,
				discountMaterialFee: 100,
				rule: '1',
				upgradation: 1,
				autoUpgrade: '1',
				term: 1,
				remark: ''
			}
		},
		openDialog(data) {
			// 打开弹出框，并触发父组件的事件传递参数  
			if (data) {
				this.form = data
				this.form.rule = data.rule ? data.rule + '' : ''
			} else {
				this.resetForm()
			}
			this.dialogVisible = true;
			// this.$emit('child-event', 'Hello from child');
		},
		closeDialog() {
			// 关闭弹出框  
			this.dialogVisible = false;
			// this.$refs.form?.resetFields()
		},
		save() {
			this.$refs.form.validate(valid => {
				if (valid) {
					if (this.form.id) {
						updateVip(this.form).then(res => {
							if (res.state === '200') {
								this.dialogVisible = false;
								this.$message({
									message: '修改成功！',
									type: 'success'
								});
								this.$emit('child-event');
							}
						})
					} else {
						addVip(this.form).then(res => {
							if (res.state === '200') {
								this.dialogVisible = false;
								this.$message({
									message: '添加成功！',
									type: 'success'
								});
								this.$emit('child-event');
							}
						})
					}
				}
			})
		},
		handleRemove(file) {

		},
	},
	watch: {
		initialMessage(newValue) {
			// 监听父组件传递的参数变化，并更新子组件的数据  
			this.message = newValue;
		}
	}
};
</script>
<style scoped>
::v-deep .quill-editor.editor {
	height: 2rem !important;
}

.fenAndYuan {
	font-size: .225rem;
	font-weight: bold;
	color: rgb(79, 79, 79);
}

.remark {
	font-size: .15rem;
	color: rgb(156, 156, 156);
}

::v-deep .el-dialog.memberLevelDialog {
	margin-top: 8vh !important;
}

.title {
	width: 100%;
	height: .375rem;
	line-height: .375rem;
	font-size: .175rem;
	background-color: rgb(245, 245, 245);
	color: black;
	margin: .125rem 0;
	font-weight: bold;

}
</style>