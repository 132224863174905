<template>
    <div class="memberInfoContainer">
        <div class="aside">
            <div class="user_info">
                <div class="user_image">
                    <el-avatar :size="50" :src="memberInfo1.headimgurl"></el-avatar>
                </div>
                <div class="user_detail_info">
                    <div class="user user_name">
                        <span>{{ memberInfo.wechatName || '默认昵称' }}</span>
                        <el-tag type="warning" effect="plain" size="mini"> {{ memberInfo.vipLevelName || '普通用户'
                        }}</el-tag>
                    </div>
                    <div class="user user_phone">
                        <i class="el-icon-phone">{{ memberInfo.telephone }}</i>
                    </div>
                    <div class="user user_time">
                        <span>注册时间：{{ memberInfo.createTime }}</span>
                    </div>
                </div>
            </div>
            <div class="aside_content">
                <div class="own_info">
                    <div class="own_info_tag">
                        <span>账户余额</span>
                        <span class="own_info_number">{{ memberInfo.selfBalance }}</span>
                    </div>
                    <div class="own_info_tag">
                        <span>可用积分</span>
                        <span class="own_info_number">{{ memberInfo.credit }}</span>
                    </div>
                    <div class="own_info_tag">
                        <span>持有套餐</span>
                        <span class="own_info_number">{{ memberInfo.holdSetmeal }}张</span>
                    </div>

                </div>
                <div class="record_info">
                    <div class="record_title">
                        交易记录（{{ memberInfo.operationName ? memberInfo.operationName : '当前操作员' }}）
                    </div>
                    <div class="record_content" v-if="recordList.length > 0">
                        <div class="record" v-for="(item, index) in recordList" :key="index">
                            <span class="record_value">【{{ item.types }}】</span>
                            <div class="record_time">
                                <span>充值{{ item.money }}元</span>
                                <span>{{ item.time }}</span>
                            </div>
                        </div>
                    </div>
                    <div v-if="recordList.length == 0" style="width:100%;">
                        <el-empty description="搜索查看记录哦~"></el-empty>
                    </div>
                </div>
            </div>
        </div>
        <div class="main">
            <el-menu :default-active="mi_defaultIndex" class="el-menu-demo" mode="horizontal" @select="mi_handleSelect">
                <el-menu-item index="mi_tag1">会员过户</el-menu-item>
                <el-menu-item index="mi_tag2">过户记录</el-menu-item>
            </el-menu>
            <div class="memberInfoMain" v-if="mi_activeIndex === 'mi_tag1'">
                <div class="mu_search">
                    <el-form ref="form" :model="form" label-width="1.25rem" size="small">
                        <el-form-item label="手机号：">
                            <el-input ref="teleRef" v-model="telephone" placeholder="输入手机号查询"></el-input>
                        </el-form-item>
                    </el-form>
                    <el-button type="primary" size="small" @click="searchMember"
                        style="margin-left: .125rem;">搜索</el-button>
                </div>
                <div class="mu_table">
                    <div class="memberInfo">
                        <div class="mu_borderStyle">
                            <el-descriptions :column="2" size="mini" border :labelStyle="label_style">
                                <el-descriptions-item :span="1" label="微信昵称">
                                    <span class="dg">{{ memberInfo1.wechatName ? memberInfo1.wechatName : '' }}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :span="1" label="手机号">
                                    <span class="dg"> {{ memberInfo1.telephone }}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :span="1" label="客户姓名">
                                    <span class="dg">{{ memberInfo1.name }}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :span="1" label="身份证号">
                                    <span class="dg">{{ memberInfo1.idCard }}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :span="1" label="会员生日">
                                    <el-date-picker class="dg" type="date" disabled placeholder="选择日期" :size="size"
                                        v-model="memberInfo1.birthday" style="width: 100%;"></el-date-picker>
                                </el-descriptions-item>
                                <el-descriptions-item :span="1" label="介绍人">
                                    <span class="dg"> {{ memberInfo1.sellerId }}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :span="1" label="车牌号码">
                                    <template slot="label">
                                        <span style="color: red;">*</span>车牌号码
                                    </template>
                                    <el-select class="dg" v-model="carNum" placeholder="请选择" :size="size"
                                        @change="carChange" clearable>
                                        <el-option v-for="(item, index) in memberInfo1.clientManageCarDOList" :key="index"
                                            :label="item.carNum" :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-descriptions-item>
                                <el-descriptions-item :span="1" label="车辆类型">
                                    <span class="dg">{{ carTypeName }}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :span="1" label="车架号码">
                                    <span class="dg">{{ carFrameNumber }}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :span="1" label="发动机号">
                                    <span class="dg">{{ carEngineNum }}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :span="1" label="保险公司">
                                    <span class="dg">{{ insurance }}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :span="1" label="上牌日期">
                                    <span class="dg">{{ carRegistrationTime }}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :span="1" label="入保时间">
                                    <el-date-picker class="dg" type="date" placeholder="选择日期" disabled :size="size"
                                        v-model="startTime" style="width: 100%;"></el-date-picker>
                                </el-descriptions-item>
                                <el-descriptions-item :span="1" label="到期日期">
                                    <el-date-picker class="dg" type="date" placeholder="选择日期" disabled :size="size"
                                        v-model="endTime" style="width: 100%;"></el-date-picker>
                                </el-descriptions-item>
                                <el-descriptions-item :span="2" label="会员级别">
                                    <span class="dg"> {{ memberInfo1.vipLevelName }}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :span="1" label="客户标签">
                                    <span class="dg">{{ memberInfo1.userLabelList ? getLabel(memberInfo1.userLabelList) : ''
                                    }}</span>
                                    <!-- <span class="dg">{{ memberInfo1.userLabel }}</span> -->
                                </el-descriptions-item>
                                <el-descriptions-item :span="1" label="发卡店">
                                    <span class="dg">{{ memberInfo1.storeName }}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :span="1" label="入会日期">
                                    <el-date-picker class="dg" type="date" placeholder="选择日期" disabled :size="size"
                                        v-model="memberInfo1.joiningTime" style="width: 100%;"></el-date-picker>
                                </el-descriptions-item>
                                <el-descriptions-item :span="1" label="截止日期">
                                    <el-date-picker class="dg" type="date" placeholder="选择日期" disabled :size="size"
                                        v-model="memberInfo1.cutoffTime" style="width: 100%;"></el-date-picker>
                                </el-descriptions-item>
                                <el-descriptions-item label="车辆备注" :span="2">
                                    <span class="dg">{{ memberInfo1.remarks }}</span>
                                </el-descriptions-item>
                            </el-descriptions>
                        </div>
                        <div class="remind">
                            <div class="qrCode">
                                <el-image class="qrCodeStyle" :src="url" fit="cover"></el-image>
                                <el-button :disabled="Object.keys(memberInfo1).length === 0 || isDj" type="primary"
                                    @click="setqrCode">生成二维码</el-button>
                            </div>
                            <div class="caution">
                                <span>注：</span>
                                <span>1.点击"生成二维码”，客户用微信扫码，填写最新资料，提交完成过户。</span>
                                <span>2. 一旦转移无法恢复，建议给新客户从新创建账号。</span>
                                <span>3.二维码有效时间5分钟，5分钟过后从新生成新二维码。</span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="memberInfoMain" v-if="mi_activeIndex === 'mi_tag2'">
                <div class="check">
                    <el-form ref="form" :model="form" label-width="1.5rem" size="small">
                        <el-row :gutter="20">
                            <el-col :span="6">
                                <el-form-item label="原有手机号：">
                                    <el-input ref="teleRef" v-model="form2.oldPhone" placeholder="输入原有手机号" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="新手机号：">
                                    <el-input v-model="form2.newPhone" placeholder="输入新手机号" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="过户日期：">
                                    <div class="block">
                                        <el-date-picker value-format="yyyy-MM-dd" v-model="time" type="daterange"
                                            range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                                        </el-date-picker>
                                    </div>
                                </el-form-item>
                            </el-col>
                            <el-col :span="4">
                                <el-button type="primary" size="small" @click="submit">查询</el-button>
                            </el-col>
                        </el-row>
                    </el-form>
                </div>

                <div class="mt_tableStyle">
                    <el-table :data="pointsSettingsTableData" :stripe="true" size="mini"
                        header-cell-class-name="headerClassName" :cell-style="{ 'text-align': 'center' }" :border="true"
                        :fit="true">
                        <el-table-column prop="id" label="id">
                            <template slot-scope="scope">
                                <span>{{ scope.$index + 1 }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="transferTime" label="过户日期">
                        </el-table-column>
                        <el-table-column prop="oldName" label="原有姓名">
                        </el-table-column>
                        <el-table-column prop="oldTelephone" label="原有手机号">
                        </el-table-column>
                        <el-table-column prop="newName" label="新姓名">
                        </el-table-column>
                        <el-table-column prop="newTelephone" label="新手机号">
                        </el-table-column>
                    </el-table>
                </div>
                <div class="sf_page">
                    <el-pagination background layout="prev, pager, next" :page-size="pageData.pageSize"
                        :current-page.sync="pageData.page" @current-change="getMemTransferList" :total="pageData.total">
                    </el-pagination>
                </div>
            </div>
        </div>
        <AddCarModal ref="addCarModal" @child-event="handleChildEvent" />
    </div>
</template>
<script>
import AddCarModal from '../memberManageModal/AddCarModal.vue'
import * as memberApi from '@/api/memberManage/index'
import { QR_URL, XS_TYPE } from '../../../utils/commom'
export default {
    data() {
        return {
            url: '',
            mi_defaultIndex: 'mi_tag1',
            mi_activeIndex: 'mi_tag1',
            form: {},
            addCarMessage: '',
            content_style: {
                'width': '3.75rem'
            },
            label_style: {
                'width': '1.3rem',
                'justify-content': 'end'
            },
            size: 'small',
            telephone: '',
            memberInfo: {},
            memberInfo1: {},
            carTypeName: '',
            carNum: '',
            carFrameNumber: '',
            carEngineNum: '',
            carRegistrationTime: '',
            pointsSettingsTableData: [],
            endTime: '',
            startTime: '',
            insurance: '',
            form2: {
                oldPhone: '',
                newPhone: '',
                startTime: '',
                endTime: ''
            },
            time: '',
            pageData: {
                page: 1,
                pageSize: 10,
                total: 0
            },
            recordList: [],
            isDj: false
        }
    },
    mounted() {
        this.$refs.teleRef.focus()
    },
    methods: {
        getLabel(list) {
            let label = []
            if (list.length > 0) {
                list.forEach(item => {
                    label.push(item.name)
                })
            }
            return label.join(",")
        },
        carChange(e) {
            this.memberInfo1.clientManageCarDOList.forEach(item => {
                if (item.id == e) {
                    this.carTypeName = item.model
                    this.carFrameNumber = item.carFrameNumber
                    this.carEngineNum = item.carEngineNum
                    this.carRegistrationTime = item.carRegistrationTime
                    this.endTime = item.endTime
                    this.startTime = item.startTime
                    this.insurance = item.insurance
                }
            })
        },
        mi_handleSelect(key, keyPath) {
            this.mi_activeIndex = key
            this.$nextTick(() => {
                this.$refs.teleRef.focus()
            })
            switch (key) {
                case 'mi_tag1':

                    break;
                case 'mi_tag2':
                    this.getMemTransferList()
                    break;

                default:
                    break;
            }
        },
        setqrCode() {
            memberApi.getQrCodeTemporary({ url: QR_URL + '?time=' + new Date().getTime() + '&&id=' + this.memberInfo1.id, type: XS_TYPE }).then(res => {
                var img = 'data:image/jpeg;base64,' + this.arrayBufferToBase64(res)
                // this.imgsrc = qrUrl;
                this.url = img
            })
        },
        arrayBufferToBase64(buffer) {
            var binary = ''
            var bytes = new Uint8Array(buffer)
            var len = bytes.byteLength
            for (var i = 0; i < len; i++) {
                binary += String.fromCharCode(bytes[i])
            }
            return window.btoa(binary)
        },
        resetForm2() {
            this.form2 = {
                oldPhone: '',
                newPhone: '',
                startTime: '',
                endTime: ''
            }
        },
        submit() {
            if (this.time && this.time != '') {
                this.form2.startTime = this.time[0]
                this.form2.endTime = this.time[1]
            } else {
                this.form2.startTime = ''
                this.form2.endTime = ''
            }
            this.getMemTransferList()
        },
        getMemTransferList() {
            memberApi.getTransferList(this.form2).then(res => {
                if (res.state == '200') {
                    this.pointsSettingsTableData = res.data.records
                    console.log(res.data.total);
                    this.pageData.total = res.data.total
                }
            })
        },
        handleChildEvent(childMessage) {
            // 处理子组件传递的事件和参数
            console.log('Received message from child:', childMessage)
            // 可以在这里执行其他逻辑或更新父组件的数据
        },

        openAddCarDialog() {
            this.$refs.addCarModal.openDialog()
        },
        //搜索会员信息
        searchMember() {
            memberApi.selectById({ telephone: this.telephone }).then(res => {
                let { data } = res
                this.memberInfo = data
            })
            memberApi.transferDetails({ telephone: this.telephone }).then(res => {
                let { data } = res
                if (data.actived == 4) {
                    this.$message.info('该会员已冻结')
                    this.isDj = true
                }
                this.memberInfo1 = data
                this.recordList = data.mainCzList
            })
        }
    },
    components: {
        AddCarModal
    }
}
</script>
<style scoped>
.user_image {
    width: 1.5rem;
    height: .75rem;
    display: flex;
    justify-content: center;
}

.user_name .el-tag {
    margin-left: .375rem !important;
}

.sf_page {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

::v-deep .el-date-editor .el-range-separator {
    width: 10% !important;
}

::v-deep .el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
    margin-bottom: 0rem !important;
}

.memberInfoContainer {
    width: 100%;
    height: 100%;
    display: flex;
    overflow: auto;

}

.aside {
    width: 4.375rem;
    display: flex;
    flex-direction: column;
    height: 100% !important;
    border-right: 1px solid rgba(212, 212, 212, 0.5);
    padding-bottom: .125rem;
}

.main {
    flex: 1;
    padding: .125rem .125rem 0 .125rem;
    display: flex;
    flex-direction: column;

}

.main .el-form-item__label {
    font-size: .2rem;
}

.aside_content {
    padding: 0 .125rem;
}

.user_info {
    width: 100%;
    display: flex;
    height: 1.75rem;
    align-items: center;

    border: none;
    border-bottom: 1px solid rgb(212, 212, 212);
    margin-bottom: .125rem;
    /* padding-top: .5rem; */
}

.own_info_tag {
    width: 100%;
    height: .875rem;
    background: rgb(231, 236, 255);
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: .125rem;
}

.own_info_tag span {
    display: inline-block;
}

.own_info_number {
    font-weight: bold;
    color: #ea8419;
}

.poi_title .el-divider__text.is-left {
    left: 0;
}

.poi_title .el-divider__text {
    font-weight: bold;
}



.memberInfoMain .el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
    margin-bottom: .125rem;
}

.save {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.record {
    margin-bottom: .125rem;
}

.record_info {
    width: 100%;
    flex: 1;
    height: 5rem;
    overflow: auto;
    border: 1px solid rgb(212, 212, 212);
    border-radius: .125rem !important;
    padding: .125rem;

}

.record_title {
    font-size: .175rem;
    border-bottom: 1px solid rgb(212, 212, 212);
    text-align: center;
    padding-bottom: .125rem;
    margin-bottom: .125rem;
}

.record_value {
    width: 100%;
    height: .3125rem;
    line-height: .3125rem;
    display: inline-block;
    background: #f2f2f2;
    font-size: .15rem;
}

.record_time {
    display: flex;
    justify-content: space-between;
}

.record_time span {
    display: inline-block;
    color: rgb(0, 79, 251);
    font-size: .1375rem;
}

.memberInfoMain {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: .25rem;

}

.mu_table {
    display: flex;
    flex-direction: column;
    border: 1px solid rgb(212, 212, 212);
    border-radius: .125rem;
    flex: 1;
}

.memberInfo {
    flex: 1;
    display: flex;

}

.mu_borderStyle {
    flex: 7;
    margin-right: .125rem;
    padding: .25rem;
}

.remind {
    flex: 4;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: .25rem;

}

.mu_search {
    border: 1px solid rgb(212, 212, 212);
    border-radius: .125rem;
    margin-bottom: .125rem;
    padding: .125rem;
    display: flex;
}

.qrCode {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: .125rem;

}

.qrCode .el-image {
    width: 2.75rem;
    height: 2.75rem;
    margin-bottom: .125rem;
}

.caution {
    width: 100%;
    padding: .125rem;
}

.caution span {
    display: block;
    color: red;
    margin-bottom: .125rem;
}

.check {
    /* border: 1px solid rgb(212, 212, 212); */
    border-radius: .125rem;
    margin-bottom: .125rem;
    padding: .125rem;
}

.mt_tableStyle {
    /* border: 1px solid rgb(212, 212, 212); */
    border-radius: .125rem;
    padding: .125rem;
    flex: 1;
}

/* .user_phone{
    margin-top: .125rem;
} */
</style>